var helpers = require('../../libs/helpers');

angular.module('koh').directive('bibleLessonSearch', [
    '$compile',
    '$rootScope',
    'Restangular',
    'dataService',
    function ($compile, $rootScope, Restangular, dataService) {

    var link = function ($scope, element, attrs) {
        var searchModels = {};
        searchModels.months = dataService.getMonths();
        searchModels.monthsTo = angular.copy(searchModels.months);
        searchModels.years = dataService.getHistoricalBibleLessonYears();
        searchModels.yearsTo = angular.copy(searchModels.years);
        dataService.getLessonSubjects().then(function(data) {
          searchModels.subjects = data;
        });
        $scope.searchModels = searchModels;

        $scope.master = {
            date: {
                startDate: 1,
                startMonth : 0,
                startYear: searchModels.years[0],
                endDate: 31,
                endMonth: searchModels.months.length-1,
                endYear: searchModels.years[searchModels.years.length-1]
            }
        };


        $scope.update = function(lesson) {
            lesson = lesson || {};
            var newLesson = angular.copy(lesson);
            newLesson.date = _.assign({}, $scope.master.date, lesson.date);
            searchLessons(newLesson);
        };

      $scope.dateVal = function (lesson) {
        var date = lesson.date;
        var eqYears = angular.equals(date.startYear, date.endYear);
        var eqUnd = angular.isUndefined(date.startYear);
        var model = $scope.searchModels;
        if(date.startYear > date.endYear){
          delete date.endYear;
        }
        if((eqYears && (!eqUnd)) && (date.startMonth > date.endMonth)){
          delete date.endMonth;
        }
        model.yearsTo = searchModels.years;
        if(!eqUnd){
          model.yearsTo = searchModels.years.slice(
            searchModels.years.indexOf(date.startYear), searchModels.years.length
          );}
        model.monthsTo = searchModels.months;
        if (eqYears && (!eqUnd)) {
          model.monthsTo = searchModels.months.slice(date.startMonth, searchModels.months.length);
        }
      }
    };

    var searchLessons = function(model){
        var query = {};

        query.dateStart = createDateString(model.date.startYear, model.date.startMonth, model.date.startDate);
        query.dateEnd   = createDateString(model.date.endYear, model.date.endMonth, model.date.endDate);

        if (model.subject) query.subject = model.subject;

        $rootScope.$state.go('lessonSearch', query);
    }

    var createDateString = function(y, m, d){
        var dateStr = y + '' + helpers.pad(m + 1, 2) + '' + helpers.pad(d, 2);
        return parseInt(dateStr);
    }

    return {
        transclude: true,
        templateUrl: './directives/pages/bible-lesson-search/bible-lesson-search.html',
        link: link
    };
}]);
