angular.module('koh').controller('ChapterCtrl', 
		['$scope', 
		'Restangular', 
		'$state',
		'$stateParams',
		'$rootScope',
		'$timeout',
		'$document',
		'$location',
		'$anchorScroll',
		'booksService',
		'selectionService',
		'helpers',
		function($scope, 
			Restangular,
			$state,
			$stateParams,
			$rootScope,
			$timeout,
			$document,
			$location,
			$anchorScroll,
			booksService,
			selectionService,
			helpers
			) {	
				$parentScope = $scope.$parent;
				$scope.booksService = booksService;
				$scope.booksService.init($scope, $stateParams, {});

				if($stateParams.initial && $parentScope.currentChapter){
					$rootScope.$state.go('reader.book.chapter', {chapter: $scope.currentChapter, initial: false});
					return;
				}

				if($stateParams.reference){
					//Chapter is set by reference, not file name
					if ($rootScope.readingData == undefined || $rootScope.readingData.chapterList == undefined){
					}
					var chapter = helpers.getFileFromReference($rootScope.readingData.chapterList, $stateParams.chapter);
					//console.log('Setting dirty links');
					$rootScope.documentLinks = undefined;
					$rootScope.dirtyLinks = true;
					if (chapter) $rootScope.$state.go('reader.book.chapter', {chapter : chapter, reference : false}, {location: 'replace'});
					else{
						;
						return;
					}
				}
				if ($stateParams.citation) {
					$rootScope.setActiveCitation($stateParams.citation);
				}
				if ($rootScope.activeCitation){
					$rootScope.$broadcast('invalidateRenderedPage');
				}


				//Use current chapter from url, or default to 'null'
				var currentChapter = $stateParams.chapter;
				var undashedChapter = currentChapter;
				if (currentChapter.length == 0){
					currentChapter = null;
				}

				// Get index from chapterList if possible
				if ($rootScope.readingData != null && currentChapter != null){
					var index = helpers.findChapterIndex($rootScope.readingData.chapterList, currentChapter);
					if (index > -1){
						$rootScope.$broadcast('pageChange', { chapter: currentChapter, currentIndex:  index});
						$parentScope.currentIndex = index;
					}
				} 

				$parentScope.currentChapter = currentChapter;
				$parentScope.currentBook = $rootScope.readingData.chapterList[$parentScope.currentIndex].book;
				$parentScope.currentFolder = $rootScope.readingData.chapterList[$parentScope.currentIndex].folder;
				$scope.booksService.syncRootScope();

				// Scroll to page if on continuous scroll
				var mode = $rootScope.readingMode,
						el = document.getElementById(currentChapter),
						page = el != null && el.childNodes.length > 0 ? el.childNodes[0] : el;
				if (mode == 'continuous'){
					helpers.setPageScroll(currentChapter, $stateParams.isScrolling);
				} else{
					window.scrollTo(0, 0);
				}
					

				var logVisit = function logVisit() {
					;
					if (_.isUndefined($rootScope.readingData)) return;
					if (_.isUndefined($rootScope.readingData.bookInfo)) return;
					
					
					return new Promise(function(resolve, reject) {
						var data = $rootScope.readingData;

						var type = data.bookInfo.bookType == 'bible_lessons' ? 'visitedLessons' : 'visitedPages';
						var title = data.chapterList[data.currentIndex].title;
						
						var findOP = function(ref) {
							for (var i = 0 ; i < data.chapterList.length ; i++) {
								if (data.chapterList[i].title==ref) {
									return data.chapterList[i].originalPageStart;
								}
							}
						}

						if (data.currentFolder == 'periodicals' || data.currentFolder == 'sentinels') {
							title = findOP(title);
						}
						var pageObj = {
							page: $location.url(), 
							book: $rootScope.readingData.bookInfo.bookTitle,
							type: type,
							title: title
						}
						
						Restangular.all('users').one($rootScope.user._id).post('visitpage', pageObj).then(function(data) {
								$rootScope.user[type] = data;
								resolve(data);
						});
				
					});
					
					
				};

				// Log page to history
				$rootScope.$on('bookLoaded', logVisit);
				logVisit();



		    /* On document click, release selection */
		    $document.bind('click', function(event){
		    	var closest = event.target.closest('.retain-selection');
		    	if(closest == null){
			    	selectionService.removeSelection();
			    }
		    });
		}
]);
