// polyfill Promise for IE
window.Promise = window.Promise || require('es6-promise-polyfill').Promise;
// Change this to whatever is appropriate
// angular is NOT required as a commonjs module because needed to be global for template cache
require('../../node_modules/angular-ui-router/release/angular-ui-router');
require('../../node_modules/ui-router-extras/release/modular/ct-ui-router-extras.core');
require('../../node_modules/ui-router-extras/release/modular/ct-ui-router-extras.dsr');
require('../../node_modules/ui-router-extras/release/modular/ct-ui-router-extras.sticky');
require('../../node_modules/restangular/dist/restangular');
require('../../node_modules/angular-bootstrap/ui-bootstrap')
require('../../node_modules/angular-bootstrap/ui-bootstrap-tpls')
require('../../node_modules/ng-sortable/dist/ng-sortable')
require('../../node_modules/angular-ckeditor/angular-ckeditor');
// States
require('./states/reader');
require('./states/account');
require('./states/users');
require('./states/promo');
require('./states/search');
require('./states/lessons');

// App definition

var isIE = /*@cc_on!@*/false || !!document.documentMode;

var myApp;
if (isIE) {
myApp = angular.module('koh', ['ui.router',
  'restangular',
  'ui.bootstrap',
  'ui.select',
  'koh.reader',
  'koh.promo',
  'koh.account',
  'koh.users',
  'koh.search',
  'koh.lessons',
  'koh-constants',
  'as.sortable',
  'ckeditor',
  'angular-carousel',
  'rzModule',
  'ngSanitize',
  'ui.bootstrap.tooltip',
  'ng-context-menu',
  'ct.ui.router.extras.core',
  'ct.ui.router.extras.dsr',
  'ct.ui.router.extras.sticky'
]); } else {
  myApp = angular.module('koh', ['ui.router',
  'restangular',
  'ui.bootstrap',
  'ui.select',
  'koh.reader',
  'koh.promo',
  'koh.account',
  'koh.users',
  'koh.search',
  'koh.lessons',
  'koh-constants',
  'as.sortable',
  'ckeditor',
  'angular-carousel',
  'rzModule',
  'hmTouchEvents',
  'ngSanitize',
  'ui.bootstrap.tooltip',
  'ng-context-menu',
  'ct.ui.router.extras.core',
  'ct.ui.router.extras.dsr',
  'ct.ui.router.extras.sticky'
]);
}
;
require('./libs/constants.js')

// Services
require('./libs/AuthenticationService');
require('./libs/ReaderService');
require('./libs/BooksService');
require('./libs/DataService');
require('./libs/PageLoaderService');
require('./libs/SearchService');
require('./libs/SelectionService');

// Controllers
require('./controllers/home/HomeCtrl');
require('./controllers/help/HelpCtrl');
require('./controllers/main/MainCtrl');
require('./controllers/users/UserCtrl');
require('./controllers/reader/ReaderCtrl');
require('./controllers/books/BooksCtrl');
require('./controllers/periodicals/PeriodicalsCtrl');
require('./controllers/sentinels/SentinelsCtrl');
require('./controllers/lessons/LessonsCtrl');
require('./controllers/books/ChapterCtrl');
require('./controllers/promo/PromoCtrl');
require('./controllers/signup/SignupCtrl');
require('./controllers/login/LoginCtrl');
require('./controllers/forgot/ForgotCtrl');
require('./controllers/profile/ProfileController');
require('./controllers/users/selections/SelectionsCtrl');
require('./controllers/search/SearchCtrl');
require('./controllers/define/DictionaryCtrl');
require('./controllers/lookup/LexiconCtrl');

// Directives
// These should match up with components in jade
require('./directives/reading-bar');
require('./directives/search-bar');
require('./directives/bible-lesson-index');
require('./directives/bible-lesson-search');
require('./directives/book-page');
require('./directives/checkout');
require('./directives/continue-reading-bar');
require('./directives/definition-modal');
require('./directives/featured-bible-lesson');
require('./directives/hero-carousel');
require('./directives/selection-editor');
require('./directives/pdf-download');
require('./directives/citation-organizer');
require('./directives/citation-viewer');
require('./directives/confirmation-box');
require('./directives/match-height');
require('./directives/multilist-dropdown');
require('./directives/save-document');
require('./directives/auto-hide-sticky-header');
require('./directives/note-markup');
require('./directives/link-markup');

// Helper directives
require('./directives/usstates');
require('./directives/resizable');
require('./directives/bind-compiled-html');
require('./directives/epub-loaded');
require('./directives/external-link');
require('./directives/citation-link');
require('./directives/video-player');
require('./directives/expand-thumbnail');
require('./directives/keep-scroll-pos');

// Injectable Modules
var helpers = function () { return require('./libs/helpers'); };

/*
isDebug = false; // toggle this to turn on / off for global controll

if (isDebug) debug = console.log.bind(window.console)
else debug = function(){}
*/

// App Config
myApp.config(function($stateProvider,
  $urlRouterProvider,
  $locationProvider,
  $httpProvider,
  $sceDelegateProvider,
  RestangularProvider,
  apiUrl
  ) {


  //initialize get if not there
  if (!$httpProvider.defaults.headers.get) {
      $httpProvider.defaults.headers.get = {};
  }
  //disable IE ajax request caching
  $httpProvider.defaults.headers.get['Cache-Control'] = 'no-cache';
  $httpProvider.defaults.headers.get['Pragma'] = 'no-cache';
  // $httpProvider.defaults.headers.get['If-Modified-Since'] = 'Mon, 26 Jul 1997 05:00:00 GMT';

  $httpProvider.defaults.cache = false;

  var inHelpers = require('./libs/helpers');
  ;

  // Ensure we're using credentials across the board on our posts
  $httpProvider.defaults.withCredentials = true;
  // This line allows for us to use variables in forms
  $sceDelegateProvider.resourceUrlWhitelist([apiUrl + '/api/*', 'self']);

  // End point we created
  RestangularProvider.setBaseUrl(apiUrl + '/api')

  // Set up default other route
  $urlRouterProvider.otherwise("/404");

  // Enable "no hash bang"
  $locationProvider.html5Mode(true);

  // Now set up the states
  // Many of the states are now in the states folder...
  $stateProvider
    .state('404', {
      url: "/404",
      templateUrl: '/controllers/main/404.html',
      params: {
      },
      resolve: {
        helpers: helpers,
        authenticate: function(kohauth) {
          return kohauth.headerAuthenticate();
        }
      }
    })
    .state('500', {
      url: "/500",
      templateUrl: '/controllers/main/500.html',
      params: {
      },
      resolve: {
        helpers: helpers,
        authenticate: function(kohauth) {
          return kohauth.headerAuthenticate();
        }
      }
    })
    .state('root', {
      url: "",
      dsr: true,
      abstract: true,
      template: "<div ng-show=\"$state.includes(\'search\')\" ui-view=\"root-search\" keep-scroll-pos=\"search\"></div>"+
                "<div ng-show=\"$state.includes(\'reader\')\" ui-view=\"root-reader\"></div>"
    })
    .state('guestHome', {
      url: "/",
      templateUrl: "/controllers/promo/guest-home.html",
      controller: 'PromoCtrl',
      params: {
      },
      resolve: {
        helpers: helpers,
        authenticate: function(kohauth) {
          return kohauth.homeAuthenticate();
        }
      }
    })
    .state('home', {
      url: "/home",
      templateUrl: "/controllers/home/home.html",
      controller: 'HomeCtrl',
      params: {
      },
      resolve: {
        helpers: helpers,
        authenticate: function(kohauth) {
          return kohauth.authenticate();
        }
      }
    })
    .state('help', {
      url: "/help",
      templateUrl: "/controllers/help/help.html",
      controller: 'HelpCtrl',
      params: {
      },
      resolve: {
        helpers: helpers,
        authenticate: function(kohauth) {
          return kohauth.headerAuthenticate();
        }
      }
    })
})
// Google Analytics
.run(['$rootScope', '$location', '$window', function($rootScope, $location, $window){
  $rootScope.$on('$stateChangeSuccess',
    function(event){

      if (!$window.ga)
          return;

      $window.ga('send', 'pageview', { page: $location.path() });
    })
}]);
