'use strict';
var _ = require('lodash');
var SEARCH_RESULT_PAGE_BY = 20

// TODO: find out how to get rid of this, then get rid of
// the extra angular.copy in the calling code
/**
 * Fixes discrepancy in how we encode 'all'.
 * For performance reasons, this actually *mutates* the filters,
 * then returns them. Our payloads are very large, so don't
 * want to add even more overhead.
 *
 */
var normalizeCollections = function(collections) {
  Object.keys(collections).forEach(function(collectionKey) {
    var collection = collections[collectionKey];
    if (collection.all) {
      collection.books = 'all';
    }
  });
  return collections;
};

/**
 * deep search for all objects in obj
 * having `value` for key `key`
 * moved from server/services/search
 * 
 * TODO: rewrite it to get rid of weird type checks and proto stuff
 *
 * @param      {<type>}  obj     The object
 * @param      {String}  key     The key
 * @param      {any}  value      The value
 * @param      {Array}   memo    (optional) holds values to be returned
 * @return     {Array}   { description_of_the_return_value }
 */
function deepSearch(obj, key, value, memo) {
  var i,
    proto = Object.prototype,
    ts = proto.toString,
    hasOwn = proto.hasOwnProperty.bind(obj);

  if ('[object Array]' !== ts.call(memo)) memo = [];

  for (i in obj) {
    if (hasOwn(i)) {
      if (i === key && obj[i] == value) {
        memo.push(obj);
      } else if ('[object Array]' === ts.call(obj[i]) || '[object Object]' === ts.call(obj[i])) {
        deepSearch(obj[i], key, value, memo);
      }
    }
  }
  return memo;
}


// used in next function
var PARENT_LIST_TO_PARENT_QUERY_MAPPING = {
  'The Bible': 'bible',
  'Mrs Eddy\'s Other Writings': 'book',
  'Science and Health': 'Science and Health',
  'Journals': 'journal',
  'Sentinels': 'sentinel'
}

/**
 * workaround to deal with gratuitious differences in how things are named.
 *
 * ['The Bible', 'Mrs Eddy\'s Other Writings']  //=> ['document_category:"bible"', 'document_category:"book"']
 *
 */
var parentListToParentQuery = function(parentList) {
  return _.chain(parentList)
          .map(function(parent) {
            return PARENT_LIST_TO_PARENT_QUERY_MAPPING[parent];
          })
          .uniq()
          .compact()
          .map(function(category) {
            return 'document_category:"' + category + '"';
          })
          .value();
};

/**
 * Convert from FE representation of collections
 * to something closer to what SOLR needs
 * (reduce search payload size [KOH-1613])
 * 
 * This code was moved *as-is* and adapted  slightly
 * from the back end: server/services/search.js
 * 
 * Given search model collections,
 * return an array of SOLR-style
 *
 * @param      {Array<Object>}   collections  The collections
 * @return     {Array<String>}   The collections filter queries.
 * 
 */
var slimCollections = function(rawCollections) {
  if (!rawCollections) {
  	return [];
  }
  var collections = normalizeCollections(rawCollections);

  var queries = []

  var titleQuery = '';

  var parentObjects = deepSearch(collections, 'all', true);
  var parentList = _.pluck(parentObjects, 'title');
  var searchedTitleObjects = deepSearch(collections, 'selected', true);
  var filterJournals = collections.journals;
  var filterSentinels = collections.sentinels;
  var parentQuery = parentListToParentQuery(parentList);

  _.each(searchedTitleObjects, function (item) {
    var searchField = 'parent_title_exact';
    var searchTerm = item.title;

    if (searchTerm == 'Miscellany') {
      searchTerm = "The First Church of Christ, Scientist, and Miscellany"
    }

    if (item.parentTitle == 'Science and Health') {
      titleQuery += '(path:"/Science%20and%20Health"%20AND%20toc_title:"' + encodeURIComponent(searchTerm) + '")%20';
    } else if(searchTerm === 'Sentinels' || searchTerm === 'Journals') {
      titleQuery += '';
    }
    else {
      titleQuery += '(' + searchField + ':"' + encodeURIComponent(searchTerm) + '")%20';
    }
  });
  var months = ['01','02','03','04','05','06','07','08','09','10','11','12'];
  var publicationQuery = '';
  if(filterJournals && filterJournals.selected){
    if(filterJournals.all && filterJournals.year && filterJournals.year === 'all') {
      if(_.isEmpty(filterJournals.months)) {
        filterJournals.months = months;
      }
      _.each(_.uniq(filterJournals.months), function (item) {
        publicationQuery += '(publication_date:' + '????-' + item + '*) ';
      });
    } else if(!filterJournals.all && filterJournals.year && filterJournals.year !== 'all') {
      if(_.isEmpty(filterJournals.months)) {
        filterJournals.months = months;
      }
      _.each(_.uniq(filterJournals.months), function (item) {
        publicationQuery += '(publication_date:' + filterJournals.year + '-' + item + '*) ';
      });
    }
    if (publicationQuery != '') {
      parentQuery.push('(document_category:"journal" AND (' + publicationQuery + '))');
    } else {
      parentQuery.push('document_category:"journal"');
    }
  }
  if(filterSentinels && filterSentinels.selected){
    publicationQuery = '';
    if(filterSentinels.all && filterSentinels.year && filterSentinels.year === 'all') {
      if(_.isEmpty(filterSentinels.months)) {
        filterSentinels.months = months;
      }
      _.each(_.uniq(filterSentinels.months), function (item) {
        publicationQuery += '(publication_date:' + '????-' + item + '*) ';
      });
    } else if(!filterSentinels.all && filterSentinels.year && filterSentinels.year !== 'all') {
      if(_.isEmpty(filterSentinels.months)) {
        filterSentinels.months = months;
      }
      _.each(_.uniq(filterSentinels.months), function (item) {
        publicationQuery += '(publication_date:' + filterSentinels.year + '-' + item + '*) ';
      });
    }
    if (publicationQuery != '') {
      parentQuery.push('(document_category:"sentinel" AND (' + publicationQuery + '))');
    } else {
      parentQuery.push('document_category:"sentinel"');
    }
  }

  var parentTitles = '';
  if (titleQuery != '') {
    parentTitles = '+(' + titleQuery + ')'
  }

  var parentQueryString = '';
  if (parentQuery.length > 0) {
    if (searchedTitleObjects.length == 0 || filterSentinels.selected || filterJournals.selected) {
      queries.push(encodeURIComponent(parentQuery.join(' OR ')));
    }
  }

  if (parentTitles.length > 0) {
    queries.push(parentTitles);
  }

  //console.log('parentTitles Query', parentTitles);

  return queries;
};

/**
 * Reduces the search payload size
 * Currently only the size of filters.collections gets reduced, but that was 95% of the problem.
 *
 */
var slimFilters = function(filters) {
	var slimmedCollections = slimCollections(filters.collections);
	return _.extend({}, filters, {collections: slimmedCollections});
};

/**
 * given searchFactory (which is actually just an object),
 * return the payload that will be used in the body of the API
 * call for requesting search results.
 * 
 * > Note: any "sort" options in the searchFactory are ignored
 * because the back end ignores sort options
 *
 */
var getSearchPayload = function(searchFactory) {
  return {
    query: searchFactory.query,
    filters: slimFilters(searchFactory.filters),
    count: SEARCH_RESULT_PAGE_BY,
    page: searchFactory.page
  }
}

module.exports = {
	getSearchPayload: getSearchPayload,
  _forTesting: {
    slimCollections: slimCollections
  }
};
