var helpers = require('../../libs/helpers');

angular.module('koh').directive('multilistDropdown', ['dataService', function(dataService) {
  function controller($scope) {
    $scope.active = {};
    $scope.active.parent  = '';
    $scope.active.child  = '';
    $scope.active.middle  = '';
    $scope.active.title  = '';
    $scope.parentList = [];

    // TODO: get rid of $scope.childList.
    // We don't need to duplicate the state, violates DRY.
    // See the $scope.getActiveChildList function for how childList
    // can just be derived from other stuff we have in $scope.
    // Sorry—I couldn't safely eradicate it in a short amount of time.
    $scope.childList = [];

    this.getList = function(listType){
      if (listType == 'parent'){
        return $scope.parentList;
      } else if (listType == 'child'){
        return $scope.childList;
      } else if (listType == 'middle'){
        return $scope.midList;
      } else{
        return [];
      }
    }

    this.setChildList = function(list){
      $scope.childList = list;
      if (list.length) $scope.child = list[0].folder;
      $scope.$parent.$broadcast('listInvalidated' );
    }

    this.islessonlist = $scope.islessonlist;

    this.setActive = function(item, type){
      $scope.active[type] = item;
      if(type == 'parent'){
        $scope.active.child = null;
        $scope.childList = getChildList($scope.list, item);
      }
    };

    this.createActiveTitle = function(item, type, title){
      if (type == 'parent') return item.title;
      if (type == 'middle') return item;
      if ($scope.type == 'sentinels'){
        if (title){
          if (item.publicationDate == $scope.date) return title;
          if ($scope.pubdate && item.title == helpers.getMonthName($scope.pubdate.getMonth()) ) return title;
          return null;
        }

        // Sentinels > Issues
        if (item.folder == $scope.child) return item.title;
        // Sentinels > Months
        var issue = _.find(item.item, {'folder': $scope.child});
        if (!_.isUndefined(issue)){
          return issue.title;
        } else{
          var ret = this.getSentinelsList($scope.childList, {'folder': $scope.child})
          return ret != null ? ret.title : '';
        }
      } else {
        return item.title;
      }
    };

    var isSentinel = function($scope) {
      return $scope.$root.readingData.currentFolder === 'sentinels';
    };

    this.buildPath = function(ref, listtype, folder){
      // not sure why we are checking listtype—does it matter for sentinels?
      if (isSentinel($scope) && listtype === 'child') {
        return '/reader/sentinels/' + folder + '/' + ref
      }
      var file, parent, child;
      if($scope.islessonlist){
        if (listtype == 'parent'){
          return '/reader/lessons/' + ref + '/_Front_Pages_0001-0';
        }
        else {
          parent =  $scope.active.parent ? '/' +  $scope.active.parent.id : '';
          return '/reader/lessons' + parent + '/' + ref;
        }
      }
      else {
        if(listtype == 'parent'){
          var book = _.find($scope.list, { 'id' : ref });
          parent = '/' + book.folder;
          child = book.contents[0].folder ? '/' + book.contents[0].folder : '';
          if (ref == 'bible') child = '/Gen';
          if (ref == 'sentinels') child = '/' + book.contents[0].item[0].folder;
          file = book.contents[0].fileName ? '/' +book.contents[0].fileName : '';
        }
        else{
          parent =  $scope.active.parent ? '/' +  $scope.active.parent.folder : '';
          child =  $scope.active.child &&  $scope.active.child.folder ? '/' +  $scope.active.child.folder : '';
          file = ref ? '/ref/' + ref : '';
          file = !_.isUndefined(folder) ? '/' + folder + '/' + ref : file;
        }
        if (child.length > 1) return '/reader' + parent + child.firstLetterUpper() + file;
        // next line should be dead code to handle sentinels, but I'm afraid to change anything
        else return '/reader' + parent + file;
      }

    };

    this.getActiveTitle = function(){
      return $scope.active.title;
    }

    this.getDate = function(){
      return $scope.pubdate || new Date($scope.date);
    }

    this.getListByYear = function(type, year){
      var dataServiceFunc = type == 'periodicals' ? dataService.getPeriodicalsByYear : dataService.getSentinelsByYear;
      return dataServiceFunc(year);
    }

    this.getSentinelsList = function(list, searchCriteria){
      var ret = null;
      _.each(list, function(month){
        var issue = _.find(month.item, searchCriteria);
        if (!_.isUndefined(issue)){
          ret = issue;
          return;
        }
      });
      return ret;
    }

  }

  function link($scope, element, attr, controller){

    $scope.$watch('parent', setParent);

    $scope.$watch('child', function(val){ setChild(val, false) });

    $scope.$watch('title', setTitle);

    $scope.$watch('list', function(val){
      $scope.parentList = $scope.islessonlist ? val : setParentList(val);
      $scope.childList = [];

      setParent($scope.parent);
      setChild($scope.child);
      setTitle($scope.title);
    });

    $scope.$watch('date', function(val){
      if (_.isUndefined(val) || val.length < 1) return;
      $scope.pubdate = new Date(val);
      if ($scope.active.middle != $scope.pubdate.getUTCFullYear()){
        $scope.active.middle  = $scope.pubdate.getUTCFullYear();
        $scope.$parent.$broadcast('updatedActiveObject', { 'type': 'middle', 'item' : $scope.active.middle });
        $scope.$parent.$broadcast('listInvalidated' );

        if ($scope.type == 'sentinels'){
          controller.getListByYear($scope.type, $scope.active.middle).then(function(l){
            $scope.childList = l;
            var issue = controller.getSentinelsList(l, {'publicationDate': val});
            issue != null ? $scope.$parent.$broadcast('updatedActiveObject', { 'type': 'child', 'item' : issue }) : '';
            $scope.$parent.$broadcast('listInvalidated');
          })
        }
      }

    });

    $scope.$watch('years', function(val){
      if (_.isUndefined(val) || val.length < 1) return;
      $scope.midList = val;
      if (!_.isUndefined($scope.active.middle) ){ // && $scope.active.middle != val[0]){
        controller.getListByYear($scope.type, $scope.active.middle).then(function(l){
          $scope.childList = l;
          if($scope.type == 'periodicals'){
            controller.getListByYear($scope.type, $scope.active.middle).then(function (data) {
              var item = _.find(data, { 'folder' : $scope.child });
              $scope.$parent.$broadcast('updatedActiveObject', { 'type': 'child', 'item' : item })
            });
          }
          if($scope.type == 'sentinels'){
            var issue = controller.getSentinelsList(l, {'publicationDate': $scope.date});
            issue != null ? $scope.$parent.$broadcast('updatedActiveObject', { 'type': 'child', 'item' : issue }) : '';
          }
          $scope.$parent.$broadcast('listInvalidated');
        })
      }
      else {
        $scope.active.middle  = $scope.active.middle || val[0];
        $scope.$parent.$broadcast('updatedActiveObject', { 'type': 'middle', 'item' : $scope.active.middle });
        $scope.$parent.$broadcast('listInvalidated' );
      }
    });

    function setParent(val){
      //console.log(helpers.tims() + 'in MLDP settingParent:', val)
      if (val.length < 1) {
        return;
      }
      if (!$scope.parentList) {
        return;
      }
      if ($scope.parentList.length < 1) {
        return;
      }
      var item = _.find($scope.parentList, { 'id' : val });

      if(!_.isUndefined(item)){
        $scope.active.parent  = item;
        if($scope.parentList.length){
          $scope.childList = getChildList($scope.list, item);
          //console.log('setParent', $scope.active.title, $scope.childList);
          if($scope.childList.length > 0 && $scope.active.title != ''){
            setChild($scope.active.title, true);
          }
        }
        $scope.$parent.$broadcast('updatedActiveObject', { 'type': 'parent', 'item' : item });
      }
      return item;
    }

    function setChild(val, isTitle){
      if (_.isUndefined(val)) return;

      var item;
      if($scope.islessonlist) {
        item = $scope.$root.readingData.chapterList[$scope.$root.readingData.currentIndex];
      }
      else if(isTitle) item = _.find($scope.childList, { 'title' : val });
      else item = _.find($scope.childList, { 'folder' : val });

      if (_.isUndefined(item)){
        item = $scope.childList.length ? $scope.childList[0] : item;
      }

      if( !_.isUndefined(item) ){
        $scope.active.child = item;
        $scope.$parent.$broadcast('updatedActiveObject', { 'type': 'child', 'item' : item });

        if (attr.type == 'periodicals'){
          $scope.active.title = item.title;
        }
        if (attr.type == 'sentinels'){
          if (item.item){
            var issue = _.find(item.item, {'folder': val});
            if (!_.isUndefined(issue)){
              return issue.title;
            } else{
              _.each($scope.childList, function(i){
                var is = _.find(i.item, {'folder': val});
                if (!_.isUndefined(is)) return is.title;
              })
              return '';
            }
          } else{
            $scope.active.title = item.title;
          }
        }
      }

      return item;
    }

    function setTitle(val){
      $scope.active.title = val;
      //console.log('setTitle', val, $scope.childList);
      if(_.isUndefined($scope.active.child) || _.isNull($scope.active.child) || $scope.active.child == ''){
        setChild(val, true);
      } else {
        $scope.$parent.$broadcast('updatedActiveObject', { 'type': 'child', 'item' :  $scope.active.child });
      }
    }
  }

  var getChildList = function(pList, pItem){
    if(pItem && pItem.title){
        var activeObj = _.find(pList, { 'title' : pItem.title });
        return activeObj.hasOwnProperty('contents') ? activeObj.contents : [];
    }
    return [];
  }

  var setParentList = function(list){
    var parentList = [];
    _.each(list, function(row){
      parentList.push(
        { 'title': row.title,
          'folder': row.folder,
          'id': row.id
        });
    });
    return parentList;
  }

  return {
    restrict: 'E',
    controller: ['$scope', controller],
    link: link,
    scope: {
      list: '=?',
      years: '=?',
      islessonlist: '=?',
      parent: '@',
      child: '@',
      title: '@?',
      type: '@?',
      date: '@?'
    }
  };

}]).directive('multilistChild', [
  '$timeout',
  '$rootScope',
  '$location',
  'documentSearchFactory',
  'dataService',
  function($timeout, $rootScope, $location, documentSearchFactory, dataService) {
    var link = function($scope, element, attr, controller){
      $scope.activeChapter = '';
      $scope.currentBook = '';
      $scope.name = attr.name;
      $scope.alwaysOpen = $scope.alwaysOpen || false;
      $scope.toggleObject = {};
      $scope.toggleConfig = $scope.toggleConfig || {};
      $scope.temporaryChildListOrNull = null;

      $scope.filter = !_.isUndefined(attr.filter);
      $scope.list = controller.getList(attr.listtype);
      $scope.yearlist = controller.getList('middle');
      $scope.islessonlist = controller.islessonlist;
      setChildList($scope, $scope.list);
      var switchTab = true;

      $scope.tabnames = attr.tabnames ? attr.tabnames.split(',') : [];

      $scope.closeMenu = function() {
        $scope.toggleObject[$scope.name] = $scope.alwaysOpen;
        $scope.toggleConfig.mobileTOC = false;
        // $scope.temporaryChildListOrNull should be null when the menu is closed.
        // It is used for when the user has selected a sentinel month in the drop-down
        // but has not yet selected a sentinel issue
        $scope.temporaryChildListOrNull = null;
      }
      $scope.openMenu = function(name, id) {
        if (id != 'sentinels' && id != 'periodicals'){
          $scope.$parent.$broadcast('openMenu', {name: name});
        }
      }

      $scope.isActive = function(item, chapter){
        if(_.isUndefined(item) || $scope.activeTitle == "") return false;
        var type = $scope.columns == 3? 'middle' : attr.listtype;
        return $scope.activeTitle == controller.createActiveTitle(item, type, $scope.activeTitle);
      };

      $scope.getActiveChildList = function() {
        // $scope.temporaryChildListOrNull will be set if the user has
        // clicked a sentinels month, but has not yet selected an issue
        // and the menu hasn't yet closed
        if ($scope.temporaryChildListOrNull) {
          return $scope.temporaryChildListOrNull;
        }
        var list = controller.getList(attr.listtype);
        var parents = (list || []).filter(function(parent) {
          return $scope.isActive(parent);
        });
        var activeChildList = parents.length && parents[0].item ? parents[0].item : [];
        return activeChildList;
      };

      $scope.isChapterActive = function (chapter) {
        return ($scope.activeChapter == chapter.title) && ($scope.currentBook == chapter.parentTitle);
      };

      $scope.setActiveChapter = function (chapter,ref) {
        $scope.closeMenu();
        $scope.scrollToTop();
        $scope.goToPath(ref);
        $scope.activeChapter = chapter.title;
        $scope.currentBook = chapter.parentTitle;
      };

      /**
       * TODO: get rid of this: too much branching and eventing
       */
      var messySetActive = function(item){
        if (_.isUndefined(item)) return;

        if(attr.listtype == 'parent'){
          controller.setActive(item, 'parent');
          $scope.closeMenu();
          $scope.$parent.$broadcast('listInvalidated');
        } else if (attr.listtype == 'child'){
          if(!_.isUndefined($scope.childList) && $scope.childList.length > 0){
            controller.setActive(item, 'child');
          }
          else{
            controller.setActive(null, 'child');
          }
        } else { //year
          controller.setActive(item, 'middle');
        }

        $timeout(function(){
          var title = controller.createActiveTitle(item, attr.listtype);
          $scope.activeTitle = $scope.columns == 3? controller.getDate().getUTCFullYear() : title;
          ;
        }, 0)

        scrollDropdowns();
      }

      $scope.handleClickItem = function(item) {
          // This is to hold some state for sentinels,
          // when the user has selected a month but has 
          // not yet selected an issue. We want clicking "December"
          // to show December issues, but for the issues to roll back 
          // to whatever they would be otherwise if the user doesn't click
          // an issue before the menu closes
          $scope.temporaryChildListOrNull = item.item;
          // temporaryChildListOrNull is reset to null when menu closes—see `closeMenu`

          // this next function does a ton of stuff, not sure how or why
          messySetActive(item);
      };

      $scope.goToPath = function(ref, folder){
        documentSearchFactory.currentSearch = '';
        documentSearchFactory.isSearching = false;
        documentSearchFactory.searchInBook = false;
        $rootScope.$broadcast('clearHitList');
        resetTabs($scope);
        var path = controller.buildPath(ref, attr.listtype, folder);
        $location.path(path);
      }

      $scope.$on('openMenu', function(event, data){
        $timeout(function(){
          $scope.toggleObject[data.name] = true;
        }, 1000)
      });

      $scope.$on('updatedActiveObject', function(event, data){
        if (attr.listtype == data.type){
          messySetActive(data.item);
        }
      });

      $scope.$on('listInvalidated', function(){
        $scope.list = controller.getList(attr.listtype);
        if ($scope.columns == 3) $scope.yearlist = controller.getList('middle');
        if(attr.listtype == 'child'){
          $scope.activeTitle = '';
          setChildList($scope, $scope.list);
          if(switchTab && $scope.columns == 3) $scope.toggleObject.activeTab = 0;
          else switchTab = true;
        }
      });

      $scope.$watch('toggleObject', function(){
        $scope.searchText = '';
      }, true);

      $scope.scrollToTop = scrollDropdowns;
      resetTabs($scope)

      $scope.loadYear = function(year, switchTab){
        messySetActive(year);
        switchTab = switchTab || false;
        controller.getListByYear(attr.type, year).then(function(data){
          controller.setChildList(data);
          if($scope.columns == 3) $scope.toggleObject.activeTab = 1;
        });
      }
      if($scope.columns == 3){
        $scope.loadYear(controller.getDate().getUTCFullYear(), true);
      }
    };

    var setChildList = function($scope, pList, item){
      if(pList && pList.length){
        pItem = item || pList[0];

        if (pItem.item && pItem.item.length > 0){
          $scope.tabs = true;
          $scope.childList = pItem.item;
        } else {
          $scope.tabs = false;
          $scope.childList = [];
        }
      }
      if($scope.columns == 3) $scope.tabs = true;
    }

    var scrollDropdowns = function(){
      var wrappers = document.getElementsByClassName('multilist-wrapper');
      _.each(wrappers, function(el){
        el.scrollTop = 0;
      });
    }

    var resetTabs = function($scope){
      $scope.closeMenu()
      $scope.toggleObject.activeTab = 0;
    }




    return {
      require: '^multilistDropdown',
      templateUrl: './multilist-dropdown/multilist-dropdown.html',
      link: link,
      scope: {
        name: '@?',
        title: '@?',
        type: '@?',
        columns: '@?',
        listtype: '@?',
        nofilter: '@?',
        tabs: '@?',
        alwaysOpen: "=?",
        toggleConfig: "=?"
      }
    }
  }
]);
