'use strict';

/* global
    _
*/

// NOTE: nothing in these helpers talks to Angular, the browser, or APIs
// Also, none of it cares about bookType, docType, etc. except the one check
// for whether bookType === 'bible' in `getNormalizedEndPage`

/**
 * `pageLabels`  Array<String>  array of page labels (display pages) such as
 * 'iii' or '20'. Distinct from indexes (physical pages).
 * 
 * returns the index (physical page, starts count from 1)
 * given the label.
 * 
 * Returns -1 if not found
 * 
 */
var getPageIndexForLabel = function(pageLabels, label) {
    var indexInArray = _.findIndex(pageLabels, function(pageLabel) {
        return pageLabel === label;
    });

    return indexInArray === -1
            ? -1
            : (indexInArray + 1)
};

/**
  returns theindex (physical page) of the book, starts counting from 1

**/
var getCurrentPageIndex = function(readingData){
    // first page is page 1
    return readingData.currentIndex + 1;
};

/**
    returns the page label (display page—such as 'vi' or '23')
**/
var getPageLabel = function(readingData) {
    return readingData.chapterList[readingData.currentIndex].title;
};

/**
 * Crazy util for dealing with items.
 * Needed because item.endPage is 1 too great
 * except for *last* item in chapter/book/whatever
 *
 * @return     {int}  The normalized end page.
 */
var getNormalizedEndPage = function(items, item, bookType) {
    // don't mess with Bible `endPage`s
    // because items in the Bible often (but not always) are such that one item ends
    // on the same page that the next item starts
    if (bookType === 'bible') {
        return item.endPage;
    }
    // crazy adjustment because item.endPage means something different if it's
    // the last item.
    var isLastItem = item === items[items.length - 1];
    var normalizedEndPage =  isLastItem ? item.endPage : item.endPage - 1;
    return normalizedEndPage;    
};

/**
 * returns [startPageIndex<int>, endPageIndex<int>]
 * 
 * The start and end page are indexes (physical pages), NOT labels (display pages)
 *
 * throws if no item found
 *
 * @param      {int} currentIndex current page index (physical page) starts counting from 1
 * @param      {Array<{sectionStartPage, sectionEndPage}>}  items (aka "sections") 
 */
var getCurrentItemRange = function(currentIndex, items, bookType) {

    var item = _.find(items, function(item) {
        return item.startPage <= currentIndex
                && getNormalizedEndPage(items, item, bookType) >= currentIndex;
    });
    if (!item) {
        throw new Error('section not found for currentIndex');
    }
    var endPage = getNormalizedEndPage(items, item, bookType);
    return [item.startPage, endPage];
};

var getPrintPageRangeUri = function(printApiUrl, parentFolder, currentBook, startIndex, endIndex) {
    var uri = printApiUrl + '/api/files/' + parentFolder + '/' + currentBook + '.pdf/' + startIndex + '-' + endIndex;
    return uri;
};

var getPrintCurrentPageUri = function(printApiUrl, parentFolder, readingData) {
    var index = getCurrentPageIndex(readingData);
    var currentBook = readingData.currentBook;
    return getPrintPageRangeUri(printApiUrl, parentFolder, currentBook, index, index);
};

/**
 * Determines if page range is within the current book.
 * 
 * @return     {boolean}  True if in item range, False otherwise.
 */
var isValidPageRange = function(items, startIndex, endIndex) {
    if (startIndex === -1 || endIndex === -1) {
        return false;
    }
    var firstItem = items[0];
    var lastItem = items[items.length - 1];

    return firstItem.startPage <= startIndex
                && endIndex <= lastItem.endPage;
};

module.exports = {
    isValidPageRange: isValidPageRange,
    getCurrentItemRange: getCurrentItemRange,
    getPageIndexForLabel: getPageIndexForLabel,
    getPrintCurrentPageUri: getPrintCurrentPageUri,
    getPrintPageRangeUri: getPrintPageRangeUri
};
