'use strict';

var helpers = function () { return require('../libs/helpers'); };

angular.module('koh.account', ['ui.router'])
  .config(['$stateProvider', function($stateProvider) {
    $stateProvider.state('signup', {
      url: "/signup",
      templateUrl: "/controllers/signup/Signup.html",
      controller: 'SignupCtrl',
      params: {
      },
      resolve: {
        helpers: helpers,
        authenticate: function(kohauth) {
          return kohauth.headerAuthenticate();
        }
      }
    })
    .state('verify', {
      url: "/signup/:token",
      templateUrl: "/controllers/signup/Signup.html",
      controller: 'SignupCtrl',
      params: {
        message: null,
        error: null
      },
      resolve: {
        helpers: helpers,
        authenticate: function(kohauth) {
          return kohauth.headerAuthenticate();
        }
      }
    })
    .state('logout', {
      url: "/logout",
      templateUrl: "/controllers/login/Login.html",
      controller: 'LoginCtrl',
      params: {
        "logout":true,
        "message":"You are now logged out"
      },
      resolve: {
        helpers: helpers,
        authenticate: function(kohauth) {
          return kohauth.headerAuthenticate();
        }
      }
    })
    .state('login', {
      url: "/login",
      templateUrl: "/controllers/login/Login.html",
      controller: 'LoginCtrl',
      params: {
        message: null,
        error: null
      },
      resolve: {
        helpers: helpers,
        authenticate: function(kohauth) {
          return kohauth.headerAuthenticate();
        }
      }
    })
    .state('forgot', {
      url: "/forgot",
      templateUrl: "/controllers/forgot/Forgot.html",
      controller: 'ForgotCtrl',
      params: {
        message: null,
        error: null
      },
      resolve: {
        helpers: helpers,
        authenticate: function(kohauth) {
          return kohauth.headerAuthenticate();
        }
      }
    })
    .state('reset', {
      url: "/forgot/:token",
      templateUrl: "/controllers/forgot/Forgot.html",
      controller: 'ForgotCtrl',
      resolve: {
        helpers: helpers,
        authenticate: function(kohauth) {
          return kohauth.headerAuthenticate();
        }
      }
    })
    .state('profile', {
      url: "/profile/:tab",
      templateUrl: "/controllers/profile/Profile.html",
      controller: 'ProfileController',
      controllerAs: 'vm',
      params: {
      },
      resolve: {
        helpers: helpers,
        authenticate: function(kohauth) {
          return kohauth.authenticate();
        }
      }
    })
  }]);
