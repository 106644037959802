// Note, when naming an angular directive, convert objects separated with dashes into camel case
// This is required!

angular.module('koh').directive('noteMarkup', function() {
  return {
    restrict: 'E',
    replace: true,
    scope: {
      id: '@',
      heading: '@?',
      text: '@?',
      top: '@'
    },
    templateUrl: './directives/pages/note-markup/note-markup.html',
    link: function (scope, element, attrs) {
      scope.inlineStyle = { 'top' : scope.top };
      scope.edit = scope.$parent.$parent.editNote;
      scope.showPreview = false;

      scope.hoverIn = function(){
        this.showPreview = true;
      };

      scope.hoverOut = function(){
          this.showPreview = false;
      };
    }
  };
});