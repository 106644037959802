// Note, when naming an angular directive, convert objects separated with dashes into camel case
// This is required!

var helpers = require('../../libs/helpers');


angular.module('koh').directive('selectionEditor', function() {
  return {
    transclude: true,
    templateUrl: './directives/pages/selection-editor/selection-editor.html',
    link: function (scope, element) {
        scope.$watch('directiveLinkObj', function(newData) {
            scope.linkObj = newData;
        })
        // console.log('Defined seUpdate');
        
    	// Showing transclude works...
    	//var ckObject = angular.element(document.querySelector('#ckObject'))[0];
    	//CKEDITOR.replace('noteEditor')
    	CKEDITOR.config.removeButtons = '';
    	CKEDITOR.replace( 'noteEditor', {
			toolbar: [
				{ name: 'basicstyles', items: [ 'Bold', 'Italic', 'Underline','BulletedList' ] }
			]
		});
    }
  };
});

function isDescendant(parent, child) {
    var traceLimit = 5;
     var node = child.parentNode;
     var i = 0;
     while (node != null) {
         if (node == parent) {
             return true;
         }
         i++;
         if (i > traceLimit) {
            return false;
         }
         node = node.parentNode;
     }
     return false;
}

angular.module('koh').directive('eventFocus', function(focus) {
    return function(scope, elem, attr) {
      elem.on(attr.eventFocus, function() {
        focus(attr.eventFocusId);
      });

      // Removes bound events in the element itself
      // when the scope is destroyed
      scope.$on('$destroy', function() {
        elem.off(attr.eventFocus);
      });
    };
});
angular.module('koh').factory('focus', function($timeout, $window) {
return function(id) {
  // timeout makes sure that it is invoked after any other event has been triggered.
  // e.g. click events that need to run before the focus or
  // inputs elements that are in a disabled state but are enabled when those events
  // are triggered.
  $timeout(function() {
    var element = $window.document.getElementById(id);
    if(element)
      element.focus();
  });
};
});
angular.module('koh').directive('clickAnywhereButHere', function($document, $parse) {
    return {
        restrict: 'A',
        scope: {
            callback : '=clickAnywhereButHere',
            type : '=type',
            state : '=state'
        },
        link: function(scope, element, attr, ctrl) {
            var whiteList = [
                '.additionContainer', '.noteContainer'
            ];
            var handler = function(event) { 
                var bFound = false;
                //console.log('Event:', event);
                _.each(whiteList, function(check) {
                    if (helpers.checkParent(event.target, check)) {
                        bFound = true;
                        return false;
                    }
                })
                if (bFound) {
                    return;
                }
                if (!isDescendant(element[0], event.target)) {
                    //console.log('Checking descendant')
                    if (_.isFunction(scope.callback))
                        scope.callback(event.target, scope.type, false);
                }
                /*
                if (!element[0].contains(event.target) && scope.state) {
                    scope.callback(event.target, scope.type);
                 }
                 */
            };
            
            $document.on('click', handler);
            scope.$on('$destroy', function() {
                $document.off('click', handler);
            });
        }
    }
});