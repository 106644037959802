'use strict';
var helpers = require('./helpers');

angular.module('koh').factory('pageLoaderService',
  function(Restangular, $document, $window, $compile, booksService) {
		// Localized scope
		var $scope = null;
    var allPages = [];
    var unloadedPages = [];
    var loadAmt = 5;

    var init = function(ctrlScope, chapterList) {
      $scope = ctrlScope;
      allPages = createAllPages(chapterList);
    };

    var addPage = function(page){
      var n = page.getPageNo()
      unloadedPages[n] = page;
    };

    var getIndex = function(p){
      for ( var i = 0 ; i < allPages.length ; i++ ) {
        if ( allPages[ i ].startsWith(p) ) return i;
      }
    }

    var loadPages = function(currentPage){
      var start = getIndex(currentPage),
          i = Math.max(start - loadAmt, 0),
          end = Math.min(start + loadAmt, allPages.length);
      for (i; i < end; i++){
          loadPage(allPages[i]);
      }
    };

    var createAllPages = function(chapterList){
      var allPages = [];
      _.each(chapterList, function(chapter){
        allPages.push(chapter.fileName);
      });
      return allPages;
    }

    var loadPage = function(pageNo){
      if (unloadedPages.hasOwnProperty(pageNo)) {
        unloadedPages[pageNo].render();
        delete unloadedPages[pageNo];
      }
      // else: page is already rendered
    }

    var createLazyPage = function( element, $rootScope, $dirScope, pageData ) {
        var props = {};
        props.pageNo = pageData.fileName;
        props.book = pageData.book;
        props.folder = pageData.folder;
        props.isRendered = false;
        props.height = null;
        // props.documentSearchFactory = pageData.documentSearchFactory;
        // if( pageData.highlight )
        // {
        //   props.highlight = pageData.highlight;
        //   props.searchTerms = angular.element("<div>"+pageData.highlight.item_body+"</div>").find('span');
        // }

        // Set element attributes
        element.attr('id', props.pageNo);
        element.parent().addClass('page-' + pageData.align);

        var throttleHandleScroll = _.throttle(handleScroll, 1000);
        var debounceHandleScroll = _.debounce(handleScroll, 1000);

        // Attach watch handlers
        angular.element($document).on('scroll', throttleHandleScroll);
        angular.element($document).on('resize', debounceHandleScroll);

        // PUBLIC METHODS
        function isRendered() {
          return props.isRendered;
        }
        function getPageNo(){
          return props.pageNo;
        }

        // Move the cached page into the live source
        function render() {
          props.isRendered = true;
          renderPage();
        }
        // Set the interpolated source value reported by the directive
        function setPage( newPageNo ) {
          props.pageNo = newPageNo;
          if ( isRendered ) {
              renderPage();
          }
        }

        function unbindHandlers(){
          angular.element($document).off('scroll', throttleHandleScroll);
          angular.element($document).off('resize', debounceHandleScroll);
        }

        // PRIVATE METHODS.
        function renderPage() {
          ;
          var page = props.pageNo.replace(/-.*$/g, '')
          helpers.getDocumentText(Restangular, props.folder, props.book, page).then(
            function(pageData) {
              if (props.folder == 'periodicals') {
                $dirScope.pageText = pageData;
              } else {
                $dirScope.pageText = pageData.chapter;
                $dirScope.pageText = $dirScope.pageText || pageData;
              }
              if ($rootScope.readingData.currentChapter == props.pageNo) {
                $rootScope.pageText = $dirScope.pageText;
              }


            },
            function(error){
              ;
            });
        }

        // function getTaggedSearchTerm(data, search) {
        //   var tags = [];
        //   if( !search && !props.searchTerms ) return tags;
        //   var breaks = data.split('<span');

        //   _.each(breaks, function(breaker, key){
        //     var spanData = breaker.split('</span>')
        //     if( spanData && spanData.length > 0 )
        //     {
        //       if( props.searchTerms.length > 0 )
        //       {
        //         _.each(props.searchTerms, function( item, k ){
        //           var searchTerm = angular.element(item).text();

        //           var searchRegex = new RegExp('\\b'+searchTerm+'\\b', "gi");
        //           if( spanData[0].match( searchRegex ) )
        //           {
        //             var matchedSpanData = '<span'+breaker;
        //             var element = angular.element(matchedSpanData);
        //             if( element[0].tagName === 'SPAN' )
        //             {
        //               element.addClass( 'selected-result' );
        //               tags.push({ original: matchedSpanData, updated: element[0].outerHTML + " " })
        //             }
        //           }
        //         })
        //       }
        //       else
        //       {
        //         var searchRegex = new RegExp('\\b'+search+'\\b', "gi");
        //         if( spanData[0].match( searchRegex ) )
        //         {
        //           var matchedSpanData = '<span'+breaker;
        //           var element = angular.element(matchedSpanData);
        //           if( element[0].tagName === 'SPAN' )
        //           {
        //             element.addClass( 'selected-result' );
        //             tags.push({ original: matchedSpanData, updated: element[0].outerHTML + " " })
        //           }
        //         }
        //       }
        //     }
        //   })
        //   return tags;
        // }


        // Handle window scrolling and resizing by loading upcoming pages
        function handleScroll() {
          if ($rootScope.readingMode != 'continuous') return;

          var doc = document.documentElement,
              pageY = (window.pageYOffset || doc.scrollTop)  - (doc.clientTop || 0),
              offset = pageY + screen.height / 4,
              top = helpers.getOffset(element[0]).top,
              chapter = props.pageNo;


          var inView = top < offset && top > pageY,
              isCurrent = chapter == $rootScope.readingData.currentChapter;

          if (inView && !isCurrent){
            helpers.changePage($rootScope, chapter, true);
          }
        }


        // Return the public API.
        return({
          isRendered: isRendered,
          getPageNo: getPageNo,
          render: render,
          setPage: setPage,
          unbindHandlers: unbindHandlers
        });
    };

		return {
      init: init,
      addPage: addPage,
      createLazyPage: createLazyPage,
      loadPages: loadPages
    }
});
