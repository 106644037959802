var _ = require('lodash');
var searchHelpers = require('../../libs/search-helpers');
var getSearchPayload = searchHelpers.getSearchPayload

// Helper function for when the user clicks "view" on a search result without
// having expanded the search results first.
// Used for when the highlights are NOT passed from the template to the controller,
// and all we have is the `result` object.
var getFirstHighlight = function(result) {
	if (result.highlights && result.highlights.length && result.highlights[0]) {
		return result.highlights[0];
	}
	// no idea what this is or what it's for, extracted from code that was in the template
	return { item_body_stop: result.title };
};

angular.module('koh').controller('SearchCtrl',
	['$scope',
	'$location',
	'helpers',
	'Restangular',
	'$rootScope',
	'$state',
	'$stateParams',
	'$http',
	'apiUrl',
	'searchService',
	'searchFactory',
	'$sce',
	'documentSearchFactory',
	function(
		$scope,
		$location,
		helpers,
		Restangular,
		$rootScope,
		$state,
		$stateParams,
		$http,
		apiUrl,
		searchService,
		searchFactory,
		$sce,
		documentSearchFactory
	) {
		$scope.window = window;
		$scope.isString = angular.isString;

		$scope.searchFactory = searchFactory;
		$scope.stripFirstDash = helpers.stripFirstDash;
		$scope.searchFactory.query = angular.copy( $stateParams.query );
		documentSearchFactory.currentSearch = angular.copy( $scope.searchFactory.query );
		// $scope.searchFactory.filters = cleanFilters( $scope.searchFactory.dirtyFilters );

		$scope.totalResults = 0;
		$scope.results = [];

		$scope.isSearching = false;

		helpers.toggleMenu($rootScope, false);
		helpers.toggleSearch($rootScope, true);

		var getValue = function(str, srch) {
			var search = srch+'="';
			var obj = str.indexOf(search);
			var res = str.substr(obj+search.length);
			var result = res.substr(0, res.indexOf('"'));
			return result;
		}
		$scope.citationData = {};

		// will set this to `true` if citations found
		$scope.isSearchCitation = false;

		$scope.getFriendlyBody = function(citation) {
			return $scope.isSearchCitation
									? helpers.getSelectionBody($scope.citationData.data.ranges)
									// the user should theoretically never see this, since
									// the friendly body is only shown if `isSearchCitation` is `true`
									: 'A search was entered with citation syntax - but matched no known citation';
		};
		$scope.getCitationData = function(query) {
			Restangular.one('citation',query).get().then(function( results ) {
				//console.log('Got citation data', results);
				if (!results || !results.data || !results.data.ranges || !results.data.ranges.length || !results.data.ranges[0].foundSpans.length >= 2) {
					return;
				}
				$scope.isSearchCitation = true;
				$scope.citationData = results;


			}, function(err) {
				;
			});
		}
		 var padInt = function(val) {
                var strVal = val + '';
                var retVal = '';
                if (strVal.length == 1) {
                        retVal = '00';
                }
                if (strVal.length == 2) {
                        retVal = '0';
                }
                retVal += '' + val;
                ;
                return retVal;
        }



		 var buildFileFromDocId = function(docId) {
                var idParts = docId.split('_');
                var firstChar = (idParts[0]).substr(0,1);
                var type = firstChar=='J' ? 'periodicals' : 'sentinels';
                var file = '';

                for (var i = 0 ; i < 5 ; i++) {
                        var row = idParts[i];
                        var character = row.substr(0,1);
                        var iVal = parseInt(character);
                        if  (!isNaN(iVal)) {
                                file+=padInt(parseInt(row));
                        } else {
                                file+=character;
                        }
                }
                ;
                return '/reader/' + type + '/' + file +
                  (idParts.length>6? '/'+file+'_'+('0000'+(parseInt(idParts[6])+1)).slice(-4): '')+
                  (documentSearchFactory.currentSearch? '/search/'+documentSearchFactory.currentSearch: '');
        }

		$scope.viewCitationFromSearch = function(highlight) {
			;
			var citation;
			if (!_.isObject(highlight)) {
				citation = highlight;
				$rootScope.viewCustomCitation(citation, $scope, true);
				return;
			}
      documentSearchFactory.currentHighlight = highlight;
			var dataDocId = helpers.getXmlValue(highlight.item_body_stop, 'data-document-id');
			;
      if (!dataDocId) {
        return;
      }
      if (dataDocId.indexOf('_Vol_') >= 0) {
        // is Journal
        ;
        var newpath = buildFileFromDocId(dataDocId);
        ;
        $location.url(newpath);
        return;
      }

			var arg = highlight.item_body_stop;
			var verseSearch = 'data-begin-chapter-verse="';
			var docSearch = 'data-item-name="';
			var searchStart = arg.indexOf(docSearch);
			var doc = arg.substr(searchStart+docSearch.length);
			citation = doc.substr(0, doc.indexOf('"'));


			// console.log('Chapter verse:', citation);

			var verseStart = arg.indexOf(verseSearch)
			doc = arg.substr(verseStart+verseSearch.length);
			var verseObject = doc.substr(0, doc.indexOf('"'));
			var verse = verseObject.split('|')[1];

			if (verse && verse != null && verse != 'null') {
				citation += ':' + verse;
			} else {
				var sectionTitle = getValue(arg, 'data-document-name');

				// One off - unless there's more

				var book = _.find($rootScope.bookData, {sectionTitle: helpers.fixSectionTitle(sectionTitle)});
				if (book.parentTitle.toLowerCase().indexOf('bible')<0) {
					var pageBegin = getValue(arg, 'data-begin-page-number');
					var pageEnd = getValue(arg, 'data-end-page-number');
					var numPageBegin = helpers.fromRoman(pageBegin);
					var numPageEnd = helpers.fromRoman(pageEnd);
					var pageLoc = pageBegin;
					if (numPageEnd < numPageBegin || pageEnd < pageBegin) {
						pageLoc = pageEnd;
					}
					var chapterStr = getValue(arg, 'data-item-name');
					var chapArray = chapterStr.split(' ');
					var chapter = chapArray[1];
					// console.log('Dealing with book');
					//var verse = getValue(arg, 'data-begin-line-number');
					var verse = getValue(arg, 'data-begin-page-index');
					;
					citation = book.sectionFolder + ' ' + pageLoc + ':' + verse;
				} else {

					var chapterStr = getValue(arg, 'data-item-name');
					var chapArray = chapterStr.split(' ');
					var chapter = chapArray[1];
					// console.log('Dealing with book');
					var verse = getValue(arg, 'data-begin-line-number');
					citation = book.sectionFolder + ' ' + chapter + ':' + verse;
				}
			}
			$rootScope.viewCustomCitation(citation, $scope);
		};

		$scope.viewResult = function(result, highlightOrUndefined) {
			var highlight = highlightOrUndefined || getFirstHighlight(result);
      var chapter = result.chapter;
      if (!chapter) {
        ;
        return;
      }
      var title = result.title,
          startPage = chapter.startPage,
          query;
	  documentSearchFactory.currentHighlight = highlight;
	  query = $scope.searchFactory.query;
	  if (!query) { // author search, no query but author name is in snippet
	    highlight.item_body_stop.replace(/<span id=[^>]+>([^<]+)<\/span>/ig, function (s, val) {
	      query = query? query+' '+val: val;
	    });
	  }
	  highlight.item_body_stop.replace(/data-begin-page-index="([^"]*)"/i, function(s, val) {
	    startPage = val;
	  });
      documentSearchFactory.currentHighlight = highlight  || {item_body_stop: result.title};
      ;
      $location.url('/reader/'+chapter.parentFolder+'/'+chapter.sectionFolder+'/'+chapter.sectionFolder+'_'+
        ('0000'+startPage).slice(-4)+'/search/'+query);
		};

		$scope.addCitationFromSearch = function(highlight) {
			var arg = highlight.item_body_stop;
			var citation;
			if (!_.isObject(highlight)) {
				$rootScope.addCustomCitation(highlight, $scope);
				return;
			}
			var citation;
			var verseSearch = 'data-begin-chapter-verse="';
			var docSearch = 'data-item-name="';
			var searchStart = arg.indexOf(docSearch);
			var doc = arg.substr(searchStart+docSearch.length);
			citation = doc.substr(0, doc.indexOf('"'));
			// console.log('Chapter verse:', citation);

			var verseStart = arg.indexOf(verseSearch)
			doc = arg.substr(verseStart+verseSearch.length);
			var verseObject = doc.substr(0, doc.indexOf('"'));
			var verse = verseObject.split('|')[1];

			if (verse && verse != null && verse != 'null') {
				citation += ':' + verse;
			} else {
				;
				var sectionTitle = getValue(arg, 'data-document-name');
				;
				var book = _.find($rootScope.bookData, {sectionTitle: sectionTitle});
				;
				if( book )
				{

					var chapterStr = getValue(arg, 'data-item-name');
					var chapArray = chapterStr.split(' ');
					var chapter = chapArray[1];
					// console.log('Dealing with book');
					var verse = getValue(arg, 'data-begin-line-number');

					citation = book.sectionFolder + ' ' + chapter + ':' + verse;
				}
				else
				{
					;
				}
			}
			;
			$rootScope.addCustomCitation(citation, $scope);
		}

		// function cleanFilters( filters )
		// {
		// 	console.log( "SearchModels:", $scope.searchFactory.filters, ", Filters:", filters);
		// 	return $scope.searchFactory.filters || filters;
		// 	if( !filters ) return {};
		// 	var filteredObject = { collections: [], types: [] };

		// 	// Filter Collections/Books
		// 	_.map(filters.collections, function( collection, key ){
		// 		var books = collection.books;
		// 		var collectionId = key;
		// 		var collectionName = collection.name;
		// 		var currentCollection = filteredObject.collections;

		// 		var collectionIndex = currentCollection.push({
		// 			id: collectionId,
		// 			name: collectionName
		// 		}) - 1;

		// 		currentCollection[ collectionIndex ].books = [];

		// 		_.map( books, function( book, key ) {
		// 			var bookId = book.id;
		// 			var bookName = book.name;

		// 			if( !_.isUndefined( book.selected ) && !_.isObject( book.chapters ) )
		// 			{
		// 				if( book.selected )
		// 				{
		// 					currentCollection[ collectionIndex ].books.push({
		// 						id: bookId,
		// 						name: bookName
		// 					});
		// 				}
		// 			}
		// 			else if( _.isObject( book.chapters ) )
		// 			{
		// 				var bookIndex;
		// 				_.map( book.chapters, function( chapter, key ){

		// 					var chapterName = chapter.name;
		// 					if( !_.isUndefined( chapter.selected ) && chapter.selected )
		// 					{
		// 						if( _.isUndefined( currentCollection[ collectionIndex ].books[ bookIndex ] ) )
		// 						{
		// 							bookIndex = currentCollection[ collectionIndex ].books.push({
		// 								id: bookId,
		// 								name: bookName
		// 							}) -1;

		// 							currentCollection[ collectionIndex ].books[ bookIndex ].chapters = [];
		// 						}

		// 						currentCollection[ collectionIndex ].books[ bookIndex ].chapters.push({
		// 							name: chapterName
		// 						});
		// 					}
		// 				})
		// 			}
		// 		});

		// 		if( _.isArray( filteredObject.collections ) && filteredObject.collections.length > 0 )
		// 		{
		// 			$scope.booklengths = filteredObject.collections.reduce( function( previousValue, currentValue, index, array ){
		// 				if( previousValue.books ) return currentValue.books.length + previousValue.books.length
		// 				else return currentValue.books.length + previousValue
		// 			})
		// 		}

		// 		return filteredObject;
		// 	});

		// 	// Filter Content Types
		// 	_.map(filters.contentTypes, function( type )
		// 	{
		// 		var typeName = type.name;
		// 		if( !_.isUndefined( type.selected ) && type.selected )
		// 		{
		// 			filteredObject.types.push( typeName );
		// 		}
		// 	});

		// 	// Filter Dates
		// 	filteredObject.dates = filters.dates;

		// 	// Filter Author
		// 	filteredObject.selectedAuthor = filters.selectedAuthor;

		// 	return filteredObject;
		// }

		$scope.trust = function(html_code) { return $sce.trustAsHtml(html_code) };
		var memo = [];
		function deepSearch(obj, key, val) {
		    if (_.has(obj, key)) // or just (key in obj)
		    	if (obj[key] == val) {
		    		memo.push (obj);
		        }
		    // elegant:
		    return _.flatten(_.map(obj, function(v) {
		        return typeof v == "object" ? deepSearch(v, key, val) : [];
		    }), true);

		    // or efficient:
		    var res = [];
		    _.forEach(obj, function(v) {
		        if (typeof v == "object" && (v = deepSearch(v, key, val)).length)
		            res.push.apply(res, v);
		    });
		    return res;
		}
		$scope.findBook = function( title ){
			title = title.toLowerCase().indexOf('Miscellany') >= 0 ? 'Miscellany' : title;
			// title = title.replace(/[\u2018\u2019]/g, "'").replace(/[\u201C\u201D]/g, '"');
			memo = [];

			_.each($scope.searchFactory.filters.collections, function( collection ){
				deepSearch( collection, 'title', title)
			})
			if (memo) {
				var foundBook = memo[0];
				if( foundBook )
				{
					if( typeof foundBook.all !== 'undefined' )
					{
						foundBook.all = true;
					}
					else
					{
						foundBook.selected = true;
					}
				}
			}
		}
		/*
		function deepSearch(obj, key, value, memo) {
			console.log('Performing deep search');
		  var i,vr
		      proto = Object.prototype,
		      ts = proto.toString,
		      hasOwn = proto.hasOwnProperty.bind(obj);

		  if ('[object Array]' !== ts.call(memo)) memo = [];

		  for (i in obj) {
		    if (hasOwn(i)) {
		      if (i === key && obj[i].toLowerCase() == value.toLowerCase()) {
		        memo.push(obj);
		      } else if ('[object Array]' === ts.call(obj[i]) || '[object Object]' === ts.call(obj[i])) {
		        deepSearch(obj[i], key, value, memo);
		      }
		    }
		  }

		  return memo[0];
		}*/

		$scope.checkAll = function(bookName){
            var book = $scope.searchModels.collections[bookName];
            var control = book.all;
            if (book.contents !== undefined) {
              _.each(book.contents, function (content) {
                _.each(content.sections, function (section) {
                  section.selected = control;
                });
              });
            } else { // for journals and sentinels
              book.selected = control;
            }
        };

    $scope.uncheckBook = function (book) {
      if (book.id === 'journals') {
        _.each($scope.searchFactory.filters.periodicalsMonths, function (month) {
            month.selected = false;
        });
        book.year = '';
        $scope.pMonths = false;
        $scope.tooglePMonths();
      } else if (book.id === 'sentinels') {
        _.each($scope.searchFactory.filters.sentinelMonths, function (month) {
          month.selected = false;
        });
        book.year = '';
        $scope.sMonths = false;
        $scope.toogleSMonths();
      }
    };

    $scope.uncheckMonths = function (collection, nameMonth) {
      var monthId = collection.months.indexOf(nameMonth.id);
		  collection.months.splice(monthId, 1);
		  nameMonth.selected = false;

		  if (collection.months.length < 1) {
		    collection.year = '';
		    collection.selected = false;
      }
    };

		$scope.search = function( query, reset ){
			$scope.isSearching = true;

			var query = $scope.searchFactory.query;
			//query = query.replace('?', '\%3F');
			$scope.getCitationData(query);

			if( reset )
			{
				$scope.results = [];
				$scope.searchFactory.page = 0;
			}

			if( $scope.totalResults > 0 && $scope.searchFactory.page == $scope.totalResults ) return;

			var cleanedFilters = function( filters ){
				var filters = angular.copy( filters );
				// console.log( 'FILTERS!!!', filters.collections );
				// throw "This needs to be fixed before moving on. The Filters list is too long and needs to be filtered down in order to be sent to the server."

				return filters;
			}
			var searchPayload = getSearchPayload(searchFactory)
			;

			Restangular.all( 'search' ).withHttpConfig({timeout: 55000}).post(searchPayload).then(function( results ){
				$scope.totalResults = results.total;
				documentSearchFactory.searchFilters = cleanedFilters($scope.searchFactory.filters).collections;

				_.each(results.results, function( item ) {
					item.pageLimit = 10;
					if(item.highlights)
					{
						item.total = item.highlights.length;
					}
					else
					{
						item.total = 0;
					}
				});

				Array.prototype.push.apply( $scope.results, results.results );

				$scope.page = $scope.searchFactory.page = $scope.results.length;

				$scope.isSearching = false;
				$scope.sort = $scope.searchFactory.sort;
			}, function(error){
				$scope.isSearching = false;
			})
		}

		if( $scope.searchFactory.query || (!$scope.searchFactory.query && $scope.searchFactory.filters.selectedAuthor)) $scope.search(null, true);

		$scope.openResult = function(result){
			result.open = true;
		};
		$scope.closeResult = function(result){
			result.open = false;
		};

	}
])
.directive('scrollToTop', [ '$window', function( $window ) {
	return {
		restrict: 'A',
		scope: {
			scrollTo: "@"
		},
		link: function(scope, $elm,attr) {
			$elm.on('click', function() {
    			 $window.scrollTo(0,0)
			});

		}
	}
}])
.factory('searchFactory', function () {
	return {
	    query : '',
	    filters: {},
	    sort: 'score desc',
	    page: 0,
	    dirtyFilters: {}
	};
});
