// This is required!
// Note, when naming an angular directive, convert objects separated with dashes into camel case
var helpers = require('../../libs/helpers');
var pageHelpers = require('../../libs/page-helpers');

angular.module('koh').directive('readingBar', [
	'$rootScope',
	'booksService',
	'$location',
	'Restangular',
	'documentSearchFactory',
	'$http',
	'$timeout',
	'selectionService',
	'searchService',
	function(
		$rootScope,
		booksService,
		$location,
		Restangular,
		documentSearchFactory,
		$http,
		$timeout,
		selectionService,
		searchService
	){
	return {
	    transclude: true,
	    templateUrl: './reading-bar/reading-bar.html',
	    link: function ($scope, element) {

    	var setPath = function(chapter) {
				return chapter ? '/books/' + $scope.currentBook + '/' + $scope.getFile(chapter) : '';
			}

			var setNavVariables = function(index) {
				if ($rootScope.readingData == undefined || $rootScope.readingData.chapterList == undefined) {
					//console.log('Nav variables couldnt be set');
					//return;
				} else {
					if (!index || index == null) {
						//console.log('Cannot setNavVariables with null index');
						//return;
					}
					//console.log('Set nav variables worked');
				}
				$scope.chapterList = $rootScope.readingData.chapterList;

				var currentIndex = index != undefined ? index : $rootScope.readingData.currentIndex,
						mode = $rootScope.readingMode || '',
						offset = mode == 'dual-page' ? 2 : 1;


				if (currentIndex == undefined || currentIndex >= $scope.chapterList.length) return;
				if ($scope.chapterList[currentIndex]) {
					$scope.currentBook = $scope.chapterList[currentIndex].book;
					$scope.currentFolder = $scope.chapterList[currentIndex].folder;
					$scope.currentChapter = $scope.chapterList[currentIndex].fileName;
				}
				$scope.startIndex = currentIndex === 0 ? null : 0;
				$scope.endIndex 	= getEndIndex(currentIndex, offset, $scope.chapterList);
				$scope.nextIndex 	= getNextIndex(currentIndex, offset, $scope.chapterList);
				$scope.prevIndex 	= getPrevIndex(currentIndex, offset, $scope.chapterList);

				if(!_.isUndefined($scope.citationList) && $scope.citationList != null){
					$scope.prevCitation = getCitation($scope.citationList, -1);
					$scope.nextCitation = getCitation($scope.citationList, 1);
					var currentChapterFileName = $scope.chapterList[currentIndex].fileName;
          $scope.currentHBLChapter = _.filter($scope.citationList, {
            citation: _.filter($scope.citationList, function(citation) {
                return citation.section.replace(/-.*$/g, '') == currentChapterFileName;
              })[0].citation
          })[0].section;
          $rootScope.currentHBLChapter = $scope.currentHBLChapter;
          $scope.booksService.syncRootScope();
				} else if(!_.isUndefined($scope.collection)){
					if ($scope.collection[$scope.currentFolder]) {
						var bookList = $scope.collection[$scope.currentFolder].books;
						$scope.nextBook = isBookLinkActive(currentIndex, $scope.chapterList.length-1) ? getBook(bookList, 1) : null;
						$scope.prevBook = isBookLinkActive(currentIndex, 0) ? getBook(bookList, -1) : null;
					}
				}
				// $scope.updateHitList();

        $scope.prevHit = $scope.checkPagingAvailability(-1);
        $scope.nextHit = $scope.checkPagingAvailability(1);
			};

			var getEndIndex = function(currentIndex, offset, chapterList){
				var lastPage = chapterList.length-1;
				var index = currentIndex + offset > lastPage? null : lastPage;
				//special case for dual-page books ended on the left
				if(offset == 2 && currentIndex + 1 == lastPage) index = lastPage;
				return index;
			}

			var getNextIndex = function(currentIndex, offset, chapterList){
				var lastPage = chapterList.length-1;
				var index = currentIndex + offset > lastPage? null : currentIndex + offset;
				//special case for dual-page books ended on the left
				if(offset == 2 && currentIndex + 1 == lastPage) index = lastPage;
				return index;
			}

			var getPrevIndex = function(currentIndex, offset, chapterList){
				var firstPage = 0;
				var index = currentIndex == 0 ? null : currentIndex - offset;
				//special case for dual-page books ended on the left
				if(offset == 2 && currentIndex - 1 == firstPage) index = firstPage;
				return index;
			}

			var setNavTitle = function(index) {

				if ($rootScope.dirtyLinks) {
					if ($scope.currentIndex!=undefined && $scope.currentIndex != null) {
						if (document.location.pathname.indexOf($scope.chapterList[$scope.currentIndex].fileName)>=0) {
							//console.log('broadcasting invalidateLinks');
							//console.log('Getting currentBook Data')
							$rootScope.getCurrentBookData().then(function() {
								$rootScope.$broadcast('invalidateLinks');
							});
						}
					}
				}

				if (!$rootScope.readingData) {
					//console.log('No readging data in setNavTitle');
					//return;
				}
				if ($rootScope.readingData == undefined || $rootScope.readingData.chapterList == undefined) return index;
				var chapter = $rootScope.readingData.chapterList[index];
				if (index == null) {
					//console.log('Index is null in setBookTitle');
					//return '';
				}
				if (!chapter) {
					//console.log('No chapter');
				}
				return chapter == null? '' : chapter.title;
			}

			var setBookTitle = function(index){
				var bookName = helpers.getBookPath(window.location.pathname);
				if ($rootScope.readingData == undefined || $rootScope.readingData.chapterList == undefined) return index;
				var chapter = $rootScope.readingData.chapterList[index];
				return chapter == null? '' : chapter.bookTitle;
			}

			var isBookLinkActive = function(index, goal){
				if (index == goal) return true;
				if ($rootScope.readingMode != 'dual-page') return false;
				var sibIndex = index + ( $scope.chapterList[index].align == 'left' ? 1 : -1 )
				return sibIndex == goal;
			}

			var getBook = function(list, offset){
				//console.log('Getting book');
				var index = _.findIndex(list, { 'folder' : $scope.currentBook });
				if (index < 0 || index + offset >= list.length || index + offset < 0) return null;

				var book = list[index + offset];
        if (offset > 0){
				  book.path = '/reader/' + $scope.currentFolder + '/' + book.folder;
        } else{
          book.path = '/reader/' + $scope.currentFolder + '/' + book.folder + '/' + 'ref/-1';
        }
				return book;
			}

			var getCitation = function(list, offset){
				var cur = _.find(list, { 'section': $scope.currentChapter });
				if (!cur) {
					return cur;
				}
				var index = offset > 0 ? _.findLastIndex(list, { 'citation': cur.citation }) : _.findIndex(list, { 'citation': cur.citation });
				if (index < 0 || index + offset >= list.length || index + offset < 0) return null;

				var citation = list[index + offset];
				var path = '/reader/lessons/' + $scope.lessonDate + '/' + citation.section;
				return path;
			}

			var clearForm = function(){
				// var input = element.find('input')[0];
				var input = angular.element( document.querySelectorAll('.page-nav form input') );
				if ( input.hasClass('ng-dirty') ){
					input.val('');
					input[0].blur()
				}
			}

			$scope.reload = function() {
          $location.reload();
      };

      var getFolder = function() {
      	return helpers.getFolder($scope.$state);
      };

      $scope.isPeriodical = function() {
      	return helpers.isPeriodical($scope.$state);
      };

      $scope.isJournal = function() {
      	var folder = getFolder();
      	return folder === 'journals' || folder === 'periodicals';
      };

      $scope.isSentinel = function() {
      	return getFolder() === 'sentinels';
      };

			/* Used to set the current click actions for arrows in the template */
      $scope.setCurrent = function(page){
        if (page < 0 || _.isUndefined($scope.chapterList[page])){
            clearForm();
            return;
        }
        $rootScope.dirtyLinks = true;

        $scope.chapterList[page].align = $scope.chapterList[page].align != '' ? $scope.chapterList[page].align : 'left';
        $scope.currentIndex = page;
        $scope.currentBook = $scope.chapterList[page].book;
        $scope.currentFolder = $scope.chapterList[page].folder;
        $scope.ref = $scope.chapterList[page].ref;
        $scope.fileName = $scope.chapterList[page].fileName;
        $scope.searchIndex = $scope.chapterList[page].index;

        var chapter = $scope.currentPage = $scope.chapterList[page].fileName;
        var currentState = $rootScope.$state.current;
        var hitData = null;

        if( chapter == "" && $scope.ref )
        {
            $rootScope.$state.go('reader.book.chapterByReference', {'book': $scope.currentBook, 'folder': $scope.currentFolder, 'chapter': $scope.ref, 'initial': false, 'reference': true});
        }
        else if (chapter != currentState) {
            helpers.changePage($rootScope, chapter);
        }

        clearForm();
        $scope.currentChapter = $scope.fileName;
        setNavVariables();
        if(!$scope.currentIndex){
          $scope.setHighlight(0);
        }
      }


    $scope.page = {};
    $scope.setNavVariables = setNavVariables;
    $scope.setNavTitle = setNavTitle;
    $scope.setBookTitle = setBookTitle;

    // returns the page index ("physical page"), with count starting from 0
    $scope.getPageIndex = function(label) {
        var labels = _.pluck($rootScope.readingData.chapterList, 'title');
        var index = pageHelpers.getPageIndexForLabel(labels, label);

        var isFound = index !== -1;
        if(!isFound) {
            return -1;
        }

        // because pageHelpers start count from 1, but we need count from 0
        return index - 1;
    };

	// If reading mode changes, re-set nav variables
    $scope.$watch(function(scope) { return $rootScope.readingMode }, function(){ setNavVariables() });


      $scope.$watch(function(scope) { return $scope.currentChapter }, function(){
        setNavVariables();
      });

			//init after book is loadedor page is changed
			$rootScope.$on('pageChange', function(event, args) {
			    setNavVariables(args.currentIndex);
			});

			$scope.$on('initiatePageChange', function(event, numPages) {
			    $scope.setCurrent(numPages + $scope.currentIndex);
			});

			$scope.$on('clearHitList', function(){
				$scope.localHitList = [];
				documentSearchFactory.isSearching = false;
				$scope.isSearching = false;
			})

            $scope.$on('searchHitsLoaded', function () {
              if (!$scope.localHitList || !$scope.currentHitIndex) {
                $scope.localHitList = documentSearchFactory.pagedData;
                $scope.currentHitIndex = getCurrentHitIndex();
              }
              $scope.setHighlight(0);
            })

			$scope.isSearching = documentSearchFactory.isSearching;

			document.onkeydown = function(event){
				switch(event.keyCode){
					case 37: $scope.setCurrent($scope.prevIndex); break;
					case 39: $scope.setCurrent($scope.nextIndex); break;
					default: break;
				}
			};

			/* Search Hits */
      var getCurrentHitIndex = function(){
      	if( !$scope.localHitList || $scope.localHitList.length < 1) return false;
      	if( !$scope.localHitList[$scope.currentChapter]) return false;

        var active = $scope.localHitList[$scope.currentChapter].active;
      	if(!_.isArray(active) || active.length == 0) {
          return 0;
        }
      	var cur = $scope.localHitList[$scope.currentChapter].active[0];
      	return $scope.localHitList[$scope.currentChapter].list.indexOf(cur)
      }

      $scope.checkPagingAvailability = function( direction ){
        if (!$scope.localHitList || $scope.localHitList.length < 1) return false;
        if (!$scope.localHitList[$scope.currentChapter]) return false;

        var curList = $scope.localHitList[$scope.currentChapter].list;
        var newIndex = $scope.currentHitIndex + direction;

        if (newIndex < 0 || newIndex > curList.length - 1) {
          // check if more pages are available
          var newPageIndex = documentSearchFactory.pageList.indexOf($scope.currentChapter) + direction;
          return (newPageIndex > -1 && newPageIndex < documentSearchFactory.pageList.length);
        } else {
          return true;
        }
      }

      $scope.setHighlight = function( direction ){
      	var ACTIVE_CLASS = 'selected-result-active';

      	if( !$scope.localHitList || $scope.localHitList.length < 1) return false;
      	if( !$scope.localHitList[$scope.currentChapter]) return false;

        var curList = $scope.localHitList[$scope.currentChapter].list;
        var newIndex = $scope.currentHitIndex + direction;

        if(newIndex < 0 || newIndex > curList.length -1){

        	var newPageIndex = documentSearchFactory.pageList.indexOf($scope.currentChapter) + direction;
        	var newPage = documentSearchFactory.pageList[newPageIndex];
        	var index = _.findIndex($scope.chapterList, {'fileName': newPage});

        	$scope.currentHitIndex = direction > 0 ? 0 : $scope.localHitList[newPage].list.length -1;
        	$scope.setCurrent(index);
        }
        else{
        	$scope.currentHitIndex = newIndex;
        	selectionService.removeClass(ACTIVE_CLASS, $scope.currentChapter);

        	var selection = searchService.getSearchElements( new Array(curList[newIndex]) );
          selectionService.applyClass(selection, ACTIVE_CLASS);
          _.each(selection, function(jqEl){
          	helpers.scrollIntoViewIfOutOfView(jqEl[0]);
          });
        }

        $timeout(function() {
          $scope.prevHit = $scope.checkPagingAvailability(-1);
          $scope.nextHit = $scope.checkPagingAvailability(1);
        }, 0);
      }


			$scope.submitSearch = function(){
				var chapterData = $rootScope.readingData.chapterListCopy[$scope.currentIndex];
				documentSearchFactory.searchData = null;
				$scope.highlightIndex = 0;
				$scope.localHitList = null;
				$scope.currentHitList = null;
				$rootScope.$state.go( 'reader.book.chapter.search', { book: chapterData.book, chapter: chapterData.fileName, initial: false, search: documentSearchFactory.currentSearch }, { reload: true } );
			}
		}
	}
}])

