"use strict";

var _ = require('lodash');

angular
  .module('koh')
  .controller('ProfileController', ProfileController);

ProfileController.$inject = ['helpers', 'Restangular', '$rootScope', '$http', '$state', '$stateParams', 'apiUrl', '$window'];

function ProfileController(helpers, Restangular, $rootScope, $http, $state, $stateParams, apiUrl, $window) {
  var vm = this;

  vm.message = null;
  vm.showPassword = false;
  vm.password = '';
  vm.confirmPassword = '';
  vm.activeTab = 'details';
  vm.apiUrl = apiUrl;
  vm.user = $rootScope.user || helpers.fakeAccount;
  vm.highlightColors = [
    {
      id: 'orange',
      name: 'Orange'
    },
    {
      id: 'yellow',
      name: 'Yellow'
    },
    {
      id: 'green',
      name: 'Green'
    },
    {
      id: 'blue',
      name: 'Blue'
    },
    {
      id: 'purple',
      name: 'Purple'
    },
    {
      id: 'pink',
      name: 'Pink'
    }
  ];

  vm.isActive = isActive;
  vm.setActiveTab = setActiveTab;
  vm.logout = logout;
  vm.togglePassword = togglePassword;
  vm.savePassword = savePassword;
  vm.formatDate = formatDate;
  vm.globalAdvancedMode = globalAdvancedMode;
  vm.saveUser = saveUser;

  helpers.toggleMenu($rootScope, false);

  if ($stateParams.tab === 'details' || $stateParams.tab === 'preferences') {
    vm.activeTab = $stateParams.tab;
  }

  if ($rootScope.user) {
    Restangular.one('users', $rootScope.user._id).get().then(function(user) {
      vm.user = user;
    });
  }

  ;

  function setActiveTab(tab) {
    vm.activeTab = tab;
  }

  function isActive(tab, returnClass) {
    if (!_.isUndefined(returnClass) && returnClass != null) {
      if (tab == vm.activeTab) {
        return 'active';
      }
      return '';
    }
    return tab == vm.activeTab;
  }

  function logout() {
    ;
    $state.go('logout');
  }

  function togglePassword() {
    vm.showPassword = !vm.showPassword;
  }

  function savePassword() {
    if (vm.newPassword != vm.confirmPassword) {
      vm.error = 'Passwords don\'t match!';
      vm.message = null;
    } else {
      $http.post(apiUrl + '/api/password', {
        _id: vm.user._id,
        password: vm.currentPassword,
        newPassword: vm.newPassword,
        confirmPassword: vm.confirmPassword
      }).success(function(data, status, headers, config) {
        if( data && data.error ) {
          vm.error = data.error;
          vm.message = null;
        } else {
          vm.error = null;
          vm.message = 'Profile saved!';
        }
      }).
      error(function(data, status, headers, config) {
        vm.error = data.error;
      });
      vm.showPassword = false;
    }
  }

  function formatDate(obj) {
    var date = new Date(obj);
    return (date.getMonth() + 1) + '/' + date.getDate() + '/' + date.getFullYear();
  }

  function globalAdvancedMode() {
    return $rootScope.advancedMode;
  }

  function saveUser() {
    ;

    if (!_.isUndefined(vm.currentPassword) && vm.currentPassword != null) {
      ;

      vm.savePassword();
      vm.message = null;
      vm.error = null;
    } else {
      vm.user.put().then(function(result) {
        vm.message = 'Profile saved!';
        vm.error = null;
        $rootScope.user = angular.copy(vm.user);
        if ($rootScope.advancedMode == false) {
          $rootScope.user.usertype = 'casual';
        }
        $window.scrollTo(0,0);
      });
    }
  }
}
