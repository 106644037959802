'use strict';
var _ = require('lodash');
var helpers = require('./helpers');

angular.module('koh').factory('readerService', 
  function(Restangular, dataService, $rootScope) {
    var playlistObject;
    var getBookXml = function(folder, book) {
        return $rootScope.getCacheValue(book, 'bookxml', function() { return dataService.fetchBookXml(folder,book) });        
    },
    getLessonObj = function(lessonDate){
        var lessonsObject = Restangular.all('lessons');
        return lessonsObject.all('file').one(lessonDate).get();
    },
    getLessonTOC = function(lessonDate){
        var date = helpers.createDate(lessonDate);
        var endDate  = helpers.getLessonDate( date, 14 );
        var startDate  = helpers.getLessonDate( date, -14 );
        ;
        var query = { 'originalDate': { '$gte': startDate, '$lte':  endDate} };
        playlistObject = playlistObject || Restangular.all('playlist');
        return playlistObject.all('search').post(query);
    },
    getPlayListObj = function(lessonDate){
        playlistObject = playlistObject || Restangular.all('playlist');
        return playlistObject.one('file').one(lessonDate).get();
    },
    getSelectionsObj = function($rootScope){
        var selectionsObject = Restangular.all('selections');
        if ($rootScope.user && $rootScope.user._id){
            return selectionsObject.one($rootScope.user._id).getList();
        } else {
            return {};
        }
    };
    return {
        getBookXml: getBookXml,
        getLessonObj: getLessonObj,
        getLessonTOC: getLessonTOC,
        getPlayListObj: getPlayListObj,
        getSelectionsObj: getSelectionsObj
    }
 });
