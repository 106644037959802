var _ = require('lodash');

var helpers = require('../../libs/helpers');

angular.module('koh').directive('bookPage', [
	'pageLoaderService',
	'selectionService',
	'$rootScope',
	'$document',
	'$timeout',
  'booksService',
  'documentSearchFactory',
	function(
		pageLoaderService,
		selectionService,
		$rootScope,
		$document,
		$timeout,
	    booksService,
		documentSearchFactory
	){
		function controller( $scope ){
			
			if ($rootScope.readingData.chapterList == null) return;
			selectionService.init($rootScope.readingData.chapterList);

			$scope.pageLoader = pageLoaderService;
			$scope.pageLoader.init($scope, $scope.list);

			$scope.$on('linkComplete', function(event, args) {
		    $scope.pageLoader.loadPages($rootScope.readingData.currentChapter);
			});

			$scope.$on('pageChange', function(event, args) {
		    $scope.currentPage = args.chapter;
		    $rootScope.readingData.currentChapter = args.chapter;
		    $scope.pageLoader.loadPages($scope.currentPage);
			});
	    $scope.$on('clearSelection', selectionService.removeSelection);

	    $scope.getHBLScale = function () {
	     	var currentBook = $scope.$root.readingData.currentBook;
	     	
	     	if (currentBook == 'SH') {
	     		return 'scaleHBLSH';
	     	}
	     	if (currentBook == 'Bible') {
	     		return 'scaleHBLBible';
	     	}
			return 'scaleHBLBook';
		}
		}


    $rootScope.isActiveChapter = function ($item){
		  var mode = $rootScope.readingMode;
		  if ('continuous' === mode) {
		    return true;
      }
      var current = $rootScope.readingData.currentChapter,
          fileName = $item.page.fileName;
      if ('dual-page' === mode) {
		    var sibling = $item.page.align == 'left'? $item.$$nextSibling : $item.$$prevSibling;
        if (!sibling || _.isUndefined(sibling.page)) {
          return fileName === current;
        }
        return fileName === current || sibling.page.fileName === current;
      }
      return fileName === current;
    }



    function activateContextMenu(touchEvent, element){
	    	touchEvent.preventDefault();
	    	var rect = element.getClientRects();
	    	if (rect.length){
	    		var box = rect[0];
		    	var ev = new MouseEvent('contextmenu', {
			    		clientX : box.right + 20,
			    		clientY : box.bottom + 20
			    	});
		    	Object.defineProperty(ev, 'target', {value: element, enumerable: true});
		    	var target = element.closest('div[context-menu]')
		    	$timeout(function(){
		  			target.dispatchEvent(ev);
		    	});
		    }
	    }

		function link( $scope, element, attributes ) {
			var page = $scope.page;
			page.documentSearchFactory = documentSearchFactory;
			var lazyPage = new $scope.pageLoader.createLazyPage( element, $rootScope, $scope, page );
    	$scope.element = element;
      $scope.pageLoader.addPage( lazyPage );

      // Linking is complete for all list items - let controller know
      if ($scope.$parent.$last) {
          $timeout(function () {
              $scope.$emit('linkComplete');
          });
      }

      $scope.$on('$destroy', function(){
      	lazyPage.unbindHandlers()
      });

      $scope.menu = angular.element( document.getElementById('koh-context-menu') );

		  var tabletSelection = _.debounce(function(e){
		  	$scope.menu.removeClass('open');
		  	var selection = setSelection(selectionService.getTabletSelection());
		  	if ( selection.length && selection[0] != null){
    			activateContextMenu(e, selection.pop());
    		}
    		$rootScope.selectionNodes = selection;
		  }, 500);

      element.bind('touchend', tabletSelection);
      if(navigator.userAgent.match(/(iPad)/)){
      	document.onselectionchange = tabletSelection;
		    angular.element( document.querySelector( '#page-container' )).addClass('mob');
      }

      // Show Selection Text: assigned to ng-mouseup
			$scope.showSelectedText = function($event) {
				//console.log('Showing selected text');
				if ($event && $event.which != 1) return; //disregard right clicks
				$timeout(function(){
					var selection = selectionService.highlightSelection();
		  		setSelection(selection);
				}, 100);
	    };

	    // Set right click word: assigned to ngRightClick and hmPress
	    $scope.setRightClickWord = function($event, isTablet){
	      var currentWord = '',
	      		currentId = '',
	      		src = $event.target || $event.srcElement || $event.originalTarget;
	      if (!_.isUndefined(src)){
	        var target = angular.element(src);
	        if (src.nodeName != 'SPAN') target = target.parent();
	        if (src.nodeName == 'SPAN'){
	          currentWord = getCurrentWord(target);
	          currentId = target.attr('id');
	        }
	      }
	      $scope.$emit('currentWordSet', { word: currentWord, id: currentId });
        if(!$rootScope.selectionApplied) selectionService.highlightSelection(new Array(target[0]));
	      if(isTablet) tabletSelection($event);
	    };

	    var getCurrentWord = function(node){
        var currentWord = node.text();
        //check for split words
        var next = getText(node, 1);
        var prev = getText(node, -1);
        if (currentWord.endsWith('-')){
          currentWord = currentWord.substr(0, currentWord.length -1) + next;
        }
        if (prev.endsWith('-')){
          currentWord = prev.substr(0, prev.length -1) + currentWord;
        }
        return currentWord.trim().replace(/^([.,\/#!$%\^&\*;:{}=\-_`~()"”']+)|([.,\/#!$%\^&\*;:{}=\-_`~()”"']+)$/g, "");
      };

      var getText = function(node, diff){
        var id = node.attr('id');
        var nextNode = angular.element(document.getElementById(helpers.getNextId(id, diff)));
        return nextNode.length > 0 ? nextNode.text().trim() : "";
      };

	    $scope.onSwipe = function(numPages){
	    	$rootScope.$broadcast('initiatePageChange', numPages);
	    };

	    // assigned to ng-click Tools Menu, Context Menu
		  $rootScope.replaceSelection = function(cb) {
        if ($rootScope.selectionNodes) {
          var selection = selectionService.highlightSelection($rootScope.selectionNodes);
          setSelection(selection);
          delete $rootScope.selectionNodes;
          if (cb) cb();
        }
      };

	    function setSelection(selectionNodes){
				if (selectionNodes.length) {
					$scope.$emit('selectionSet', {
						'selectionNodes' :  selectionNodes,
						'selectionObj' : selectionService.getSelectionObject(selectionNodes)
					});
				}
				return selectionNodes;
	    }
		}

	  return {
	    restrict: 'E',
	    templateUrl: './book-page/book-page.html',
	    link: link,
	    controller: controller,
	    scope: {
	    	page: '=',
	    	list: '='
	    }
	  }
}])
.directive('ngRightClick', function($parse) {
    return function(scope, element, attrs) {
        var fn = $parse(attrs.ngRightClick);
        element.bind('contextmenu', function(event) {
            scope.$apply(function() {
                event.preventDefault();
                fn(scope, {$event:event});
            });
        });
    };
});
