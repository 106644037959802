
/**
 * Directive for outputting and handling a dropdown of US States.
 *
 * @author James Huston <jhuston@redventures.com>
 * @since 2013-07-10
 */

angular.module('koh').directive('angSelectUsstates', function() {
  return {
    restrict: 'E',
    templateUrl: './directives/usstates/usstates.tpl.html',
    replace: true,
    scope: true,

    link: function ($scope, element, attributes) {
      $scope.emptyName = attributes.emptyname || 'Select State';
      var iscountry = attributes.type === 'country';
      $scope.list = iscountry ? $scope.countries : $scope.states;
    },

    controller: [ "$scope", function ($scope) {
      $scope.states = [
        {
            "name": "Alabama",
            "abbreviation": "AL"
        },
        {
            "name": "Alaska",
            "abbreviation": "AK"
        },
        {
            "name": "Arizona",
            "abbreviation": "AZ"
        },
        {
            "name": "Arkansas",
            "abbreviation": "AR"
        },
        {
            "name": "California",
            "abbreviation": "CA"
        },
        {
            "name": "Colorado",
            "abbreviation": "CO"
        },
        {
            "name": "Connecticut",
            "abbreviation": "CT"
        },
        {
            "name": "Delaware",
            "abbreviation": "DE"
        },
        {
            "name": "District Of Columbia",
            "abbreviation": "DC"
        },
        {
            "name": "Florida",
            "abbreviation": "FL"
        },
        {
            "name": "Georgia",
            "abbreviation": "GA"
        },
        {
            "name": "Hawaii",
            "abbreviation": "HI"
        },
        {
            "name": "Idaho",
            "abbreviation": "ID"
        },
        {
            "name": "Illinois",
            "abbreviation": "IL"
        },
        {
            "name": "Indiana",
            "abbreviation": "IN"
        },
        {
            "name": "Iowa",
            "abbreviation": "IA"
        },
        {
            "name": "Kansas",
            "abbreviation": "KS"
        },
        {
            "name": "Kentucky",
            "abbreviation": "KY"
        },
        {
            "name": "Louisiana",
            "abbreviation": "LA"
        },
        {
            "name": "Maine",
            "abbreviation": "ME"
        },
        {
            "name": "Maryland",
            "abbreviation": "MD"
        },
        {
            "name": "Massachusetts",
            "abbreviation": "MA"
        },
        {
            "name": "Michigan",
            "abbreviation": "MI"
        },
        {
            "name": "Minnesota",
            "abbreviation": "MN"
        },
        {
            "name": "Mississippi",
            "abbreviation": "MS"
        },
        {
            "name": "Missouri",
            "abbreviation": "MO"
        },
        {
            "name": "Montana",
            "abbreviation": "MT"
        },
        {
            "name": "Nebraska",
            "abbreviation": "NE"
        },
        {
            "name": "Nevada",
            "abbreviation": "NV"
        },
        {
            "name": "New Hampshire",
            "abbreviation": "NH"
        },
        {
            "name": "New Jersey",
            "abbreviation": "NJ"
        },
        {
            "name": "New Mexico",
            "abbreviation": "NM"
        },
        {
            "name": "New York",
            "abbreviation": "NY"
        },
        {
            "name": "North Carolina",
            "abbreviation": "NC"
        },
        {
            "name": "North Dakota",
            "abbreviation": "ND"
        },
        {
            "name": "Ohio",
            "abbreviation": "OH"
        },
        {
            "name": "Oklahoma",
            "abbreviation": "OK"
        },
        {
            "name": "Oregon",
            "abbreviation": "OR"
        },
        {
            "name": "Pennsylvania",
            "abbreviation": "PA"
        },
        {
            "name": "Rhode Island",
            "abbreviation": "RI"
        },
        {
            "name": "South Carolina",
            "abbreviation": "SC"
        },
        {
            "name": "South Dakota",
            "abbreviation": "SD"
        },
        {
            "name": "Tennessee",
            "abbreviation": "TN"
        },
        {
            "name": "Texas",
            "abbreviation": "TX"
        },
        {
            "name": "Utah",
            "abbreviation": "UT"
        },
        {
            "name": "Vermont",
            "abbreviation": "VT"
        },
        {
            "name": "Virginia",
            "abbreviation": "VA"
        },
        {
            "name": "Washington",
            "abbreviation": "WA"
        },
        {
            "name": "West Virginia",
            "abbreviation": "WV"
        },
        {
            "name": "Wisconsin",
            "abbreviation": "WI"
        },
        {
            "name": "Wyoming",
            "abbreviation": "WY"
        }
      ];

      $scope.countries = [
        {"name": "United States", "abbreviation": "United States"},
        {"name": "Canada", "abbreviation": "Canada"},
        {"name": "Afghanistan", "abbreviation": "Afghanistan"},
        {"name": "Albania", "abbreviation": "Albania"},
        {"name": "Algeria", "abbreviation": "Algeria"},
        {"name": "American Samoa", "abbreviation": "American Samoa"},
        {"name": "Andorra", "abbreviation": "Andorra"},
        {"name": "Angola", "abbreviation": "Angola"},
        {"name": "Anguilla", "abbreviation": "Anguilla"},
        {"name": "Antarctica", "abbreviation": "Antarctica"},
        {"name": "Antigua and/or Barbuda", "abbreviation": "Antigua and/or Barbuda"},
        {"name": "Argentina", "abbreviation": "Argentina"},
        {"name": "Armenia", "abbreviation": "Armenia"},
        {"name": "Aruba", "abbreviation": "Aruba"},
        {"name": "Australia", "abbreviation": "Australia"},
        {"name": "Austria", "abbreviation": "Austria"},
        {"name": "Azerbaijan", "abbreviation": "Azerbaijan"},
        {"name": "Bahamas", "abbreviation": "Bahamas"},
        {"name": "Bahrain", "abbreviation": "Bahrain"},
        {"name": "Bangladesh", "abbreviation": "Bangladesh"},
        {"name": "Barbados", "abbreviation": "Barbados"},
        {"name": "Belarus", "abbreviation": "Belarus"},
        {"name": "Belgium", "abbreviation": "Belgium"},
        {"name": "Belize", "abbreviation": "Belize"},
        {"name": "Benin", "abbreviation": "Benin"},
        {"name": "Bermuda", "abbreviation": "Bermuda"},
        {"name": "Bhutan", "abbreviation": "Bhutan"},
        {"name": "Bolivia", "abbreviation": "Bolivia"},
        {"name": "Bosnia and Herzegovina", "abbreviation": "Bosnia and Herzegovina"},
        {"name": "Botswana", "abbreviation": "Botswana"},
        {"name": "Bouvet Island", "abbreviation": "Bouvet Island"},
        {"name": "Brazil", "abbreviation": "Brazil"},
        {"name": "British lndian Ocean Territory", "abbreviation": "British lndian Ocean Territory"},
        {"name": "Brunei Darussalam", "abbreviation": "Brunei Darussalam"},
        {"name": "Bulgaria", "abbreviation": "Bulgaria"},
        {"name": "Burkina Faso", "abbreviation": "Burkina Faso"},
        {"name": "Burundi", "abbreviation": "Burundi"},
        {"name": "Cambodia", "abbreviation": "Cambodia"},
        {"name": "Cameroon", "abbreviation": "Cameroon"},
        {"name": "Cape Verde", "abbreviation": "Cape Verde"},
        {"name": "Cayman Islands", "abbreviation": "Cayman Islands"},
        {"name": "Central African Republic", "abbreviation": "Central African Republic"},
        {"name": "Chad", "abbreviation": "Chad"},
        {"name": "Chile", "abbreviation": "Chile"},
        {"name": "China", "abbreviation": "China"},
        {"name": "Christmas Island", "abbreviation": "Christmas Island"},
        {"name": "Cocos (Keeling) Islands", "abbreviation": "Cocos (Keeling) Islands"},
        {"name": "Colombia", "abbreviation": "Colombia"},
        {"name": "Comoros", "abbreviation": "Comoros"},
        {"name": "Congo", "abbreviation": "Congo"},
        {"name": "Cook Islands", "abbreviation": "Cook Islands"},
        {"name": "Costa Rica", "abbreviation": "Costa Rica"},
        {"name": "Croatia (Hrvatska)", "abbreviation": "Croatia (Hrvatska)"},
        {"name": "Cuba", "abbreviation": "Cuba"},
        {"name": "Cyprus", "abbreviation": "Cyprus"},
        {"name": "Czech Republic", "abbreviation": "Czech Republic"},
        {"name": "Denmark", "abbreviation": "Denmark"},
        {"name": "Djibouti", "abbreviation": "Djibouti"},
        {"name": "Dominica", "abbreviation": "Dominica"},
        {"name": "Dominican Republic", "abbreviation": "Dominican Republic"},
        {"name": "East Timor", "abbreviation": "East Timor"},
        {"name": "Ecudaor", "abbreviation": "Ecudaor"},
        {"name": "Egypt", "abbreviation": "Egypt"},
        {"name": "El Salvador", "abbreviation": "El Salvador"},
        {"name": "Equatorial Guinea", "abbreviation": "Equatorial Guinea"},
        {"name": "Eritrea", "abbreviation": "Eritrea"},
        {"name": "Estonia", "abbreviation": "Estonia"},
        {"name": "Ethiopia", "abbreviation": "Ethiopia"},
        {"name": "Falkland Islands (Malvinas)", "abbreviation": "Falkland Islands (Malvinas)"},
        {"name": "Faroe Islands", "abbreviation": "Faroe Islands"},
        {"name": "Fiji", "abbreviation": "Fiji"},
        {"name": "Finland", "abbreviation": "Finland"},
        {"name": "France", "abbreviation": "France"},
        {"name": "France, Metropolitan", "abbreviation": "France, Metropolitan"},
        {"name": "French Guiana", "abbreviation": "French Guiana"},
        {"name": "French Polynesia", "abbreviation": "French Polynesia"},
        {"name": "French Southern Territories", "abbreviation": "French Southern Territories"},
        {"name": "Gabon", "abbreviation": "Gabon"},
        {"name": "Gambia", "abbreviation": "Gambia"},
        {"name": "Georgia", "abbreviation": "Georgia"},
        {"name": "Germany", "abbreviation": "Germany"},
        {"name": "Ghana", "abbreviation": "Ghana"},
        {"name": "Gibraltar", "abbreviation": "Gibraltar"},
        {"name": "Greece", "abbreviation": "Greece"},
        {"name": "Greenland", "abbreviation": "Greenland"},
        {"name": "Grenada", "abbreviation": "Grenada"},
        {"name": "Guadeloupe", "abbreviation": "Guadeloupe"},
        {"name": "Guam", "abbreviation": "Guam"},
        {"name": "Guatemala", "abbreviation": "Guatemala"},
        {"name": "Guinea", "abbreviation": "Guinea"},
        {"name": "Guinea-Bissau", "abbreviation": "Guinea-Bissau"},
        {"name": "Guyana", "abbreviation": "Guyana"},
        {"name": "Haiti", "abbreviation": "Haiti"},
        {"name": "Heard and Mc Donald Islands", "abbreviation": "Heard and Mc Donald Islands"},
        {"name": "Honduras", "abbreviation": "Honduras"},
        {"name": "Hong Kong", "abbreviation": "Hong Kong"},
        {"name": "Hungary", "abbreviation": "Hungary"},
        {"name": "Iceland", "abbreviation": "Iceland"},
        {"name": "India", "abbreviation": "India"},
        {"name": "Indonesia", "abbreviation": "Indonesia"},
        {"name": "Iran (Islamic Republic of)", "abbreviation": "Iran (Islamic Republic of)"},
        {"name": "Iraq", "abbreviation": "Iraq"},
        {"name": "Ireland", "abbreviation": "Ireland"},
        {"name": "Israel", "abbreviation": "Israel"},
        {"name": "Italy", "abbreviation": "Italy"},
        {"name": "Ivory Coast", "abbreviation": "Ivory Coast"},
        {"name": "Jamaica", "abbreviation": "Jamaica"},
        {"name": "Japan", "abbreviation": "Japan"},
        {"name": "Jordan", "abbreviation": "Jordan"},
        {"name": "Kazakhstan", "abbreviation": "Kazakhstan"},
        {"name": "Kenya", "abbreviation": "Kenya"},
        {"name": "Kiribati", "abbreviation": "Kiribati"},
        {"name": "Korea, Democratic People's Republic of", "abbreviation": "Korea, Democratic People's Republic of"},
        {"name": "Korea, Republic of", "abbreviation": "Korea, Republic of"},
        {"name": "Kuwait", "abbreviation": "Kuwait"},
        {"name": "Kyrgyzstan", "abbreviation": "Kyrgyzstan"},
        {"name": "Lao People's Democratic Republic", "abbreviation": "Lao People's Democratic Republic"},
        {"name": "Latvia", "abbreviation": "Latvia"},
        {"name": "Lebanon", "abbreviation": "Lebanon"},
        {"name": "Lesotho", "abbreviation": "Lesotho"},
        {"name": "Liberia", "abbreviation": "Liberia"},
        {"name": "Libyan Arab Jamahiriya", "abbreviation": "Libyan Arab Jamahiriya"},
        {"name": "Liechtenstein", "abbreviation": "Liechtenstein"},
        {"name": "Lithuania", "abbreviation": "Lithuania"},
        {"name": "Luxembourg", "abbreviation": "Luxembourg"},
        {"name": "Macau", "abbreviation": "Macau"},
        {"name": "Macedonia", "abbreviation": "Macedonia"},
        {"name": "Madagascar", "abbreviation": "Madagascar"},
        {"name": "Malawi", "abbreviation": "Malawi"},
        {"name": "Malaysia", "abbreviation": "Malaysia"},
        {"name": "Maldives", "abbreviation": "Maldives"},
        {"name": "Mali", "abbreviation": "Mali"},
        {"name": "Malta", "abbreviation": "Malta"},
        {"name": "Marshall Islands", "abbreviation": "Marshall Islands"},
        {"name": "Martinique", "abbreviation": "Martinique"},
        {"name": "Mauritania", "abbreviation": "Mauritania"},
        {"name": "Mauritius", "abbreviation": "Mauritius"},
        {"name": "Mayotte", "abbreviation": "Mayotte"},
        {"name": "Mexico", "abbreviation": "Mexico"},
        {"name": "Micronesia, Federated States of", "abbreviation": "Micronesia, Federated States of"},
        {"name": "Moldova, Republic of", "abbreviation": "Moldova, Republic of"},
        {"name": "Monaco", "abbreviation": "Monaco"},
        {"name": "Mongolia", "abbreviation": "Mongolia"},
        {"name": "Montserrat", "abbreviation": "Montserrat"},
        {"name": "Morocco", "abbreviation": "Morocco"},
        {"name": "Mozambique", "abbreviation": "Mozambique"},
        {"name": "Myanmar", "abbreviation": "Myanmar"},
        {"name": "Namibia", "abbreviation": "Namibia"},
        {"name": "Nauru", "abbreviation": "Nauru"},
        {"name": "Nepal", "abbreviation": "Nepal"},
        {"name": "Netherlands", "abbreviation": "Netherlands"},
        {"name": "Netherlands Antilles", "abbreviation": "Netherlands Antilles"},
        {"name": "New Caledonia", "abbreviation": "New Caledonia"},
        {"name": "New Zealand", "abbreviation": "New Zealand"},
        {"name": "Nicaragua", "abbreviation": "Nicaragua"},
        {"name": "Niger", "abbreviation": "Niger"},
        {"name": "Nigeria", "abbreviation": "Nigeria"},
        {"name": "Niue", "abbreviation": "Niue"},
        {"name": "Norfork Island", "abbreviation": "Norfork Island"},
        {"name": "Northern Mariana Islands", "abbreviation": "Northern Mariana Islands"},
        {"name": "Norway", "abbreviation": "Norway"},
        {"name": "Oman", "abbreviation": "Oman"},
        {"name": "Pakistan", "abbreviation": "Pakistan"},
        {"name": "Palau", "abbreviation": "Palau"},
        {"name": "Panama", "abbreviation": "Panama"},
        {"name": "Papua New Guinea", "abbreviation": "Papua New Guinea"},
        {"name": "Paraguay", "abbreviation": "Paraguay"},
        {"name": "Peru", "abbreviation": "Peru"},
        {"name": "Philippines", "abbreviation": "Philippines"},
        {"name": "Pitcairn", "abbreviation": "Pitcairn"},
        {"name": "Poland", "abbreviation": "Poland"},
        {"name": "Portugal", "abbreviation": "Portugal"},
        {"name": "Puerto Rico", "abbreviation": "Puerto Rico"},
        {"name": "Qatar", "abbreviation": "Qatar"},
        {"name": "Reunion", "abbreviation": "Reunion"},
        {"name": "Romania", "abbreviation": "Romania"},
        {"name": "Russian Federation", "abbreviation": "Russian Federation"},
        {"name": "Rwanda", "abbreviation": "Rwanda"},
        {"name": "Saint Kitts and Nevis", "abbreviation": "Saint Kitts and Nevis"},
        {"name": "Saint Lucia", "abbreviation": "Saint Lucia"},
        {"name": "Saint Vincent and the Grenadines", "abbreviation": "Saint Vincent and the Grenadines"},
        {"name": "Samoa", "abbreviation": "Samoa"},
        {"name": "San Marino", "abbreviation": "San Marino"},
        {"name": "Sao Tome and Principe", "abbreviation": "Sao Tome and Principe"},
        {"name": "Saudi Arabia", "abbreviation": "Saudi Arabia"},
        {"name": "Senegal", "abbreviation": "Senegal"},
        {"name": "Seychelles", "abbreviation": "Seychelles"},
        {"name": "Sierra Leone", "abbreviation": "Sierra Leone"},
        {"name": "Singapore", "abbreviation": "Singapore"},
        {"name": "Slovakia", "abbreviation": "Slovakia"},
        {"name": "Slovenia", "abbreviation": "Slovenia"},
        {"name": "Solomon Islands", "abbreviation": "Solomon Islands"},
        {"name": "Somalia", "abbreviation": "Somalia"},
        {"name": "South Africa", "abbreviation": "South Africa"},
        {"name": "South Georgia South Sandwich Islands", "abbreviation": "South Georgia South Sandwich Islands"},
        {"name": "Spain", "abbreviation": "Spain"},
        {"name": "Sri Lanka", "abbreviation": "Sri Lanka"},
        {"name": "St. Helena", "abbreviation": "St. Helena"},
        {"name": "St. Pierre and Miquelon", "abbreviation": "St. Pierre and Miquelon"},
        {"name": "Sudan", "abbreviation": "Sudan"},
        {"name": "Suriname", "abbreviation": "Suriname"},
        {"name": "Svalbarn and Jan Mayen Islands", "abbreviation": "Svalbarn and Jan Mayen Islands"},
        {"name": "Swaziland", "abbreviation": "Swaziland"},
        {"name": "Sweden", "abbreviation": "Sweden"},
        {"name": "Switzerland", "abbreviation": "Switzerland"},
        {"name": "Syrian Arab Republic", "abbreviation": "Syrian Arab Republic"},
        {"name": "Taiwan", "abbreviation": "Taiwan"},
        {"name": "Tajikistan", "abbreviation": "Tajikistan"},
        {"name": "Tanzania, United Republic of", "abbreviation": "Tanzania, United Republic of"},
        {"name": "Thailand", "abbreviation": "Thailand"},
        {"name": "Togo", "abbreviation": "Togo"},
        {"name": "Tokelau", "abbreviation": "Tokelau"},
        {"name": "Tonga", "abbreviation": "Tonga"},
        {"name": "Trinidad and Tobago", "abbreviation": "Trinidad and Tobago"},
        {"name": "Tunisia", "abbreviation": "Tunisia"},
        {"name": "Turkey", "abbreviation": "Turkey"},
        {"name": "Turkmenistan", "abbreviation": "Turkmenistan"},
        {"name": "Turks and Caicos Islands", "abbreviation": "Turks and Caicos Islands"},
        {"name": "Tuvalu", "abbreviation": "Tuvalu"},
        {"name": "Uganda", "abbreviation": "Uganda"},
        {"name": "Ukraine", "abbreviation": "Ukraine"},
        {"name": "United Arab Emirates", "abbreviation": "United Arab Emirates"},
        {"name": "United Kingdom", "abbreviation": "United Kingdom"},
        {"name": "United States minor outlying islands", "abbreviation": "United States minor outlying islands"},
        {"name": "Uruguay", "abbreviation": "Uruguay"},
        {"name": "Uzbekistan", "abbreviation": "Uzbekistan"},
        {"name": "Vanuatu", "abbreviation": "Vanuatu"},
        {"name": "Vatican City State", "abbreviation": "Vatican City State"},
        {"name": "Venezuela", "abbreviation": "Venezuela"},
        {"name": "Vietnam", "abbreviation": "Vietnam"},
        {"name": "Virigan Islands (British)", "abbreviation": "Virigan Islands (British)"},
        {"name": "Virgin Islands (U.S.)", "abbreviation": "Virgin Islands (U.S.)"},
        {"name": "Wallis and Futuna Islands", "abbreviation": "Wallis and Futuna Islands"},
        {"name": "Western Sahara", "abbreviation": "Western Sahara"},
        {"name": "Yemen", "abbreviation": "Yemen"},
        {"name": "Yugoslavia", "abbreviation": "Yugoslavia"},
        {"name": "Zaire", "abbreviation": "Zaire"},
        {"name": "Zambia", "abbreviation": "Zambia"},
        {"name": "Zimbabwe", "abbreviation": "Zimbabwe"}
    ]

    }]

  };
});