angular.module('koh').controller('UserSelectionsCtrl', [
	'$scope',
	'$rootScope',
	'$stateParams',
	'Restangular',
	'helpers',
	'$sce',
	function($scope,
		$rootScope,
		$stateParams,
		Restangular,
		helpers,
		$sce
		) {
		var user_id = '556f16ab0779d03b67d69d16';
		$scope.bookmarks = [];
		$scope.highlights = [];
		$scope.notes = [];
		
		$scope.highlights = {
			itemsPerPage: 5,
		  currentPage: 0,
		  total: 0,
		  totalItems: [],
		  pagedItems: [],
		}

		/* Extend active flags, ovewriting current values */
		_.assign($scope.$parent.activeFlags,
			{
				selection: 'bookmarks',
				sort: 'alphabetical',
				additionType: null
			});
		;
		$scope.activeFlags = $scope.$parent.activeFlags;

		var userdataObject = Restangular.all('selections');

		var htmlToPlaintext = function(bmObj) {
			bmObj.safeNote = $sce.trustAsHtml(bmObj.note)
		};

		var createSafeMarkup = function() {
			_.each($scope.bookmarks, function(row) {
				htmlToPlaintext(row)
			});
		}
		var waitEditNote = function() {
			try {
				
				if (!_.isUndefined(document.getElementsByTagName('IFRAME')[0].contentDocument.getElementsByClassName('cke_editable')[0])) {
					//if (document.getElementsByTagName('IFRAME')[0].contentDocument.getElementsByClassName('cke_editable')[0].innerHTML)
					if (!_.isUndefined($stateParams.note_id)) {
						;
						$scope.selectedNote = _.find($scope.notes, function(row) {
							return row._id == $stateParams.note_id;
						});
						$scope.editNote($scope.selectedNote);
					}
				}
				else {
					;
					setTimeout(waitEditNote, 25)
				}
			}
			catch (err) {
				;
				setTimeout(waitEditNote, 25)
			}
		}


		$scope.activeSort = { type:'date', order: '-' };
		$scope.sort = function() {
			return $scope.activeSort.order + '' + $scope.activeSort.type;
		};

		$scope.setSort = function(type, order) {
			var newType = type;
			if (type == 'alphabetical') {
				// Lets leave some room for the inevitable change in the alpha sort
				if ($scope.activeFlags['selection'] == 'bookmarks') {
					newType = 'section';
				} else {
					newType = 'cleanCitation';	
				}				
			}
			if (type == 'chronological') {
				// Lets leave some room for the inevitable change in the alpha sort
				newType = 'date';
			}
			if ($scope.activeSort.type == newType) {
				if ($scope.activeSort.order == '-') {
					order = '+';
				}
				else {
					order = '-';
				}
			}
			$scope.activeSort.type = newType;
			$scope.activeSort.order = order;
			$scope.setActiveType('sort',type);
			;	
		};

		$scope.editNote = function(note) {
		  if(note.type=='bookmark') {
			  	document.getElementsByTagName('IFRAME')[0].contentDocument.getElementsByClassName('cke_editable')[0].innerHTML = note.note;	
			} else {
				document.getElementsByTagName('IFRAME')[0].contentDocument.getElementsByClassName('cke_editable')[0].innerHTML = note.note;	

			}
			$scope.linkObj = _.clone(note, true);
			$scope.setActiveType('additionType','note');
		}

		$scope.saveSelection = function() {
			;
		 	$scope.linkObj.route = $scope.linkObj._id;
		 	var innerNote = '';
		 	try {
		 		innerNote = document.getElementsByTagName('IFRAME')[0].contentDocument.getElementsByClassName('cke_editable')[0].innerHTML;	
			}
			catch (err) {
				;
			}
			$scope.linkObj.note = innerNote;
			Restangular.copy($scope.linkObj).put().then(function() {
		 		loadSelections();
		 		$scope.setActiveType('additionType',null);
		 	});
		 };
		$scope.confirmRemoveBookMark = function(id) {
			Restangular.one('selections', id).remove().then(function(err, res) {
				;
				loadSelections();
			});
		};

		$scope.removeBookMark = function(id) {
			;
			$scope.setActiveType('confirmAction', id, $scope.confirmRemoveBookMark);		
		};

		$scope.removeHighlight = function(id) {
			;
			Restangular.one('selections', id).remove().then(function(err, res) {
				;
				loadSelections();
			});
		};

		$scope.removeNote = function(){
			return $scope.removeHighlight($scope.linkObj._id)
		}
		/*
		$scope.saveBookmark = function(message, showAddNote) {
			console.log(helpers.tims() + 'Saving bookmark');
			$scope.showAddNote = showAddNote || false;
			if (_.isUndefined(message)) {
				message = helpers.capitalizeFirstLetter($scope.linkObj.type) + ' Saved';
			}
			if (_.isUndefined($scope.linkObj._id)||$scope.linkObj._id==null) {
				userdataObject.post($scope.linkObj).then(function(retObj) {
					$scope.lastAddedObj = retObj.data;
					$scope.linkObj = null;
					$scope.addedBookMark = true;
					$scope.message = message
					$scope.setActiveType('additionType',null);
					loadSelections();
					$timeout($scope.resetMessage, 2500);
				})
			}
			else {
				$scope.linkObj.put().then(function(retObj) {
					$scope.lastAddedObj = retObj.data;
					$scope.linkObj = null;
					$scope.addedBookMark = true;
					$scope.message = message
					$scope.setActiveType('additionType',null);
					loadSelections();
					$timeout($scope.resetMessage, 2500);
				})
			}
		};*/

		$scope.resetMessage =function() {
			$scope.message = null;
		};

		$scope.getPath = function(item){
			return "/reader/" + item.folder + "/" + item.book + "/" + item.section;
		}

		$scope.getSelectionBody = function(arr) {
            var retVal = '';
            if (arr) {
                if (arr.body) {
                    return arr.body;
                }
                for (var i = 0 ; i < arr.length ; i++) {
                    if (i > 1) {
                        retVal += ', ';
                    }
                    retVal = arr[i].body;
                }
            }
            return retVal;
        }

		$scope.addNoteToHighlight = function(highlight){
			var note = {};
			note.selection = highlight.selection;
			note.user_id	= highlight.user_id;
			note.note = highlight.note;
			note.book = highlight.book;
			note.section = highlight.section;
			note.type = 'note';

			$scope.editNote(note);
		}

		if (!_.isUndefined($rootScope.user)&&$rootScope.user!=null) {
			user_id = $rootScope.user._id;
		}

		;
		if (!_.isUndefined($stateParams.selection)&&$stateParams.selection!=null) {
			;
			$scope.activeFlags['selection'] = $stateParams.selection;
		}

		var selectionsObject = Restangular.one('selections', user_id);
		$scope.bookmarks = [];

		helpers.toggleMenu($rootScope, false);
		var selectionData = [];

		var loadSelections = function() {
			;
			selectionsObject.getList().then(function(data) {
				selectionData = data;

				;
				$scope.allSelections = data = setSelectionObject(data);

				_.each($scope.allSelections, function(row) {
					if (row.citation) {
						row.cleanCitation = row.citation.replace('S&H', 'Science and Health');
					}
				});

				$scope.bookmarks = _.where($scope.allSelections, {'type':'bookmark'});
				$scope.highlights.totalItems = _.where($scope.allSelections, {'type':'highlight'});
				
				$scope.notes = _.where($scope.allSelections, function(row) {
					if (row.note!=null) {
						if (row.note.length>4) {
							return true;
						}
					}
				});//{'type':'note'});

				_.each($scope.notes, function (row) {
					row.link = 'books/' + row.book + '/' + row.section;
				})
				initLoadMore();
				createSafeMarkup();
				waitEditNote();
			});
		};
		loadSelections();

		var loadMoreTypes = ["highlights"];
		var initLoadMore = function(){
			for (i = 0; i < loadMoreTypes.length; i++){
				var type = loadMoreTypes[i],
						end = ($scope[type].currentPage * $scope[type].itemsPerPage) + $scope[type].itemsPerPage;
				$scope[type].total = $scope[type].totalItems.length;
				$scope[type].pagedItems = $scope[type].totalItems.slice(0, end);
			}
		}

		/* Selection object is stored as an object array in the database, but an object locally */
		var setSelectionObject = function(data){
			_.each(data, function(obj){
				if ( Array.isArray(obj.selection) && obj.selection.length == 1)
					obj.selection = obj.selection[0];
			})
			return data;
		}

		$scope.loadMore = function(type) {
	    $scope[type].currentPage++;
	    var start = $scope[type].currentPage * $scope[type].itemsPerPage,
	    		newItems = $scope[type].totalItems.slice( start, start + $scope[type].itemsPerPage);
	    $scope[type].pagedItems = $scope[type].pagedItems.concat(newItems);
	  };

	  $scope.nextPageDisabledClass = function(type) {
	    return $scope[type].currentPage === $scope.pageCount(type)-1 ? "disabled" : "";
	  };

	  $scope.pageCount = function(type) {
	    return Math.ceil($scope[type].total/$scope[type].itemsPerPage);
	  };

}]);