// Note, when naming an angular directive, convert objects separated with dashes into camel case
// This is required!

var helpers = require('../../libs/helpers');

var getTop = function (str) {
  return parseInt(str.replace('px',''));
}

angular.module('koh').directive('linkMarkup', ['$location',
    '$rootScope',
    '$window',
    function($location,
        $rootScope,
        $window
    ){
    return {
    restrict: 'E',
    replace: true,
    scope: {
      forwards: '@',
      top: '@',
      left: '@',
      linkid: '@',
      align: '@'
    },
    templateUrl: './directives/pages/link-markup/link-markup.html',
    link: function (scope, element, attrs) {
      scope.showPopup = false;
      scope.linksText = '';
      scope.shownLinks = [];
      scope.markupClass = attrs.class.replace(/ linkMarkup/g, '');
      scope.getPopupLinks = function (linkid) {
        ;
        var url = $location.url();
        var page = $rootScope.readingData.currentChapter;
        var thisTop = getTop(attrs.top);
        scope.shownLinks = [];
        var LinkObject = {
            linkid: '',
            reference: '',
            forwards: true
          };
        _.each($rootScope.pageLinks, function(row) {
          if (helpers.inRangeMarkin(row.top, thisTop) && row.markupClass == scope.markupClass && row.url === page) {

            var LinkObject = {
              linkid: '',
              reference: '',
              forwards: true
            };
            var objectData = _.find($rootScope.links, { _id: row.linkid });
            var bFound = false;
            if (objectData.data.linkData) {
              if (objectData.data.linkData.data) {
                _.each(objectData.data.linkData.data.ranges, function(rangeRow)  {
                  _.each(rangeRow.files, function(fileRow) {
                    if (fileRow.replace('.html','')==scope.$parent.$parent.readingData.currentChapter) {
                      bFound = true;
                    }
                  })
                });
              }
            }

            var newLink = _.extend({}, LinkObject);
            if ($rootScope.isHBL) {
              if ($rootScope.readingData.currentFolder == 'bible') {
                newLink.forwards = true;
                newLink.linkid = row.linkid;
                newLink.reference = objectData.data.linkData.reference;
                if (newLink.reference.indexOf(':')<0) {
                  newLink.reference = objectData.data.linkData.name.replace(/-/,' ');
                }
              } else {
                newLink.linkid = row.linkid;
              newLink.reference = objectData.data.citationData.reference;
              if (newLink.reference.indexOf(':')<0) {
                  newLink.reference = objectData.data.citationData.name.replace(/-/,' ');
                }
              newLink.forwards = false;
              }

            } else {
              if (bFound) {
                ;
                newLink.linkid = row.linkid;
                newLink.reference = objectData.data.citationData.reference;
                if (newLink.reference.indexOf(':')<0) {
                  newLink.reference = objectData.data.citationData.name.replace(/-/,' ');
                }
                newLink.forwards = false;
              } else {
                newLink.forwards = true;
                newLink.linkid = row.linkid;
                newLink.reference = objectData.data.linkData.reference;
                if (newLink.reference.indexOf(':')<0) {
                  newLink.reference = objectData.data.linkData.name.replace(/-/,' ');
                }

              }
            }
            ;
             var check = _.find(scope.shownLinks, { reference: newLink.reference});
             if (check) {
              ;
             } else {
              scope.shownLinks.push(newLink);
             }


          }
        })
      };

      scope.stripFirstDash = helpers.stripFirstDash;

      scope.isFirstLink = function() {
        var url = $location.url();
        var thisTop = getTop(attrs.top);
        var thisLeft = getTop(attrs.left);
        //console.log('Adding top to pagelink margin for url');
        $rootScope.pageLinkMargins = $rootScope.pageLinkMargins || [];

        var foundUrl = _.find($rootScope.pageLinkMargins, { url: url });
        if (foundUrl) {
          var foundLink = _.find(foundUrl.topMargins, { linkid: scope.linkid});
          if (foundLink) {
          //  //console.log('Link already in margin list');
          } else {
            foundUrl.topMargins = foundUrl.topMargins || [];
            var foundTop;
            var foundMainTop;
            _.each(foundUrl.topMargins, function(row) {
               //if (row.top >= thisTop-5 && row.top <= thisTop+5 && row.left >= thisLeft-10 && row.left <= thisLeft+10) {
                if (helpers.inRangeMarkin(row.top, thisTop) && row.markupClass == attrs.markupClass) {
                 foundTop = row;
                 if (foundTop.main) {
                  foundMainTop = foundTop.main;
                 }
              }
            });
            var newObj = { linkid: scope.linkid, top: thisTop, left: thisLeft, main: true };
            if (foundTop) {
              if (foundMainTop) {
                newObj.main = false;
                // Only set the very last one as the displayed one
              }
            }
            newObj.linkData = _.find($rootScope.links, { _id: attrs.linkid });
            foundUrl.topMargins.push(newObj);
          }
        }
        else {
          var newUrl = {
            url: url,
            topMargins: [],
          };
          var newObj = { linkid: scope.linkid, top: thisTop, left: thisLeft, main: true};
          newObj.linkData = _.find($rootScope.links, { _id: attrs.linkid });
          newUrl.topMargins.push(newObj);
          $rootScope.pageLinkMargins.push(newUrl);
          
        }


        //console.log('Checking if link is in range of another top: ' + getTop(attrs.top));
        var foundUrl = _.find($rootScope.pageLinkMargins, { url: url });
        var foundLink = _.find(foundUrl.topMargins, { linkid: attrs.linkid});
        if (!foundLink.linkData) {
          //console.log('No data for link:' + foundLink.linkid);
        } else {
          if (foundLink.linkData.data.linkData.error) {
            //console.log('Link has error!!!!!!!!!!!');
          }
        }

        if (foundLink.main == true) {
          //console.log('displaying link for:' + foundLink.linkData.data.linkData.reference + ' at ' + attrs.top);
          return true;
        }

        return false;
      }

      scope.inlineStyle = { 'top' : attrs.top };
      scope.followLink = scope.$parent.$parent.followLink;
      scope.unsetPopups = function(ele, type) {
        ;
        if ($rootScope.activePopupLink) {
          if (ele.className.indexOf('book-page')>=0) {
            return;
          }
          if (!$rootScope.ignoreClick) {
            $rootScope.activePopupLink = undefined;
          }

        }
      }

      scope.shouldShowPopup = function(linkid) {
        //console.log('Checking should show popup');
        if (!$rootScope.activePopupLink) {
          return false;
        }
        if ($rootScope.activePopupLink == linkid) {
          return true;
        }
        return false;
      }
      scope.releaseHold = function() {
        $rootScope.ignoreClick = false;
      }

      scope.changeZIndexOnMargins = function(toggleVal) {
        //console.log('Changing the z-index');
        var elementsList = document.getElementsByClassName('Sidebar');
        if (elementsList.length > 0) {
          _.each(elementsList, function(obj) {
            var ele = obj.parentNode;
            if (ele) {
              if (toggleVal) {
                ele.style.zIndex = "-2";
              } else {
                ele.style.zIndex = "0";
              }
            }
          })
        }

      }

      scope.showLinkPopup = function(linkId, direction) {
        scope.changeZIndexOnMargins(true);
        ;
        scope.getPopupLinks(linkId);
        $rootScope.ignoreClick = true;
        $rootScope.activePopupLink = linkId;
        setTimeout(scope.releaseHold, 1000);
        //scope.showPopup = !scope.showPopup;
      }
    } // END LINK
  };
}]);
