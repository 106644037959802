'use strict';
var helpers = require('./helpers');

angular.module('koh').factory('selectionService',
  function(Restangular, $document, $window, $compile, $rootScope) {
		// Localized scope
		var selectionObj = {};
    selectionObj.body = null;
    selectionObj.startSpanId = null;
    selectionObj.endSpanId = null;
    selectionObj.startPageIndex = null;
    selectionObj.endPageIndex = null;
    selectionObj.start = null;
    selectionObj.length = null;
    selectionObj.words = null;

    /*private vars */
    var CLASS_NAME = 'selected';
    var EXCEPTIONAL_CLASS = 'Footnote';
    var SKIPPED_CLASSES = ['Line-right', 'Line-right-Misc', 'Line-left', 'Line-left-Misc', 'Chapter-title', 'Basic-Paragraph', 'Sidebar-right', 'Sidebar', 'Footnote', 'noteMarkup', 'leftMargin', 'rightMargin', 'linkMarkup', 'koh-icon-link', 'RUNNING-HEADER-RIGHT', 'Basic-Graphics-Frame','Chapter', 'mobile-only'];
    var highlighter, chapterList, markup;

    var init = function(list){
      chapterList = list;
    }

    var highlightSelection = function(sel){
      ;
      var selection = sel || getSelectedSpans();

      // Clear out previous selections
      removeSelection();

      // Ensure that something was selected
      if (selection.length == 0){
        return [];
      }

      setDocumentRange(selection);
      applyClass(selection, CLASS_NAME);

      $rootScope.selectionApplied = true;

      return selection;
    }

    var getTabletSelection = function(){
      return getSelectedSpans();
    }

    var getSelectionObject = function(selection){
      var startNode = selection[0],
          endNode = selection[selection.length - 1],
          text = getSelectionText(selection);

      selectionObj.startSpanId = startNode.id;
      selectionObj.endSpanId = endNode.id;
      selectionObj.startPageIndex = getPageIndex( findAncestor(startNode, 'book-page').id );
      selectionObj.endPageIndex = getPageIndex( findAncestor(endNode, 'book-page').id );
      selectionObj.body = text;
      selectionObj.markup = markup;
      selectionObj.length = text.length;
      selectionObj.words = calculateWords(text);
      return selectionObj;
    }

    var removeSelection = function(){
      selectionObj = {};
      removeClass([CLASS_NAME, 'inline'])
      var copyDiv = document.getElementById('koh-copy-div');
      if(copyDiv) document.body.removeChild(copyDiv);
      $rootScope.selectionApplied = false;
    }

    var removeClass = function(classArr, idArg){
      if( !Array.isArray(classArr) ) classArr = new Array(classArr);

      var id = idArg || 'page-container';
      var page = document.getElementById(id);
      if (!page||page==null) {
        return;
      }
      _.each(classArr, function(cls){
        var results = page.getElementsByClassName(cls),
            length = results.length;
        while(length--){
          var node = angular.element(results[length]);
          node.removeClass(cls);
        }
      })
    }

    var createSelection = function(selObj){
      var nodeArr = [],
          start   = document.getElementById(selObj.startSpanId),
          end     = document.getElementById(selObj.endSpanId);

      // move on if start or end hasn't been loaded yet
      if (start == null || _.isUndefined(start) || end == null || _.isUndefined(end)) return;

      /**
       future optimization:
       Iterate through all pages in highlighted selection.
       If start or end node, pass node and first/last child.  otherwise, pass first/last child
       **/
      nodeArr.push(start);
      if(end != start){
        for (i = selObj.startPageIndex; i < selObj.endPageIndex + 1; i++){
            var pageId = $rootScope.readingData.chapterList[i].fileName,
                page = document.getElementById(pageId),
                startNode, endNode;

            if (page == null || _.isUndefined(page)) return;

            if (findAncestor(start, 'book-page').id == pageId) startNode = start;
            else startNode = page.getElementsByTagName('span')[0];

            if (findAncestor(end, 'book-page').id == pageId) endNode = end;
            else{
                var pageSpans = page.getElementsByTagName('span');
                endNode = pageSpans[pageSpans.length-1];
            }

            nodeArr = nodeArr.concat(getTextNodesBetween(page, startNode, endNode));
        }
      }
      nodeArr.push(end);
      return nodeArr;
    }

    var applyClass = function(selection, className){
      if (!selection) {
        return;
      }
      for (var i = 0; i < selection.length; i++) {
          var node = angular.element(selection[i]);
          node.addClass(className);
          if (i == selection.length - 1) node.addClass('inline');
      }
    }

    var applyToPage = function(span, className){
      var bookPage = findAncestor(span, 'book-page'),
          epubPage = bookPage.getElementsByClassName('epub-text');
      if (epubPage.length) epubPage = epubPage[0];
      angular.element(epubPage).addClass(className);
    }

    var insertNode = function(span, className){
      if (!_.isUndefined(span) && span != null){
        if (span.previousElementSibling.className == className) return;
        var newNode = document.createElement("span");
        newNode.className = className;
        newNode.style.top = ( parseInt(span.style.top) + parseInt(span.style.height) ) + 'px';
        newNode.style.left = ( parseInt(span.style.left) + parseInt(span.style.width) ) + 'px';
        span.parentNode.insertBefore(newNode, span);
      }
    }

    function setDocumentRange(selection){
      var selObj = getNativeSelection(),
      newdiv = document.createElement('textarea');
      newdiv.id = 'koh-copy-div';

      //hide the newly created container
      newdiv.style.position = 'fixed';
      newdiv.style.left = '-99999px';
      newdiv.style.top = $window.pageYOffset;

      //insert the container, fill it with the extended text, and define the new selection
      document.body.appendChild(newdiv);
      newdiv.textContent = getSelectionText(selection);
      selObj.removeAllRanges();
      newdiv.select();
    }

    function getTextNodesBetween(rootNode, startNode, endNode) {
        var pastStartNode = false, reachedEndNode = false, textNodes = [];

        function getTextNodes(node, skipNode) {
            if (node == startNode) {
                pastStartNode = true;
            } else if (node == endNode) {
                reachedEndNode = true;
            } else if (node.nodeName == "SPAN" &&  !isSkippedNode(node)) {
                if (pastStartNode && !reachedEndNode && !skipNode) {
                    textNodes.push(node);
                }
            } else {
                for (var i = 0, len = node.childNodes.length; !reachedEndNode && i < len; ++i) {
                    getTextNodes(node.childNodes[i], isSkippedNode(node));
                }
            }
        }

        getTextNodes(rootNode, false);
        return textNodes;
    }

    function isSkippedNode(node){
      var ret = false;
      _.each(SKIPPED_CLASSES, function(cls){
        if (angular.element(node.parentNode).hasClass(cls) || angular.element(node).hasClass(cls)) ret = true;
      });
      return ret;
    }

    //edit the selection to remove nontext nodes
    function getSelectedSpans(){
      var ranges = getAllRanges(),
          selection = [];

      _.each(ranges, function(range){
        var documentFragment = range.cloneContents(),
            skippedNodes = getSkippedNodes(documentFragment, range),
            length = skippedNodes.length;

        /* remove any nodes that should be skipped */
        while(length--){
          skippedNodes[length].parentNode.removeChild(skippedNodes[length]);
        }

        /* select all SPAN tags and get their actual on page element */
        var spanArr = [], spans = documentFragment.querySelectorAll('span');
        for (var i = 0; i < spans.length; ++i) {
          if (spans[i] != null && spans[i].innerHTML.length){
            spanArr.push($document[0].getElementById(spans[i].id));
          }
        }

        //special case for single word selection
        if( (!documentFragment.children || documentFragment.children.length == 0)
            && documentFragment.childNodes && documentFragment.childNodes.length > 0){
          if(range.startContainer == range.endContainer) spanArr.push(range.startContainer.parentNode);
        }

        selection = selection.concat(spanArr);
      });

      return selection;
    }

    function getSkippedNodes(documentFragment, range){
      var query = '';
      var startSpan = range.startContainer.nodeName == '#text' ? range.startContainer.parentNode : range.startContainer;
      var exp = startSpan.closest('.' + EXCEPTIONAL_CLASS);
      if(exp != null){
        query = 'p:not(.' + EXCEPTIONAL_CLASS + ')';
      }else {
        query = '.'.concat(SKIPPED_CLASSES.toString().replace(/,/g, ', .'));
      }
      return documentFragment.querySelectorAll(query);
    }

    function getAllRanges(){
      var ranges = [], selection = getNativeSelection();
      for (var i = 0; i < selection.rangeCount; i++){
        ranges.push(selection.getRangeAt(i));
      }
      return ranges;
    }

    function getNativeSelection(){
      if (window.getSelection) {
          return window.getSelection();
      } else {
          return document.getSelection();
      }
    }

    function getSelectionText(selection){
      var text = '',
          index,
          acceptableParentTags = ['p', 'h1', 'h2'];

      for (var i = 0, max = selection.length; i < max; ++i) {

        if (selection[i]) {
          if (selection[i].children.length){
            text += getSelectionText(selection[i].children);
          } else if(selection[i].parentNode.lastChild == selection[i]) {

            index = acceptableParentTags.indexOf(selection[i].parentNode.tagName.toLowerCase());

            if( index !== -1) {
              text += selection[i].innerHTML + "\n";
            } else {
              text += selection[i].innerHTML
            }
          } else {
            text += selection[i].innerHTML;
          }
        }
      }
      return text.replace(/&nbsp;/gi, ' ');
    }


    function getPageIndex (chapter) {
      return helpers.findChapterIndex(chapterList, chapter);
    }

    function getMarkup() {
      var selection = getSelection();
      var chapterData = $rootScope.pageText;
      var revString = chapterData.split('').reverse().join('');
      var beginningId = selection.anchorNode.parentNode.id;
      var endId = selection.endSpanId.parentNode.id
      var eirvId = revString.indexOf(endId.split('').reverse().join(''));
      var birvId = revString.indexOf(beginningId.split('').reverse().join(''));

      var temp = eirvId;
      if (birvId<eirvId) {
        eirvId = birvId;
        birvId = temp;
      }
      var bChunk = revString.substr(birvId);
      var bId = revString.indexOf('<', birvId);
      bId++;

      var eChunkArr = revString.split('');
      var eId = -1;
      // Iterate in reverse until we find the >
      var bFoundClose = false;
      for (var i = eirvId ; i > 0 ; i--) {
        if (eChunkArr[i]=='>' && bFoundClose) {
          eId = i;
          break;
        }
        if (eChunkArr[i]=='/') {
          bFoundClose = true;
        }
      }
      //var eId = revString.indexOf('>', eirvId);
      var startIter = bId;
      var endIter = eId;
      if (bId>eId) {
        startIter = eId;
        endIter = bId;
      }
      var chunkLen = endIter - startIter;
      var frChunk = revString.substr(startIter, chunkLen);
      var chunk = frChunk.split('').reverse().join('');
      return chunk;
    }

    function findAncestor (node, nodeType) {
      while ((node = node.parentNode) && !(node.nodeName.toLowerCase() == nodeType.toLowerCase()));
      return node;
    }

    function calculateWords (text) {
      var arr = text.trim().split(' ');
      return arr.length;
    }

		return {
      init: init,
      highlightSelection: highlightSelection,
      getTabletSelection: getTabletSelection,
      removeSelection: removeSelection,
      removeClass: removeClass,
      createSelection: createSelection,
      getSelectionObject: getSelectionObject,
      applyClass: applyClass,
      applyToPage: applyToPage,
      insertNode: insertNode,
      isSkippedNode: isSkippedNode
    }
});
