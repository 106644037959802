var _ = require('lodash');

angular.module('koh').directive('checkout', function() {
  return {
    restrict: 'E',
    transclude: true,
    templateUrl: './directives/pages/checkout/checkout.html',
    link: function (scope, element) {
    	scope.cart = [
    		{
    			id: 0,
                subscriptionDescription: "One Yearly Subscription to Kingdom of Heaven Library",
    			subscriptionType: "Year",
    			subscriptionPrice: 99.95,
    			isGift: false,
                giftContact: {
                    firstName: "Jane",
                    lastName: "Smith",
                    email: "jsmith@mail.com",
                    add1: "123 Fake St",
                    add2: "",
                    city: "Omaha",
                    state: "NE",
                    zip: "10001"
                }
    		},
    		{
    			id: 1,
                subscriptionDescription: "One Lifetime Subscription to Kingdom of Heaven Library",
    			subscriptionType: "Lifetime",
    			subscriptionPrice: 149.95,
    			isGift: true,
                giftContact: {
                    firstName: "",
                    lastName: "",
                    email: "",
                    add1: "",
                    add2: "",
                    city: "",
                    state: "",
                    zip: ""
                }
    		}
    	]
    	scope.total = 0;

        scope.updateTotal = function() {
            scope.total = _.sum(scope.cart, 'subscriptionPrice').toFixed(2);
        }

        scope.remove = function(item) { 
          var index = scope.cart.indexOf(item);
          scope.cart.splice(index, 1);   
          scope.updateTotal();  
        }

        scope.updateTotal();
    }
  };
});