var _ = require('lodash');


/* Adapted from http://angularscript.com/auto-hide-sticky-header-for-angularjs/ */
angular.module('koh').directive('autoHideStickyHeader', [
	'$rootScope',
	'$state', 
	'$window',
	'$timeout',
	'$location',
	function($rootScope, $state, $window, $timeout, $location) {
    return {
      restrict: 'A',
      scope: {
        cssClassSticky: '@?',
        cssClassHidden: '@?',
        showAtBottom: '@?',
        isHeader: '@?'
      },
      link: link
    };

    function link(scope, element) {
      var handle, $win, $el, scrolling;

      var options = {
        cssClassSticky: scope.cssClassSkicky || 'sticky-header',
        cssClassHidden: scope.cssClassHidden || 'header-hidden',
        cssClassPush: scope.cssClassHidden || 'push',
        showAtBottom: scope.showAtBottom ? scope.showAtBottom === 'true' : false,
        isHeader: scope.isHeader ? scope.isHeader === 'true' : false,
        start: 0
      };

      var onChange = getOnChange(element, options);
      var onStart = getOnStart(element, options);
      var onClick = getOnClick(element, options);

      scrolling = new Scrolling(options, onChange, onStart);
      handle = angular.bind(scrolling, scrolling.handle);

      function bindHandlers(){
		    if ($win && $el){
		    	$win.off('scroll', handle);
		      $el.off('click', onClick);
	    	}
	    	
      	if ($location.path().startsWith('/reader')){
		      $win = angular.element($window).on('scroll', handle);
		      $el = element.on('click', onClick);
		      $rootScope.$broadcast('headerChange');
		    }
      }
      bindHandlers();

      $rootScope.$on('$locationChangeSuccess', bindHandlers);

      $rootScope.$watch('readingMode', function(){
      	if ($rootScope.readingMode != 'continuous') element.removeClass(options.cssClassSticky);
      })

      scope.$on('unhideHeader', function(){
	    	scrolling.change(false);
      });
      scope.$on('updateHeaderState', function(e, push){
      	if(options.isHeader) return;
	    	if(push) $timeout(function(){ element.addClass(options.cssClassPush) }, 750);
	    	else element.removeClass(options.cssClassPush);
      });

      scope.$on('$destroy', function(){
      	if ($win && $el){
		    	$win.off('scroll', handle);
		      $el.off('click', onClick);
	    	}
      });
    }

    function getOnStart(element, options) {
      return function change(sticky) {
      	if ($rootScope.readingMode != 'continuous') sticky = false;
        element.toggleClass(options.cssClassSticky, sticky);
        $rootScope.$broadcast('headerChange');
      }
    }

    function getOnChange(element, options) {
      return function change(hidden) {
      	if (options.isHeader && $rootScope.header.hiddenHeader) hidden = true;
      	if (element.hasClass('keep-open')) hidden = false;
        element.toggleClass(options.cssClassHidden, hidden);
        $rootScope.$broadcast('headerChange');
      }
    }

    function getOnClick(element, options) {
    	return function change(hidden) {
	    	$rootScope.$broadcast('unhideHeader');
      }
    }

    function getPositionTop (element){
    	var el = element[0].offsetTop === 0 ? element[0].offsetParent : element[0];
    	return el.offsetTop - el.scrollTop + el.clientTop;
    }

		function Scrolling(options, change, changeStart) {
		    this.options = options;
		    this.change = change;
		    this.changeStart = changeStart;

		    this.dHeight = 0;
		    this.wHeight = 0;
		    this.current = 0;
		    this.previous = 0;
		    this.diff = 0;

		  	this.atBottom = function() {
		      return (this.current + this.wHeight) >= this.dHeight;
		    };

		    this.down = function() {
		      return this.diff < 0;
		    };

		    this.pastStart = function(){
		    	return this.current > (this.options.start);
		    }

		    this.handle = _.throttle(function() {
		      var hidden = false, started = false;
		      var scrolling = this.update();

		      if (scrolling.pastStart()) {
		        started = true;
			      if (scrolling.down()) {
			        if (this.options.showAtBottom) {
			          var bottom = scrolling.atBottom();
			          hidden = !bottom;
			        } else {
			          hidden = true;
			        }
			      }
			    }

			    this.changeStart(started);
		      this.change(hidden);
		    }, 100);

		   this.update = function() {
		      this.dHeight = document.body.offsetHeight;
		      this.wHeight = window.innerHeight;

		      this.previous = this.current;
		      this.current = window.pageYOffset;
		      this.diff = this.previous - this.current;

		      return this;
		    };

		}


}]);
