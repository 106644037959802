var helpers = require('../../libs/helpers');

angular.module('koh').
  controller('MainCtrl',
      ['$scope',
        '$rootScope',
        '$location',
        '$timeout',
        '$window',
        '$http',
        '$log',
        '$state',
        'Restangular',
        'documentSearchFactory',
        'apiUrl',
        function($scope,
          $rootScope,
          $location,
          $timeout,
          $window,
          $http,
          $log,
          $state,
          Restangular,
          documentSearchFactory,
          apiUrl
        ){

          // Safari, in Private Browsing Mode, looks like it supports localStorage but all calls to setItem
          // throw QuotaExceededError. We're going to detect this and just silently drop any calls to setItem
          // to avoid the entire page breaking, without having to do a check at each usage of Storage.
          if (typeof localStorage === 'object') {
              try {
                  localStorage.setItem('localStorage', 1);
                  localStorage.removeItem('localStorage');
              } catch (e) {
                  Storage.prototype._setItem = Storage.prototype.setItem;
                  Storage.prototype.setItem = function() {};
                  ;
              }
          }

          //var booksObject = Restangular.all('books');
          ;
          $rootScope.apiUrl = apiUrl;
          ;
          helpers.httpwrap($http, $rootScope.apiUrl, '/api/books/all').then(function(data) {
            ;
            $rootScope.bookData = data;
          });

          /*
          helpers.httpwrap($http, $rootScope.apiUrl, '/api/search/periodicals').then(function(data) {
                  if (data.chunk) {
                    console.log('Data has chunks!');
                    helpers.getAllChunks($http, $rootScope, 'periodicalChunks', 'search_periodicals', data, function(chunkData) {
            console.log('periodical wrapper completed');
            $rootScope.periodicalData = chunkData;
              });
            }
          });
          */

          /*
          booksObject.one('all').get().then(function(data) {
                    helpers.logMessage('Got back Book data',$rootScope, 'saveSelection',1);
                    $rootScope.bookData = data;
                  })
          */

          $rootScope.previousState = null;
           $rootScope.$on( '$stateChangeStart', function(e, toState, toParams, fromState, fromParams) {
          if( toState.name != 'login' && toState.name != 'logout')
          {
            $rootScope.previousState = toState;
            $rootScope.previousState.toParams = toParams;
          }
          $scope.closeMenu(undefined, 'menu');
        });

          $rootScope.$state = $state;
        // Initialize
        $scope.toggleObject = {
          menu : false
        };
        $rootScope.extractLinksFromBookXml = function(bookXml) {
        //	helpers.logMessage('Running extract links',$rootScope, 'saveSelection',1);
        };

        $rootScope.setLinkProcessing = function (scopeId, bSetOrUnset) {
          $rootScope.settingLinks = $rootScope.settingLinks || [];
          var spliceindex = _.indexOf($rootScope, scopeId);
          // Check if we're removing
          if (spliceindex>=0) {
            if (bSetOrUnset) {
              ;
              return false;
            } else {
              $rootScope.settingLinks = _.remove($rootScope.settingLinks, scopeId);
              return true;
            }
          } else {
            if (bSetOrUnset) {
              $rootScope.settingLinks[$rootScope.settingLinks] = scopeId;
              return true;
            } else {
              //console.log('No index of, and cant remove it');
              return false;
            }
          }
          return false;
        }

        $rootScope.isLinkProcessing = function(scopeId) {
          var spliceindex = _.indexOf($rootScope, scopeId);
          return (spliceindex >= 0);
        };

        $rootScope.getConfig = function() {
          return new Promise(function(resolve, reject) {
            Restangular.all('configuration').getList().then(function(data) {
              if (data) {
                $rootScope.advancedMode = false;
                var advancedMode = _.find(data, {key: 'advancedMode'});
                if (advancedMode) {
                  if (advancedMode) {
                    if (advancedMode.value[0] == true) {
                      $rootScope.advancedMode = true;
                    }
                  }
                }
                $rootScope.logLevel = 0;
                var logging = _.find(data, {key: 'logging'});
                if (logging) {
                  if (logging.value) {
                    $rootScope.configLogLevel = parseInt(logging.value);
                  }
                }
                resolve(data);
              } else {
                reject();
              }
            });
          });
        };

        $rootScope.removeCachedValue = function(type, reference, recacheCb) {
            var removedArray = _.remove($rootScope.cachedValues, function(row) {
              return row.type == type && row.reference == reference;
            });

            if (recacheCb) {
              recacheCb(removedArray);
            }
        }

        $rootScope.setCacheValues = function () {
          $rootScope.cachedLinkObject = {
            cb: undefined,
            dirty: false,
            type: '',
            reference: '',
            processing: false,
            data: {}
          };
          return [];
        }
        $rootScope.cachedValues = $rootScope.cachedValues || $rootScope.setCacheValues();

        $rootScope.getStaticCache = function(reference, type) {
          var data = _.find($rootScope.cachedValues, function(row) {
            return row.type == type && row.reference == reference;
          });
          return data;
        };

        $rootScope.setStaticCache = function(reference, type, data) {
          var data = $rootScope.getStaticCache(reference, type);

          if (!data) {
            var newObj = _.extend({}, $rootScope.cachedLinkObject);
            newObj.type = type;
            newObj.reference = reference;
            newObj.processing = true;
            $rootScope.cachedValues.push(newObj);
            return true;
          } else {
            helpers.logMessage('Data already cached',$rootScope, 'MainCtrl',1);
          }
          return false;
        }

        $rootScope.getCacheValue = function(reference, type, getData) {
          return new Promise(function(resolve, reject) {
            if (!reference || !type) {
              reject('No cache reference or type specified', reference, type);
              return;
            }
            var cacheValue = _.find($rootScope.cachedValues, function(value) {
              return value.type == type && value.reference == reference;
            });
            if (cacheValue) {
              if (cacheValue.processing) { // other caller might be looking for the same data
                var offGetDataSuccess = $rootScope.$on('getDataSuccess', function (event, newValue) {
                  if (newValue === cacheValue) {
                    offGetDataSuccess();
                    ;
                    return resolve(cacheValue.data);
                  }
                });
                var offGetDataError = $rootScope.$on('getDataError', function (event, newValue, error) {
                  if (newValue === cacheValue) {
                    offGetDataError();
                    ;
                    return reject(error);
                  }
                });
              } else {
                ;
                return resolve(cacheValue.data);
              }
              return;
            }
            // creating new cache value
            var newValue = _.extend({}, $rootScope.cachedLinkObject);
            newValue.type = type;
            newValue.reference = reference;
            newValue.refDate = helpers.tims();
            newValue.processing = true;
            $rootScope.cachedValues.push(newValue);
            // requesting data
            ;
            getData().then(function(data) {
                newValue.data = data;
                newValue.processing = false;
                ;
                resolve(data);
                $rootScope.$emit('getDataSuccess', newValue);
              }, function(error) {
                _.pull($rootScope.cachedValues, newValue);
                newValue.processing = false;
                ;
                reject(error);
                $rootScope.$emit('getDataError', newValue, error);
              }
            );
          });
        };
        $rootScope.setActiveCitation = function(citationString) {
          $rootScope.activeCitation = undefined;
          _.each($rootScope.user.selections, function(row) {
            if (row.type == 'citation') {
              if (row.citation == citationString) {
                helpers.logMessage('Citation was cached in user selections',$rootScope, 'setActiveCitation',1);
                $rootScope.activeCitation = row;
                return;
              }
            }
          });
          if (!$rootScope.activeCitation) {
            var citArr = citationString.split(':');
            if (citArr[0].indexOf('-')>=0) {
              citationString = citationString.replace('-',' ');
            }
            Restangular.all('citation').one(citationString).get().then(function(data) {
              helpers.logMessage('Retrieved citation' ,$rootScope, 'setActiveCitation',1);
              if (data.data) {
                $rootScope.activeCitation = data.data;

              }
            });
          }
        }
        $rootScope.viewCustomCitation = function(customCitation, scope, activeCitation) {
          var cleanCitation = customCitation;
          var ccArr = cleanCitation.split(':');
          if (ccArr[0].indexOf('-')>=0) {
            cleanCitation = cleanCitation.replace('-', ' ');
          }
          cleanCitation = cleanCitation.replace('?', '%3F');
          Restangular.all('citation').one(cleanCitation).get().then(function(data) {
            if (!data.data.error) {
              var section = data.data.ranges[0];
              var sectionRange = section.files;
              //var properSection = sectionRange[sectionRange.length-1];
              var properSection = sectionRange[0];
              properSection = properSection.replace('.html','');
              var folder = data.data.type;
              if (folder == 'book') {
                folder = 'books';
              }
              var linkObj = {
                  folder: folder,
                  type: 'citation',
                  displayedOrder: 1,
                  section: properSection,
                  order: 1,
                  note: '',
                  book: data.data.names.fileName,
                  citation: customCitation,
                  title: '',
                  user_id: $rootScope.user._id,
                  selection: {}
              };
              var outputUrl = '/reader/' + linkObj.folder + '/' + linkObj.book + '/' + linkObj.section + '/' + linkObj.citation;

              helpers.logMessage('Going to url:' + outputUrl ,$rootScope, 'setActiveCitation',1);
              if(activeCitation){
                documentSearchFactory.isCitation = true;
                $rootScope.activeCitation = data.data;
              }
              if( documentSearchFactory.currentSearch != '' )
              {
                documentSearchFactory.citationSpans = section.foundSpans;
                $state.go( 'reader.book.chapter.search', { folder: linkObj.folder, book: linkObj.book, chapter: linkObj.section, search: documentSearchFactory.currentSearch });
              }
              else
              {
                $state.go( 'reader.book.citation', { folder: linkObj.folder, book: linkObj.book, chapter: linkObj.section, citation: linkObj.citation }, { reload:true } );
              }
              // $location.url(outputUrl);
            }
          }).catch(function(err) {
            ;
          });
        };

        $rootScope.editCustomCitation = function(currentCitation, customCitation, cb) {
          helpers.logMessage('Saving edited custom citation',$rootScope, 'setActiveCitation',1);
          function saveSelection(linkObj, cb) {
            currentCitation.put().then(function(retObj) {
              helpers.logMessage('Save of citation complete',$rootScope, 'setActiveCitation',1);
                    cb(retObj);
                    return;
            }).catch(function(err) {
              ;
              cb(err);
            });
          }
                Restangular.all('citation').one(customCitation).get().then(function(data) {
                    if (!data.data.error) {
                        currentCitation.selection = data.data.ranges;
                        currentCitation.section = currentCitation.selection[0].files[0].replace('.html','');
                        currentCitation.book = data.data.citationObject.names.fileName;
                        currentCitation.citation = customCitation;
                        saveSelection(currentCitation, cb);
                    } else {
                        
                    }
                }).catch(function(err) {
            ;
          });;
        }

        $rootScope.addCustomCitation = function(customCitation, scope, cb) {
          var cleanCitation = customCitation;
          var ccArr = cleanCitation.split(':');
          if (ccArr[0].indexOf('-')>=0) {
            cleanCitation = cleanCitation.replace('-', ' ');
          }
          customCitation = cleanCitation.replace('?', '%3F');

          function saveSelection(linkObj) {
            Restangular.all('selections').post(linkObj).then(function(retObj) {
              if (cb) {
                      cb(retObj);
                      return;
                    }
                  // Set success message
                  if (scope) {
                    scope.lastAddedObj = retObj;
                    scope.linkObj = null;
                    scope.selectionObj = null;
                    scope.addedBookMark = true;
                  }
                  if (retObj.type == 'citation') {
                    helpers.logMessage('Opening citation organizer',$rootScope, 'saveSelection',1);
                    //$rootScope.user.selections.push(linkObj);
                    //$location.url('/reader/' + linkObj.folder + '/' + linkObj.book + '/' + linkObj.section + '/' + linkObj.citation);

                    $rootScope.$broadcast('openCitationOrganizer', retObj, true);
                  }
            }).catch(function(err) {
              ;
              cb(err);
            });
          }
            // console.log(helpers.tims() + 'I have data:' + customCitation);
                Restangular.all('citation').one(customCitation).get().then(function(data) {
                    var folderId = 1;
                    if (scope.activeFlags) {
                      if (scope.activeFlags['activeFolder'])
                        folderId = scope.activeFlags['activeFolder'].id
                    }
                    if (!data.data.error) {
                        var newCitationObject = {
                            folder: data.data.type,
                            type: 'citation',
                            displayedOrder: 1,
                            section: data.data.ranges[0].files[0].replace('.html',''),
                            citationFolder: folderId,
                            order: 1,
                            note: '',
                            book: data.data.names.fileName,
                            citation: customCitation,
                            title: '',
                            user_id: $rootScope.user._id,
                            selection: {}
                        };
                        var maxOrder = 0;
                        for (var i = 0 ; i < $rootScope.user.citations ; i++) {
                            if ($rootScope.user.citations[i].citationFolder == scope.activeFlags['activeFolder'].id) {
                                maxOrder++;
                            }
                        }
                        newCitationObject.order = maxOrder;
                        newCitationObject.displayedOrder = maxOrder;
                        newCitationObject.citation = newCitationObject.citation.replace('%3F', '?');
                        newCitationObject.selection = data.data.ranges;
                        saveSelection(newCitationObject, scope);
                    } else {
                        
                    }
                }).catch(function(err) {
            ;
          });;
        }

        $scope.lastScrollTop = 0;
        $scope.direction = "";
        $scope.splashScreen = !sessionStorage.getItem("koh-visited");
        $scope.onBookPage = $location.path().startsWith('/books/');
        $scope.headerTimeout;
        $scope.onPageTop = false;
        $scope.activeFlags = [];

        $rootScope.$on('$stateChangeError', function(event) {
          ;
          $state.go('500');
        });

        $rootScope.$on("$stateChangeSuccess", function (event, currentRoute, previousRoute) {
          var isChapterRoute = currentRoute.name.indexOf('chapter') > 0;
          if(!isChapterRoute) window.scrollTo(0, 0);
        });

        $rootScope.$watch('user',function(newVal,oldVal) {
          $scope.user = newVal;
        });

        $scope.showCitationOrganizer = function() {
          $rootScope.$broadcast('toggleCitationOrganizer');
        }

        $scope.showReadNavigation = function() {
          if($rootScope.$state.$current.includes.reader){
            $scope.toggleObject.read = !$scope.toggleObject.read;
            $scope.toggleObject.search = false;
            $scope.advSearchOpen = false;
          } else{
            var pageList = $rootScope.user.visitedPages,
            lastPage = {};

            if (pageList == null || _.isUndefined(pageList) || pageList.length == 0) $location.path('/reader/bible/Gen/');

            lastPage = pageList[pageList.length - 1];
            $location.path(lastPage.page);
          }
        }

        $rootScope.$watch('readingData',function(newVal,oldVal) {
          if (_.isUndefined(newVal)) {
            for (var key in newVal){
              $scope[key] = null;
            }
          }
          else {
            for (var key in newVal){
              $scope[key] = newVal[key];
            }
          }
        });

        // Set up point for broacast to catch menu update call from controllers
        $rootScope.$on("updateMenu", function(src,args) {
          var type = args.type;

          if (args.state == 'toggle') {
            $scope.toggleObject[type] = !$scope.toggleObject[type];
            return;
          }
          if (_.isUndefined(args.ele)) {
            if ($scope.toggleObject[type]) {
              $scope.toggleObject[type] = args.state;
            }
            return;
          }
          if (!args.ele) {
            return;
          }
          else {
            if (!args.ele.attributes && !args.ele.parentElement) {
              return;
            }
          }
          var	menuButton;
          if (args.ele) {
            menuButton = args.ele.attributes.menu;
            if (!menuButton) {
              if (args.ele.parentElement) {
              menuButton = args.ele.parentElement.attributes.menu;
              }
            }
          }

          if (_.isUndefined(menuButton) || menuButton.value != type) {
            $scope.$apply(function() {
              if ($scope.toggleObject[type]) {
                $scope.toggleObject[type] = args.state;
              }
            })
          }
        });

        $scope.setActiveType = function(type, val, toggleOrArg) {
          helpers.setActiveType($scope, type, val, toggleOrArg);
        };

        $scope.isActive = function(type, val) {
          if (type &&
            (_.isUndefined(val)||val==null)) {
            return $scope.activeFlags[type]!=null;
          }
          if ($scope.activeFlags[type]==null) {
            return false;
          }
          if (typeof(val)=='object') {
            if (!_.isUndefined(val._id)) {
              return val._id == $scope.activeFlags[type]._id;
            }
            else if (!_.isUndefined(val.id)) {
              return val.id == $scope.activeFlags[type].id;
            }
            else {
              return (isEqual(val, $scope.activeFlags[type]))
            }
          }
          return ($scope.activeFlags[type]==val);
        };


      $scope.isActiveClass = function(type, val) {
        if (type &&
          (_.isUndefined(val)||val==null)) {
          return $scope.activeFlags[type]!=null ? 'active' : '';
        }
        if ($scope.activeFlags[type]==val) {
          return 'active';
        }
      };

      $scope.getActiveState = function(name){
        return $scope.toggleObject[name];
      }

      $rootScope.holdCloseMenu = false;
      $scope.closeMenu = function(ele, type) {
        if (!$rootScope.holdCloseMenu) {
          $rootScope.$broadcast('updateMenu', {state: false, ele: ele, type:type});
        } else {
          //console.log('Holding off on spamming close menu');
        }
      }

      var timeoutSplashScreen = function(){
        sessionStorage.setItem("koh-visited", "true"); // set local storage
          $timeout(function(){
              $scope.splashScreen = false;
          }, 5000);
        };
        timeoutSplashScreen();

        /* Show header if user scrolls up page */
        // angular.element($window).bind("scroll", function() {
        // 	if (!$scope.onBookPage) return;
      //     $scope.st = window.pageYOffset;
      //     if ($scope.st < $scope.lastScrollTop) {
      //         resetHeader();
      //     } else {
      //         $scope.hideHeader = true;
      //     }
      //     $scope.lastScrollTop = $scope.st;
      //     $scope.$apply();
        //  });

        /* Show header if user hovers at top of page for more than 1 second */
        // angular.element($window).bind("mousemove",function(event) {
        // 	if (!$scope.onBookPage) return;
       //    if (event.pageY < 20) {
       //    	$scope.onPageTop = true;
        // 		$scope.headerTimeout = $timeout(function(){
        //         if ($scope.onPageTop) resetHeader();
        //     }, 1000);
       //    } else{
       //    	$scope.onPageTop = false;
       //    }
        // });

        $rootScope.toggleAnObject = function(objString, altString, state) {
          if(typeof(objString) != "string") objString = altString;
          $scope.toggleObject[objString] = state == undefined ?! $scope.toggleObject[objString] : state;
          $rootScope.holdCloseMenu = true;
          setTimeout(function() {
            $rootScope.holdCloseMenu = false;
          }, 750);
        }

        $scope.activeFlags = {};

        $scope.setActiveType = function(type, val, toggleOrArg) {
          helpers.setActiveType($scope, type, val, toggleOrArg);
        };

        $scope.isActive = function(type, val) {
          if (type &&
            (_.isUndefined(val)||val==null)) {
            return $scope.activeFlags[type]!=null;
          }
          return ($scope.activeFlags[type]==val);
        };

        $scope.toggleAnAObject = $rootScope.toggleAnObject;

        $scope.isActiveClass = function(type, val) {
          if (type &&
            (_.isUndefined(val)||val==null)) {
            return $scope.activeFlags[type]!=null ? 'active' : '';
          }
          if ($scope.activeFlags[type]==val) {
            return 'active';
          }
        };

        $scope.gotoBook = function(url) {
          $rootScope.dirtyLinks = true;
          $rootScope.processingLinks = false;
          $location.url(url);
        }

        // Inline NG-CLASS Method
        // path is the path of the URL
        // requireLogin = true means, "Only show if the user must be logged in"
        // requireLogin = false, only show if the user is not logged in
        // requireLogin = not specified (undefined) dont check
        $scope.isActiveUrl = function(path, requireLogin, advancedOnly) {

          if (requireLogin==true && $rootScope.user) {
            if ($rootScope.user.usertype == 'casual' && advancedOnly) {
              return 'doNotDisplay';
            }
          }

          if (requireLogin==true && (!$rootScope.user||_.isUndefined($rootScope.user))) {
            return 'doNotDisplay';
          }

          //
          if (requireLogin==false && $rootScope.user) {
            return 'doNotDisplay';
          }

          if (path == $location.path()) {
            return 'active';
          }
          return;

        }

        // Inline NG-CLASS Method
        // determines if root path matches location
        // requireLogin = true means, "Only show if the user must be logged in"
        // requireLogin = false, only show if the user is not logged in
        // requireLogin = not specified (undefined) dont check
        $scope.isActivePath = function(path, requireLogin, advancedOnly) {

          if (requireLogin==true && $rootScope.user) {
            if ($rootScope.user.usertype == 'casual' && advancedOnly) {
              return 'doNotDisplay';
            }
          }

          if (requireLogin==true && (!$rootScope.user||_.isUndefined($rootScope.user))) {
            return 'doNotDisplay';
          }

          //
          if (requireLogin==false && $rootScope.user) {
            return 'doNotDisplay';
          }

          if ($location.path().startsWith(path)) {
            return 'active';
          }
          return;

        }
  }])

.directive('isAccesible', function () {
  return {
    restrict: 'A',
    require: '?ngModel',
    link: function (scope, element, attrs, ngModel) {
      scope.$watch('user', function(newVal, oldVal){
        var user = newVal;
        if( !user ) return;
        if( user.usertype != 'advanced' )
        {
          throw 'TODO: Extend NG-HIDE instead of this....'
        }
      });
    }
  };
})
