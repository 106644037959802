var pageHelpers = require('../../libs/page-helpers');

angular.module('koh').controller('ReaderCtrl',
    ['$scope',
    'helpers',
    'Restangular',
    '$document',
    '$sce',
    '$stateParams',
    '$location',
    '$compile',
    '$rootScope',
    'apiUrl',
    '$http',
    '$timeout',
    '$anchorScroll',
    'booksService',
    'selectionService',
    'printApiUrl',
    function($scope,
      helpers,
      Restangular,
      $document,
      $sce,
      $stateParams,
      $location,
      $compile,
      $rootScope,
      apiUrl,
      $http,
      $timeout,
      $anchorScroll,
      booksService,
      selectionService,
      printApiUrl
      ) {
      $scope.activePopupLink = undefined;
      $scope.masterScope = true;
      $document.find('html').addClass('white-background');

      /* Open reading bar on load */
      $scope.$parent.toggleObject = $scope.$parent.toggleObject || {};
      $timeout(function(){
        $scope.$parent.showReadNavigation();
      }, 0);

      $scope.apiUrl = apiUrl;
      $scope.readingMode = $rootScope.readingMode = $rootScope.readingMode || $rootScope.user.readingMode;
      $scope.fontSize = 3;

      var booksObject = Restangular.all('books');
      var selectionsObject = Restangular.all('selections');

      // //console.log('Getting user data object');
      var userdataObject = selectionsObject.one($scope.user._id);

      // var filesObject = Restangular.all('files');

      $scope.booksService = booksService;
      $scope.booksService.init($scope, $stateParams, userdataObject);
      var loadSelections = booksService.loadSelections;
      
      $scope.selectionService = selectionService;

      $scope.myVar = 5;

      /* Extend active flags, ovewriting current values */
      _.assign($scope.$parent.activeFlags,
      {
          toolsContainer: false,
          additionType: null,
          saveFlyout: false
      });
      //console.log('Overwriting activeflags');
      $scope.activeFlags = $scope.$parent.activeFlags;

      $scope.$on('$destroy', function(){
        $scope.$parent.toggleObject.read = false;
        delete $rootScope.readingMode;
      });



      /* CITATION ORGANIZER */

      // Wrapper for setActive Type
      $scope.setActiveType = function(type, val, toggleOrArg) {
        helpers.setActiveType($scope, type, val, toggleOrArg);
      };

      $scope.saveCitation = function() {
        $scope.linkObj.route = $scope.linkObj._id;
        $scope.linkObj.save().then(function() {
          $scope.$emit('citationsUpdated');
          $scope.setActiveType('editingCitation',null);
        });
      }

      $scope.followLink = function(linkId, argforwards) {
        ;
        var linkObj = _.find($rootScope.links, {_id: linkId});
        $rootScope.paintedLinks =  [];
        var url;
        var forwards = (argforwards === "true");

        if (!forwards) {
          url = '/reader/' + linkObj.data.citationData.data.type +
            '/' +
            linkObj.data.citationData.data.names.fileName +
            '/' +
            linkObj.data.citationData.data.ranges[0].files[0].replace('.html','') +
            '/' + linkObj.citation;
        } else {
          url = '/reader/' + linkObj.data.linkData.data.type +
            '/' +
            linkObj.data.linkData.data.names.fileName +
            '/' +
            linkObj.data.linkData.data.ranges[0].files[0].replace('.html','') +
            '/' + linkObj.data.linkData.name;
        }
        //$rootScope.links = undefined;
        //console.log('Unsetting page links');
        $rootScope.pageLinks = [];
        $rootScope.documentLinks = undefined;
        $rootScope.pagelinkmargins = [];
        $rootScope.settingLinks = false;
        $rootScope.links = undefined;
        url = url.replace('/book/', '/books/');
        $location.url(url);
      }

      $scope.editNote = function(noteId) {
        // //console.log('editing note');
        if (noteId) {
          var tempObj = _.find($rootScope.user.selections.notes, { selectionId: noteId});
          userdataObject.getList().then(function(data){
            $scope.linkObj = _.find(data, {'_id' : tempObj._id});
            $scope.directiveLinkObj = $scope.linkObj;
            document.getElementsByTagName('IFRAME')[0].contentDocument.getElementsByClassName('cke_editable')[0].innerHTML = $scope.linkObj.note;
            $scope.setActiveType('additionType','note');
          })
        }
        // Selections are now an array.  So this needs to extract the body from the first.
        // Is there ever an instance where we have multiple?
        // if (_.isObject(tempObj.selection)) {
        //     $scope.linkObj.selection = tempObj.selection;
        // } else {
        //   $scope.linkObj.selection[0] = tempObj.selection[0];
        // }
      }

      $scope.removeNote = function() {
        Restangular.one('selections', $scope.linkObj._id).remove().then(function(err, res) {
          loadSelections().then(function() {
            $rootScope.$broadcast('invalidateRenderedPage', {chapter: $scope.currentChapter});
          });
        });
      };

      $scope.saveCitations = function(citationData) {
        var updateArray = [];
        //console.log('Saving delta on citations');


        _.each(citationData, function(row) {
          var compObj = _.find($rootScope.cachedCitations, {_id: row._id});
          if (!_.isEqual(row, compObj)) {
            updateArray.push(row);
          }
        });

        var updates = updateArray.length;
        _.each(updateArray,function(row) {
          row.route = row._id;
          row.selection_id = row._id;
          Restangular.copy(row).put().then(function() {
            updates--;
            if (updates == 0) {
              // //console.log('All changes applied.');
              Restangular.one('users', $scope.user._id).get().then(function(data) {
                    // //console.log('Got back data');
                    data.folders = $scope.user.folders;
                    data.save().then(function() {
                      // //console.log('Last push made');
                      loadSelections();
                      // //console.log('Saved data!');
                    });
                  })
            }
            // //console.log('Item Saved');
          })
        });
        // //console.log('Update array' + updateArray);
      }

      $scope.saveChanges = function(ind, val) {
        // //console.log('Put data');
        if (!_.isUndefined(ind)) {
              $scope.user.folders[ind].name = val;
              $scope.setActiveType('editingFolder', null)
          }
            Restangular.one('users', $scope.user._id).get().then(function(data) {
              // //console.log('Got back data');
              data.folders = $scope.user.folders;
              data.save().then(function() {
                // //console.log('Saved data!');
              });
            })
            //scope.user.save();
        };

      $scope.links = null;
      $scope.myIds = null;

      $scope.doSearch = $scope.booksService.doSearch;
      $scope.printDiv = $scope.booksService.printDiv;

      $scope.getMessageClass = function() {
        if ($scope.addedBookMark) {
          return 'added';
        }
        return '';
      }

      /* RIGHT CLICK MENU FUNCTIONS */
      $scope.$on('currentWordSet', function(e, data){
        $scope.currentWord = data.word;
        $scope.currentId = data.id;
      });

      $scope.copySelection = function($event){
        $event.preventDefault();
        var selObj = window.getSelection(),
            copyNode = document.getElementById('koh-copy-div');

        selObj.removeAllRanges();
        if(copyNode){
          copyNode.select();
        }
        else {
          selObj.addRange($scope.currentSelection.selectionRange);
        }
        try {
          var successful = document.execCommand('copy');
          var msg = successful ? 'successful' : 'unsuccessful';
          // //console.log('Copy command was ' + msg);
        } catch(err) {
          // //console.log('Unable to copy');
        }
      }

      $scope.setToolsWord = function(){
        var selObj = $scope.selectionObj;
        if (selObj.words == 1){
          $scope.$emit('currentWordSet', { word: selObj.body, id: selObj.startSpanId });
        }
      }

      $scope.lookUpLexicon = function($event){
        if($scope.currentId){
          $scope.definitionObj = {};
          $scope.definitionObj.word = $scope.currentWord;
          Restangular.all('lookup/mapping').one($scope.currentId).get().then(function(data){
            if (data.docs && data.docs.length){
              $scope.definitionObj.docs = separateBySource(data.docs);
              $scope.definitionObj.lexicon = true;
              $scope.definitionObj.phrase = getLexiconPhrase(data.docs[0]);
              $scope.definitionObj.strongs = getStrongsNumber(data.docs[0]);
            } else {
              $scope.definitionObj.error = true;
              $scope.definitionObj.errorMsg = data.error;
            }
              $scope.setActiveType('additionType', 'define');
          }, function(error){
            console.err(error);
          });
        }
      }

      var separateBySource = function(docs){
        var lastSource = '',
            all = [],
            current = [];

        _.each(docs, function(doc){
          if (doc.source != lastSource){
            if (current.length > 0) all.push(current);
            current = new Array(doc);
            lastSource = doc.source;
          } else{
            current.push(doc);
          }
        });
        all.push(current);
        return all;
      }
      var getStrongsNumber = function(doc){
        if (doc.hasOwnProperty('strongs-number')){
          return doc['strongs-number'];
        } else return '';
      }
      var getLexiconPhrase = function(doc){
        var phrase = '';
        if (doc.hasOwnProperty('spans')){
          _.each(doc.spans, function(spanID){
            var span = document.getElementById(spanID);
            if (!_.isUndefined(span) && !_.isNull(span)){
              var aSpan = angular.element(span);
              if (!aSpan.hasClass('Verse')){ phrase = phrase + aSpan.text(); }
            }
          });
        }
        //remove leading and training punctuation and spaces
        return phrase.trim().replace(/([.,\/#!$%\^&\*;:{}=\-_`~()])|([.,\/#!$%\^&\*;:{}=\-_`~()])/g, "");
      }

      $scope.lookUpDefinition = function($event){
        if ($scope.currentWord){
          Restangular.all('define').one($scope.currentWord).one('20').one('0').get().then(function(data){
            $scope.definitionObj = {};
            $scope.definitionObj.word = $scope.currentWord;
            data.docs = _.each(data.docs, function(x) {
              _.each(x.word, function(word) {
                x.title = word;
              });
            });
            if (data.docs && data.docs.length){
              $scope.groups = _.pluck(_.sortBy(_.uniq(data.docs, 'source'), 'source'), 'source');
              $scope.definitionObj.phrase = getLexiconPhrase(data.docs[0]);
              $scope.definitionObj.docs = getLookupSource(data.docs,$scope.groups,$scope.currentWord);
            }
            else {
              $scope.definitionObj.error = true;
              $scope.definitionObj.errorMsg = data.error || "No Definition Found";
            }
            $scope.setActiveType('additionType', 'define');
          }, function(error){
            console.err(error);
          });
        }
      };

      var getLookupSource = function (docs,groups,phrase) {
        var all = [],
            glossary,
            webster;
        phrase = phrase.toLowerCase();
        _.each(docs,function (doc) {
          if(doc.source == groups[0] && doc.title.toLowerCase() == phrase){
            glossary = doc;
          }
          if(doc.source == groups[1] && doc.title.toLowerCase() == phrase){
            webster = doc;
          }
        });
        glossary ? all.push(glossary) : all.push(_.find(docs,{ source : groups[0]}));
        webster ? all.push(webster) : all.push(_.find(docs,{ source : groups[1]}));
        return groups.length < 3 ? [all[0]] : all;
      };

      var setDefinitionSelection = function(){
        if ($scope.selectionObj) selectionService.removeSelection();

        var selObj = window.getSelection();
        selObj.removeAllRanges();
        selObj.addRange($scope.currentSelection.selectionRange);
        if (!document.execCommand("HiliteColor")) {
          document.execCommand("BackColor");
        }
      }

      /* ADD SELECTION TYPES */

      $scope.$on('selectionSet', function(ev, data){
        $scope.selectionNodes = data.selectionNodes;
        $scope.selectionObj = data.selectionObj;
      });


      $scope.addBookmark = function() {
        $scope.selectionObj = $scope.selectionObj;
        $scope.linkObj = $scope.booksService.generateLinkObj($scope.selectionObj, 'bookmark');
        $scope.saveSelection('Bookmark added!', true);
      };

      $scope.addNote = function() {
        //console.log('Adding note');
        $scope.selectionObj = $scope.selectionObj;
        document.getElementsByTagName('IFRAME')[0].contentDocument.getElementsByClassName('cke_editable')[0].innerHTML = '';
        $scope.setActiveType('toolsContainer',false);
        var content = $scope.selectionObj.body;

        if (_.isUndefined($scope.currentNote)||$scope.currentNote==null) {
          $scope.currentNote = content;
        }
        if (!$scope.currentNote) {
          $scope.message = 'No notes or text selected';
        }
        else {
          $scope.linkObj = 'wtf';

          $scope.linkObj = $scope.booksService.generateLinkObj($scope.selectionObj, 'note');
          $scope.directiveLinkObj = $scope.linkObj;
          $scope.setActiveType('additionType','note');
        }
      }

      $scope.getSelectionSubHeader = function() {
        if (!$scope.linkObj) {
          return '';
        }
        if ($scope.linkObj.citation) {
          return $scope.linkObj.citation
        } else {
          return $scope.currentFolder + ' / ' + helpers.getFullBookInfo($rootScope.bookData, $scope.currentBook).sectionTitle;
        }
      }

      var chapterCheck = function(selection) {
        // This method makes no sense. why does the word chapter have to appear in the text??

        ;
        var cleanSelectionIndex = selection.body.replace(/[`^\s']/gi,'').toLowerCase().indexOf('chapter');
        //Check for chapter text and if it occurs at the start
        if (cleanSelectionIndex>=0 && cleanSelectionIndex<2) {
        //if (selection.body.replace(/ /g,'').toLowerCase().startsWith('chapter')) {

          $scope.activeFlags['confirmError'] = 'Invalid selection. Some or all of the text is outside of valid citation syntax. Cannot create entry.';
          return false;
        }
        return true;

      }

      $scope.addCitation = function(){
        ;
        $scope.selectionObj = $scope.selectionObj;
        $scope.setActiveType('toolsContainer', false);
        var firstChar = $scope.selectionObj.body.charAt(0);
        if (!chapterCheck($scope.selectionObj)) {
          return;
        }
        //console.log('Getting urlPageIndex');
        var urlPageIndex = helpers.getPageFromUrl($rootScope.readingData.currentChapter);

        //console.log('First char:' + firstChar);
        if (firstChar == ' ') {
          //console.log('Bumping selection by a space')
          $scope.selectionObj.body = $scope.selectionObj.body.substr(1);
          var tsArr = $scope.selectionObj.startSpanId.split('TextSpan');
          var currentStartId = parseInt(tsArr[1]);
          currentStartId++;
          $scope.selectionObj.startSpanId = tsArr[0] + 'TextSpan'  + currentStartId;
        }
        // Check minimum citation length
        if ($scope.selectionObj.words < 2){
          $scope.message = "Please select more than 2 words for a citation.";
          return;
        }

        $scope.linkObj = $scope.booksService.generateLinkObj($scope.selectionObj, 'citation');
        /*
        if ($rootScope.isHBL) {
            $scope.linkObj.book = helpers.getBookFromUrl($rootScope.readingData.currentChapter);
            $scope.linkObj.folder =
            $scope.selectionObj.startPageIndex = urlPageIndex-1;
            $scope.selectionObj.endPageIndex = $scope.selectionObj.startPageIndex + endPageDiff;
        }  */

        if (helpers.getBookFromUrl($rootScope.readingData.currentChapter).toLowerCase() == 'sh') {
          if ($rootScope.isHBL) {
            var endPageDiff = $scope.selectionObj.endPageIndex - $scope.selectionObj.startPageIndex;
            $scope.linkObj.selection.startPageIndex = urlPageIndex-1;
            $scope.linkObj.selection.endPageIndex = $scope.linkObj.selection.startPageIndex + endPageDiff;
          }
        }
        if ($scope.activeFlags['activeFolder']!=null) {
          // //console.log('Adding to folder with id:' + $scope.activeFlags['activeFolder'].id);
          $scope.linkObj.citationFolder = $scope.activeFlags['activeFolder'].id;
        }

        $scope.linkObj.displayedOrder = $scope.linkObj.order = _.isUndefined($scope.user.citations) ? 0 : $scope.user.citations.length;
        $rootScope.lastAddedCitation = $scope.linkObj.citation;
        $scope.saveSelection('Citation added!').then( function(){
          $scope.$broadcast('citationsUpdated')
        });
      }

      $scope.addHighlight = function(){
        //console.log('Adding highlight');

        $scope.linkObj = $scope.booksService.generateLinkObj($scope.selectionObj, 'highlight');

        // When a note had previously been added, it was a ghost.
        $scope.linkObj.note = null;
        if ($scope.activeFlags['activeFolder']!=null) {
          // //console.log('Adding to folder');
          $scope.linkObj.folder = $scope.activeFlags['activeFolder'].id;
        }

        document.getElementsByTagName('IFRAME')[0].contentDocument.getElementsByClassName('cke_editable')[0].innerHTML = '';
        $scope.linkObj.displayedOrder = $scope.linkObj.order = _.isUndefined($scope.user.highlights) ? 0 : $scope.user.highlights.length;
        $scope.saveSelection('Highlight added!');
      }

      $scope.addLinkNote = function() {
        // //console.log('Adding link note');
        $scope.lastAddedObj.note = '';
        document.getElementsByTagName('IFRAME')[0].contentDocument.getElementsByClassName('cke_editable')[0].innerHTML = '';
        $scope.linkObj = $scope.lastAddedObj;
        $scope.setActiveType('additionType','note');
      }


      $scope.recentlyAdded = null;

      /* Save all types of selections - bookmark, citation, highlight, note */
      $scope.saveSelection = function(message, showAddNote, overrideObject) {
        return new Promise(function(resolve, reject) {
          // //console.log('Saving bookmark');
          if (overrideObject) {
            $scope.linkObj = overrideObject;
          }
          $scope.showAddNote = showAddNote || false;
          // Force it in to be sure
          $scope.linkObj.note = document.getElementsByTagName('IFRAME')[0].contentDocument.getElementsByClassName('cke_editable')[0].innerHTML;

          $scope.linkObj.user_id = $rootScope.user._id;

          // make sure selection exists
          if (_.isUndefined($scope.linkObj.selection)&&$scope.linkObj.type!='bookmark') return;

          if (_.isUndefined($scope.linkObj._id)||$scope.linkObj._id==null) {
            saveNewSelection(message, resolve, reject);
          }
          else {
            updateSelection(message, resolve, reject);
          }
          selectionService.removeSelection();
        });
      };

      $scope.pageHasBookmark = function () {
        if (!$rootScope.user) {
          return false;
        }
        if (!$rootScope.user.selections) {
          return false;
        }
        var url = document.location.pathname;
        var bFound = false;
        _.each($rootScope.user.selections.bookmarks, function(row) {
          if (url.indexOf(row.section)>=0) {
            bFound = true;
            return bFound;
          }
        })
        return bFound;
      }

      var saveNewSelection = function(message, resolve, reject){
        //console.log('Saving new selection');
        $scope.setActiveType('additionType',null);
        //immediatelyAddMarkup($scope.linkObj);
        $scope.linkObj.selectionId = helpers.guid();

        if ($scope.linkObj.type == 'note') {
          $rootScope.user.selections.notes.push($scope.linkObj);
        }
        if ($scope.linkObj.type == 'citation') {
          $rootScope.user.citations.push($scope.linkObj);
        }
        if ($scope.linkObj.type == 'highlight') {
          $rootScope.user.selections.highlights.push($scope.linkObj);
        }
        // Broadcast to book-page: clear user selection
        $scope.$broadcast('clearSelection');

        // Reload selection object
        // //console.log('Reloading selections');
        $rootScope.$broadcast('invalidateRenderedPage', {chapter: $scope.currentChapter});

        selectionsObject.post($scope.linkObj).then(function(retObj) {
          // Set success message
          $scope.message = message;
          $timeout($scope.resetMessage, 2500);

          // Update scope
          $scope.lastAddedObj = retObj;
          $scope.linkObj = null;
          $scope.selectionObj = null;
          $scope.addedBookMark = true;

          if (retObj.type == 'citation') {
            $rootScope.$broadcast('openCitationOrganizer', retObj);
          }

          loadSelections().then(function() {
            $rootScope.$broadcast('invalidateRenderedPage', {chapter: $scope.currentChapter});
          });
          resolve();
        }, reject);
      }

      var immediatelyAddMarkup = function(obj) {
        // //console.log('Immediately adding');
        var newObject = Object.create({});
        newObject = _.extend(newObject, obj);
        if (_.isObject(newObject.selection)) {
          var newArray = [];
          newArray[0] = newObject.selection;
          newObject.selection = newArray;
        }
        if (newObject.type == 'note') {
          // //console.log('Tossing in note');
          $rootScope.user.selections.notes.push(newObject);
          $rootScope.$broadcast('invalidateRenderedPage', {chapter: $scope.currentChapter});
        }
      }

      var updateSelection = function(message, resolve, reject){
        var innerNote = '';
        try {
          innerNote = document.getElementsByTagName('IFRAME')[0].contentDocument.getElementsByClassName('cke_editable')[0].innerHTML;
        }
        catch (err) {
          // No note
        }

        $scope.linkObj.note = innerNote;
        $scope.linkObj.save($scope.linkObj).then(function(retObj) {
          // //console.log('BEN:2');
          $scope.linkObj = null;
          $scope.addedBookMark = true;
          $scope.message = message
          $scope.setActiveType('additionType',null);
          loadSelections().then(function(){
            $timeout($scope.resetMessage, 2500);
            $rootScope.$broadcast('invalidateRenderedPage');
            resolve();
          });
        }, reject);
      }

      $scope.resetMessage = function() {
        $scope.message = null;
      };
      var getRNOffset = function(docType) {
        var isRomanNumeral = false;

        var shPrePageArray = ['0','i','ii','iii','iv','v','vi','vii','viii','ix','x','xi','xii','xiii','xiv','xv','xvi','xvii','xviii','xix','xx'];
          if ($rootScope.currentBookData.sections[0].ref =='i' && docType != 'SH') {
            isRomanNumeral = true;
            var maxRomanNumeralIterator = 0;
            for (var i = 0 ; i < 30 ; i++) {
              var bFound = false;

              for (var j = 0 ; j < shPrePageArray.length ; j++ ) {
                if ($rootScope.currentBookData.sections[i].ref == shPrePageArray[j]) {
                  maxRomanNumeralIterator++;
                  bFound = true;
                  break;
                }
              }
            }
            $scope.printDiff = parseInt($rootScope.currentBookData.sections[maxRomanNumeralIterator-1].sectionEndPage)-1;
          }
          return isRomanNumeral;
      }

      $scope.saveLink = function() {
        $scope.linkObj = $scope.booksService.generateLinkObj($scope.selectionObj, 'link');
        userdataObject.post($scope.linkObj).then(function(err, res) {
          $scope.message = 'Saved link';
          $scope.links = null;
          $scope.booksService.matchLinks($scope.currentChapterData);
          if ($scope.readingMode === 'dual-page') {
            $scope.booksService.matchLinks($scope.prevChapterData, prevChapterDiv);
          }
        })
      };
      $scope.safeNotes = '';

      $scope.getNotes = function() {
        var notesString = '';
        // //console.log('Applying markup');
        // _.each($scope.notes, function(row) {

        // })
        $scope.safeNotes = $sce.trustAsHtml(notesString);
      };

      $scope.setReadingMode = function(mode) {
        $timeout(function() {
          $rootScope.readingMode = $scope.readingMode = mode;
        });
        if (mode == 'continuous'){
          helpers.setPageScroll($rootScope.readingData.currentChapter, false);
        }
        if (mode == 'dual-page'){
          $scope.isDualPage = true;
          onWidthChange($scope.mq);
        } else {
          $scope.isDualPage = false;
        }
      };


      $scope.saveLinkData = function(link) {
        link.put().then(function(data) {
          // //console.log('Did put');
        });
      }

      $scope.changeFontSize = function(val){
        $timeout(function() {
          $rootScope.fontTooBig = false;
          $rootScope.fontTooSmall = false;
          var fontSz = $scope.fontSize + val;
          if (fontSz > 6 || fontSz < 0){
            $rootScope.fontTooBig = fontSz > 6;
            $rootScope.fontTooSmall = fontSz < 0;
            return;
          }
          else{
            $scope.fontSize = fontSz;
          }
        });
        // give page a second to update before revaluating media query
        $timeout(function() {
          setMediaListener();
        }, 10);
      };


      $scope.clickLink = function(link) {
        ;
        $scope.activeLink = link;
      };

      $scope.saveFlyOutLink = function () {

        var link = _.find($scope.links, {_id:$scope.activeLink});
        link.route = link._id;
        link.put().then(function() {
          // //console.log('Saved link');
        });
      };

      var openPrintUri = function(uri) {
        window.open(uri);
      };

      /**
       * Calculates the periodical page ranges.
       * Not sure exactly how it works, but needs to have a lot of logic
       * because printed periodicals can have non-sequential pages. Example: 'A1-16,134-138'
       *
       * I think this deals in labels ("display pages") rather than indexes ("physical pages")
       *
       */
      var calculatePeriodicalPageRanges = function() {
        ;
        var pageBreaks = $scope.$root.periodicalData.pageBreaks;
        var page = helpers.getPageFromUrl($rootScope.readingData.currentChapter);
        var orgRange = pageBreaks[page==0? 0: page-1].originalRange;
        var calcRange = orgRange.indexOf(',') >= 0 ? orgRange.split(',') :
          orgRange.indexOf('-') >= 0 ? orgRange.split('-') : null;
        if (calcRange != null) {
          $scope.startPrintPage = calcRange[0];
          $scope.endPrintPage = calcRange[1];
        } else {
          $scope.startPrintPage = $scope.endPrintPage = orgRange;
        }
      };

      /**
       * Sets the default values for the modal for printing page ranges
       * — the "saveFlyout" modal
       */
      var setPrintRangeDefaults = function() {
        if ($rootScope.isPeriodical) {
          // will probably never reach here, but adding this in just in case
          // we ever add print range functionality for periodicals
          calculatePeriodicalPageRanges();
          return;
        }

        var readingData = $rootScope.readingData;

        // pageHelpers and server expect currentIndex to start from 1
        var normalizedCurrentIndex = $scope.currentIndex + 1;
        var items = $rootScope.currentBookData.sections;

        var bookType = readingData.bookInfo.bookType;

        var currentItemRange = pageHelpers.getCurrentItemRange(normalizedCurrentIndex, items, bookType);

        // currentItemRange values are 1-indexed, but we need 0-indexed for chapterList
        var endIndexFromZero = currentItemRange[1] - 1;
        var itemEndPageObject = readingData.chapterList[endIndexFromZero];

        // label is the "display page", not index ("physical page")
        var itemEndPageLabel = itemEndPageObject.title;
        var itemStartPageLabel = readingData.chapterList[$rootScope.readingData.currentIndex].title;

        $scope.startPrintPage = itemStartPageLabel;
        $scope.endPrintPage = itemEndPageLabel;
      };

      $scope.printLesson = function() {
        // //console.log('Printing lesson!!', $rootScope.currentLesson);
        var url = $scope.apiUrl + '/api/lessons/print/' + $scope.currentLesson + '.pdf';
        // //console.log('Full url: '+ url + ' and scope url ' + $scope.apiUrl);
        openPrintUri(url);
      }
      var rcRootScope = $rootScope;
      $scope.printPeriodical = function() {
        ;
          var tempApiUrl = 'http://' + helpers.extractServer($scope.apiUrl) + ':' + (helpers.extractPort($scope.apiUrl)+100);
          var url = tempApiUrl + '/api/files/' + $scope.currentFolder + '/' + $scope.currentBook + '.pdf/all';
          openPrintUri(url);
          $scope.setActiveType('saveFlyout',false);
      }

      $scope.printPeriodicalItem = function() {
          ;
          calculatePeriodicalPageRanges();
          var tempApiUrl = $scope.apiUrl;
          var url = tempApiUrl + '/api/files/' + $scope.currentFolder + '/' + $scope.currentBook + '.pdf/' + $scope.startPrintPage +
            ($scope.startPrintPage.indexOf('-')>=0||$scope.endPrintPage.indexOf('-')>=0? ',': '-') + $scope.endPrintPage;
          openPrintUri(url);
          $scope.setActiveType('saveFlyout',false);
      }

      // this is aliased in BookService—syncRootScope magic - otherwise doesn't work!
      $scope.printCurrentPage = function() {
        var readingData = $rootScope.readingData;
        var parentFolder = $rootScope.currentBookData.parentFolder;
        var uri = pageHelpers.getPrintCurrentPageUri(printApiUrl, parentFolder, readingData);
        openPrintUri(uri);
      };

      /**
       * for books only
       * periodicals use `printPeriodicalItem()` instead of this function
       *
       * Note: this function is aliased in BookService—syncRootScope magic - otherwise doesn't work!
       *
       */
      $scope.printCurrentItem = function() {
        var apiUrl = $scope.apiUrl;

        var readingData = $scope.$root.readingData;

        var currentIndex = readingData.currentIndex;
        var items = $scope.$root.currentBookData.sections;

        // all the page helpers (and the server) treat page indexes (physical pages)
        // as starting from 1
        var normalizedCurrentIndex = currentIndex + 1;

        var bookType = readingData.bookInfo.bookType;

        var currentItemRange = pageHelpers.getCurrentItemRange(normalizedCurrentIndex, items, bookType);

        var startIndex = currentItemRange[0];
        var endIndex = currentItemRange[1];
        var parentFolder = $rootScope.currentBookData.parentFolder;
        var uri = pageHelpers.getPrintPageRangeUri(apiUrl, parentFolder, readingData.currentBook, startIndex, endIndex);
        openPrintUri(uri);
      };

      /**
       * Shows the save flyout (modal for printing page ranges)
       *
       * Note: this is aliased in BookService—syncRootScope magic - otherwise doesn't work!
       */
      $scope.showSaveFlyout = function() {
        // this doesn't seem to do anything, but kept it in to keep the logic that was here before
        $rootScope.toggleAnObject('readingControls', 'readingControls', false)
        setPrintRangeDefaults();
        $scope.setActiveType('saveFlyout', true);
      };


      /* Watch for browser size changing and update if in dual-page mode */
      if (window.matchMedia) {
        $scope.isDualPage = $rootScope.readingMode == 'dual-page';

        setMediaListener()
        $scope.$on('pageInited', setMediaListener);
      }

      function setMediaListener(){
        if($scope.mq) $scope.mq.removeListener(onWidthChange);
        window.removeEventListener("orientationchange", onOrientationChange);

        $scope.mq = window.matchMedia('(min-width: ' + getMinWidth() + ')');
        $scope.mq.addListener(onWidthChange);
        window.addEventListener("orientationchange", onOrientationChange)

        onWidthChange($scope.mq);
      }

      function onWidthChange(mq) {
        var min = 992; // $screen-md
        if (!$scope.isDualPage) return;
        $timeout(function() {
          if (mq.matches) {
            $scope.readingMode = $rootScope.readingMode = 'dual-page';
          }
          else {
            if(window.screen.width < min){
              $scope.readingMode = $rootScope.readingMode = '';
            }
          }
        });
      }

      function onOrientationChange(){
        onWidthChange($scope.mq);
      }

      function getMinWidth(){
        var min = 992; // $screen-md
        var pages = document.querySelectorAll('div[epub-loaded]');
        _.each(pages, function(page){
          var width = page.getClientRects().length ? page.getClientRects()[0].width : page.clientWidth;
          width = width * 2;
          min = width > min ? width : min;
        });
        return min + 'px';
      }

    }
]);
