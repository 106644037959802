var helpers = function () { return require('../libs/helpers'); };

angular.module('koh.search', ['ui.router', 'ct.ui.router.extras.core', 'ct.ui.router.extras.dsr', 'ct.ui.router.extras.sticky'])
.config(['$stateProvider', function($stateProvider) {
	$stateProvider.state('search', {
		url: '/search/:query?',
    views: {
		  'root-search': {
        templateUrl: '/controllers/search/search.html',
        controller: 'SearchCtrl',
      }
    },
    parent: 'root',
    dsr: true,
    sticky: true,
    reloadOnSearch: false,
		params: {
			query: null,
			filters: null
		},
		resolve: {
			helpers: helpers,
			authenticate: function(kohauth) {
				return kohauth.authenticate();
			}
		}
	})
    .state('periodicals', {
      url: "/journals",
      controller: 'PeriodicalCtrl',
		templateUrl: '/controllers/periodicals/periodicals.html',
      params: {
      },
      resolve: {
        helpers: helpers,
        authenticate: function(kohauth) {
          
          return kohauth.authenticate();
        }
      }
    })
    .state('sentinels', {
      url: "/sentinels",
      controller: 'SentinelCtrl',
		templateUrl: '/controllers/sentinels/sentinels.html',
      params: {
      },
      resolve: {
        helpers: helpers,
        authenticate: function(kohauth) {
          
          return kohauth.authenticate();
        }
      }
    })
	.state('define', {
		url: '/define/:query?',
		templateUrl: '/controllers/define/dictionary.html',
		controller: 'DictionaryCtrl',
		params: {
			query: null,
			filters: null
		},
		resolve: {
			helpers: helpers,
			authenticate: function(kohauth) {
				return kohauth.authenticate();
			}
		}
	})
	.state('lookup', {
		url: '/lookup/:query?',
		templateUrl: '/controllers/lookup/lexicon.html',
		controller: 'LexiconCtrl',
		params: {
			query: null,
			filters: null
		},
		resolve: {
			helpers: helpers,
			authenticate: function(kohauth) {
				return kohauth.authenticate();
			}
		}
	})
}]);
