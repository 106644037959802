var helpers = require('../../libs/helpers');

angular.module('koh').directive('epubLoaded', [
    'Restangular',
    'booksService',
    '$sce',
    '$rootScope',
    '$document',
    '$compile',
    'selectionService',
    'searchService',
    '$location',
    '$state',
    '$stateParams',
    'documentSearchFactory',
    '$timeout',
    function (
    Restangular,
    booksService,
    $sce,
    $rootScope,
    $document,
    $compile,
    selectionService,
    searchService,
    $location,
    $state,
    $stateParams,
    documentSearchFactory,
    $timeout) {

    function init($scope, force) {
      if (!force && $scope.hasInited) {
        return; // no need to init 2nd time
      }
      ;
      setCitations($scope);
      setHighlights($scope);
      setLinks($scope);
      setNotes($scope);
      var highlightSearchTerm = function() {
        if (documentSearchFactory.isSearching) {
          setSearch(documentSearchFactory.pagedData, $scope);
        }
      }
      $scope.$on('searchResultsReady', function (event, args) {
        highlightSearchTerm();
      });
      highlightSearchTerm();
      // notify fellows that we loaded
      $scope.hasInited = true;
      $scope.$emit('pageInited');
    }

    function isCurrentPage($scope) {
      return $rootScope.readingData.currentChapter === $scope.page.fileName;
    }

    var controller = function($scope){
      $scope.$on('invalidateRenderedPage', function(){
        if (isCurrentPage($scope)) {
          ;
          init($scope, true);
        }
      });
    };

    var link = function ($scope, element, attrs) {
      // setup listeners
      $scope.$on('invalidateLinks', function(event, args) {
        if (isCurrentPage($scope)) {
          ;
          $rootScope.paintedLinks = [];
          if (!$scope.hasInited) {
            init($scope);
          } else {
            setLinks($scope);
          }
        }
      });
      // initialize data
      setStyle(element);
      if (isCurrentPage($scope)) {
        init($scope);
      }
    };

    /* private methods */

    /* Add epub height/width to wrapper */
    var setStyle = function(element){
        var node = element[0];
        var height = element.css('height');
        element.css('max-height', height);
        var bookPage = angular.element(node.parentElement.parentElement); //.epub-text-wrapper
        var style = node.getAttribute('style');
        bookPage.attr('style', style).addClass('loaded');
        // setting height for mobile div that blank pages can be swiped left/right
        angular.element(node.parentElement.parentElement.nextElementSibling).css('height', height);
    };

    /* Set up user selections for page */
    var setLessonCitations = function($scope){
        selectionService.removeClass(['chalked', 'start', 'end'], $scope.page.fileName);

        if (!$rootScope.activeCitation && !$rootScope.isHBL) {
            return;
        }

        var citations = _.where($rootScope.user.citations, {'_id' : $rootScope.activeCitation});
        if ($rootScope.isHBL) citations = citations.concat($rootScope.hbl.citations);

        _.each(citations, function(citation) {
            if(isSectionOnPage(citation.section, $scope)){
                var selObj  = citation.selection[0] || citation.selection;
                var start   = getElement(selObj.startSpanId, citation.section),
                    mStart   = getElement('mobile_' + selObj.startSpanId, citation.section),
                    end     = getElement(selObj.endSpanId, citation.section),
                    mEnd     = getElement('mobile_' + selObj.endSpanId, citation.section);
                if (!$rootScope.activeCitation) {
                    selectionService.applyClass(new Array(start, mStart), 'chalked start');
                    selectionService.applyClass(new Array(end, mEnd), 'chalked end');
                } else {
                    selectionService.applyClass(new Array(start, mStart), 'selected start');
                    selectionService.applyClass(new Array(end, mEnd), 'selected end');
                }


                if(selObj.specialMarkings){
                    selectionService.applyClass(new Array(end, mEnd), selObj.specialMarkings);
                }
            }
        });
    };
  var setCitations = function($scope){
        //console.log(helpers.tims() + 'Drawing citations');
        //console.log('Setting citations for: ' + $scope.page.fileName);
        if ($scope.page.fileName.indexOf('Front_Pages')>=0 || $scope.page.fileName.indexOf('SH_0001')>=0) {
            return;
        }
        selectionService.removeClass(['chalked', 'start', 'end'], $scope.page.fileName);

        if (!$rootScope.activeCitation) {
            if (!_.isObject($rootScope.activeCitation) && !$rootScope.isHBL) {
                return;
            }
        }
        // Draw active citation
        if ($rootScope.activeCitation) {
            drawActiveCitation($scope);
        }
        if ($rootScope.isHBL) {
            drawHBLCitations($scope);
        }
    };

    var drawHBLCitations = function($scope) {
        var citations = [];
        citations = citations.concat($rootScope.hbl.citations);
        _.each(citations, function(citation) {
            if(isSectionOnPage(citation.section, $scope)){
                var selObj  = citation.selection[0] || citation.selection;
                var start   = getElement(selObj.startSpanId, citation.section),
                    mStart   = getElement('mobile_' + selObj.startSpanId, citation.section),
                    end     = getElement(selObj.endSpanId, citation.section),
                    mEnd     = getElement('mobile_' + selObj.endSpanId, citation.section);

                if ($rootScope.isHBL) {
                    selectionService.applyClass(new Array(start, mStart), 'chalked start');
                    selectionService.applyClass(new Array(end, mEnd), 'chalked end');
                }

                if(selObj.specialMarkings){
                    selectionService.applyClass(new Array(end, mEnd), selObj.specialMarkings);
                }
            }
        });
    }

    var drawActiveCitation = function($scope) {
        if (!_.isObject($rootScope.activeCitation)) {
            var citations = _.where($rootScope.user.citations, {'_id' : $rootScope.activeCitation});

            _.each(citations, function(citation) {
                if(isSectionOnPage(citation.section, $scope)){

                    var selObj  = citation.selection[0] || citation.selection;
                    var start   = getElement(selObj.startSpanId, citation.section),
                        mStart   = getElement('mobile_' + selObj.startSpanId, citation.section),
                        end     = getElement(selObj.endSpanId, citation.section),
                        mEnd     = getElement('mobile_' + selObj.endSpanId, citation.section);

                    var cls = ($rootScope.activeCitation || $rootScope.isHBL) ? 'chalked' : 'selected';
                    selectionService.applyClass(new Array(start, mStart), cls + ' start');
                    selectionService.applyClass(new Array(end, mEnd), cls + ' end');

                    if($stateParams.chapter == $scope.page.fileName){
                        $timeout(function(){
                            helpers.scrollIntoViewIfOutOfView(start, end);
                            helpers.scrollIntoViewIfOutOfView(mStart, mEnd);
                        }, 500);
                    }


                    if(selObj.specialMarkings){
                        selectionService.applyClass(new Array(end, mEnd), selObj.specialMarkings);
                    }
                }
            });
        }// end if isobject
        else {
             citation = $rootScope.activeCitation; // Should assume only here.
             var citArr = citation.ranges;
             if (citation.ranges.length == 0){
                citArr = new Array(citation.selection)
             }
             _.each(citArr, function(selObj){
                if (!selObj.foundSpans) {
                    _.each(citation.ranges, function(span) {
                        var startSpanArr = span.startSpanId.split('TextSpan');
                        var endSpanArr = span.endSpanId.split('TextSpan');
                        var startId = parseInt(startSpanArr[1]);
                        var endId = parseInt(endSpanArr[1]);
                        for (var i = startId ; i <= endId ; i++) {
                            var spanElement = startSpanArr[0] + 'TextSpan' + i;
                            var start   = getElement(spanElement, citation.section),
                                mStart   = getElement('mobile_' + selObj.startSpanId, citation.section);
                            selectionService.applyClass(new Array(start, mStart), 'selected');
                        }
                    });
                } else {
                    _.each(selObj.foundSpans, function(span) {
                        var start   = getElement(span, citation.section),
                            mStart   = getElement('mobile_' + selObj.startSpanId, citation.section);
                        if(!selectionService.isSkippedNode(start)) {
                          selectionService.applyClass(new Array(start, mStart), 'selected');
                        }
                    });

                    var start   = getElement(selObj.foundSpans[0], $stateParams.chapter),
                        mStart   = getElement('mobile_' + selObj.foundSpans[0], $stateParams.chapter),
                        end     = getElement(selObj.foundSpans[selObj.foundSpans.length-1], $stateParams.chapter),
                        mEnd     = getElement('mobile_' + selObj.foundSpans[selObj.foundSpans.length-1], $stateParams.chapter);

                    helpers.scrollIntoViewIfOutOfView(start, end);
                    helpers.scrollIntoViewIfOutOfView(mStart, mEnd);
                }

                if(selObj.specialMarkings){
                    selectionService.applyClass(new Array(end, mEnd), selObj.specialMarkings);
                }
             })
        }
    }

    /* Set up search highlights for page */
    var setSearch = function(searchArray, $scope){
        selectionService.removeClass(['selected-result selected-result-active', $rootScope.user.highlight], $scope.page.fileName);
        if (!$rootScope.user) {
            return;
        }
        if (!$rootScope.user.selections) {
            return;
        }
        if (!searchArray) {
          return;
        }
        var searchSpans = searchArray[$rootScope.readingData.currentChapter];
        if(searchSpans){
            var selection = searchService.getSearchElements(searchSpans.list);
            selectionService.applyClass(selection, 'selected-result');
        }
        $rootScope.$broadcast('searchHitsLoaded');
    };


    /* Set up user highlights for page */
    var setHighlights = function($scope){
        selectionService.removeClass(['highlighted', $rootScope.user.highlight], $scope.page.fileName);
        if (!$rootScope.user) {
            return;
        }
        if (!$rootScope.user.selections) {
            return;
        }
        var highlights = $rootScope.user.selections.highlights;

        _.each(highlights, function(highlight, i) {
            /*if(isSectionOnPage(highlight.section, $scope.page.fileName)){ Commenting for future fixing for the epub-loaded */
                var selObj  = highlight.selection[0] || highlight.selection;
                var selection = selectionService.createSelection(selObj);
                selectionService.applyClass(selection, 'highlighted ' + $rootScope.user.highlight);
            /*}*/
        });
    };

    /* Set up user highlights for page */
    var setNotes = function($scope){
        if (!$rootScope.user) {
            return;
        }
        if (!$rootScope.user.selections) {
            return;
        }

        var notes = $rootScope.user.selections.notes,
            page = document.getElementById($scope.page.fileName);

        angular.element(page.querySelectorAll('.noteMarkup')).remove();

        if (notes.length > 0) {
            _.each(notes, function(note) {
                if(isSectionOnPage(note.section, $scope)){
                    var selObj = note.selection[0] || note.selection;
                    var start   = angular.element(getElement(selObj.startSpanId, note.section));
                    var align   = getNoteAlignment(start[0]);
                    var text   = note.note.length > 0 ? angular.element(note.note).text() : '';
                    var noteMarkup = '<note-markup class="' + align + '" ';
                        noteMarkup += 'heading="' + note.title + '" ';
                        noteMarkup += 'text="' + text + '" ';
                        noteMarkup += 'id="' + note.selectionId + '" ';
                        noteMarkup += 'top="' + start.css('top') + '">';
                        noteMarkup += '</note-markup>';

                    var noteEl = $compile(noteMarkup)($scope);
                    noteEl.addClass();
                    start.after(noteEl);
                }
            });

            changeZIndexOnMargins(page, true);

            // delete $scope.curNote;
            $compile(angular.element(page).contents(), true);
        }
    };

    var setLinks = function($scope) {
        if (helpers.isPeriodical($scope)) {
          ;
          return;
        }
        ;
        return new Promise(function(resolve, reject) {
            var paintLinks = function () {
                ;
                removeOldLinks();

                //if ($rootScope.linkIcons.length > 0) {
                    _.each($rootScope.linkIcons, function(row) {
                        var bFound = false;
                            var linkMarkup = $compile(
                            '<link-markup class="' + row.markupClass +'" forwards="false" linkId="' + row.linkid + '" top="' + row.top + '" left="' + row.left + '" url="' + row.url + '"></link-markup>'
                            )($scope);
                            linkMarkup.addClass();
                            row.start.after(linkMarkup);
                    });
                    $rootScope.dirtyLinks = false;
                    setTimeout(function() {
                        $rootScope.processingLinks = false;
                    }, 500);
                    resolve();
                //}
            }

            var addLink = function(markupClass, linkid, top, left, start, page, selType) {
                var chkList = $scope.element[0].querySelectorAll('.linkMarkup');
                var foundObj = $scope.element[0].querySelectorAll('#link_' + linkid);

                var bFound = false;
                _.each(chkList, function(row) {
                    if (helpers.inRangeMarkin(row.top, top) && row.markupClass == markupClass)
                        bFound = true;
                });

                var link = _.find($rootScope.links, {_id: linkid});
                var linkUrl = link[selType][0].files[0].replace('.html','');

                var linkText = link[selType][0].text;
                if (!bFound && foundObj.length <= 0) {
                    var pageLink = {};
                    pageLink.markupClass = markupClass;
                    pageLink.linkid = linkid;
                    pageLink.top = top;
                    pageLink.left = left;
                    pageLink.url = linkUrl;
                    pageLink.start = start;
                    pageLink.selType = selType;
                    pageLink.linkText = linkText;
                    // console.log('added for url:' + pageLink.url);
                    $rootScope.pageLinks.push(pageLink);
                }
            };

            var cullLinkIcons = function() {
                $rootScope.linkIcons = [];
                _.each($rootScope.pageLinks, function(row) {
                    var bFound = false;
                    _.each($rootScope.linkIcons, function(iconRow) {
                        if (helpers.inRangeMarkin(row.top, iconRow.top) && row.markupClass == iconRow.markupClass && iconRow.url == row.url) {
                            bFound = true;
                        }
                    });
                    if (!bFound) {
                        $rootScope.linkIcons.push(row);
                    }
                });
            }


            var removeOldLinks = function() {
                var chkList = $scope.element[0].querySelectorAll('.linkMarkup');
                if (chkList.length > 0) {
                    _.each(chkList, function(child) {
                        child.parentNode.removeChild(child);
                    })
                }
                helpers.removeElementsByClass('linkMarkup');
            }
            var getSHLinks = function(bookOrder) {
                var sample = _.find($rootScope.cachedValues, { type: 'SH', reference: 'links'});
                if (sample) {
                    if (sample.data.length > 1)
                        return sample.data;
                }
                sample = _.find($rootScope.cachedValues, { type: 'bookdata', reference: 'SH_' + bookOrder});
                if (sample) {
                    return sample.links;
                }
                return sample;
            }
            var addLinkToQueue = function(page, link) {

                var selObj  = link.outgoing[0] || link.outgoing;
                var start   = angular.element(getElement(selObj.startSpanId));

                if (!_.isUndefined(start[0])) {
                    if (!_.isUndefined(start[0].offsetLeft)) {
                        addLink(getNoteAlignment(start[0]), link._id, start.css('top'), start.css('left'), start, page, 'outgoing');
                     }
                }

                try {
                    var selObj  = link.incoming[0] || link.incoming;
                    var start   = angular.element(getElement(selObj.startSpanId));
                    if (start[0]) {
                        if (!_.isUndefined(start[0].offsetLeft)) {
                           addLink(getNoteAlignment(start[0]), link._id, start.css('top'), start.css('left'), start, page, 'incoming');
                        }
                    }
                } catch (exception) {
                    //console.log(helpers.tims() + 'While building incoming, Caught:'  + exception)
                }
            }

            var processLinks = function() {
                //console.log('Processing links');
                /*if ($rootScope.isHBL) {
                    $rootScope.processingLinks = false;
                }*/
                if ($rootScope.processingLinks) {
                    return;
                }
                var thisPage = helpers.getPageFromPath(window.location.pathname, true);
                var thisBook = helpers.getBookFromPath(window.location.pathname);
                if ($scope.page.fileName != thisPage) {
                    return;
                }

                //console.log('Chekcing linkicons for back');
                if (!$rootScope.isHBL) {
                    if (helpers.noMatchIncOrOut($rootScope.links, thisPage)) {
                        //console.log('Doesnt match');
                        $rootScope.linkIcons = undefined;
                        $rootScope.links = [];
                    }
                } else {
                    if (helpers.noMatchIncOrOut($rootScope.links, thisPage)) {
                        //console.log('Doesnt match');
                        $rootScope.linkIcons = undefined;
                        $rootScope.links = [];
                    }
                }

                $rootScope.links = $rootScope.links || [];

                var needNewLinks = false;
                if ($rootScope.links.length < 1) {
                    if (!helpers.isPeriodical($rootScope.$state)) {
                    //console.log('Fetching links');
                    booksService.getLinks($rootScope.isHBL).then(function(data) {
                        $rootScope.links = data;
                        processLinks();
                    });
                    needNewLinks = true;
                    }
                }else {
                    if ($rootScope.isHBL) {
                        if ($rootScope.lastProcessedLinks != 'HBL') {
                            needNewLinks = true;
                            $rootScope.lastCheckedHBLPage = thisPage;
                            
                            booksService.getLinks($rootScope.isHBL).then(function(data) {
                                $rootScope.lastProcessedLinks = 'HBL';
                                if (data.length > 1) {
                                    $rootScope.links = data;
                                    needNewLinks = true;
                                    processLinks();
                                }
                            });
                        } else {
                            if (thisPage.indexOf('SH_')>=0) {
                                // ONLY FOR SH
                                $rootScope.links = getSHLinks(1);
                                if (!$rootScope.links) {
                                    booksService.getLinks($rootScope.isHBL).then(function(data) {
                                        $rootScope.lastProcessedLinks = 'HBL';
                                        if (data.length > 1) {
                                            needNewLinks = true;
                                            $rootScope.links = data;
                                            processLinks();
                                        }
                                    });
                                }
                            } else {
                                // NON SH BOOK
                                
                                if ($rootScope.lastCheckedHBLPage != thisPage) {
                                    $rootScope.lastCheckedHBLPage = thisPage;
                                    //if (helpers.noMatchIncOrOut($rootScope.links, thisPage)) {
                                        ;
                                        needNewLinks = true;

                                        booksService.getLinks($rootScope.isHBL).then(function(data) {
                                            $rootScope.lastProcessedLinks = 'HBL';
                                            if (data.length > 1) {
                                                $rootScope.links = data;

                                                processLinks();
                                            }
                                        });
                                    //}
                                }
                             }
                        }
                    }
                    if (!$rootScope.isHBL && $rootScope.lastProcessedLinks != 'nonHBL') {
                        booksService.getLinks($rootScope.isHBL).then(function(data) {
                            $rootScope.lastProcessedLinks = 'nonHBL';
                            if (data.length > 1) {
                                $rootScope.links = data;
                                processLinks();
                            }
                        });
                        needNewLinks = true;
                    }
                }

                if (needNewLinks) {
                    return false;
                }

                $rootScope.pageLinks = [];
                $rootScope.processingLinks = true;


                var links = $rootScope.links,
                    page = document.getElementById($scope.page.fileName);

                removeOldLinks();
                _.each(links, function(link, i) {
                    var bTwoMode = $rootScope.readingMode == 'dual-page';
                    if (!bTwoMode) {
                        bTwoMode = $rootScope.user.readingMode == 'dual-page';
                    }
                    var bFound =helpers.linkOnPage(page, link, bTwoMode);
                    if (bFound) {
                        addLinkToQueue(page, link);
                    }
                });

                cullLinkIcons();
                paintLinks();
            } // END PROCESS LINKS

            processLinks();

        }); // END SET LINKS PROMISE
    }; // END SET LINKS

    var isSectionOnPage = function(section, $scope) {
        var currentPage = $scope.page.fileName;
        return (section.indexOf('-')>=0? section.replace(/-.*$/g, ''): section) ===
               (currentPage.indexOf('-')>=0? currentPage.replace(/-.*$/g, ''): currentPage);
    }

    var getNoteAlignment = function(anchor){
        if (anchor) {
            var frame = anchor.closest('.Basic-Text-Frame');
            if (frame) {
                var bound = frame.children[0];
                if (!_.isUndefined(bound)){
                    var left = parseInt(anchor.style.left, 10);
                    var width = parseInt(bound.style.width, 10);
                    return left < (width / 2) ? 'leftMargin' : 'rightMargin';
                }
            } else {
                return 'leftMargin';
            }
        }
        return "";
    }

    var getElement = function(id, pageId){
        try{
            if (!pageId || pageId == null) {
                return document.getElementById(id);
            }
            var page = document.getElementById(pageId);
            if (page == null) return null;
            var el = page.querySelector('#' + id);
            if(el == null){
                //create dummy element if it doesn't exist yet
                return document.createElement('span');
            }
            return el;
        } catch(syntaxErr){
            return document.getElementById(id);
        }
    }

     var changeZIndexOnMargins = function(page, toggleVal) {
        //console.log('Changing the z-index');
        var elementsList = page.getElementsByClassName('Sidebar');
        if (elementsList.length > 0) {
          _.each(elementsList, function(obj) {
            var ele = obj.parentNode;
            if (ele) {
              if (toggleVal) {
                ele.style.zIndex = "-2";
              } else {
                ele.style.zIndex = "0";
              }
            }
          })
        }
      }


    return {
        restrict: 'A',
        link: link,
        controller: controller
    };
}]);
