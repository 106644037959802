'use strict';
var tims
var tims = function() {
	var now = new Date(),
    then = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate(),
        0,0,0),
    diff = now.getTime() - then.getTime();
    diff = diff / 1000;
	return diff;
}

var httpwrap = function(http, url, path, data) {
	var fullUrl = url + path;
	return new Promise(function(resolve, reject) {
		try {
			//console.log('Wrapping ' + fullUrl);
			data = data || {};
			var req = {
				method: 'GET',
				url: url,
				async: true,
				headers: {
					'Content-Type': 'JSON',

				},
				data: data
			};
			//console.log(tims() + ' - Making get call to : ' + fullUrl)
			http.get(fullUrl).then(function(response) {
				//console.log(tims() + ' - Get returned:' + fullUrl);
				if (response.data) {
					resolve(response.data);
				} else {
					resolve(response);
				}

			});
		} catch (exc) {
			reject(exc);
		}
	});
}
var formatPage = function(prefix, intVal) {
	var str = '';
	if (intVal<1000) {
		str = '0';
	}
	if (intVal<100) {
		str = '00';
	}
	if (intVal<10) {
		str = '000'
	}
	return prefix + '_' + str + intVal;
}
var checkPrevAndPostPage = function(incoming, base, intVal, page) {
	intVal++;
	var currentPage = formatPage(base, parseInt(intVal));

	//var check = (incoming.toLowerCase().replace('.html','')==page.id.toLowerCase());
	//return check;

	var previousPage = formatPage(base, (parseInt(intVal)-1));

	var currentPage = formatPage(base, parseInt(intVal));

	var nextPage = formatPage(base, (parseInt(intVal)+1));

	if (intVal <= 1) {
		previousPage = '';
	}
	var returnVal = false;
	if (incoming.toLowerCase().replace('.html','')==currentPage.toLowerCase()) {
		returnVal = true;
	}
	if (incoming.toLowerCase().replace('.html','')==previousPage.toLowerCase()) {
		returnVal = true;
	}
	if (incoming.toLowerCase().replace('.html','')==nextPage.toLowerCase()) {
		returnVal = true;
	}
	return returnVal;
}

var linkOnPage = function(page, link, bTwoMode) {
	//var bTwoMode = user.readingMode == 'dual-page';
	var pageInt = 0;
	var pagePrefix = '';
	try {
		pagePrefix = page.id.split('_')[0];
		pageInt = parseInt(page.id.split('_')[1]);
	}
	catch (ex) {
		;
	}
	pageInt--;
	// console.log('Page val:' + pageInt);
	var twoUpPrevPage = formatPage(pagePrefix, pageInt);
	// console.log('Two up prev : ' + twoUpPrevPage);

	var bFound = false;
	try {
		_.each(link.incoming, function(linkRow) {
			_.each(linkRow.files, function(linkFileRow) {
				if (linkFileRow.toLowerCase().replace('.html','')==page.id.toLowerCase()) {
					bFound = true;
					return true;
				}
				if (bTwoMode && pageInt>1) {
					if (linkFileRow.toLowerCase().replace('.html','')==twoUpPrevPage.toLowerCase()) {
						bFound = true;
						return true;
					}
				}
			})
		});
	} catch (ex) {
		;
	}
	try {
		_.each(link.outgoing, function(linkRow) {
			_.each(linkRow.files, function(linkFileRow) {
				if (checkPrevAndPostPage(linkFileRow, pagePrefix, pageInt, page)) {
					bFound = true;
					return true;
				}
				/*
				if (bTwoMode && pageInt>1) {
					if (linkFileRow.toLowerCase().replace('.html','')==twoUpPrevPage.toLowerCase()) {
						bFound = true;
						return true;
					}
				}*/
			})
		});
	} catch (ex) {
		;
	}
	return bFound;
}

var getRemainingChunkCount = function(scope,variable) {
	var remaining = 0;
	for (var i = 0 ; i < scope[variable].length ; i++) {
		if (_.isEmpty(scope[variable][i])) {
			remaining++;
		}
	}
	return remaining;
}
var getAllChunks = function(http, scope, variable, ref, data, finalCb) {
	scope[variable] = new Array(data.size);
    for (var i = 0 ; i < data.size ; i++) {
      fetchChunks(http, scope, variable, ref, i, finalCb);
    }
}
var fetchChunks = function(http, scope, variable, ref, id, finalCb) {
	var url = '/api/chunks/' + ref + '/' + id;

	httpwrap(http, scope.apiUrl, url).then(function(data) {
		//console.log('Got back data from httpwrap!');
		scope[variable][id] = data;
		if (getRemainingChunkCount(scope,variable)<=0) {
			var data = '';
			_.each(scope[variable], function(row) {
				data+=row.chunkData;
			})
			var jsonData = JSON.parse(data);
			finalCb(jsonData);
		}
	})
}

var extractPort = function (str) {
	if (str.indexOf('.com')>=0) {
		return -20;
	}
        if (str.split(':').length<3) {
                return 8000;
        }

	var arr = str.split(':');
	var retArr = arr[2].split('/')
	return parseInt(retArr[0]);
}

var extractServer = function (str) {
	var arr = str.split(':');
	return arr[1].replace('//','');
}

var checkedCachedPageRange = function ( cachedValues, page) {
	var retVal = false;
	_.each(cachedValues, function(row) {
		if (row.startPage) {
			if (row.startPage >= page && row.endPage <= page) {
				retVal = true;
				return true;
			}
		}
	})
	return retVal;
}

var getPixelVal = function (str) {
	if (!_.isNaN(parseInt(str))) {
		return parseInt(str);
	}
	try {
		var newStr = str.replace('px','');
		var val = parseInt(newStr)
	  	return val;
  	} catch (ex) {
  		;
  	}
}
var getPageFromUrl = function(str) {
	try {
		var arr = str.split('_');
		if (arr) {
			if (arr.length > 0) {
				var firstChunk = arr[1].split('-')[0];
				var num = parseInt(firstChunk);
				return num;
			}
		}

	}
	catch(ex) {
		return str;
	}
	return str;
}

var getBookFromPath = function(str) {
	var retVal = undefined;
	try {
    var arr = str.split('/');
		_.each(arr, function(row, i) {
			if (row.indexOf('_')>=0 && !retVal) {
				retVal = arr[i-1];
			}
		})
	}
	catch(ex) {
		;
	}
	return retVal;
}

var getPageFromPath = function(str,includeALlDashes) {
	var retVal = undefined;
	try {
		includeALlDashes = includeALlDashes||false;
		if (str.indexOf('%')>=0) {
			includeALlDashes = false;
		}
		var arr = str.split('/');
		if (includeALlDashes) {
			return arr[arr.length-1];
		}
		_.each(arr, function(row) {
			if (row.indexOf('_')>=0 && !retVal) {
				retVal = row;
			}
		})
	}
	catch(ex) {
		
	}
	return retVal;
}
var getBookFromUrl = function(str) {
	try {
		var arr = str.split('_');
		return (arr[0]);
	}
	catch(ex) {
		
		return str;
	}
}
var inRangeMarkin = function(a, b) {
	var av = getPixelVal(a);
	var bv = getPixelVal(b);
	if (av >= bv-50 && av <= bv+50) {
		return true;
	}
	return false;
}

var inRangeObjects = function(a, b) {
	if (inRangeMarkin(a.left, b.left) && inRangeMarkin(a.top, b.top)) {
		return true;
	}
	return false;
}
var stripFirstDash = function (str) {
	if (str) {
		return str.replace('-',' ');
	}
	return str;
}

var fromRoman = function (roman, accept) {
    var s = roman.toUpperCase().replace(/ +/g, ''),
        L = s.length, sum = 0, i = 0, next, val,
        R = { M: 1000, D: 500, C: 100, L: 50, X: 10, V: 5, I: 1 };

    function fromBigRoman(rn) {
        var n = 0, x, n1, S, rx =/(\(*)([MDCLXVI]+)/g;

        while ((S = rx.exec(rn)) != null) {
            x = S[1].length;
            n1 = Number.fromRoman(S[2])
            if (isNaN(n1)) return NaN;
            if (x) n1 *= Math.pow(1000, x);
            n += n1;
        }
        return n;
    }

    if (/^[MDCLXVI)(]+$/.test(s)) {
        if (s.indexOf('(') == 0) return fromBigRoman(s);

        while (i < L) {
            val = R[s.charAt(i++)];
            next = R[s.charAt(i)] || 0;
            if (next - val > 0) val *= -1;
            sum += val;
        }
        return sum;
    }
    return NaN;
};
var getId = function() {
	return 1234;
}

var getLastItemInArray = function(str) {
	var arr = str.split('/');
	return arr[arr.length-1];
}

var toggleMenu = function(topScope, param)
{	topScope.$broadcast('updateMenu', {state: param, type: 'menu'});
}

var toggleSearch = function(topScope, param) {
	topScope.$broadcast('updateMenu', {state: param, type: 'search'});
}

function guid() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
    s4() + '-' + s4() + s4() + s4();
}

var getMonthName = function(month){
	var months = [
      "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"
  ]
  return months[month];
}
var getXmlValue = function(str, srch) {
	if (str.indexOf(srch)<0) {
		return undefined;
	}
	var arr = str.split(srch);
	var quotArr = arr[1].split('\"');
	return quotArr[1];
}
var parseDate = function(date){
	var month = getMonthName(date.getUTCMonth()),
			day = date.getUTCDate(),
			year = date.getUTCFullYear();
	return month + ' ' + day + ', ' + year;
}

var createDate = function(str){
  var y = str.substr(0,4),
      m = str.substr(4,2) - 1,
      d = str.substr(6,2);
  var D = new Date(y,m,d);
  return (D.getFullYear() == y && D.getMonth() == m && D.getDate() == d) ? D : 'invalid date';
}

var getDocumentText = function(RAObj, folder, book, chapter) {
	return new Promise(function(resolve, reject) {
		RAObj.all('books')
			.one(folder)
			.one(book)
			.one(chapter)
			.withHttpConfig({ cache: true })
			.get()
			.then(function(chapterData) {
				resolve(chapterData);
			});
		});
};

var showCitationOrganizer = function() {
	// console.log('Calling show citation org');
	$rootScope.citationsOpen = true;
}

var getLessonDate = function(dateString, days){
	var date = new Date(dateString);
  date.setUTCDate(date.getUTCDate() + days);

  var year = date.getUTCFullYear(),
  		month = pad(date.getUTCMonth() + 1, 2),
  		day = pad(date.getUTCDate(), 2);

  return year + '' + month + '' + day;
}

function scrollIntoViewIfOutOfView(el, el2) {
    var topOfPage = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
    var heightOfPage = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
    var elAttr = getElementTopAndHeight(el);
    if(el2){
      var el2Attr = getElementTopAndHeight(el2);
      var diff = (el2Attr.y + el2Attr.h) - elAttr.y;
      elAttr.h = diff;
      if((topOfPage + heightOfPage) < diff){
        el2.scrollIntoView(false);
        return;
      }
    }
    if ((topOfPage + heightOfPage) < (elAttr.y + elAttr.h)) {
        el.scrollIntoView(false);
    }
    else if (elAttr.y < topOfPage) {
        el.scrollIntoView(true);
    }
}

var getElementTopAndHeight = function(el){
  try{
    var obj = {};
    if (document.layers) { // NS4
        obj.y = el.y;
        obj.h = el.height;
    }
    else {
        obj.y = 0;
        for(var p=el; p&&p.tagName!='BODY'; p=p.offsetParent){
            obj.y += p.offsetTop;
        }
        obj.h = el.offsetHeight;
    }
    return obj;
  } catch (ex){
    //el isn't an element?
  }
}


var isElementInViewport = function(el){
	var rect = el.getBoundingClientRect();

  return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}

var setPageScroll = function(chapter, isScrolling){
	var element = document.getElementById(chapter);
	if (!element) {
		return;
	}
	var epub = element.getElementsByClassName('epub-text-wrapper');
	if (epub.length == 0) return;

  var height = epub[0].scrollHeight;
  var wrappers = document.getElementsByClassName('epub-text-wrapper');
  for (var i = 0; i < wrappers.length; i++){
  	wrappers[i].style.minHeight = height + 'px';
  }

	// Allow CSS to change
	if(!isScrolling){
		
		setTimeout(function () {
			element.scrollIntoView();
			window.scrollBy(0, getNavOffset());  // to account for sticky nav
	  }, 250);
	}

}

var getNavOffset = function(){
	var offset = 0;
	var readingBar = document.getElementsByClassName('reading-bar')[0];

	if(angular.element(readingBar).hasClass('sticky-header')){
		offset = offset - readingBar.offsetHeight;
	} else {
		offset = getHeaderHeight() * -1;
	}
	return offset;
}

var getOffset = function(elem){
    var docElem, win, rect;

    if ( !elem ) {
        return;
    }

    rect = elem.getBoundingClientRect();

    // Make sure element is not hidden (display: none) or disconnected
    if ( rect.width || rect.height || elem.getClientRects().length ) {
        win = window;
        docElem = document.documentElement;

        return {
            top: rect.top + win.pageYOffset - docElem.clientTop,
            left: rect.left + win.pageXOffset - docElem.clientLeft
        };
    }
}

var getHeaderHeight = function(){
	var offset = 0,
			stickies = document.querySelectorAll('[auto-hide-sticky-header]:not(.header-hidden)');
	_.each(stickies, function(sticky){
		if( angular.element(sticky).hasClass('extend') ) sticky = sticky.querySelector('.wrapper');
		offset = offset + sticky.offsetHeight;
		//console.log('current offset', offset, sticky)
	})
	return offset;
}


var pad = function(n, width, z) {
  z = z || '0';
  n = n + '';
  return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
}

var changePage = function(topScope, chapter, isScrolling){
	if (chapter.endsWith('.html')) chapter = chapter.substring(0, chapter.length - 5);
	topScope.$state.go(topScope.$state.current.name, {'chapter': chapter, 'initial': false, 'isScrolling': isScrolling});
}

var findChapterIndex = function(chapterList, fileName){
	var ret = -1;
	_.each(chapterList, function(chapter, i){
		if (chapter.fileName == fileName) ret = i;
	})
	return ret;
}

var getFileFromReference = function(list, index){
  try{
    if(index < 0){
      return list.slice(-1)[0].fileName.replace(/-.*$/g, '');
    }
    return _.find(list, {'title': index}).fileName.replace(/-.*$/g, '')
  }
  catch(ex){
    console.err('Chapter not found in chapter list:', index)
  }
}

var setActiveType = function(scope, type, val, toggleOrArg) {
	// Clear out old values
	// console.log('Setting active type');
	if (type=='additionType'&&val==null) {
		scope.currentNote = null;
		scope.activeFlags[type] = null;
	}
	else if (val == 'toggle') {
		scope.activeFlags[type] = !scope.activeFlags[type];
	}
	else {
		if (toggleOrArg==true&&val==scope.activeFlags[type]) {
			scope.activeFlags[type] = null;
		}
		else {
			if (!_.isUndefined(toggleOrArg)) {
				if (typeof(toggleOrArg)=='function') {
					scope.confirmationCb = toggleOrArg;
					scope.confirmationArg = val;
				}
			}
			scope.activeFlags[type] = val;
		}
	}
};

function getFullBookInfo(bookData, bookAbbr) {
	var bookDataObj = _.find(bookData, function(row) {
		return row.sectionFolder.toLowerCase() == bookAbbr.toLowerCase();
	});
	if (bookDataObj) {
		return bookDataObj;
	}
	return undefined;
}

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

var getSelectionBody = function(argData, str, length, delimeter) {

	// str = str || 'body';
	delimeter = delimeter || ';.!?';
	var getText = function(data) {
		if (!data) {
			return data;
		}
		if (_.isArray(data)) {
			var returnData = '';
			_.each(data, function(row, index) {
				if (index > 1) {
					returnData+=' ';
				}
				if (row['text']) {
					returnData += row['text'] + ' ';
				} else if (row['body']) {
					returnData += row['body'] + ' ';
				}
				else {
					returnData += row + ' ';
				}
				index++;
			})
			return returnData;
		}
		if (_.isObject(data)) {
			if (data['body']) {
				return data['body'];
			}
			if (data['text']) {
				return data['text'];
			}
		}
		if (data.body) {
	        return data.body;
	    }

		if (data.text) {
	        return data.text;
	    }
		return data;
	}

	var text = getText(argData);
	if (!text) {
		return text;
	}
	var iFirstDel = -1;
	for (var i = 0 ; i < delimeter.length; i++) {
		var ind = text.indexOf(delimeter[i]);
		if (ind < length && ind >=0 ) {
			if (iFirstDel == -1) {
				iFirstDel = ind;
			}
			else {
				if (ind < iFirstDel) {
					iFirstDel = ind;
				}
			}
		}
	}

	if (iFirstDel >= 0) {
		return text.substr(0, iFirstDel);
	} else {
		return text.substr(0, length) + '...';
	}


}

var getBookPath = function(pathstr) {
	var pathArr = pathstr.split('/');
      var bookAbbrStr = pathArr[pathArr.length-1];
      return bookAbbrStr;
}


var getBookFileNameFromPath = function(pathstr) {
      var bookAbbr = getBookPath(pathstr).split('_')[0];
      return bookAbbr;
}

var checkParent = function(obj, str, str2) {
	var retVal = false;
	if (str.indexOf('#')>=0) {
		if (obj.id) {
			if (obj.id.indexOf(str.replace('#',''))>=0) {
				return true;
			}
			if (str2) {
				if (obj.id.indexOf(str2.replace('#',''))>=0) {
					return true;
				}
			}
		}
	} else
	if (str.indexOf('.')>=0) {
		if (obj.className) {
			if (typeof(obj.className)=='string') {
				if (obj.className.indexOf(str.replace('.',''))>=0) {
					return true;
				}
				if (str2) {
					if (obj.className.indexOf(str.replace('.',''))>=0) {
						return true;
					}
				}
			}
		}
	}
	if (obj.parentNode) {
		retVal = checkParent(obj.parentNode, str, str2);
	}
	return retVal;
}


// decodeHtmlEscaped('The People&rsquo;s Idea of God') === 'The People’s Idea of God'
var decodeHtmlEscaped = function(encodedString) {
	var isSafe = encodedString.indexOf('<') === -1 && encodedString.indexOf('>') === -1
	if (!isSafe) {
		return '';
	}
	var el = document.createElement('div');
	el.innerHTML = encodedString;
	return el.textContent;
};

var fixSectionTitle = function(sectionTitle) {
	if (sectionTitle.toLowerCase().indexOf('miscellany')>=0) {
		sectionTitle = 'Miscellany';
	}
	// so 'People&quot;s Idea of God' will work
	var decoded = decodeHtmlEscaped(sectionTitle);
	return decoded;
}
function sizeof(object){
	var o = object;
	o.o = o;

	var cache = [];
	var ret = JSON.stringify(o, function(key, value) {
	    if (typeof value === 'object' && value !== null) {
	        if (cache.indexOf(value) !== -1) {
	            // Circular reference found, discard key
	            return;
	        }
	        // Store value in our collection
	        cache.push(value);
	    }
	    return value;
	});
	cache = null;
  // return the calculated size

  return ret.length;

//}
}

function removeElementsByClass(className){
    var elements = document.getElementsByClassName(className);
    while(elements.length > 0){
        elements[0].parentNode.removeChild(elements[0]);
    }
}

var logMessage = function(message, scopeArg, codeLocation, loglevel) {
	codeLocation = codeLocation || 'Unspecific'
	loglevel = loglevel || 1;
	scopeArg.logMessageQueue = scopeArg.logMessageQueue || [];

	var doConsole = function() {
		if (scopeArg.logMessageQueue.length > 0) {
			_.each(scopeArg.logMessageQueue, function(row) {
				;
			})
			scopeArg.logMessageQueue = [];
		}
		if (scopeArg.configLogLevel>=loglevel) {
			;
		}
	};

	if (!scopeArg) {
		;
		return false;
	}

	if (!scopeArg.configLogLevel) {
		var newmessage ='[Log]' + tims() + '[' + codeLocation + '][Level:' + loglevel + ']: ' + message;
		scopeArg.logMessageQueue.push(newmessage);
		scopeArg.getCacheValue('appConfig', 'configuration', function() {
			return scopeArg.getConfig();
		});
	} else {
		doConsole();
	}
}
var noMatchIncOrOut = function(linkArray, thisPage) {
	if (thisPage.indexOf('Front_Pages')>=0) {
		return false;
	}
    if (!linkArray) {
        return true;
    }
    if (!linkArray[0]) {
        return true;
    }
    if (!linkArray[0].incoming) {
        return true;
    }
    var incArr = linkArray[0].incoming[0].files[0].replace('.html','').split('_');
    var outArr = linkArray[0].outgoing[0].files[0].replace('.html','').split('_');
    var thisInc = incArr[0];
    var thisOut = outArr[0];
    var thisPageArr = thisPage.split('_');
    var thisPageBook = thisPageArr[0];
    if (thisOut == thisPageBook) {
    	return false;
    }
    if (thisInc == thisPageBook) {
    	return false;
    }
    return true;
}

var getNextId = function(id, diff){
  var num = id.match(/\d+$/)[0];
  var start = id.substr(0, id.length - num.length);
  var str = start + (parseInt(num) + diff);
  return str;
}

var fakeAccount = {"__v":0,
"_id":"557ec942053c69fc22d2ea47",
"email":"b@b.com","firstname":"Ben",
"lastname":"Mack",
"password":"$2a$08$wc3An8/FhclA2xPyTASRmex6xdMywnyseZ/9YQOaHWGLvX5hVJrSu",
"usertype":"advanced","folders":[{id:1, name: 'Folder 1'}, {id:2,name:'Folder 2'}]};


var getFolder = function($state) {
	var hasFolder = $state && $state.params && $state.params.folder;
	if (!hasFolder) {
		return false;
	}
	return $state.params.folder;
};

var isPeriodical = function($state){
    var folder = getFolder($state);
    return folder === 'periodicals'
        || folder === 'sentinels'
        || folder === 'journals';
};

module.exports = {
  getId:getId,
  getSelectionBody:getSelectionBody,
  toggleMenu:toggleMenu,
  toggleSearch:toggleSearch,
  getDocumentText:getDocumentText,
  fakeAccount:fakeAccount,
  capitalizeFirstLetter:capitalizeFirstLetter,
  setActiveType:setActiveType,
  changePage: changePage,
  scrollIntoViewIfOutOfView: scrollIntoViewIfOutOfView,
  setPageScroll: setPageScroll,
  pad: pad,
  findChapterIndex: findChapterIndex,
  getMonthName: getMonthName,
  parseDate: parseDate,
  createDate: createDate,
  isElementInViewport: isElementInViewport,
  getFileFromReference: getFileFromReference,
  getLessonDate: getLessonDate,
  guid: guid,
  showCitationOrganizer: showCitationOrganizer,
  getFullBookInfo:getFullBookInfo,
  getOffset: getOffset,
  checkParent:checkParent,
  getBookFileNameFromPath:getBookFileNameFromPath,
  getBookPath:getBookPath,
  tims:tims,
  stripFirstDash:stripFirstDash,
  inRangeObjects:inRangeObjects,
  inRangeMarkin:inRangeMarkin,
  getPixelVal:getPixelVal,
  getNavOffset: getNavOffset,
  getHeaderHeight: getHeaderHeight,
  fromRoman:fromRoman,
  getPageFromUrl:getPageFromUrl,
  getBookFromUrl:getBookFromUrl,
  fixSectionTitle:fixSectionTitle,
  logMessage:logMessage,
  fetchChunks:fetchChunks,
  httpwrap: httpwrap,
  getAllChunks:getAllChunks,
  sizeof:sizeof,
  getLastItemInArray:getLastItemInArray,
  removeElementsByClass:removeElementsByClass,
  getBookFromPath:getBookFromPath,
  getPageFromPath:getPageFromPath,
  noMatchIncOrOut:noMatchIncOrOut,
  checkedCachedPageRange:checkedCachedPageRange,
  extractPort:extractPort,
  extractServer:extractServer,
  getNextId: getNextId,
  linkOnPage:linkOnPage,
  formatPage: formatPage,
  getXmlValue:getXmlValue,
  getFolder: getFolder,
  isPeriodical: isPeriodical
};


String.prototype.startsWith = function (str)
{
   return this.indexOf(str) == 0;
}

String.prototype.endsWith = function(str)
{
    return this.indexOf(str, this.length - str.length) !== -1;
};

String.prototype.compareInsensitive = function(str)
{
  return this.trim().toUpperCase().replace(/[^\w\s]|_/g, "") === str.trim().toUpperCase().replace(/[^\w\s]|_/g, "");
};

String.prototype.toProperCase = function () {
    return this.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
};

String.prototype.firstLetterUpper = function () {
    return this.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1);});
};

(function (ELEMENT) {
	ELEMENT.matches = ELEMENT.matches || ELEMENT.mozMatchesSelector || ELEMENT.msMatchesSelector || ELEMENT.oMatchesSelector || ELEMENT.webkitMatchesSelector;

	ELEMENT.closest = ELEMENT.closest || function closest(selector) {
		var element = this;

		while (element) {
			if (element.nodeType == 1 && element.matches(selector)) {
				break;
			}

			element = element.parentElement || element.parentNode;
		}

		return element;
	};
}(Element.prototype));

