angular.module('koh').directive('continueReadingBar', function($rootScope) {
	var link = function( $scope, element, attributes){
		var type = attributes.type,
				pageList = $rootScope.user[type],
				lastPage = {};

		if (pageList == null || _.isUndefined(pageList) || pageList.length == 0) return;

		lastPage = pageList[pageList.length - 1];
		lastPage.pageName = lastPage.book || lastPage.page.split('/').pop();
		$scope.isHBL = type === 'visitedLessons';
		$scope.lastPage = lastPage;
	}

  return {
    restrict: 'E',
    templateUrl: './directives/pages/continue-reading-bar/continue-reading-bar.html',
    link: link,
    scope: {
      type: '=type'
    }
  };
});