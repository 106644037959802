  angular.module('koh')
    .directive('keepScrollPos', function ($window, $timeout, $location, $anchorScroll, $state) {
    var cache = {}; // cache scroll position of each route's templateUrl
    return {
      restrict: 'A',
      link: function (scope, element, attrs) {
        var name = attrs.keepScrollPos;
        if (!name) {
          ;
          return;
        }
        scope.$on('$stateChangeStart', function () {
          // store scroll position for the current view
          if($state.$current && $state.current.name == name) {
            cache[name] = {
              offsetX: $window.pageXOffset,
              offsetY: $window.pageYOffset
            };
          }
        });

        scope.$on('$stateChangeSuccess', function () {
          if ($state.current.name != name) {
            return;
          }
          if ($location.hash()) { // if hash is specified explicitly, it trumps previously stored scroll position
            $anchorScroll();
          } // else get previous scroll position; if none, scroll to the top of the page
          else {
            var prevPos = cache[name];
            $timeout(function () {
              prevPos ? $window.scrollTo(prevPos.offsetX, prevPos.offsetY) :
                $window.scrollTo(0, 0);
            }, 200); //give page time to render
          }
        });
      }
    };
  });
