'use strict';

var helpers = function () { return require('../libs/helpers'); };

angular.module('koh.lessons', ['ui.router'])
  .config(['$stateProvider', function($stateProvider) {
    $stateProvider.state('lessons', {
      url: "/lessons",
      templateUrl: "/controllers/lessons/lessons.html",
      controller: 'LessonsCtrl',
      resolve: {
        helpers: helpers,
        authenticate: function(kohauth) {
          return kohauth.authenticate();
        },
        lessonObj: function() { return {}; },
        tocObj: function() { return {}; },
        playlistObj: function() { return {}; },
        selectionsObj: function() { return {}; } 
      }
    })
    .state('lessonSearch', {
      url: "/lessons/search?dateStart&dateEnd&subject",
      templateUrl: "/controllers/lessons/lessonResults.html",
      controller: 'LessonsCtrl',
      resolve: {
        helpers: helpers,
        authenticate: function(kohauth) {
          return kohauth.authenticate();
        },
        lessonObj: function() { return {}; },
        tocObj: function() { return {}; },
        playlistObj: function() { return {}; },
        selectionsObj: function() { return {}; } 
      }
    })
    .state('lessonIndex', {
      url: "/lessons/:lesson",
      templateUrl: "/controllers/lessons/lessonIndex.html",
      controller: 'LessonsCtrl',
      params: {
        chapter: null
      },
      resolve: {
        helpers: helpers,
        authenticate: function(kohauth) {
          return kohauth.authenticate();
        },
        lessonObj: ['$stateParams', 'readerService',
          function($stateParams, readerService) {
            return readerService.getLessonObj($stateParams.lesson);
        }],
        tocObj: ['$stateParams', 'readerService',
          function($stateParams, readerService) {
            return readerService.getLessonTOC($stateParams.lesson);
        }],
        playlistObj: ['$stateParams', 'readerService',
          function($stateParams, readerService) {
            return readerService.getPlayListObj($stateParams.lesson);
        }],
        selectionsObj: ['$rootScope', 'readerService',
          function($rootScope, readerService) {
            return readerService.getSelectionsObj($rootScope);
        }]
      }
    });
  }]);