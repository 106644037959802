// Note, when naming an angular directive, convert objects separated with dashes into camel case
// This is required!
var helpers = require('../../libs/helpers');
var pageHelpers = require('../../libs/page-helpers');

angular.module('koh').directive('saveDocument',
    ['$location',
    'Restangular',
    '$rootScope',
    'booksService',
    '$window',
    '$http',
    'printApiUrl',
    function($location,
        Restangular,
        $rootScope,
        booksService,
        $window,
        $http,
        printApiUrl
    ){
    return {
    transclude: true,
    templateUrl: './directives/pages/save-document/save-document.html',
    link: function (scope, element, $window) {
      pathArr = window.location.pathname.split('/');

      //var bookAbbrStr = pathArr[pathArr.length-1];
      //var bookAbbr = bookAbbrStr.split('_')[0];
      $rootScope.getCurrentBookData = function() {
        return new Promise(function(resolve, reject) {
          var docType = 'books';
          if ($location.$$url.indexOf('bible')>=0) {
            docType = 'bible';
          }
          if ($location.$$url.indexOf('/SH/')>=0) {
            docType = 'SH';
          }
          if ($location.$$url.indexOf('/periodicals/')>=0||$location.$$url.indexOf('/sentinels/')>=0) {
            docType = 'periodical';
          }
          //console.log('Entering getCurrentBookData:' + $rootScope.readingData.currentChapter);
          var page = helpers.getPageFromUrl($rootScope.readingData.currentChapter);
          var ktts = false;
          if ($rootScope.readingData.currentBook.toLowerCase() == 'sh' && page >= 511) {
            ktts = true;
          }

          var book;
          var getBook = function () {
            ;
            if ($rootScope.readingData.currentFolder == 'periodicals'||$rootScope.readingData.currentFolder == 'sentinels') {
               book = _.isObject($rootScope.periodicalData)?  $rootScope.periodicalData:
                 _.find($rootScope.periodicalData, function(row) {
                    return row.sectionFolder.toLowerCase() == $rootScope.readingData.currentBook.toLowerCase();
                  });
            } else {
              book = _.find($rootScope.bookData, function(row) {
              if (ktts) {
                  return row.sectionFolder.toLowerCase() == $rootScope.readingData.currentBook.toLowerCase() && row.bookOrder == 2;
                } else {
                  if (docType == 'SH') {
                    return row.sectionFolder.toLowerCase() == $rootScope.readingData.currentBook.toLowerCase() && row.bookOrder == 1;
                  } else {
                    return row.sectionFolder.toLowerCase() == $rootScope.readingData.currentBook.toLowerCase();
                  }

                }
              });
            }
          };

          var processBookData = function() {
            //var documentObj = Restangular.all('books').one('info').one(scope.currentFolder,scope.currentBook);
            var currentPage = helpers.getPageFromUrl($rootScope.readingData.currentChapter);
            // var checkedVal = helpers.checkedCachedPageRange()

            var page = Math.floor(currentPage/5);
            if (page == 0) {
              page = 1;
            } else {
              page = page*5;
            }
            var fetchBookData = function() {
              return new Promise(function(resolve, reject) {
                ;
                var url = '/api/books/info/';
                var bookName = book.sectionFolder;
                if (book.parentFolder == 'bible') {
                  url += book.parentFolder + '/' + book.sectionFolder;
                } else {
                  if (book.sectionFolder == 'SH') {
                    ;
                    bookName = book.sectionFolder +'_' + book.bookOrder;
                    ;
        //                    url = '/api/books/info/SH/page/' + page;
                    url = '/api/books/info/SH_' + book.bookOrder;
                  }
                  else {
                    url += book.sectionFolder;
                  }
                }

                ;
                helpers.httpwrap($http, $rootScope.apiUrl, url).then(function(data) {
                  if (data.chunk) {
                    ;
                    helpers.getAllChunks($http, $rootScope, 'bookChunks', precursor + '_' + bookName, data, function(chunkData) {
                      ;
                      resolve(chunkData);
                    });
                  } else {
                    ;
                    if (_.isArray(data)) {
                      resolve(data[0]);
                    } else {
                      resolve(data);
                    }
                  }
                }); // End httpWrap
              }); // End promise
            }

            //helpers.logMessage('Getting cached bookData',$rootScope, 'save-document',1);
            // console.log('Getting book data');
            var precursor = 'book'
            if (book.parentFolder == 'bible') {
              precursor = 'bible';
            };

            //console.log('Looking for cached book data: ' + book.sectionFolder);
            var cachedValue = book.sectionFolder;
            if (book.sectionFolder == 'SH') {
              cachedValue = 'SH_' + book.bookOrder;
              //console.log('Getting cached values for ' + cachedValue)
            }
            $rootScope.getCacheValue(cachedValue, 'bookdata',  fetchBookData).then(function(data) {
              //helpers.logMessage('Book data retrieved',$rootScope, 'save-document',1);
              if (_.isArray(data)) {
                //$rootScope.currentBookData = _.find(data, { sectionFolder: book.sectionFolder});
                $rootScope.currentBookData = _.find(data, { sectionFolder: book.sectionFolder});
                //$rootScope.currentBookData = data[0];
              } else {
                $rootScope.currentBookData = data;
              }
              $rootScope.documentLinks = $rootScope.currentBookData.links;
              //console.log('setting documentlinks')
              resolve($rootScope.currentBookData);
            });
          } // end of process book data

          // Begin to process book data
          if ($rootScope.readingData.currentFolder == 'periodicals') {
            // sk: for highlighting of search results, periodicalData should be in sync with readingData
            if (!$rootScope.periodicalData || $rootScope.readingData.currentBook !== $rootScope.periodicalData.sectionFolder) {
              if (!window.isGettingPeriodicals) { // sk: hack to avoid $$digest loop
                window.isGettingPeriodicals = true;
                helpers.httpwrap($http, $rootScope.apiUrl, '/api/periodicals/' + $rootScope.readingData.currentBook).then(function (data) {
                  delete(window.isGettingPeriodicals);
                  $rootScope.periodicalData = data[0];
                  ;
                  resolve($rootScope.periodicalData);
                });
              }
              return;
            } else {
              resolve($rootScope.periodicalData);
              return;
            }
          } else {
              if ($rootScope.readingData.currentFolder == 'sentinels') {
                if (!$rootScope.periodicalData || $rootScope.readingData.currentBook !== $rootScope.periodicalData.sectionFolder) {
                  if (!window.isGettingSentinels) {
                    window.isGettingSentinels = true;
                    helpers.httpwrap($http, $rootScope.apiUrl, '/api/sentinels/' + $rootScope.readingData.currentBook).then(function (data) {
                      delete(window.isGettingSentinels);
                      $rootScope.periodicalData = data[0];
                      ;
                      resolve($rootScope.periodicalData);
                    });
                  }
                  return;
                } else {
                  resolve($rootScope.periodicalData);
                  return;
                }
              }
            }



          if (!$rootScope.bookData) {
            helpers.httpwrap($http, $rootScope.apiUrl, '/api/books/all').then(function(data) {
              ;
              $rootScope.bookData = data;
              getBook();
              processBookData();
            })
            return;
          } else {
            getBook();
            processBookData();
          }

        }).then(function () {
            $rootScope.$broadcast('currentBookDataReady');
          });
      }
      $rootScope.getCurrentBookData();

      //scope.startPage  = scope.pageLimitations.startPage;
      //scope.endPage  = scope.pageLimitations.endPage;

      scope.cancelSave = function() {
        scope.setActiveType('saveFlyout',false);
      }

      checkInPageRange = function(docType, ktts, romanNumeral) {
        ;
        if (docType == 'bible') {
          scope.printDiff = $rootScope.pageLimitations.startPage - 1;
          if (scope.endPrintPage > $rootScope.pageLimitations.endPage || scope.startPrintPage < $rootScope.pageLimitations.startPage) {
            return false;
          }
          return true;
        }
        if (docType == 'SH') {
          if (ktts) {
            if (scope.endPrintPage > $rootScope.pageLimitations.endPage+12 || scope.startPrintPage < $rootScope.pageLimitations.startPage-12) {
              return false;
            }
            return true;
          } else {
            if (scope.endPrintPage > $rootScope.pageLimitations.endPage || scope.startPrintPage < $rootScope.pageLimitations.startPage) {
              return false;
            }
            return true;
          }
        }
        // standard book fall through
        if (scope.endPrintPage > $rootScope.pageLimitations.endPage || scope.startPrintPage < $rootScope.pageLimitations.startPage) {
          return false
        }
        return true;
      }

      scope.printPageRange = function() {
        var startLabel = scope.startPrintPage;
        var endLabel = scope.endPrintPage;

        var readingData = $rootScope.readingData;

         // `chapterList` is an array of page objects
        // the `title` property is the page label (display page)
        // "labels" here means the "display pages" (not the indexes / "physical pages")
        // such as 'vii'
        var labels = _.pluck(readingData.chapterList, 'title')

        // page helpers expect index to start at 1
        var normalizedCurrentIndex = scope.currentIndex + 1;
        var items = $rootScope.currentBookData.sections;

        // indexes start from 1 if found
        // and are -1 if not found
        var startIndex = pageHelpers.getPageIndexForLabel(labels, startLabel);
        var endIndex = pageHelpers.getPageIndexForLabel(labels, endLabel);

        var isValid = pageHelpers.isValidPageRange(items, startIndex, endIndex);
        if (!isValid) {
          scope.saveMessage = 'You have specified a range outside of the current book';
          return;
        }
        
        var parentFolder = $rootScope.currentBookData.parentFolder;
        var currentBook = readingData.currentBook;
        
        var uri = pageHelpers.getPrintPageRangeUri(printApiUrl, parentFolder, currentBook, startIndex, endIndex);
        window.open(uri);
        scope.setActiveType('saveFlyout',false);
      };


      scope.saveDocument = function() {
        ;
        var romanNumeral = false;
        if ($rootScope.currentBookData.sections[0].ref =='i') {
          romanNumeral = true;
        }
        if ($rootScope.pageLimitations.endPage) {
          var ktts = false;
          var page = helpers.getPageFromUrl($rootScope.readingData.currentChapter);
          var docType = 'book';
          if ($location.$$url.indexOf('/SH/')>=0) {
            docType = 'SH';
          }
          if ($location.$$url.indexOf('/bible/')>=0) {
            docType = 'bible';
          }
          if (docType == 'SH' && page >= 511) {
            $rootScope.pageLimitations.startPage = 511;
            ktts = true;
          }
        }
        if (!checkInPageRange(docType, ktts, romanNumeral)) {
          scope.saveMessage = 'You have specified a range outside of the chapter'
          return;
        }
        var baseUrl = 'http://' + helpers.extractServer(scope.apiUrl) + ':' + (helpers.extractPort(scope.apiUrl)+100);
        var startPage = parseInt(scope.startPrintPage);
        var endPage = parseInt(scope.endPrintPage);
        var startPrintPage = _.isNaN(startPage)? scope.startPrintPage: startPage - scope.printDiff;
        var endPrintPage = _.isNaN(endPage)? scope.endPrintPage: endPage - scope.printDiff;
        var url;
        if (docType == 'bible') {
          url = baseUrl + '/api/files/' + scope.currentFolder + '/' + scope.currentBook + '.pdf/' +
            startPrintPage + '-' + endPrintPage;
        }
        else if (docType == 'SH') {
          url = baseUrl + '/api/files/' + docType + '/' + scope.currentBook + '.pdf/' +
            (scope.startPrintPage) + '-' + (scope.endPrintPage);
        }
        else if (docType == 'book' && romanNumeral) {
          url = baseUrl + '/api/files/' + docType + '/' + scope.currentBook + '.pdf/' +
            (scope.startPrintPage) + '-' + (scope.endPrintPage);
        }
        else {
          url = baseUrl + '/api/files/' + scope.currentFolder + '/' + scope.currentBook + '.pdf/' +
            startPrintPage + '-' + endPrintPage;
        }
        window.open(url);
        scope.setActiveType('saveFlyout', false);
      }
    }
  };
}]);
