angular.module('koh').directive('bibleLessonIndex', [
    '$compile', 
    '$rootScope', 
    '$sce',
    'Restangular', 
    function ($compile, $rootScope, $sce, Restangular) {

    var link = function ($scope, element, attrs) {
        $scope.sermonHTML = '';
        ;
        Restangular.all('lessons').one('html').one($scope.filename).get().then(function(data){
            var i = data.indexOf('<div>');
            var fixedData = data;
            if (i < 0 || i > 20) {
                fixedData = '<div>\r\n' + data;
            }
            $scope.sermonHTML = $sce.trustAsHtml(fixedData);
        })
    };
    
    return {
        transclude: true,
        templateUrl: './directives/pages/bible-lesson-index/bible-lesson-index.html',
        link: link,
        scope: {
            filename: '@?'
        }
    };
}]);
        