var _ = require('lodash');

angular.module('koh').controller('LexiconCtrl',
	['$scope',
	'helpers',
	'Restangular',
	'$rootScope',
	'$state',
	'$stateParams',
	'$http',
	'apiUrl',
	'$location',
	'searchService',
	function(
		$scope,
		helpers,
		Restangular,
		$rootScope,
		$state,
		$stateParams,
		$http,
		apiUrl,
		$location,
		searchService
	) {
		$scope.window = window;
		$scope.lookupQuery = $stateParams.query;
		$scope.totalResults = 0;
		$scope.page = 0;
		$scope.results = [];
		$scope.isSearching = false;
		$scope.searched = false;
		helpers.toggleMenu($rootScope, false);

		$scope.cleanResults = function( text ){
			text = text.replace(/�/gi,'');
			// Add more filters here if necessary.
			return text;
		}

		$scope.lookup = function( page ){
			count = 20;

			if( !page )
			{
				$scope.results = [];
				$scope.page = 0;
			}

			if( $scope.totalResults > 0 && $scope.page == $scope.totalResults ) return;

			$location.path( '/lookup/' +  $scope.lookupQuery );
			
			$scope.isSearching = true;

			Restangular.one( 'lookup', $scope.lookupQuery ).one(''+count).one(''+$scope.page).get().then(function( results ){
				if( results )
				{
					$scope.totalResults = results.numFound;
					Array.prototype.push.apply( $scope.results, results.docs );
					$scope.page += results.docs.length;
				}
				$scope.isSearching = false;
				$scope.searched = true;
			})
		}

		if( $scope.lookupQuery ) $scope.lookup();
	}
])