angular.module('koh').controller('HelpCtrl', [
	'$scope',
	'$rootScope',
	'$sce',
	'$http',
	'apiUrl',
	'Restangular',
	function($scope, $rootScope, $sce, $http, apiUrl, Restangular) {
		$scope.apiUrl = apiUrl;
		$scope.user = angular.copy($rootScope.user);
		$scope.faqs = [];
		$scope.helpContent = "";
		$scope.videos = [];
		$scope.videoIndex = 0;
		$scope.notLoaded = true;

		var contentObj = Restangular.one('content');
		var helpObj = Restangular.one('help');

    contentObj.one('help').one('content').get().then(function(data) {
      $scope.helpContent = $sce.trustAsHtml(data);
    }, function(response) {
        ;
    });
        
    helpObj.one('faq').get().then(function(data) {
      $scope.faqs = data;
    }, function(response) {
        ;
    });
        
    helpObj.one('helpCarousel').get().then(function(data) {
      $scope.notLoaded = false;
      $scope.videos = data;
    }, function(response) {
        ;
    });

    $scope.setVideoIndex = function(i){
    	$scope.videoIndex = i;
    	// Figcaption disappears on video slides - manually set z-index to bring it back to front
    	var li = document.getElementsByClassName('carousel-item')[i];
      var video = li.getElementsByTagName("video");
      if (video.length > 0 ){
      	video[0].style.zIndex = "0";
      	video[0].focus();
      }
      $scope.$broadcast('pauseAllVideos');
    }

    $scope.isActiveIndex = function(i){
    	return i == $scope.videoIndex;
    }

		$scope.sendContact = function()
		{
			$http.post($scope.apiUrl + '/api/contact-email', { name: $scope.user.firstname +" " + $scope.user.lastname, email: $scope.user.email, message: $scope.message })

			.success(function(data, status, headers, config)
			{
				if( data.error )
				{
					$scope.error = data.error;
					$scope.success = null;
				}
				else
				{
					$scope.success = data.success;
					$scope.error = null;

					$scope.message = null;
				}

				$scope.HelpCtrl.contactForm.$setPristine();
				$scope.HelpCtrl.contactForm.$setUntouched();
			})

			.error(function(data, status, headers, config)
			{
				if( data )
				{
					$scope.error = data.error;
					$scope.success = null;
				}
				else
				{ 
					$scope.error = 'The server may be unreachable at this time. Please try again later.';
					$scope.success = null;
				}

				$scope.HelpCtrl.contactForm.$setPristine();
				$scope.HelpCtrl.contactForm.$setUntouched();
			});
		}
}]);