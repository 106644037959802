// Note, when naming an angular directive, convert objects separated with dashes into camel case
// This is required!

angular.module('koh').directive('confirmationBox', function() {
  return {
    transclude: true,
    templateUrl: './directives/pages/confirmation-box/confirmation-box.html',
    link: function (scope, element) {
    	// Showing transclude works...
    	var mergeFlags = {
                confirmAction: null,
                confirmError: null
            };
       Object.keys(mergeFlags).forEach(function(key) {
            scope.activeFlags[key] = mergeFlags[key];
        });

       scope.confirmationCb = scope.confirmationCb || null;
       scope.confirmationArg = scope.confirmationArg || null;

       scope.setActions = function (confirmationCb, confirmationArg) {
          scope.confirmationCb = confirmationCb;
          scope.confirmationArg = confirmationArg;
       };

       scope.cancelAction = function() {
			    scope.confirmationCb = null;
       		scope.confirmationArg = null;      
       		scope.setActiveType('confirmAction',null);
          scope.setActiveType('confirmError',null);          
       };

       scope.confirmAction = function() {
       		scope.confirmationCb(scope.confirmationArg);
       		scope.setActiveType('confirmAction',null);
       }
    }
  };
});