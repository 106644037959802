angular.module('koh').controller('PeriodicalCtrl', 
		['$scope', 
		'helpers', 
		'Restangular', 
		'$state',
		'$stateParams',
		'$rootScope',
		'$location',
		'apiUrl',
		'$timeout',
		'dataService',
		function(
			$scope, 
			helpers, 
			Restangular,
			$state,
			$stateParams,
			$rootScope,
			$location,
			apiUrl,
			$timeout,
			dataService
			) {	

	$scope.apiUrl = apiUrl;
	$scope.isActive = function(volume) {
		return (volume == $scope.shownVolume);
	}
	$scope.activateVolume = function(volume) {
		$scope.shownVolume = volume;
	}
	var periodicalsObject = Restangular.all('periodicals');
	// TODO : Remove all instances of linksobj
	
	$scope.shownVolume = 0;
	$scope.volumeCount = 10;
	$scope.journalData = [];
	var months = ['', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep','Oct','Nov', 'Dec'];
	$scope.getMonth = function(monthId) {
		return months[monthId];
	}
	var deDupeAndSort = function(arr) {
		var newArr = [];
		

		for (var i = 0 ; i < arr.length ; i++) {
			if (!_.find(newArr, function(row) {
				return row.file == arr[i].file;
				}))
				 {
				newArr.push(arr[i]);
			}
		}

		newArr = _.sortBy(newArr, 'rowDate');
		return newArr;
	}
	periodicalsObject.getList().then(function(volumeList) {
		
		for (var i = 1 ; i < $scope.volumeCount ; i++) {
			if (!$scope.journalData[i]) {
					$scope.journalData[i] = {};
					$scope.journalData[i].volume = i;
					$scope.journalData[i].name = 'Journal Vol ' + '(';
					$scope.journalData[i].lastDate = 0;
					$scope.journalData[i].firstDate = 99999999999999;
					$scope.journalData[i].issues=[];
			}

			_.each(volumeList, function(row) {			
			var tmpRow = row;	
				if (row.volume == i) {
					_.each(row.issues, function(issueRow) {
						var rowDate = issueRow.publicationMonth + issueRow.publicationYear*12;
						$scope.journalData[i].rowDate = rowDate;

						if (rowDate > $scope.journalData[i].lastDate) {

							$scope.journalData[i].lastDate = rowDate;
							$scope.journalData[i].lastPublicationYear = issueRow.publicationYear;
							$scope.journalData[i].lastPublicationMonth = issueRow.publicationMonth;
						}
						if (rowDate < $scope.journalData[i].firstDate) {
							$scope.journalData[i].firstDate = rowDate;
							$scope.journalData[i].firstPublicationYear = issueRow.publicationYear;
							$scope.journalData[i].firstPublicationMonth = issueRow.publicationMonth;
						}
						$scope.journalData[i].issues.push({issue: issueRow.issueNumber, rowDate: rowDate, file:tmpRow.file, publicationMonth:issueRow.publicationMonth, publicationYear: issueRow.publicationYear});
					});
				}
			});
			$scope.journalData[i].name += months[$scope.journalData[i].firstPublicationMonth] + ' ' + $scope.journalData[i].firstPublicationYear;
					var newIssues = deDupeAndSort($scope.journalData[i].issues)
				$scope.journalData[i].issues = newIssues;
		$scope.journalData[i].name += ' - ' + months[$scope.journalData[i].lastPublicationMonth] + ' ' + $scope.journalData[i].lastPublicationYear + ')';
		}
		;
		$scope.volumeList = volumeList;
	});
}]);

