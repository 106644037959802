angular.module('koh').controller('PromoCtrl', [
	'$scope',
	'$rootScope',
	'$stateParams',
	'Restangular',
	'$sce',
	'$location',
	function($scope,
		$rootScope,
		$stateParams,
		Restangular,
		$sce,
		$location) {
			$scope.promoContent = null;
			var url = $stateParams.url || null;
			var urlArr = url == null ? [] : url.split('/');

			if(urlArr.length > 2) $location.path( "/404" ).replace();

			var contentPromise = Restangular.one('content');

			_.each(urlArr, function(url){
				contentPromise = contentPromise.one(url);
			});
			
			contentPromise.get().then(function(data) {
	    	$scope.promoContent = $sce.trustAsHtml(data);
	    }, function(response) {
			    ;
			    /* If no page is returned, go to a 404 page */
			    if (response.status === 500){
			    	$location.path( "/404" ).replace();
			    }
			});
}]);