'use strict';
var _ = require('lodash');
var helpers = require('./helpers');
var searchHelpers = require('./search-helpers');
var getSearchPayload = searchHelpers.getSearchPayload

angular.module('koh').factory('searchService',
  function($rootScope, $sce, Restangular, documentSearchFactory){
    var setSearchHighlights = function(searchTerm, bookTitle, chapter) {
      documentSearchFactory.currentSearch = searchTerm;
      documentSearchFactory.isSearching = true;
      documentSearchFactory.searchInBook = true;
      documentSearchFactory.searchData = undefined;

      if (!documentSearchFactory.isCitation) {
        $rootScope.getCacheValue(
            bookTitle.split(' ').join('_').toLowerCase() + '_search',
            searchTerm,
            function () {
              return startCitation( searchTerm).then(function () {
                  return startSearch(searchTerm, bookTitle);
                }, function(err) {
                  return startSearch(searchTerm, bookTitle);
                });
            }, 55000
          ).then(
            function (data) {
              if (data) {
                var spans = spansByFilename(data);
                var highlight = documentSearchFactory.currentHighlight;
                if (highlight && highlight.item_body_stop) {
                  var matched = [];
                  highlight.item_body_stop.replace(/data-span-ids="([^"]*)"/ig, function(s, id) {
                      matched.push(id);
                    });
                  if (matched.length>0) {
                    spans[chapter].active = matched;
                  }
                }
                documentSearchFactory.pagedData = spans;
                documentSearchFactory.pageList = getPageList(documentSearchFactory.pagedData);
                $rootScope.$broadcast('searchResultsReady');
              }
            },
            function (err) {
              documentSearchFactory.isSearching = false;
              documentSearchFactory.searchInBook = false;
              documentSearchFactory.currentSearch = '';
            }
        );
      }
    };

    var spansByFilename = function(searchData, searchIndex){
      var spans = {};
      _.each(searchData, function(chapter){
        var chapterIndex = chapter.fileName;
        var chapterSpans = spans[chapterIndex] = spans[chapterIndex] || {};

        var list = chapterSpans.list || [];
        list = list.concat(chapter.spanIds);
        chapterSpans.list = _.sortBy( list, function(id){
           return parseInt(id.match(/\d+$/)[0]);
        } );

        if(chapter.spanIds.length > 0){
          var activeSpans = _.intersection(list, documentSearchFactory.citationSpans);
          if (activeSpans.length > 0){
            chapterSpans.active = new Array(activeSpans[0]);
          }
          else{
            chapterSpans.active = [chapterSpans.list[0]];
          }
        }
      });
      return spans;
    }

    var getPageList = function( pagedData ){
      return Object.keys(pagedData).sort();
    }

    var getSearchElements = function( spanArray ){
      var arr = [];
      _.each(spanArray, function( spanIdString ){
        _.each( spanIdString.split(','), function( spanId ){
          var els = getElements(spanId, documentSearchFactory.currentSearch);
          arr = arr.concat(els);
        });
      });
      return arr;
    }

    var getElements = function(spanId, currentWord){
      var arr = []
      var element = angular.element(document.getElementById( spanId )),
      mobileElement = angular.element(document.getElementById( 'mobile_' + spanId ));
      arr.push(element);
      arr.push(mobileElement);

      var hyphenedNodeId = getHyphenatedNodeId(spanId, currentWord);

      if (hyphenedNodeId != null){
        arr.push(angular.element(document.getElementById( hyphenedNodeId )));
        arr.push(angular.element(document.getElementById( 'mobile_' + hyphenedNodeId )));
      }

      return arr;
    }

    var getHyphenatedNodeId = function(spanId, currentWord){
      var element = angular.element(document.getElementById( spanId ));

      if (!element.text().compareInsensitive(currentWord)){
          var next = helpers.getNextId(spanId, 1);
          var prev = helpers.getNextId(spanId, -1);

          if (element.text().endsWith('-')){
            return next;
          }
          if (angular.element(document.getElementById( prev )).text().endsWith('-')){
            return prev;
          }
      }
      return null;
    }

    var startSearch = function(term, bookTitle){
        return new Promise(function(resolve, reject) {
          if(!term) {
            return reject('No term to look for');
          }
          bookTitle = helpers.fixSectionTitle(bookTitle);
          var searchFilters = documentSearchFactory.searchFilters || {};
          searchFilters['book_search'] = {
              title: bookTitle,
              selected: true
            };

          var searchPayload = getSearchPayload({
              // sk: 500 should be enough as we limit search by bookTitle
              count: 500, /* reducing size from 3000 for performance */
              filters: {'collections': searchFilters},
              page: 0,
              query: term,
              sort: 'title_exact asc'
          });

          Restangular.all( 'search' )
            .withHttpConfig({timeout: 55000})
            .post(searchPayload).then(function(results) {
              var chapterList = [];
              if(!(results && results.results && results.results.length>0)) {
                ;
                return resolve(chapterList);
              }
              // process highlights
              var isReadingPeriodical = $rootScope.readingData.bookInfo.bookType == 'periodical';
              _.each(results.results, function(doc) {
                  doc.total = doc.highlights? doc.highlights.length: 0;
                  var docTitle = _.isArray(doc.toc_title) && doc.toc_title.length>0? doc.toc_title[0]: '';
                  var docTitleLowered = docTitle.toLowerCase().replace(/^\s+|\s+$/gm, '');

                  var spanIds = [];
                  _.each(doc.highlights, function(rawHighlight) {
                      var highlight = parseHighlights(rawHighlight.item_body_stop);
                      highlight.toc_title = docTitle;
                      highlight.item_body = rawHighlight.item_body_stop;

                      var docNameFixed = helpers.fixSectionTitle(highlight.documentName);
                      var book = isReadingPeriodical? (bookTitle == docNameFixed? $rootScope.periodicalData: null):
                        _.find($rootScope.bookData, {sectionTitle: docNameFixed});
                      if(!book) {
                        return;
                      }

                      var bookItem = _.find(book.item, function (item) {
                          return item.title.toLowerCase().replace(/^\s+|\s+$/gm, '') === docTitleLowered;
                        });
                      if (!bookItem) {
                        var docNameLowered = highlight.documentName.toLowerCase().replace(/^\s+|\s+$/gm, '');
                        bookItem = _.find(book.item, function (o) {
                            return o.parentTitle.toLowerCase().replace(/^\s+|\s+$/gm, '') === docNameLowered;
                          });
                        if (!bookItem) {
                          ;
                          return;
                        }
                      }

                      var searchFileName = isReadingPeriodical? _.find(book.pageBreaks, {
                          title: _.unescape(highlight.itemName),
                          originalRange: highlight.beginPageNumber
                        }): _.find(book.pageBreaks, { begin: { title: highlight.beginPageNumber } });
                      if (!searchFileName) {
                        return;
                      }

                      var fileName = book.sectionFolder + "_" + ("0000" + searchFileName.number).slice(-4);
                      if(!fileName) {
                        return;
                      }

                      // Add all the necessary book details to the parsedHighlight.
                      highlight.book = book;
                      highlight.ref = bookItem.ref;
                      highlight.page = bookItem.page;
                      highlight.align = bookItem.align;
                      highlight.fileName = fileName;

                      spanIds[rawHighlight.page] = (spanIds[rawHighlight.page] || []).concat(highlight.spanIds);

                      var chapterIndex = _.findIndex(chapterList, {fileName: highlight.fileName});
                      spanIds[rawHighlight.page] = _.sortBy(spanIds[rawHighlight.page]);

                      if (chapterIndex >= 0) {
                        chapterList[chapterIndex].spanIds = spanIds[rawHighlight.page];
                      } else {
                        chapterList.push({
                          align: highlight.align,
                          book: book.sectionFolder,
                          bookTitle: highlight.documentName,
                          chapterTitle: highlight.itemName,
                          fileName: highlight.fileName,
                          folder: highlight.documentCollection,
                          title: highlight.ref,
                          ref: highlight.ref,
                          page: highlight.page,
                          highlight: highlight,
                          foundTerms: highlight.foundTerms,
                          spanIds: spanIds[rawHighlight.page]
                        })
                      }
                  });
              });

              // prepare chapter list
              if (chapterList.length>0) {
                _.sortBy(chapterList, 'fileName');
                _.each(chapterList, function (item, key) {
                  item.align = !item.align || item.align === '' ? 'left' : item.align;
                });
                _.each(chapterList, function (chapter) {
                  var activeChapter = _.find($rootScope.readingData.chapterList, function (o) {
                    return o.fileName.toLowerCase() == chapter.fileName.toLowerCase();
                  });
                  if (!activeChapter) {
                    return;
                  }
                  if (chapter.highlight) {
                    activeChapter.highlight = chapter.highlight;
                  }
                  if (chapter.foundTerms) {
                    activeChapter.foundTerms = chapter.foundTerms;
                  }
                });
              }
              return resolve(chapterList);
            }, function(error){
              return reject(error);
            })
        })
    };

    var startCitation = function(customCitation){
      return new Promise(function(resolve, reject) {
        var cleanCitation = customCitation;
        var ccArr = cleanCitation.split(':');
        if (ccArr[0].indexOf('-')>=0) {
          cleanCitation = cleanCitation.replace('-', ' ');
        }
        cleanCitation = cleanCitation.replace('?', '%3F');
        ;
        Restangular.all('citation').one(cleanCitation).get().then(function(data) {
          if(data.data.error) return reject('No Results Found');

          $rootScope.activeCitation = data.data;
          documentSearchFactory.isCitation = true;
          return resolve();

        }, function(error){
            return reject( error );
        })
      });
    }

    var getValue = function(str, srch) {
        var search = srch+'="';
        var obj = str.indexOf(search);
        var res = str.substr(obj+search.length);
        var result = res.substr(0, res.indexOf('"'));
        return result;
    }

    var parseHighlights = function( highlight ){
      var bookID = /(?:<span id=\")([\s\S]+?)(?:\")/igm.exec(highlight);
      var element = angular.element( '<div>'+highlight+'</div>' );
      var foundTerms = angular.element("<div>"+highlight+"</div>").find('span');
      var spanIds = [];
      highlight.replace(/data-span-ids="([\s\S]+?)"/igm, function(m, p1){ spanIds.push(p1); } );

      return {
          bookId: bookID ? bookID[1] : null,
          beginIndex: getValue(highlight, 'data-begin-index'),
          endIndex: getValue(highlight, 'data-end-index'),
          documentId: getValue(highlight, 'data-document-id'),
          documentName: getValue(highlight, 'data-document-name'),
          itemName: getValue(highlight, 'data-item-name'),
          documentType: getValue(highlight, 'data-document-type'),
          documentCollection: getValue(highlight, 'data-document-collection'),
          taxonomyTerms: getValue(highlight, 'data-taxonomy-terms'),
          collectionTypes: getValue(highlight, 'data-collection-types'),
          beginChapterVerse: getValue(highlight, 'data-begin-chapter-verse'),
          endChapterVerse: getValue(highlight, 'data-end-chapter-verse'),
          beginPageNumber: getValue(highlight, 'data-begin-page-number'),
          endPageNumber: getValue(highlight, 'data-end-page-number'),
          spanIds: spanIds,
          foundTerms: foundTerms
      }
    }


  	/* Public Methods */
  	return{
  		setSearchHighlights: setSearchHighlights,
        getSearchElements: getSearchElements
  	}

}).factory('documentSearchFactory', function () {
  return {
      currentSearch : '',
      searchData : [],
      pagedData : [],
      citationSpans: [],
      isSearching: false,
      isCitation: false,
      searchInBook: false,
      searchFilters: {}
  };
});
