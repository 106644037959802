var helpers = require('../../libs/helpers');

angular.module('koh').directive('featuredBibleLesson', [
    '$compile',
    '$rootScope',
    '$sce',
    '$q',
    '$timeout',
    'Restangular',
    'booksService',
    function ($compile, $rootScope, $sce, $q, $timeout, Restangular, booksService) {

    var lessonObj = null;

    var link = function ($scope, element, attrs) {
      $scope.isCarousel = $scope.$eval(attrs.iscarousel);
      $scope.loaded = false;
      $scope.error = false;
      $scope.lessonList = [];

      lessonObj = Restangular.all('lessons');

      lessonObj.one('today').get().then( function(data) {
        lessonObj.one('listing').get().then(function(lessonArr){
          var list = _.filter(lessonArr, function(l){
            return l.endsWith('.xml');
          });
          list.forEach(function(l, i, arr){
            arr[i] = l.replace('.xml', '');
          });
          $scope.lessonList = list;
          handleLessonObject(data);
        });
      }, handleError );

      $scope.setCurrentFeature = function(filename){
      	$scope.loaded = false;

      	$timeout(function(){
      		lessonObj.one('file', filename).get().then( function(data) { handleLessonObject(data) }, handleError );
      	}, 200);
      }

      var handleLessonObject = function(data){
      	if (data.length > 0){
	    		var lessonObj = data[0];
    			getFeaturedLesson(lessonObj, $scope.lessonList).then(function(lesson){
          	$scope.featuredLesson = lesson;
          	$scope.loaded = true;
          });
        } else{
          handleError();
        }
    	}

      var handleError = function(){
        ;
        $scope.error = true;
        $scope.loaded = true;
      }

    };

    var getFeaturedLesson = function(lessonObj, lessonList){
    	return $q(function(resolve, reject) {
        helpers.logMessage('Getting featured lesson',$rootScope, 'featured-bible-lesson',1);
	      var featuredLesson = {},
	          filename = lessonObj.filename,
	          id = lessonObj._id,
            index = _.indexOf(lessonList, filename);

        Restangular.all('books').one('bible_lessons').one(filename).one('xml').get().then(function(xml){
          var fragment = booksService.getXMLFragment(xml),
              sections = fragment.querySelectorAll('head[type="section"]'),
              lessonFrag;

          if (sections.length < 1){
            ;
            return;
          }


	        featuredLesson.filename = filename;
          featuredLesson.subjectHTML = parseHTML(sections, 'Subject');

          var cleanDate = lessonObj.publicationDate.replace(/"/g, '') +  'T00:00:00Z';
          var testDate = new Date(cleanDate);

          featuredLesson.date = helpers.parseDate(testDate);
          featuredLesson.goldenTextHTML = parseHTML(sections, 'Golden Text:');
          featuredLesson.path = createPath(filename);
	        featuredLesson.previous = index - 1 >= 0 ? lessonList[index -1] : null;
	        featuredLesson.next = index + 1 < lessonList.length ? lessonList[index + 1] : null;
          helpers.logMessage('Featured lesson resolved',$rootScope, 'featured-bible-lesson',1);
	      	resolve(featuredLesson);
	      });
		  });
		}


    var parseHTML = function(sections, key){
      // var subjectNode = _.find(sections, {'innerHTML': html}) || _.find(sections, {'textContent': html}),
    	var subjectNode = _.find(sections, function(section){
        var html = section.innerHTML || section.textContent;
        return html.startsWith(key);
      });
      var p = subjectNode.parentNode,
          html = '';
      if (!_.isUndefined(p.innerHTML)){
 			  html = p.innerHTML.replace(/<head.+?>/, '<b>').replace('</head>', '</b>');
      } else {
        html = p.textContent.replace(subjectNode.textContent, "<b>" + subjectNode.textContent + "</b>");
        if (p.childElementCount > 1){
          html = html.replace(p.lastChild.textContent, '<div type="attribution">' + p.lastChild.textContent + "</div>");
        }
      }
    	return $sce.trustAsHtml(html);
    }

    var createPath = function(id){
    	return '/lessons/' + id;
    }

    return {
        transclude: true,
        templateUrl: './directives/pages/featured-bible-lesson/featured-bible-lesson.html',
        link: link,
    };
}]);
