angular.module('koh').controller('LoginCtrl', 
	['$scope', 
	'helpers', 
	'Restangular', 
	'$stateParams',
	'$http',
	'$rootScope',
	'$location',
	'apiUrl',
	'doLogin',
	'$state',
		function($scope, 
			helpers, 
			Restangular,
			$stateParams,
			$http,
			$rootScope,
			$location,
			apiUrl,
			doLogin,
			$state
	) {	
		$scope.email = '';
		$scope.password = '';
		$scope.error = null;
		helpers.toggleMenu($rootScope, false);
		
		// TODO: Find a way to temporarily hide the search bar when on the login or sign up pages.
		// helpers.toggleSearch($rootScope, false);
		
		if ($stateParams.message) {
			$scope.message = $stateParams.message;
		}

		if ($stateParams.error) {
			$scope.error = $stateParams.error;
		}

		if ($stateParams.logout) {
			$rootScope.user = null;
			$rootScope.isLoggedIn = false;
			$http.get(apiUrl + '/api/logout', {cache:false}).then(function(res, data) { $rootScope.user = null; });
		}

		if( $rootScope.user )
        {
            $state.go('home');
        }
		
		$scope.doLogin = function() {
		;
        $rootScope.activeCitation = undefined;
			var authObj = { email:$scope.email, password: $scope.password };
			doLogin(
				authObj,
				function( response ){
					if( $rootScope.user )
					{	
						helpers.toggleMenu($rootScope, false);
						helpers.toggleSearch($rootScope, false);
						
						if( $rootScope.previousState )
						{
							return $state.go( $rootScope.previousState.name, $rootScope.previousState.toParams );
						}
						else
						{
							return $state.go('home');
						}
					}
					
					if( response.data && response.data.error )
					{
						$scope.error = response.data.error;
						$scope.message = null;
					}
					else
					{ 
						$scope.error = 'The server may be unreachable at this time. Please try again later.';
						$scope.message = null;
					}
				},
				function( error ){
					if( error )
					{
						$scope.error = error;
						$scope.message = null;
					}
					else
					{ 
						$scope.error = 'The server may be unreachable at this time. Please try again later.';
						$scope.message = null;
					}
				}
			)
		};

}])
.factory('doLogin', ['$http', 'Restangular', '$rootScope', function($http, Restangular, $rootScope){
	return function( user, done ){
		Restangular.all('login').withHttpConfig({ cache: false }).post(user).then(
		function( data ){
			;
			$rootScope.user = data.plain();
			;
        	$rootScope.activeCitation = undefined;
			if ($rootScope.advancedMode == false) {
				$rootScope.user.usertype = 'casual';
			}
			//$rootScope.user.showCitationOrganizer = helpers.showCitationOrganizer;
			done( data );
		},
		function( error ){
			done( error );
		});
	}
}])


