var helpers = require('../../libs/helpers');
angular.module('koh').directive('autofocusIf', function($timeout) {
return {
  restrict: 'A',
  scope: {
    autofocusIf: '&'
  },
  link: function($scope, $element) {
    $scope.$watch('autofocusIf', function(shouldFocus) {
      var someFocus = shouldFocus;
      ;
      if (shouldFocus) {
        $timeout(function() {
          $element[0].focus();
        });
      } else {
        $timeout(function() {
          $element[0].blur();
        });
      }
    });
  }
};
})

angular.module('koh').directive('focusMe', function () {
    return {
        link: function(scope, element, attrs) {
            ;
            scope.$watch(attrs.focusMe, function(value) {
                if(value === true) {
                    element[0].focus();
                    element[0].select();
                }
            });
        }
    };
});
angular.module('koh').directive('citationOrganizer',
    ['$location',
    'Restangular',
    '$rootScope',
    'booksService',
    'selectionService',
    '$window',
    '$timeout',
    'focus',
    function($location,
        Restangular,
        $rootScope,
        booksService,
        selectionService,
        $window,
        $timeout,
        focus
    ){
    return {
    transclude: true,
    templateUrl: './directives/pages/citation-organizer/citation-organizer.html',
    link: function (scope, element, attrs) {
        var rootScope = $rootScope;
        scope.isOpen = false;
        //console.log(helpers.tims() + 'Entered citation organizer');
        var filesObject = Restangular.all('files');
        scope.changedFolder = null;
        scope.customCitation = '';//scope.currentBook + ' 1:1';

        var mergeFlags = {
            editingFolder: null,
            citationsOpen: false,
            editingCollapsedCog: false,
            folderList: true,
            addCustomCitation: false
        };

        scope.focusedFolderInput = -1;
        scope.customInlineCitation = '';
        scope.showTab = attrs.showTab == 'true' ? true : false;
        //console.log(helpers.tims() + 'attrs:', attrs)
        //console.log(helpers.tims() + 'Merging active flags');
        Object.keys(mergeFlags).forEach(function(key) {
            scope.activeFlags[key] = mergeFlags[key];
        });
        scope.activeFlags['activeFolder'] = scope.activeFlags['activeFolder'] || null;
        // Ensure we have flags for our obj and if not, add it.
        $rootScope.$on('toggleCitationOrganizer', function() {
            var unlockCOClick = function() {
                $rootScope.delayedCOClick = false;
            }
            if ($rootScope.delayedCOClick) {
                //console.log(helpers.tims() + 'Holding spam click');
                return;
            }
            $rootScope.delayedCOClick = true;
            setTimeout(unlockCOClick, 500);
            //console.log(helpers.tims() + 'Toggling citation organizer');
            scope.activeFlags['citationsOpen'] = _.isUndefined(scope.activeFlags['citationsOpen']) ? true : scope.activeFlags['citationsOpen'];
            scope.activeFlags['citationsOpen'] = !scope.activeFlags['citationsOpen'];
        })

        $rootScope.$on('openCitationOrganizer', function(e, citation, bRefresh) {
            //console.log(helpers.tims() + 'In openCitationOrganizer:' + bRefresh);
            if (citation) {
                //console.log('A citation was specified:', citation);
                var checkCitation = _.find(scope.user.citations, { _id: citation._id });
                if (!checkCitation) {
                    scope.user.citations.push(citation);
                }
                $rootScope.activeCitation = citation._id;
            }
            scope.activeFolder = citation.citationFolder;
            scope.activeFlags['citationsOpen'] = true;
            //if(citation) scope.viewCitation(citation);
        })
        scope.isFocused = function(folder) {
            return folder && folder.id !== undefined && scope.focusedFolderInput == folder.id
        }
        if (!scope.user)
            return;

        if (!scope.user.selections) {
            booksService.loadSelections(scope).then(function(data) {
                //console.log(helpers.tims() + 'Got selection data:', data);
            });
        }

        scope.saveProjectNote = function() {
            //console.log(helpers.tims() + 'Saving project note');
            scope.activeFlags['editingCitation'] =  null;
            /*scope.linkObj.citation = scope.linkObj.title;
            scope.linkObj.selection = [];
            var newBody = {
                body: 'New note',
                note: true
            }
            newBody.body = scope.projectNote;
            scope.linkObj.selection.push(newBody);*/
            scope.saveSelection('', false, scope.linkObj);
        }
        scope.activeFlags['editingInlineCitation'] = false;
        scope.editInlineCitation = function(citation) {
            scope.customInlineCitation = citation.citation;
            scope.activeFlags['editingInlineCitation'] = citation;
            //console.log(helpers.tims() + 'Editing:', citation);
        }
        scope.isEditingInlineCitation = function(citation) {
            if (scope.activeFlags['editingInlineCitation']) {
                if (scope.activeFlags['editingInlineCitation']._id == citation._id) {
                    return true;
                }
            }
            return false;
        }
        scope.reloadCitation = function(customInlineCitation) {
            //console.log(helpers.tims() + 'Reloading citiion');
            var completeSave = function() {
                //console.log(helpers.tims() + 'Save complete!');
                scope.activeFlags['editingInlineCitation'] = false;
            }

            $rootScope.editCustomCitation(scope.activeFlags['editingInlineCitation'], customInlineCitation, completeSave)

        }
        scope.user.folders = scope.user.folders || [];
        scope.user.folders = _.without(scope.user.folders, null);
        $rootScope.delayedCOClick = false;


        scope.closeCitationOrganizer = function(ele) {
            var unlockCOClick = function() {
                $rootScope.delayedCOClick = false;
            }
            if ($rootScope.delayedCOClick) {
                //console.log(helpers.tims() + 'Holding spam click');
                return;
            }
            ////console.log(helpers.tims() + 'CO Detected click');
            if (helpers.checkParent(ele, '.citationOrganizerIcon')) {
                ////console.log(helpers.tims() + 'Clicked CO toggle icon');
                return;
            }
            if (helpers.checkParent(ele, '.confirmationContainer')) {
                ////console.log(helpers.tims() + 'Clicked in confirmation box');
                return;
            }
            if (scope.activeFlags) {
                if (scope.activeFlags['citationsOpen']) {
                    if (!helpers.checkParent(ele, '.citation')) {
                        ////console.log(helpers.tims() + 'Setting citations Open to false');
                        scope.activeFlags['citationsOpen'] = false;

                        //if(!scope.$$phase && scope.$$phase != null) {
                            try {
                                scope.$digest();
                            } catch (exc) {

                            }

                        //}
                        $rootScope.delayedCOClick = true;
                        setTimeout(unlockCOClick, 500);
                    } else {
                        ////console.log(helpers.tims() + 'Clicked in citatino folder');
                    }
                }
            }
        }

        scope.hasNote = function(citation) {
            if (!citation) return false;
            if (!citation.selection) return false;
            if (!citation.selection[0]) return false;
            return !_.isUndefined(citation.selection[0].note);
        }

        scope.addProjectNote = function(folder) {
            var newCitationObject = {
                    folder: scope.bookInfo.bookType,
                    type: 'citation',
                    displayedOrder: 1,
                    section: scope.currentChapter.replace(' ', '_'),
                    citationFolder: scope.activeFlags['activeFolder'].id,
                    order: 1,
                    note: 'My note',
                    book: scope.currentBook,
                    citation: 'New Note',
                    title: '',
                    user_id: $rootScope.user._id,
                    selection: {}
                };
                newCitationObject.selection = { body: 'New Project Note', note: true };
                scope.setActiveType('editingCollapsedCog', null);
                scope.saveSelection(null, false, newCitationObject, true).then(function() {
                    //console.log(helpers.tims() + 'Saved selection')
                    scope.activeFlags['addCustomCitation'] = false;
                }).catch(function(err) {
                    scope.customCitationMessage = err;
                });
        }

        scope.addCustomCitation = function(folder) {
            //console.log(helpers.tims() + 'Adding custom citation');
            scope.setActiveType('editingCollapsedCog', null);
            scope.activeFlags['addCustomCitation'] = true;
            focus('customCitation');
        }

        scope.confirmCustomCitation = function(customCitation) {
            $rootScope.addCustomCitation(customCitation, scope);
        }

        scope.dragControlListeners = {
            placeholder: function(obj) {
                //console.log('Placholder dcL');
                return '<div class="citationContents custom-placeholder as-sortable-placeholder">' +
                     generateCitationText(obj.citation) +
                    '</div>';
            },
            accept: function (sourceItemHandleScope, destSortableScope) {
                return true;
            },
            dragStart: function () {
                $rootScope.draggedItem = arguments[0].source;
                scope.draggedItem = arguments[0].source;
                //console.log('dragStart : ', $rootScope.draggedItem );
            },
            dragEnd: function () {
                //console.log('dragEnd : ', arguments)
            },
            dragMove: function(arguments) {
              //console.log('dragMove : ', arguments, $rootScope.draggedItem);
              return false;
            },
            itemMoved: function (event) {
                //console.log(helpers.tims() + 'dragControlListeners Order itemMoved');
                checkMoveCitation();
            },//Do what you want},
            orderChanged: function(event) {

                //console.log(helpers.tims() + 'dragControlListeners Order orderChanged');

                scope.saveChanges();
            },//Do what you want},
            containment: '#board'//optional param.
        };
        scope.checkDraggingItem = function() {
            //console.log('Checking if Im hovered');
            if (scope.draggedItem) {
                //console.log('Dragging item');
            } else {
                //console.log('Not dragging item')
            }
        }
        scope.checkActiveFolder = function(folder) {
            if (folder.name == scope.activeDragFolder) {
                ////console.log('Active dragging folder:' + folder.name);
                return 'activeDragFolder';
            }
            if (scope.isActive('activeFolder', folder)) {
                //console.log('Active opened folder:' + folder.name);
                return 'activeCitationFolder';
            }
            return '';
        }
        scope.updatePageY = function(arr) {
            scope.lastMouseY = scope.lastMouseY || 9999;
            var retVal = 0;
            if (arr[2].pageY > scope.lastMouseY) {
                retVal = 1;
            }
            if (arr[2].pageY < scope.lastMouseY) {
                retVal =  -1;
            }
            scope.lastMouseY = arr[2].pageY;
            return retVal;
        }
        scope.dragCitationListeners = {
            placeholder: function(obj) {
                //console.log('Placholder dcL');
                return '<div class="citationContents custom-placeholder as-sortable-placeholder">' +
                     generateCitationText(obj.citation) +
                    '</div>';
            },
            dragStart: function () {
                $rootScope.draggedItem = arguments[0].source;
                scope.draggedItem = arguments[0].source;
                //console.log('dragStart : ', $rootScope.draggedItem )
            },
            dragEnd: function () {
                scope.draggedItem = undefined;
                scope.activeDragFolder = undefined;
                //console.log('dragEnd : ', arguments)
            },
            dragMove: function() {
                ////console.log('dragMove : ', arguments, $rootScope.draggedItem);
                ////console.log('1 Index:',arguments[2].toElement)

                var dragObjClass = event.srcElement.className;
                ////console.log('Dragging to :' + event.srcElement.className + ' ' + event.srcElement.innerText + ' event:' + arguments[2].pageY);
                if (parseInt(arguments[2].pageY)<322) {
                    scope.activeDragFolder = undefined;
                }
                if (dragObjClass.indexOf('text-row')>=0) {
                    //scope.activeDragFolder = undefined;
                } else {
                    if (event.srcElement.className.indexOf('citationFolder')>=0) {
                        ////console.log('Draggign to folder!' + event.srcElement.innerText);
                        scope.lastFolder = scope.lastFolder || '';
                        var offset = 0;
                        if (scope.updatePageY(arguments) < 0) {
                            offset = -1;
                        }
                        //console.log('Trying to choose right folder with offset:' + offset )

                        if (offset != 0) {
                            var currentFolder = 0;
                            for (var i = 0 ; i < scope.user.folders.length ; i++) {
                                if (scope.user.folders[i].name == event.srcElement.innerText) {
                                    currentFolder = i;
                                }
                            }
                            currentFolder+=offset;
                            scope.activeDragFolder = scope.user.folders[currentFolder].name;
                        } else {
                            scope.activeDragFolder = event.srcElement.innerText;
                        }



                        ////console.log('Source text:' + event.srcElement.innerText);
                    }
                }
                arguments[2].preventDefault();
                return false;
            },
            accept: function (sourceItemHandleScope, destSortableScope) {
                return sourceItemHandleScope.itemScope.sortableScope.$id === destSortableScope.$id;
            },
            itemMoved: function (event) {
                //console.log(helpers.tims() + 'dragCitationListeners Order itemMoved');
                //console.log(helpers.tims() + 'Got event' + event);
                checkMoveCitation(event);
            },
            orderChanged: function(event, otherstuff) {
                //console.log(helpers.tims() + 'dragCitationListeners Order changeddata');
                var evtId = event.dest.index;
                if (!scope.activeFolder) {
                    var activeFlagFolder = scope.$parent.activeFlags['activeFolder'];
                    if (activeFlagFolder) {
                        scope.activeFolder = activeFlagFolder.id;
                    }
                    else {
                        scope.activeFolder = 0;
                    }
                }


                //var srcId = event.source.index;


                // Rebuild citations
                //scope.user.citations = $rootScope.cachedCitations;//_.where($rootScope.user.selections, {type: 'citation'});

                //var destObj = _.find(scope.user.citations, { order: event.dest.index});
                var citationList = [];
                _.each($rootScope.cachedCitations, function(row) {
                    var newObj = _.extend({}, row);
                    citationList.push(newObj);
                });

                //var srcObj = _.find(citationList, {_id: event.source.itemScope.citation._id});
                //var srcId = srcObj.order;
                var srcId = event.source.index;
                var srcObj = _.find(citationList, {order: srcId});

                //var srcId = event.source.index;
                var destObj = _.find(citationList, function(row) {
                    if (!scope.activeFolder || scope.activeFolder == 0) {
                        return row.order == event.dest.index && (!row.citationFolder || row.citationFolder == 0);
                    } else {
                        return row.order == event.dest.index && row.citationFolder == scope.activeFolder;
                    }
                });
                //console.log('Moved ' + evtId + ' to ' + srcObj.order);

                if (evtId<srcId) {
                    //console.log(helpers.tims() + 'moved object up');
                    //console.log(helpers.tims() + 'Got changed obj:' + JSON.stringify(srcObj));
                    var oldOrder = destObj.order;
                    _.each(citationList, function(row) {
                        if (!row.citationFolder) {
                            row.citationFolder = 0;
                        }
                        if (row.citationFolder == scope.activeFolder) {
                            //console.log('Found another matching folder citation lol: ', row);
                            if (row.order>=evtId  && row.order<=srcId) {
                                if (row._id != srcObj._id) {
                                    //console.log(helpers.tims() + 'Increasing order');
                                    row.order++;
                                }
                            }
                        }
                    })
                    //console.log(helpers.tims() + 'Setting object order of moved citation to:', evtId);
                    srcObj.order = oldOrder;
                }
                else {
                    //console.log(helpers.tims() + 'Object moved down');
                    var oldOrder = destObj.order;
                    _.each(citationList, function(row) {
                        if (!row.citationFolder) {
                            row.citationFolder = 0;
                        }
                        if (row.citationFolder == scope.activeFolder) {
                            //console.log('Found another matching folder citation: ', row);
                            if (row.order>=srcId  && row.order<=evtId) {
                                 if (row._id != srcObj._id) {
                                    //console.log(helpers.tims() + 'decreasing order');
                                    row.order--;
                                }
                            }
                        }
                    });
                    srcObj.order = oldOrder;
                }

                //scope.selections = _.remove(scope.selections, function(row) {
                    //return row.type != 'citation';
                //});


                //scope.user.citations = _.sortBy(scope.user.citations, function(row) {
                    //return row.order;
                //})

                //for (var j = 0 ; j < scope.user.citations.length ; j++) {
                    //scope.user.citations[j].order = j;
                //}



                //scope.selections = _.union(scope.selections,scope.user.citations);

                scope.saveCitations(citationList);

            },
            containment: '#board'
        };

        var cleanFolders = function() {
             scope.user.folders = _.remove(scope.user.folders, function(row) {
                return (_.isUndefined(row.type)); // Remove any trash
            });
        }
        scope.citationsByFolder = function(folder, i) {
            //console.log(helpers.tims() + 'Filtering by' + folder +  ' and i ' + i);
        }

        var checkMoveCitation = function(event) {
            //console.log(helpers.tims() + 'Checking move citatnoi');
            if (_.isUndefined(event)) {
                //console.log(helpers.tims() + 'Invalid move event');
                cleanFolders();
                scope.loadSelections();
                return;
            }
            if (!_.isUndefined(event.source.itemScope.citation)) {
                var tempCitations = _.clone(rootScope.cachedCitations, true);
                var obj = _.find(tempCitations, function(row) {
                    return row._id == event.source.itemScope.citation._id;
                });

                var folder = scope.user.folders[event.dest.index-1];
                if (event.dest.index==0) {
                    obj.citationFolder = null;
                }
                else {
                    obj.citationFolder = folder.id;
                }
                //console.log(helpers.tims() + 'my folder: ' + folder);
                cleanFolders();
                //scope.user.citations = ;
                scope.saveCitations(tempCitations);
            }
        }

       scope.setActiveType = function(type, val, toggleOrArg) {
        ////console.log(helpers.tims() + 'Set active type:', toggleOrArg);
        helpers.setActiveType(scope, type, val, toggleOrArg);
       };

       scope.renumberCitations = function (folder) {
        //console.log(helpers.tims() + 'Renumbering citations');
        _.each($rootScope.user.citations, function(row) {
          if (row.type == 'citation') {
            if (row.citationFolder == folder.id) {
              row.displayedOrder = row.order;
            }
          }
        });

        $rootScope.user.citations = _.sortBy($rootScope.user.citations, function(row) {
              return row.citationFolder;
          });

          for (var j = 0, i = 0 ; j < $rootScope.user.citations.length ; j++) {
            if ($rootScope.user.citations[j].type == 'citation') {
                if ($rootScope.user.citations[j].citationFolder == folder.id) {
                  $rootScope.user.citations[j].displayedOrder = $rootScope.user.citations[j].order = i;
                  i++;
                }
            }
          }
        scope.setActiveType('editingCollapsedCog', null);
        // //console.log(helpers.tims() + 'Renumbered');
        scope.saveCitations();
      }

        scope.confirmedDeleteCitation = function(citationId) {
        Restangular.all('selections').one(citationId).remove().then(function(ret, res) {
          if (ret.message!='Removed') {
            // //console.log(helpers.tims() + "error!")
          }
          else {
            //console.log(helpers.tims() + 'Confirmed deleting');
            scope.clearCitations();
            //console.log(helpers.tims() + 'Removing from organizer');
            if (scope.user.citations.length == 1) {
                scope.user.citations = [];
            } else {
                scope.user.citations = _.remove(scope.user.citations, function(row) {
                    return row._id != citationId
                });
            }
            $rootScope.$broadcast('invalidateRenderedPage');
          }
        });
      };
        scope.clearCitations = function() {
            var citations = $rootScope.user.citations;
            $rootScope.activeCitation = '';
            // var pageCitations = _.where(citations, {'section' : scope.page.fileName});
            //console.log(helpers.tims() + 'Assigning blank classes to all existing citations');
            _.each(citations, function(citation) {
                ////console.log(helpers.tims() + 'Iterating citatoins:', citation);
                var selObj  = citation.selection[0] || citation.selection;
                var start   = document.getElementById(selObj.startSpanId),
                    end     = document.getElementById(selObj.endSpanId);
                //selectionService.removeSelection('chalked');
                ////console.log('Clearing citations');
                selectionService.removeClass(new Array('chalked'));

                if(selObj.specialMarkings){
                    selectionService.applyClass(new Array(end), selObj.specialMarkings);
                }
            });
        }

        scope.deleteCitation = function(citation) {

            ;
            var citationId = citation._id;
            scope.setActiveType('confirmAction', citationId, scope.confirmedDeleteCitation);
        };

        scope.getCitationOrder = function(citation) {
            ////console.log('Getting citation order');
            var citationList = _.where($rootScope.user.citations, { citationFolder: citation.citationFolder});

            var sortedCitations = _.sortBy(citationList, 'order');

            var actualOrder = 0;

            _.each(sortedCitations, function(row) {
                if (row._id == citation._id) {
                    return false;
                } else {
                    actualOrder++;
                }
            })
            return parseInt(actualOrder)+1;
        };

        scope.isActiveCitation = function (citation) {
            if ($rootScope.activeCitation == citation._id) {
                return 'selectedCitation'
            }
            if ($rootScope.lastAddedCitation == citation.citation) {
                ////console.log('last added!');
                return 'changedCitation';
            }
            return '';
        }
        scope.viewCitation = function(citation) {
            //console.log(helpers.tims() + 'Viewing citation');
            scope.clearCitations();
            $rootScope.activeCitation = citation._id;
            if (scope.currentBook != citation.book || scope.currentChapter != citation.section) {
                $location.url('/reader/' + citation.folder + '/' + citation.book + '/' + citation.section);
                $rootScope.$broadcast('invalidateRenderedPage');
            } else{
                $rootScope.$broadcast('invalidateRenderedPage');
            }
        };

        scope.editCitation = function(citation) {
            //console.log(helpers.tims() + 'Editing citation');
            scope.linkObj = citation;
            scope.setActiveType('editingCitation', citation);
        };


        scope.advanceCitation = function(dir) {
            var activeFolder = scope.activeFlags['activeFolder'];
            if (_.isObject(activeFolder)) {
                activeFolder = activeFolder.id;
            }
            //console.log(helpers.tims() + 'Setting citation with active folder:' + activeFolder);

            var getCitationOrder = function(citationArray, id) {
                if (!id) {
                    return (0);
                }

                var citationCount = citationArray.length;
                var order = -1;
                for (var i = 0 ; i < citationCount ; i++) {

                    if (citationArray._id == id) {
                        order = i;
                        break;
                    }
                }
                return order;
            }

            var getFolderCitations = function(folderId) {
                var folderCitations = [];
                if (!folderId) {
                    for (var i = 0 ; i < scope.user.citations.length ; i++) {
                        if (!scope.user.citations[i].citationFolder) {
                            folderCitations.push(scope.user.citations[i]);
                        }
                    }

                } else {
                    for (var i = 0 ; i < scope.user.citations.length ; i++) {
                        if (scope.user.citations[i].citationFolder==folderId) {
                            folderCitations.push(scope.user.citations[i]);
                        }
                    }
                }
                return folderCitations;
            }
            var getCitationCount = function() {
                var citationCount = 0;
                if (!activeFolder) {
                    for (var i = 0 ; i < scope.user.citations.length ; i++) {
                        if (!scope.user.citations[i].citationFolder) {
                            citationCount++;
                        }
                    }

                } else {
                    var citationFolderArray = _where(scope.user.citations, { citationFolder: activeFolder});
                    citationCount = citationFolderArray.length;
                }
                return citationCount;
            }
            var citationArray = getFolderCitations(activeFolder);
            var citationCount = citationArray.length;
            var foundId = -1;
            if ($rootScope.activeCitation) {
                var currentOrder = getCitationOrder(citationArray, $rootScope.activeCitation);
                currentOrder+=dir;
                if (currentOrder < 0) {
                    //console.log('Ignoreing');
                    return;
                    //currentOrder = citationArray.length-1;
                }
                if (currentOrder >= citationCount) {
                    //console.log('Ignoreing');
                    return;
                    //currentOrder = 0;
                }
                foundId = citationArray[0]._id;
            } else {
                foundId = citationArray[0]._id;
            }

            //console.log(helpers.tims() + 'Setting by order');
            var currentCitation = _.find(scope.user.citations, { _id: foundId});
            //scope.clearCitations();
            //$rootScope.$broadcast('invalidateRenderedPage');
            //scope.setActiveType('activeFolder', _.find($rootScope.user.folders, {id: currentCitation.citationFolder}));
            scope.viewCitation(currentCitation);
            scope.activeFlags['citationsOpen'] = true;

        }



        scope.createFolder = function(folderName) {
            var newName = folderName || 'Folder Name';
            var iter = 1;
            // while(_.find(scope.user.folders, function(row){
            //     return row.name==newName;
            // })) {
            //     newName = 'New Folder ' + iter++;
            // }
            var newId = (new Date).getTime();//scope.user.folders.length+1;
            var newObj = {
                name: newName,
                id: newId
            };
            //console.log(helpers.tims() + 'Adding new item');
            scope.user.folders.push(newObj);
            scope.setActiveType('activeFolder',null);
        }
        /*
        if (scope.user.folders.length < 1) {
            scope.createFolder('Default Folder');
        }
        */

        scope.getSelectionBody = helpers.getSelectionBody;

        scope.getSelections = function(type, val) {
            var typeArr = _.where(scope.selections, {'type':type});
            var ret = _.where(typeArr, function(row) {
                var key = Object.keys(val)[0];
                if (val[key] == null) {
                    return _.isUndefined(typeArr[val[key]])
                }
                return val[key]==row;
            });
            return ret;
        }

        scope.deleteFolder = function(folder) {
            scope.setActiveType('editingCollapsedCog', null);
            scope.setActiveType('confirmAction', folder, scope.confirmedDelete);
        };

        scope.confirmedDelete = function(folder) {
            //console.log(helpers.tims() + 'Confirmed deletion');
            scope.user.folders = _.without(scope.user.folders, folder);
            scope.saveChanges();
            scope.setActiveType('editingCollapsedCog', null);
        }
        scope.renameFolder = function(folder) {
            scope.focusedFolderInput = folder.id;
            ;
            scope.setActiveType('editingFolder',folder)
            scope.setActiveType('editingCollapsedCog', null);
            window.document.getElementById('editFolder_' + (folder.id-1)).focus();
        };

        scope.printFolder = function(folder) {
            //console.log(helpers.tims() + 'Printing ' + folder.name);
            var url = scope.apiUrl + '/api/files/folder/' + $rootScope.user._id + '/' + folder.id;
            //console.log(helpers.tims() + 'Full url: '+ url + ' and scope url ' + scope.apiUrl);
            window.open(url);
            scope.setActiveType('editingCollapsedCog', null);
        }

        scope.checkBlur = function(ind, cb, customInlineCitation) {
            //console.log(helpers.tims() + 'blurred');
            if (cb) {
                cb(customInlineCitation);
            } else {
                scope.saveChanges(ind, scope.user.folders[ind].name);
            }
        };

        scope.checkKey = function(evt, ind, cb, customInlineCitation) {
            //console.log(helpers.tims() + 'Got event:' + evt);
            if (evt.keyCode == 13) {
                if (cb) {
                    cb(customInlineCitation);
                } else {
                    scope.saveChanges(ind, scope.user.folders[ind].name);
                }
            }
        };

        scope.isExpanded = function() {
            if (scope.activeFlags['citationsOpen']==false) {
                return 'closed';
            }
        }
        scope.clickFolder = function(folder, ind, open) {
            open = open || true;
            scope.activeFlags['citationsOpen'] = open;
            scope.setActiveType('editingCollapsedCog', null);
            if (scope.isActive('activeFolder', folder)) {
                scope.setActiveType('activeFolder',null);
            }
            else {
                scope.setActiveType('activeFolder', folder);
                var obj = document.getElementById('editFolder_' + ind);
                obj.focus();

            }
        }

        //Used to dynamically calculate citationOrg height - not currently being used
        scope.headerHeight = '0px';
        scope.delaySpeed = '1s';

        var setStyle = _.debounce(function(){
            var h = helpers.getHeaderHeight().toString() + 'px';
            //console.log('setHeaderHeight',h)
            $timeout(function(){
                scope.delaySpeed = scope.headerHeight > h ? '1s' : '0s';
                scope.headerHeight = h;
            }, 0);
        }, 100);
        setStyle();

        scope.$on('headerChange', setStyle);
        scope.$on('updateHeaderState', setStyle);
        scope.$on('unhideHeader', function(){ $timeout(setStyle, 1000) });
        var getSelectionBody = helpers.getSelectionBody;

        var generateCitationText = function(citation) {

            if (!citation) {
                return 'Loading...';
            }
            var selectionBody = helpers.getSelectionBody(citation.selection, 'text', 40, '.;~?:');
            //console.log('Selection body:', selectionBody);
            var returnString = '<div class="citation">' +
            '<div class="citationIter">'  + (citation.displayedOrder+1) + '</div>' +
            '<div class="citationHeader">'  + citation.citation +'</div>' +
            '<div class="text-row">' + selectionBody + '</div>' +
            '</div>';
            return returnString;
        }
    }
  };
}]);
