
angular.module('koh').directive('definitionModal', function($timeout) {
  return {
    transclude: true,
    templateUrl: './definition-modal/definition-modal.html',
    link: function (scope, element) {
        var container = element[0].querySelector('.additionContainer');
        scope.cleanResults = function( text ){
            text = text.replace(/�/gi,'');
            // Add more filters here if necessary.
            return text;
        }
        scope.closeModal = function(){
            scope.definitionObj = null;
            scope.setActiveType('additionType',null);
        }
        scope.$watch('definitionObj', function(defObj) {
            scope.result = defObj;
            element[0].scrollTop = 0;
            container.scrollTop = 0;
        })
        scope.$on('$destroy', function(){
            scope.setActiveType('additionType',null);
        })
    }
  };
});
