'use strict';

var helpers = function () { return require('../libs/helpers'); };

angular.module('koh.users', ['ui.router'])
  .config(['$stateProvider', function($stateProvider) {
    // $stateProvider.state('users', {
    //       url: "/users",
    //       templateUrl: "/controllers/users/users.html",
    //       controller: 'UserCtrl',
    //       params: {
    //       },
    //       resolve: {
    //         helpers: helpers,
    //         authenticate: function(kohauth) {
    //           return kohauth.authenticate();
    //         } 
    //       }
    //     })
      $stateProvider.state('userselections', {
          url: "/users/selections",
          templateUrl: "/controllers/users/selections/selections.html",
          controller: 'UserSelectionsCtrl',
          params: {
          },
          resolve: {
            helpers: helpers,
            authenticate: function(kohauth) {
              return kohauth.authenticate();
            } 
          }
        })
      .state('userselections_references', {
          url: "/users/selections/references",
          templateUrl: "/controllers/users/selections/selections.html",
          controller: 'UserSelectionsCtrl',
          params: {
            selection: 'references'
          },
          resolve: {
            helpers: helpers,
            authenticate: function(kohauth) {
              return kohauth.authenticate();
            } 
          }
        })
      .state('userselections_bookmarks', {
          url: "/users/selections/bookmarks",
          templateUrl: "/controllers/users/selections/selections.html",
          controller: 'UserSelectionsCtrl',
          params: {
            selection: 'bookmarks'
          },
          resolve: {
            helpers: helpers,
            authenticate: function(kohauth) {
              return kohauth.authenticate();
            } 
          }
        })
      .state('userselections_highlights', {
          url: "/users/selections/highlights",
          templateUrl: "/controllers/users/selections/selections.html",
          controller: 'UserSelectionsCtrl',
          params: {
            selection: 'highlights'
          },
          resolve: {
            helpers: helpers,
            authenticate: function(kohauth) {
              return kohauth.authenticate();
            } 
          }
        })
      .state('userselections_notes', {
          url: "/users/selections/notes",
          templateUrl: "/controllers/users/selections/selections.html",
          controller: 'UserSelectionsCtrl',
          params: {
            selection: 'notes'
          },
          resolve: {
            helpers: helpers,
            authenticate: function(kohauth) {
              return kohauth.authenticate();
            } 
          }
        })
      .state('userselections_notes_note_id', {
          url: "/users/selections/notes/:note_id",
          templateUrl: "/controllers/users/selections/selections.html",
          controller: 'UserSelectionsCtrl',
          params: {
            selection: 'notes'
          },
          resolve: {
            helpers: helpers,
            authenticate: function(kohauth) {
              return kohauth.authenticate();
            } 
          }
        })
    }
]);
