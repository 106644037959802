angular.module('koh').directive('expandThumbnail', [
    '$compile',
    '$document',
    '$timeout',
    function ($compile, $document, $timeout) {

    var imageList = [];

    var link = function ($scope, element, attrs) {
        $scope.modalImg = null;

        $scope.openModal = function(){
            element.off('click', $scope.openModal);
            if (!$scope.modalImg){
                $scope.modalImg = angular.element('<div class="modal-img"><img src="' + attrs.fullSizeSrc +'"><i ng-click="closeModal()" class="koh-icon-times icon-circled clickable"></i></div>');
                $compile($scope.modalImg.contents())($scope);
                element.after($scope.modalImg);
            } else {
                $scope.modalImg.removeClass('hide');
            }
            $timeout(function(){
                $document.on('click', $scope.closeOnClick);
            }, 10);
        };

        $scope.closeModal = function(){
            $scope.modalImg.addClass('hide');
            $document.off('click', $scope.closeOnClick);
            element.on('click', $scope.openModal);
        }

        $scope.closeOnClick = function($event){
            if($scope.modalImg && !$scope.modalImg[0].contains($event.target)){
              $scope.closeModal();
            }
        }

        element.on('click', $scope.openModal);
    }
    return {
        restrict: 'A',
        link: link,
        scope: {}
    };
}]);
