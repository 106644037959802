// Note, when naming an angular directive, convert objects separated with dashes into camel case
// This is required!
var _ = require('lodash');
var helpers = function () { return require('../../libs/helpers'); };
helpers = helpers();

angular.module('koh').directive('searchBar', [
    '$rootScope',
    '$state',
    '$location',
    'doLogin',
    '$document',
    '$window',
    '$timeout',
    'searchService',
    'searchFactory',
    'dataService',
    'documentSearchFactory',
    'sliderService',
    '$q',
    '$stickyState',
function($rootScope, $state, $location, doLogin, $document, $window, $timeout, searchService, searchFactory, dataService, documentSearchFactory, sliderService, $q, $stickyState) {
    var controller = function($scope){
        $scope.user = $rootScope.user;
        $scope.email = '';
        $scope.password = '';
        $scope.location = $location;
        $scope.searchFactory = searchFactory;
        $scope.sliderService = sliderService;
        $scope.authors = [];
        $scope.$watch(function() { return $rootScope.currentHBLChapter }, function(){
          $scope.currentHBLChapter = $rootScope.currentHBLChapter;
        });
        $scope.forceSliderRedraw = $scope.sliderService.forceSliderRedraw($scope);
        $scope.onSliderChange = $scope.sliderService.onSliderChange($document, $scope);
        $scope.updateAvailableYears = $scope.sliderService.updateAvailableYears();
        $scope.documentSearchFactory = documentSearchFactory;

        /* set content url */
        $scope.templateUrl = getTemplateUrl($scope);

        var loadTemplate = function() {
            $scope.user = $rootScope.user;
            $scope.templateUrl = getTemplateUrl($scope);
            $scope.currentHBLChapter = $rootScope.currentHBLChapter;
        }

        $rootScope.$watch('user', function(){ loadTemplate() });
        $rootScope.$on('$locationChangeSuccess', function(){ loadTemplate() });

        $scope.setActiveType('bible', 'Old Testament')
        $scope.setActiveType('advSearch', 'Collections')


        $rootScope.$on("updateMenu", function(src,args) {
            var type = args.type;

            if( type == 'search' )
            {
                $scope.toggleObject.search = true;
            }

            if (args.state == 'toggle') {
                $scope.toggleObject[type] = !$scope.toggleObject[type];
                return;
            }
            if (_.isUndefined(args.ele)) {
                if ($scope.toggleObject[type]) {
                    $scope.toggleObject[type] = args.state;
                }
                return;
            }
        });
    };

    var link = function($scope, element, attrs){
        var defaultCollections = defaultContentTypes = defaultAuthors = {};
        $scope.email = '';
        $scope.password = '';


        $scope.toggleAnObject = $rootScope.toggleAnObject;

        $scope.searchFactory = searchFactory;
        $scope.searchHistory = [];

        $scope.searchModels = searchFactory.filters = setSearchModel($scope);
        $scope.tableOfContents = {};

        $scope.searchModels.authors = [{id:-1, name: "Start typing to see authors"}];

        $scope.isMobile = function() {
            return navigator.userAgent.toLowerCase().indexOf('mobile') !== -1;
        };

        $scope.findAuthors = function(query) {
          if (!query || query.length<2) {
            return;
          }
          $scope.searchModels.authors = [{id: -2, name: "Loading..."}];
    	    dataService.getAuthors(query).then(function(result) {
		        $scope.searchModels.authors = !result.authors || result.authors.length==0?
              [{id: -3, name: "No authors found for: '"+query+"'"}]:
              result.authors.map(function(name, idx) {
			          return {id:idx, name: name};
		          });
	        });
        };

      var promises = {
            collection: dataService.getCollections(),
            periodicalsYears: dataService.getPeriodicalsYears(),
            sentinelsYears: dataService.getSentinelsYears(),
        }

        $q.all(promises).then(function(result) {
            $scope.searchModels.collections = collections = result.collection;
            $scope.searchModels.collections.journals = result.periodicalsYears;
            $scope.searchModels.collections.sentinels = result.sentinelsYears;

            defaultPerMonths = dataService.getSelectedMonths();
            $scope.searchModels.periodicalsMonths = angular.copy(defaultPerMonths);
            $scope.searchModels.sentinelMonths = angular.copy(defaultPerMonths);

            defaultAuthors = angular.copy( $scope.searchModels.authors );
            defaultCollections = angular.copy( $scope.searchModels.collections );
            defaultContentTypes = angular.copy( $scope.searchModels.contentTypes );


            createTableOfContents(result).then(function(r){
                $scope.tableOfContents = r;
            });
        }, function(reason) {
            console.err(reason);
        });


        var getFolder = function() {
            return helpers.getFolder($scope.$state);
        };

        $scope.isPeriodical = function() {
            return helpers.isPeriodical($scope.$state);
        };

        $scope.isJournal = function() {
        var folder = getFolder();
            return folder === 'journals' || folder === 'periodicals';
        };

        $scope.isSentinel = function() {
            return getFolder() === 'sentinels';
        };

        $scope.navBackToSearch = function() {
          $scope.toggleObject.search= true;
          $scope.toggleObject.read= false;
          // get search params from existing sticky state if any
          var searchState = _.find($stickyState.getInactiveStates(), {name: 'search'});
          $state.go('search', searchState? searchState.locals.globals.$stateParams: {query: documentSearchFactory.currentSearch});
        };

        $scope.toggleObject = {};
        $scope.getFilteredBooks = function(data) {
            // Hacky for the moment i know
            var cleanData = [];
            _.each(data,function(row) {
                if (row.title.toLowerCase().indexOf('key to the')<0) {
                    cleanData.push(row);
                }
            });
            return cleanData;
        }
        $scope.doLogin = function() {
            var authObj = { email: this.email, password: this.password };

            doLogin(
                authObj,
                function( response ){
                    if( $rootScope.user )
                    {
                        helpers.toggleMenu($rootScope, false);
                        helpers.toggleSearch($rootScope, false);

                        return $state.go('home');
                    }

                    if( response.data && response.data.error )
                    {
                        this.error = response.data.error;
                        this.message = null;
                    }
                    else
                    {
                        this.error = 'The server may be unreachable at this time. Please try again later.';
                        this.message = null;
                    }

                    $state.go('login', response.data);
                }
            )
        };

        $scope.hideBar = function () {
            if (_.isUndefined(scope.toggleObject)) {
                return '';
            }
            if ($scope.toggleObject.search) {
                $scope.toggleObject.search = ''
                return '';
            }
            return '';
        }

        $scope.goToTOC = function(){
            $rootScope.$state.go('reader.book.chapter', {chapter: $scope.currentBook + '_0000', initial: false});
        }

        $scope.isNotTOC = function(){
            return $scope.currentChapter.substr($scope.currentChapter.length - 4) != '0000';
        }


        $scope.showSearch = function() {
            if ( (_.isUndefined($scope.currentBook) || $scope.currentBook==null) || $rootScope.forceSearch==true) {
                    return true;
            }
            return false;
        }

        $scope.advSearchOpen = false;
        $scope.toggleAdvSearch = function(){
            $scope.advSearchOpen = !$scope.advSearchOpen;
        }
        $scope.pMonths = false;
        $scope.sMonths = false;
        $scope.jAll = false;
        $scope.sAll = false;
        $scope.tooglePMonths = function(){
            if($scope.searchFactory.filters.collections.journals.year !== '') {
              $scope.pMonths = true;
            } else if ($scope.searchFactory.filters.collections.journals.year === '') {
              $scope.pMonths = false;
            }
        }
        $scope.toogleSMonths = function(){
          if($scope.searchFactory.filters.collections.sentinels.year !== '') {
            $scope.sMonths = true;
          } else if ($scope.searchFactory.filters.collections.sentinels.year === '') {
            $scope.sMonths = false;
          }
        }

        $scope.setPeriodicals = function(name,mName){
            var name =  name,
            mName = mName,
            per = $scope.searchModels.collections[name],
            month = $scope.searchModels[mName];

            per.months = [];

            _.each(month,function(row) {
                if(row.selected){
                    per.months.push(row.id);
                }
            })

            if( _.isEqual(per.months,[]) && _.isEqual(per.year,"")) {
                per.selected = false;
            }else per.selected = true;
        }

        $scope.submitSearch = function() {
            $scope.setPeriodicals('journals','periodicalsMonths');
            $scope.setPeriodicals('sentinels','sentinelMonths');

            $scope.historyOpen = false;
            // $scope.advSearchOpen = true;
            // console.log( $scope.searchModels);
            $state.go('search', { query: $scope.searchFactory.query, filters: $scope.searchModels },  {reload: true});
        }

        $scope.clearSearch = function(){
            $scope.searchModels = {};
            $scope.searchModels.collections = angular.copy( defaultCollections );
            $scope.searchModels.contentTypes = angular.copy( defaultContentTypes );
            $scope.searchModels.authors = defaultAuthors;
            $scope.searchModels.selectedAuthor = '';
            $scope.searchModels.periodicalsMonths = angular.copy(defaultPerMonths );
            $scope.searchModels.sentinelMonths = angular.copy(defaultPerMonths );
            $scope.searchFactory.filters = $scope.searchModels;
            // $scope.searchModels.dates = dates;
            // $scope.searchModels.months = months;
            // $scope.searchModels.years = years;
        }
        $scope.clearMonths = function(months){
          $scope.searchModels[months] = angular.copy(defaultPerMonths);
          switch (months) {
            case 'periodicalsMonths':
              $scope.tooglePMonths();
              if ($scope.searchModels.collections.journals.year != ('all' || '')) {
                $scope.searchModels.collections.journals.all = false;
              } else {
                $scope.searchModels.collections.journals.all = true;
                $scope.checkAllPeriodicals('journals','periodicalsMonths');
              }
              break;
            case 'sentinelMonths':
              $scope.toogleSMonths();
              if ($scope.searchModels.collections.sentinels.year != ('all' || '')) {
                $scope.searchModels.collections.sentinels.all = false;
              } else {
                $scope.searchModels.collections.sentinels.all = true;
                $scope.checkAllPeriodicals('sentinels','sentinelMonths');
              }
              break;
          }
        };

        $scope.$watch( 'searchModels', function(n,o){
            _.each(n.collections, function( book, key ){
                if( book.id && book.all )
                {
                    $scope.checkAll( book.id );
                }
            });
        }, true)

        $scope.checkAll = function(bookName){
            var book = $scope.searchModels.collections[bookName];
            var control = book.all;
            _.each(book.contents, function(content){
                _.each(content.sections, function(section){
                    section.selected = control;
                });
            });
        }

        $scope.checkAllPeriodicals = function(book,month){
            var book = $scope.searchModels.collections[book];
            if (book.all) book.year = 'all'; else book.year = '';
            var months = $scope.searchModels[month];
                _.each(months, function(month){
                    month.selected = book.all;
                })


        }

        $rootScope.header = $rootScope.header || {
            hiddenHeader : false
        };
        $rootScope.hideHeader = function(hidden){
            $rootScope.header.hiddenHeader = hidden;
            $rootScope.$broadcast('updateHeaderState', !hidden);
            if(!hidden) $rootScope.$broadcast('unhideHeader');
        }


        // $scope.hideHeader = function(){
        //     var header = $document.find('header');
        //     angular.element(header).addClass('hidden-header');
        //     angular.element($window).on('scroll', handleHeader);
        //     $rootScope.header.hiddenHeader = true;

        //     $scope.$on('destroy', function() {
        //         $scope.boundScroll.off('scroll', handleHeader);
        //     });
        // }

        // $scope.current = window.pageYOffset;
        // var handleHeader = function(){
        //     var previous = $scope.current;
        //     $scope.current = window.pageYOffset;
        //     var diff = previous - $scope.current;

        //     if (diff > 0){
        //         showHeader();
        //     }
        // }

        // var showHeader = function(){
        //     var header = $document.find('header');
        //     angular.element(header).removeClass('hidden-header');
        //     angular.element($window).off('scroll', handleHeader);
        //     $timeout(function(){
        //         $rootScope.header.hiddenHeader = false;
        //     }, 500);
        // }
        // $rootScope.showHeader = showHeader;

        $scope.toggleHistory = function() {
            if (!_.isUndefined($rootScope.user)&& $rootScope.user!=null) {
                $scope.searchHistory = $rootScope.user.searches;
            }
            $scope.historyOpen = !$scope.historyOpen;
        }

        // Specific to click-any-where-but-here
        $scope.hideHistory = function () {
            $scope.historyOpen = false;
        }

        $scope.showHistory = function () {
            return $scope.historyOpen;
        }

        $scope.showSearch = function() {
            if ( (_.isUndefined($scope.currentBook) || $scope.currentBook==null) || $rootScope.forceSearch==true) {
                    return true;
            }
            return false;
        }

        $scope.getFolder = function(){
            if(_.isUndefined($scope.currentBook)) return;
            return $scope.currentBook.toUpperCase() === 'SH' ? $scope.currentBook : $scope.currentFolder;
        }

        $scope.getTitle = function(){
            if ( !_.isUndefined($scope.chapterList) && !_.isUndefined($scope.currentIndex) ) {
                //console.log('Trying to set title based on chapter list', $scope.chapterList);
                //console.log('using current index:' + $scope.currentIndex);
                if ($scope.chapterList[$scope.currentIndex]) {
                    return $scope.chapterList[$scope.currentIndex].chapterTitle;
                }
            }
            return '';
        }

    };

    var getTemplateUrl = function($scope){
        var url = '', active = '';
        // if (!$rootScope.user||_.isUndefined($rootScope.user)) url = './directives/pages/search-bar/search-bar-loggedOut.html';
        if (!$scope.user){
            url = './directives/pages/search-bar/search-bar-loggedOut.html';
            active = 'loggedOut';
        }
        else if ($location.path().startsWith('/reader')){
            url = './search-bar/search-bar-books.html';
            active = 'books';
        }
        else {
            url = './search-bar/search-bar-search.html';
            active = 'search';
        }

        $scope.mode = active;
        return url;
    }

    var setSearchModel = function($scope){
        var searchModels = {};
        searchModels.collections = {};
        searchModels.contentTypes = dataService.getContentTypes();
        searchModels.authors = $scope.authors;
        searchModels.selectedAuthor = '';
        searchModels.dates = dataService.getDates();
        searchModels.months = dataService.getMonths();
        searchModels.years = dataService.getHistoricalYears();
        return searchModels;
    }

    var createTableOfContents = function(data){
        return new Promise(function(resolve, reject) {
            var toc = [];
            _.each(data.collection, function(row){
                if( !row.contents ) return;
                var book = angular.copy(row);

                // Flatten the book contents to one item
                book.contents = flattenArray(book.contents, 'sections');

                toc.push(book);
            });

            var promises = {
                periodicals: dataService.getPeriodicalsByYear(data.periodicalsYears.years[0]),
                sentinels: dataService.getSentinelsByYear(data.sentinelsYears.years[0])
            }

            $q.all(promises).then(function(result) {
                var p = {
                    all: false,
                    books: result.periodicals,
                    contents: result.periodicals,
                    folder: 'periodicals',
                    id: 'periodicals',
                    title: 'Journals'
                }
                var s = {
                    all: false,
                    books: result.sentinels,
                    contents: result.sentinels,
                    folder: 'sentinels',
                    id: 'sentinels',
                    title: 'sentinels'
                }
                toc.push(p, s)
                resolve(toc);
            })
        });
    }

    var flattenArray = function(arr, val){
        var newContent = arr.shift();
        _.each(arr, function(c){
            newContent[val] = _.union(newContent[val], c[val]);
        });
        return newContent[val]
    }

    return {
        replace: true,
        restrict: 'E',
        template: '<div ng-include="templateUrl"></div>',
        controller: controller,
        link: link
    }


}]).factory('sliderService', function () {
    var updateSliders = function(sliders, $scope){
        var slider = sliders[0],
            sticky = sliders[1],
            centerSpan = sticky.querySelectorAll('.rz-bubble')[3],
            sliderWrapper = slider.querySelector('.rz-selection').parentElement,
            stickyWrapper = sticky.querySelector('.rz-selection').parentElement,
            center = parseInt(centerSpan.style.left, 10) + (parseInt(centerSpan.offsetWidth, 10) / 2);

        if ($scope.searchModels.dates.min >= $scope.searchModels.dates.floor){
            stickyWrapper.style.left = sliderWrapper.style.left;
            stickyWrapper.style.width = (center - parseInt(sliderWrapper.style.left, 10)) + 'px';
        }
        if ($scope.searchModels.dates.max < $scope.searchModels.dates.center){
            var right = parseInt(slider.querySelectorAll('.rz-bubble')[3].style.left, 10),
                left = parseInt(slider.querySelectorAll('.rz-bubble')[2].style.left, 10);

            stickyWrapper.style.width = right !=  left ? (right - left) + 'px' : '0px';
        }
    };

    return {
        forceSliderRedraw : function($scope){
            return function(){
                setTimeout(function() {
                    $scope.$broadcast('rzSliderForceRender');
                }, 10);
            }
        },

        onSliderChange : function($document, $scope){
            return function(delay){
                var sliders = $document.find('rzslider');
                if (sliders.length != 2) return;

                if (delay){
                    setTimeout(function(){
                        updateSliders(sliders, $scope);
                    }, 500);
                } else{
                    updateSliders(sliders, $scope)
                }
            }
        },

        updateAvailableYears : function(){
            /* Future work:
            Update all options so options are disabled if they should not be selected.
            ie: If max is 1900, min cannot be set to 1901.
            May need to change years array to object array and then disable/enable properties each time min/max is updated?
            */
        }
    };
});
