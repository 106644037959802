'use strict';
var _ = require('lodash');
var helpers = require('./helpers');
/* To be replaced with restangular calls to stored data in DB */

angular.module('koh').factory('dataService',
  function($q, Restangular, $rootScope, $http){

    var getMonths = function(){
      return [
          "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"
      ]
    }
    var getSelectedMonths = function(){
      return [
          {"title":"January","id":"01"}, {"title":"February","id":"02"}, {"title":"March","id":"03"}, {"title":"April","id":"04"}, {"title":"May","id":"05"}, {"title":"June","id":"06"}, {"title":"July","id":"07"}, {"title":"August","id":"08"}, {"title":"September","id":"09"}, {"title":"October","id":"10"}, {"title":"November","id":"11"}, {"title":"December","id":"12"}
      ]
    }

    var getHistoricalYears = function(){
      var y = [];
      for (var i = 1883; i < 1923; i++){
          y.push(i);
      }
      return y;
    };

  	var getHistoricalBibleLessonYears = function(){
      var y = [];
      for (var i = 1910; i <= 1922; i++){
          y.push(i);
      }
      return y;
  	};

    var getLessonSubjects = function(){
      return new Promise(function(resolve, reject) {
        ;
        helpers.httpwrap($http, $rootScope.apiUrl, '/api/lessons/topics').then(function(data) {
          ;
          resolve(data);
        })
        .catch(function(err) {
          ;
          reject(err);
        });
      });
    };
            function loadLinks(book, isHBL) {
                  return new Promise(function(resolve, reject) {
                      isHBL = isHBL || false;
                      ;
                      var processLinkData = function(data) {
                         var links = [];
                         if (!_.isUndefined(data) && data != null) {
                            _.each(data, function(row) {
                              if (row.linkData) {
                                    var newLink = {
                                          section: null,
                                          selectionId: '',
                                          title: '',
                                          selection: [],
                                          folder:'',
                                          book: '',
                                          citation:'',
                                          _id:'',
                                          type: 'link'
                                    };
                                    newLink.section = 'tbd';
                                    newLink.citation = row.citation;
                                    newLink._id = row._id;
                                    newLink.book = row.parentBook;
                                    newLink.folder = row.parentFolder;
                                    if (row.linkData.data) {
                                          newLink.outgoing = row.linkData.data.ranges;
                                    }
                                    if (row.citationData) {
                                          newLink.selectionId = row.citationData._id;
                                          if (row.citationData.data) {
                                                newLink.incoming = row.citationData.data.ranges;
                                          }
                                    }
                                    newLink.data = row;
                                    if (newLink.data.linkData.error && newLink.data.linkData.error != 'none') {
                                          ;
                                    } else {
                                          links.push(newLink);
                                    }
                              }
                          });
                          ;//,$rootScope, 'DataService',1);
                          resolve(links);
                        } // END IF
                      } // END function


                      ;
                      var waitIterations = 20;
                      function wait() {
                        if (!$rootScope.documentLinks) {
                          if (waitIterations == 0) {
                              
                            } else {
                              
                              waitIterations--;
                              setTimeout(wait,250);
                            }
                          } else {
                          processLinkData($rootScope.documentLinks)
                        }
                      }

                      if (!isHBL) {
                        
                        wait();//processLinkData($rootScope.documentLinks)
                      } else {
                        helpers.httpwrap($http, $rootScope.apiUrl, '/api/links/' + book).then(function(data) {
                          processLinkData(data)
                        });
                    }
                  //});
            }); // End promise

      } // END LOAD LINKS

      var fetchBookXml = function(folder, book) {
            return new Promise(function(resolve, reject) {
              ;
              helpers.httpwrap($http, $rootScope.apiUrl, '/api/books/' + folder + '/' + book + '/xml').then(function(data) {
                ;
                   $rootScope.extractLinksFromBookXml(data);
                    resolve(data);
              });
                /*Restangular.all('books').one(folder).one(book).one('xml').get().then(function(bookXml) {
                    $rootScope.extractLinksFromBookXml(bookXml);
                    resolve(bookXml);
                });
              */
            });
      };

      var getSearchModel = function() {
          ;
            return new Promise(function(resolve, reject) {
              var finalCb = function(data) {
                var collection = {};
                _.each(data, function(row){
                    if(row.title == 'Science and Health'){
                        row.id = 'SH';
                        row.folder = row.folder + '/SH';
                    } else {
                        row.id = row.folder;
                    }
                    collection[row.id] = row;
                    collection[row.id].all = false;
                });
                resolve(collection);
              } // End FinalCB
              ;
               helpers.httpwrap($http, $rootScope.apiUrl, '/api/search/books').then(function(data) {
                if (data.chunk) {
                  ;
                  helpers.getAllChunks($http, $rootScope, 'bookChunks', 'search_all', data, function(chunkData) {
                    finalCb(chunkData);
                  });
                } else {
                  finalCb(data);
               }
              }); // End http Wrap
            }); // end inner promise
      } // end getSearchModel

      var getPeriodicalModel = function() {
            return new Promise(function(resolve, reject) {
               helpers.httpwrap($http, $rootScope.apiUrl, '/api/periodicals').then(function(data) {
                if (data.chunk) {
                  helpers.getAllChunks($http, $rootScope, 'bookChunks', 'search_periodicals', data, function(chunkData) {
                    resolve(chunkData);
                  });
                } else {
                  resolve(data);
               }
              }); // End http Wrap
            }); // end inner promise
      } // end getPeriodicalModel

      var getSentinelModel = function() {
            return new Promise(function(resolve, reject) {
               helpers.httpwrap($http, $rootScope.apiUrl, '/api/sentinels').then(function(data) {
                if (data.chunk) {
                  helpers.getAllChunks($http, $rootScope, 'bookChunks', 'search_sentinels', data, function(chunkData) {
                    resolve(chunkData);
                  });
                } else {
                  resolve(data);
               }
              }); // End http Wrap
            }); // end inner promise
      } // end getSearchModel

      var getPeriodicalYearsModel = function() {
          return new Promise(function(resolve, reject) {
              helpers.httpwrap($http, $rootScope.apiUrl, '/api/periodicals/years').then(function(data) {
                     resolve({
                          title: 'Journals',
                          id: 'journals',
                          folder: 'journals',
                          all: false,
                          selected: false,
                          year: '',
                          months: [],
                          years: data
                      });
              }); // End http Wrap
          }); // end inner promise
      } // end getPeriodicalYearsModel

      var getSentinelYearsModel = function() {
          return new Promise(function(resolve, reject) {
              helpers.httpwrap($http, $rootScope.apiUrl, '/api/sentinels/years').then(function(data) {
                  resolve({
                      title: 'Sentinels',
                      id: 'sentinels',
                      folder: 'sentinels',
                      all: false,
                      selected: false,
                      year: '',
                      months: [],
                      years: data
                  });
              }); // End http Wrap
          }); // end inner promise
      } // end getSentinelYearsModel

    var getAuthorsModel = function(query) {
      return new Promise(function(resolve, reject) {
        helpers.httpwrap($http, $rootScope.apiUrl, '/api/authors?name='+escape(query)).then(function(data) {
          resolve(data);
        }); // End http Wrap
      }); // end inner promise
    }; // end getAuthorsModel

    var getCollections = function(){
      return $q(function(resolve, reject) {
          $rootScope.getCacheValue('searchmodel', 'all', getSearchModel).then(function(data) {
                ;
                resolve(data);
          });
      }); // End $q
    };

    var getPeriodicalsByYear = function(year){

      var cb = function() {
        return new Promise(function(resolve, reject) {
          var months = getMonths();
          helpers.httpwrap($http, $rootScope.apiUrl, '/api/periodicals/years/' + year).then(function(data) {
            _.each(data, function(row){
              row.title = months[row.publicationMonth - 1];
              row.fileName = row.sectionFolder + "_0000";
              row.folder = row.sectionFolder;
              row.item = [];
            })
            resolve(data);
          }); // End http Wrap
        }); // end inner promise
      } // end getPeriodicalModel

      return $q(function(resolve, reject) {
        $rootScope.getCacheValue('periodicalmonths', year, cb).then(function(data) {
            resolve(data);
        });
      }); // End $q
    }

    var getSentinelsByYear = function(year){

      var cb = function() {
        return new Promise(function(resolve, reject) {
          var months = getMonths();
          helpers.httpwrap($http, $rootScope.apiUrl, '/api/sentinels/years/' + year).then(function(data) {
              var sentinels = [];
              _.each(months, function(month, i){
                var s = {};
                s.title = month;
                s.parentFolder = 'sentinels';
                s.item = _.filter(data, { 'publicationMonth': i + 1});
                _.each(s.item, function(row){
                  var date = new Date(row.publicationDate)
                  row.title = helpers.parseDate(date);
                  row.fileName = row.sectionFolder + "_0000";
                  row.folder = row.sectionFolder;
                  row.open = false;
                });
                if (s.item.length > 0){
                  sentinels.push(s);
                }
              });
            resolve(sentinels);
          }); // End http Wrap
        }); // end inner promise
      } // end getSentinelModel

      return $q(function(resolve, reject) {
        $rootScope.getCacheValue('sentinelmonths', year, cb).then(function(data) {
            resolve(data);
        });
      }); // End $q
    };

    var getAuthors = function(query){
      return $q(function(resolve, reject) {
//        $rootScope.getCacheValue('authors', 'all', getAuthorsModel).then(function(data) {
        getAuthorsModel(query).then(function(data) {
          resolve(data);
        });
      }); // End $q
    };

      var getPeriodicalsYears = function(){
          return $q(function(resolve, reject) {
              $rootScope.getCacheValue('periodicalyearsmodel', 'all', getPeriodicalYearsModel).then(function(data) {
                  resolve(data);
              });
          }); // End $q
      }

      var getSentinelsYears = function(){
          return $q(function(resolve, reject) {
              $rootScope.getCacheValue('sentinelyearsmodel', 'all', getSentinelYearsModel).then(function(data) {
                  resolve(data);
              });
          }); // End $q
      }


    var getContentTypes = function(){
      return [
        {name: 'Articles', selected: false},
        {name: 'Churches and Associations', selected: false},
        {name: 'Editorials', selected: false},
        {name: 'Images', selected: false},
        {name: 'Lectures', selected: false},
        {name: 'Letters', selected: false},
        {name: 'Mary Baker Eddy\'s Writings', selected: false},
        {name: 'New Testament', selected: false},
        {name: 'Notices', selected: false},
        {name: 'Old Testament', selected: false},
        {name: 'Other', selected: false},
        {name: 'Poems', selected: false},
        {name: 'Quotes', selected: false},
        {name: 'Table of Contents', selected: false},
        {name: 'Testimonies of Healing', selected: false},
        {name: 'Title Pages', selected: false}
      ]
    };

    var getDates = function(){
      return {
        min: 1883,
        max: 1922,
        ceil: 1922,
        floor: 1883,
        center: 1910
    }}

    var getBookListing = function(collectionObj){
      var list = {};
      _.each(collectionObj, function(folder){
        var folderObj = folder;
        var books = [];
        if(folder.id != 'SH'){
          _.each(folder.contents, function(c){
            books = books.concat(c.sections);
          });
        }
        folderObj.books = books;
        list[folder.id] = folderObj;
      });
      return list;
    };


  	/* Public Methods */
  	return{
      getMonths: getMonths,
      getSelectedMonths: getSelectedMonths,
      getHistoricalYears: getHistoricalYears,
  	  getHistoricalBibleLessonYears: getHistoricalBibleLessonYears,
      getLessonSubjects: getLessonSubjects,
      getCollections: getCollections,
      getPeriodicalsYears: getPeriodicalsYears,
      getSentinelsYears: getSentinelsYears,
      getPeriodicalsByYear: getPeriodicalsByYear,
      getSentinelsByYear: getSentinelsByYear,
      getContentTypes: getContentTypes,
      getAuthors: getAuthors,
      getDates: getDates,
      getBookListing: getBookListing,
      getSearchModel: getSearchModel,
      fetchBookXml:fetchBookXml,
      loadLinks:loadLinks
  	}

  });
