var _ = require('lodash');

angular.module('koh').controller('DictionaryCtrl',
	['$scope',
	'helpers',
	'Restangular',
	'$rootScope',
	'$state',
	'$stateParams',
	'$http',
	'apiUrl',
	'$location',
	'searchService',
	function(
		$scope,
		helpers,
		Restangular,
		$rootScope,
		$state,
		$stateParams,
		$http,
		apiUrl,
		$location,
		searchService
	) {
		$scope.window = window;
		$scope.defineQuery = $stateParams.query;
		$scope.totalResults = 0;
		$scope.page = 0;
		$scope.results = [];
    $scope.groups = [];
		$scope.isSearching = false;
		$scope.searched = false;
		helpers.toggleMenu($rootScope, false);

		$scope.cleanResults = function( text ){
			text = text.replace(/�/gi,'');
			// Add more filters here if necessary.
			return text;
		};

		$scope.define = function( page ){
			count = 20;

			if( !page )
			{
				$scope.results = [];
				$scope.page = 0;
			}

			if( $scope.totalResults > 0 && $scope.page == $scope.totalResults ) return;

			$location.path( '/define/' +  $scope.defineQuery );

			$scope.isSearching = true;

			Restangular.one( 'define', $scope.defineQuery ).one(''+count).one(''+$scope.page).get().then(function( results ){
				if( results )
				{
					$scope.totalResults = results.numFound;

          /* Adding title to easy sort */
          results.docs = _.each(results.docs, function(x) {
            _.each(x.word, function(word) {
              x.title = word;
            });
          });

          Array.prototype.push.apply( $scope.results, results.docs );

          /* Get Groups from results */
          $scope.groups = _.pluck(_.sortBy(_.uniq($scope.results, 'source'), 'source'), 'source');

          /* Check exact match by double quotes */
          if($scope.defineQuery[0] == '"' && $scope.defineQuery[$scope.defineQuery.length - 1] == '"') {
            /* Get exact matched results */
            var exactRes = _.filter($scope.results, function(x) {
              return x.title.toLowerCase() === $scope.defineQuery.toLowerCase().replace(/["']/g, "");
            });

            /* Get similar results */
            var similarRes = _.sortBy(_.difference($scope.results, exactRes), 'title');

            /* Update results */
            $scope.results = exactRes;
            _.each(similarRes, function(x) {
              $scope.results.push(x);
            });
          } else {
            $scope.results = _.sortBy($scope.results, 'title');
          }

          $scope.page += results.docs.length;
				}
				$scope.isSearching = false;
				$scope.searched = true;
			})
		};

		if( $scope.defineQuery ) $scope.define();
	}
]);
