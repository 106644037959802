'use strict';

var helpers = function () { return require('../libs/helpers'); };

angular.module('koh.promo', ['ui.router'])
  .config(['$stateProvider', function($stateProvider) {
    $stateProvider.state('promo-index', {
      url: "/content",
      templateUrl: "/controllers/promo/promo.html",
      controller: 'PromoCtrl',
      params: {
      },
      resolve: {
        helpers: helpers,
        authenticate: function(kohauth) {
          return kohauth.headerAuthenticate();
        }
      }
    })
    .state('promo-content', {
      url: "/content/{url:[a-zA-Z0-9/_-]*}",
      templateUrl: "/controllers/promo/promo.html",
      controller: 'PromoCtrl',
      params: {
      },
      resolve: {
        helpers: helpers,
        authenticate: function(kohauth) {
          return kohauth.headerAuthenticate();
        }
      }
    });
  }]);