'use strict';
var helpers = require('./helpers');

angular.module('koh').factory('kohauth',
  function($q,
    $timeout,
    $http,
    $location,
    $rootScope,
    $state,
    apiUrl) {
  // Initialize a new promise
    return {
        authenticate: function () {
          var deferred = $q.defer();
          $rootScope.isLoggedIn = $rootScope.isLoggedIn || null;

          //Make an AJAX call to check if the user is logged in
          // sk: use a random url to prevent Safari from caching loggedin request
          // sk:  we have response header 'Cache-Control' correctly set but it doesn't help
          $http.get( apiUrl + '/api/loggedin?nche='+Math.random(), { cache: false }).success(function(user) {
            if (user && !user.error) {
              // Occasionally on livereload, we lose our user
              if (_.isUndefined($rootScope.user)||$rootScope.user == null) {
                $rootScope.user = user;
                if ($rootScope.advancedMode == false) {
                  $rootScope.user.usertype = 'casual';
                }
              }
              $rootScope.isLoggedIn = true;
              deferred.resolve();
            }

            // Not Authenticated
            else {
              $rootScope.isLoggedIn = false;
              deferred.reject();
              $state.go('login', { redirecturl: 'users'});
            }
          })
          .error(function(err) {
              deferred.reject();
              $state.go('login', { redirecturl: 'users'});
          });
          return deferred.promise;
        },
        homeAuthenticate: function () {
          var deferred = $q.defer();
          $rootScope.isLoggedIn = $rootScope.isLoggedIn || null;
          //Make an AJAX call to check if the user is logged in
          $http.get( apiUrl + '/api/loggedin?nche='+Math.random(), { cache: false } ).success(function(user) {
            if (user && !user.error) {
              // Occasionally on livereload, we lose our user
              if (_.isUndefined($rootScope.user)||$rootScope.user == null) {
                $rootScope.user = user;
                if ($rootScope.advancedMode == false) {
                  $rootScope.user.usertype = 'casual';
                }
              }
              $rootScope.isLoggedIn = true;
              $state.go('home', { redirecturl: 'users'});
            }

            // Not Authenticated
            else {
              //console.log(helpers.tims() + 'Not authenticated');
              $rootScope.isLoggedIn = false;
              deferred.resolve();
            }
          })
          .error(function(err) {
            $rootScope.isLoggedIn = false;
            deferred.resolve();
          });
          return deferred.promise;
        },
        headerAuthenticate: function () {
          var deferred = $q.defer();
          $rootScope.isLoggedIn = $rootScope.isLoggedIn || null;

          //Make an AJAX call to check if the user is logged in
          $http.get( apiUrl + '/api/loggedin?nche='+Math.random(), { cache: false } ).success(function(user) {
            if (user && !user.error) {
              // Occasionally on livereload, we lose our user
              if (_.isUndefined($rootScope.user)||$rootScope.user == null) {
                $rootScope.user = user;
                if ($rootScope.advancedMode == false) {
                  $rootScope.user.usertype = 'casual';
                }
              }
              $rootScope.isLoggedIn = true;
              deferred.resolve();
            }

            // Not Authenticated
            else {
              $rootScope.isLoggedIn = false;
              deferred.resolve();
            }
          })
          .error(function(err) {
            $rootScope.isLoggedIn = false;
            deferred.resolve();
          });
          return deferred.promise;
        }
      }
  });
