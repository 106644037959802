angular.module('koh').controller('SentinelCtrl', 
		['$scope', 
		'helpers', 
		'Restangular', 
		'$state',
		'$stateParams',
		'$rootScope',
		'$location',
		'apiUrl',
		'$timeout',
		'dataService',
		function(
			$scope, 
			helpers, 
			Restangular,
			$state,
			$stateParams,
			$rootScope,
			$location,
			apiUrl,
			$timeout,
			dataService
			) {	

	$scope.apiUrl = apiUrl;
	$scope.isActive = function(volume) {
		return (volume == $scope.shownVolume);
	}
	$scope.activateVolume = function(volume) {
		$scope.shownVolume = volume;
	}
	var sentinelsObject = Restangular.all('sentinels');
	// TODO : Remove all instances of linksobj
	
	$scope.shownVolume = 0;
	$scope.journalData = [];
	var months = ['', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep','Oct','Nov', 'Dec'];
	$scope.getMonth = function(monthId) {
		return months[monthId];
	}
	var deDupeAndSort = function(arr) {
		var newArr = [];
		

		for (var i = 0 ; i < arr.length ; i++) {
			if (!_.find(newArr, function(row) {
				return row.file == arr[i].file;
				}))
				 {
				newArr.push(arr[i]);
			}
		}

		newArr = _.sortBy(newArr, 'rowDate');
		return newArr;
	}
	var getMaxJournal = function(data) {
		var maxJournal =0;
		_.each(data, function(row) {
			
			if (row.volume > maxJournal) {
				maxJournal = row.volume;
			}
		});
		return maxJournal;
	}
	$scope.cleanJournals = [];
	var foundJournal = function(data, volume) {
		return _.find(data, {volume:volume});
	}
	sentinelsObject.getList().then(function(volumeList) {
	var maxJournal = getMaxJournal(volumeList);
		;
		for (var i = 1 ; i <= maxJournal ; i++) {
			var journalObject = foundJournal($scope.cleanData, i);
			var foundObject = true;
			if (!journalObject) {
				journalObject = {};
				foundObject = false;
				journalObject.volume = i;
				journalObject.name = 'Sentinal Vol ' + '(';
				journalObject.lastDate = 0;
				journalObject.firstDate = 99999999999999;
				journalObject.issues=[];
			}

			_.each(volumeList, function(row) {			
			var tmpRow = row;	
				if (row.volume == i) {
					_.each(row.issues, function(issueRow) {
						var rowDate = issueRow.publicationMonth + issueRow.publicationYear*12;
						journalObject.rowDate = rowDate;

						if (rowDate > journalObject.lastDate) {
							journalObject.lastDate = rowDate;
							journalObject.lastPublicationYear = issueRow.publicationYear;
							journalObject.lastPublicationMonth = issueRow.publicationMonth;
						}
						if (rowDate < journalObject.firstDate) {
							journalObject.firstDate = rowDate;
							journalObject.firstPublicationYear = issueRow.publicationYear;
							journalObject.firstPublicationMonth = issueRow.publicationMonth;
						}
						if (journalObject.lastDate!=0) {
							foundObject = true;
							journalObject.issues.push({issue: issueRow.issueNumber, rowDate: rowDate, file:tmpRow.file, publicationMonth:issueRow.publicationMonth, publicationYear: issueRow.publicationYear});
						}
						else 
							foundObject = false;
					});
				}
			});
			journalObject.name += months[journalObject.firstPublicationMonth] + ' ' + journalObject.firstPublicationYear;
			var newIssues = deDupeAndSort(journalObject.issues)
			journalObject.issues = newIssues;
			journalObject.name += ' - ' + months[journalObject.lastPublicationMonth] + ' ' + journalObject.lastPublicationYear + ')';
			if (foundObject) {
				$scope.cleanJournals.push(journalObject);
			}
		}
		;
		$scope.volumeList = volumeList;
	});
}]);

