var cheerio = require('cheerio');

angular.module('koh').controller('BooksCtrl',
		['$scope',
		'helpers',
		'Restangular',
		'$state',
		'$stateParams',
		'$rootScope',
		'$location',
		'apiUrl',
		'$timeout',
		'booksService',
		'selectionService',
		'dataService',
		'searchService',
		'bookXML',
		'selectionsObj',
		'collectionObj',
		function(
			$scope,
			helpers,
			Restangular,
			$state,
			$stateParams,
			$rootScope,
			$location,
			apiUrl,
			$timeout,
			booksService,
			selectionService,
			dataService,
			searchService,
			bookXML,
			selectionsObj,
			collectionObj
			) {

	$scope.apiUrl = apiUrl;


	var booksObject = Restangular.all('books');
	// TODO : Remove all instances of linksobj
	var selectionsObject = Restangular.all('selections')
	var userdataObject = selectionsObject.one($scope.user._id);

	var filesObject = Restangular.all('files');

	$scope.booksService = booksService;
	$scope.booksService.init($scope, $stateParams, userdataObject);

	$scope.selectionService = selectionService;

	$scope.myVar = 5;

	$scope.path = $location.path();

	var currentReadChapter = null;
	$rootScope.$watch('pageText', function(oldVal, newVal) {
		if ($rootScope.pageText) {
			helpers.logMessage('Page text updated:' + $rootScope.pageText.length, $rootScope, 'BookCtrl', 1)
			currentReadChapter = $rootScope.readingData.currentChapter;
			$scope.currentChapterData = $rootScope.pageText;
			$scope.booksService.matchLinks($scope.currentChapterData);
			$scope.getNotes();

			// console.log( 'Reading Data', $rootScope.readingData );
			// console.log( 'Chapter Data', $scope.currentChapterData );
		}
	});

	$rootScope.isHBL = false;
	$scope.citationList = null;

	$scope.currentBook = null;
	$scope.currentChapter = null;
	$scope.currentIndex = null;
	$scope.currentChapterData = null;
	$scope.prevChapterData = null;
	$scope.errorMessage = null;
	$scope.successMessage = null;
	$scope.selectionText = '';
	$scope.currentSearch = '';
	$scope.textSearch = '';
	$scope.currentLocation = 0;
	$scope.currentMarkedUpChapterData = null;
	$scope.currentNote = null;


	$scope.linkObj = null;
	$scope.message = null;
	$scope.showLinks = false;
	$scope.activeLink = null;
	$scope.addedBookMark = false;
	$scope.currentSelection = null;
	$scope.actualSearch = '';

	$scope.currentChapterNumber = 1;

	$scope.constructUrlFromCitation = function(citation) {
		;
	}

	$scope.loadBookList = function() {
		booksObject.getList().then(function(bookList) {
			// $scope.bookList = bookList;
			$scope.bookList = [
				{
					title: 'The Bible',
					link: '/books/bible'
				},
				{
					title: 'Science and Health',
					link: '/books/SH'
				},
				{
					title: 'Mrs. Eddy\'s Other Writings',
					link: '#'
				}
			]
		});
	}
	$scope.getFile = function(obj) {
		if(typeof obj === 'string')
			return obj.split('.')[0];
	}

	$scope.loadBookList();

	$scope.showIndex = function() {
		return (!$scope.currentBook && !$scope.currentChapter);
	}

	$scope.saveLinkData = function(link) {
		link.put().then(function(data) {
			// console.log('Did put');
		});
	}
    // This may be specific to each book!
	$scope.charactersPerLine = 60;
	$scope.lineNumbers = '';

	// Kludgy until we do the citaition bits
	$scope.determineLine = function(chapterData, search) {
		var doc = cheerio.load(chapterData);
		var eleArray = doc('*');

		// Its a 1 based index
		// Root counts for 1.
		// Earliest line it could appear is 2
		var lineCount = 2;
		var bFound = false;
		//_.each(eleArray, function(row) {
		for (var eleI = 0 ; eleI < eleArray.length ; eleI++) {
			var row = eleArray[eleI];
			$scope.rcFindData(row, function(obj) {
				if (!_.isUndefined(obj.data)&&obj.data!=null) {
					if (obj.data.indexOf(search)>=0) {

						bFound = true;
						return lineCount;
					}
					if (bFound==false) {
						var innerArr = obj.data.split(' ');
						var iCurrentWord = 0;
						var CurrentString = '';
						var originalArrLength = innerArr.length;
						for (var i = 0 ; i < originalArrLength ; i++) {
							if (CurrentString.length+innerArr[i].length > $scope.charactersPerLine) {
								CurrentString = innerArr[i];
								lineCount++;
								i++;
							}
							else {
								CurrentString+=innerArr[i] + ' ';
							}
						}
						var newString = innerArr.join('');
						obj.data = innerArr.join(' ');
					}
				}
			})
		}
		return lineCount;
	};

	$scope.getLineNumbers = function(lineCount) {
		for (var i = 1 ; i <+ lineCount ; i++) {
			$scope.lineNumbers+=i + ' ';
		}
	};

	$scope.constrainLines = function(chapterData) {
		//chapterData = chapterData.replace(/<pre^>/ig,"");
		//chapterData = chapterData.replace(/\<(?!hr|p|br|style|\/style).*?\>/g, "");
		return chapterData;
		var returnChapterData = '';
		var doc = cheerio.load(chapterData);
		var eleArray = doc('*');
		var currArray = [];

		// Cheerio has the root object which has tite data, etc.
		// Thus doc('*') actually starts in the body
		// Will need to separately count lines if we wish to include title
		var lineCount = 2;
		_.each(eleArray, function(row) {
			$scope.rcFindData(row, function(obj) {
				if (!_.isUndefined(obj.data)&&obj.data!=null) {
					var innerArr = obj.data.split(' ');
					var iCurrentWord = 0;
					var CurrentString = '';
					var originalArrLength = innerArr.length;
					for (var i = 0 ; i < originalArrLength ; i++) {
						if (CurrentString.length+innerArr[i].length > $scope.charactersPerLine) {
							CurrentString = innerArr[i];
							lineCount++;
							innerArr.splice(i, 0, 'NEWLINE');
							i++;
						}
						else {
							CurrentString+=innerArr[i] + ' ';
						}
					}
					var newString = innerArr.join('');
					obj.data = innerArr.join(' ');
				}
			})
		});
		$scope.getLineNumbers(lineCount);
		returnChapterData = doc.html();
		returnChapterData = returnChapterData.replace(/NEWLINE/g, '<br>');
		return returnChapterData;
	};

	$scope.rcFindData = function(obj, cb, newlineCount) {
		newlineCount = newlineCount || 0;
		if (obj.name == 'br') {
			newlineCount++;;
		}
		if (!_.isUndefined(obj.data)&&obj.data!=null) {

			// Force the newline count into the obj
			obj.newlineCount = newlineCount;
			cb(obj);
			return obj;
		}
		else {
			if (!_.isUndefined(obj.children)&&obj.children!=null) {
				var retObj = null;
				_.each(obj.children, function(row) {
					retObj = $scope.rcFindData(row, cb, newlineCount);

				});
				return retObj;
			}
			else {
				// console.log("EOL")
				return null;
			}
		}
	};

	var getLimitations = function() {
		;
		var obj = { startPage: 0, endPage: 9999};
		return obj;
	}


	var isPeriodical;
	function isPeriodicalFn() {
    if (isPeriodical === undefined) {
      var folder = $state.params.folder;
      // checking for 'journals' for future-proofing when we correct the
      // 'periodicals' vs. 'journals' terminology in the code
      isPeriodical = folder === 'sentinels' || folder === 'journals' || folder === 'periodicals';
    }
    return isPeriodical;
	};
	$scope.isPeriodical = isPeriodicalFn;

	var adjustReadingMode = function() {
	    // we don't support dual-page mode for periodicals
	    // so fall back to user preference or single-page
	    if('dual-page' === $rootScope.readingMode && isPeriodicalFn()){
			  var mode = $rootScope.user.readingMode === 'dual-page'? 'single-mode' : $rootScope.user.readingMode;
			  $scope.$parent.setReadingMode(mode);
	    }
	};

	$scope.selectBook = function(folder, book, xml) {
		// Auto close the open menu
		helpers.toggleMenu($rootScope, false);
		booksService.unsetRootScope();
		$scope.currentFolder = folder;
		$scope.currentBook = book;

		$scope.collection = dataService.getBookListing(collectionObj);

		var fullFile = book + '.pdf';
		$rootScope.pageLimitations = getLimitations();
		/*
		console.log('Fetching book info for start page and end')
		filesObject.one(fullFile).one('chapter', $scope.currentChapterNumber).get().then(function(data) {
			$scope.startPage = $scope.pageLimitations.start = data.start;
			$scope.endPage = $scope.pageLimitations.endPage = data.end;
		});*/
		var chapterList = null;

		$scope.booksService.setBookInformation(xml, folder, book);
		$scope.booksService.syncRootScope();

		adjustReadingMode();

		// default to first chapter
		try {
			if ($scope.path.endsWith($scope.currentBook) || $scope.path.endsWith($scope.currentBook + '/') ){
				$scope.currentChapter = $scope.chapterList[0].fileName;
				$scope.currentIndex = 0;
			      $scope.booksService.syncRootScope($scope);
			      $rootScope.$state.go('reader.book.chapter', {chapter: $scope.currentChapter, initial: true});
			}
		}
		catch(e) {
			
		}
		// $rootScope.exitingLesson = false;

		// initial load
		if ($scope.currentChapter && !$scope.currentIndex){
	      var index = helpers.findChapterIndex($rootScope.readingData.chapterList, $scope.currentChapter);
	      if (index > -1){
	      	;
	        $scope.currentIndex = index;
	      }
    }


		$scope.booksService.loadSelections(selectionsObj);
    $scope.booksService.syncRootScope();

    if($state.params.search && $rootScope.readingData) {
      var offCurrentBookDataReady = $scope.$on('currentBookDataReady', function (event, args) {
          offCurrentBookDataReady();
          searchService.setSearchHighlights($state.params.search,
            $rootScope.readingData.bookInfo.bookTitle, $rootScope.readingData.currentChapter);
        });
    }

		$rootScope.$broadcast('bookLoaded', {currentIndex:  $scope.currentIndex});
	};

	$scope.toggleShowLinks = function() {
		$scope.showLinks = !$scope.showLinks;
	};

	$scope.search = function(search) {
		$scope.currentSearch = search;
	};
	$scope.originalCurrentChapterData = null;

	var initialize = function() {
		if ($stateParams.linkid) {
				if ($scope.links) {
					var link = _.find($scope.links, {_id: $stateParams.linkid});
					$scope.currentBook = $stateParams.book = link.book;
					$scope.currentChapter = $stateParams.chapter = link.section;
					syncUrlToState();
				}
				else {
					;
					$scope.booksService.getLinks($rootScope.isHBL).then(function() {
						var link = _.find($scope.links, {_id: $stateParams.linkid});
						if (_.isUndefined(link)&&link!=null) {
							$scope.currentBook = $stateParams.book = link.book;
							$scope.currentChapter = $stateParams.chapter = link.section;
							$scope.booksService.syncUrlToState();
						}
						else {
							if ($scope.selections) {
								var link = _.find($scope.selections, {_id: $stateParams.linkid});
								$scope.currentBook = $stateParams.book = link.book;
								$scope.currentChapter = $stateParams.chapter = link.section;
								$scope.booksService.syncUrlToState();
							}
						}
					});
				}
			}
		else {
			$scope.selectBook($stateParams.folder, $stateParams.book, bookXML);
		}
	}

	initialize();

  function getBookScale() {
  	;
    // getting scale class only for 1st page assuming other ones are the same
    var chapter = $scope.chapterList[0];
    if (!chapter) {
      return "";
    }
    if (chapter.folder == 'bible') {
    	return 'scaleBibleOnePage';
    }
    if (chapter.book == 'SH') {
    	return 'scaleSHOnePage';
    }
    if (isPeriodicalFn()) {
    	return 'scalePeriodialOnePage';	
    }
    /*var clazz = isPeriodicalFn()? 'scaleX1 no-vertical-limit': // for periodicals
        chapter.folder != 'bible'? (chapter.book == 'SH'? 'scaleX1' : 'scaleX2'): // for books
        ""; // default scale class for bible
    console.log('Book scale class', clazz);
    */
    return 'scaleBookOnePage';
   // return clazz;
  }

  $scope.bookScale = getBookScale();
}]);
