angular.module('koh').directive('citationViewer', function() {
  return {
    transclude: true,
    templateUrl: './directives/pages/citation-viewer/citation-viewer.html',
    link: function (scope, element) {

        var mergeFlags = {
            editingCitation: null,
        };

       Object.keys(mergeFlags).forEach(function(key) {
            scope.activeFlags[key] = mergeFlags[key];
        });

       if (scope.linkObj) {
         scope.linkObj.title = scope.linkObj.citation;
         scope.projectNote = scope.getSelectionBody(linkObj.selection);
       }
       /*
       console.log('In citation viewer directive');
        //var ckObject = angular.element(document.querySelector('#ckObject'))[0];
        //CKEDITOR.replace('noteEditor')
        CKEDITOR.config.removeButtons = '';
        CKEDITOR.replace( 'projectNoteEditor', {
        toolbar: [
          { name: 'basicstyles', items: [ 'Bold', 'Italic', 'Underline','BulletedList' ] }
        ]
      });
    */
    }
  };
});
