var helpers = require('../../libs/helpers');

angular.module('koh').directive('bindCompiledHtml', ['$compile', '$rootScope', '$sanitize', '$sce', '$timeout', '$q', function ($compile, $rootScope, $sanitize, $sce, $timeout, $q) {
    var SKIPPED_CLASSES = ['.Line-right', '.Line-left', '.Basic-Paragraph', '.Sidebar-right', '.Sidebar', '.First-Second', '.Title', '.Chapter-Start-Page-Num', '.Title-2', '.Line-left-Misc', '.Line-right-Misc', '.RUNNING-HEADER-RIGHT'];
    var allowedParas = [
        'p.Normal',
        'p.Flush',
        'p.Chapter',
        'p.Chapter1',
        'p.Chapter-title',
        'p.Drop-normal',
        'p.TOC_Chapter-heading_no-space-under',
        '.TOC_Heading2_2K',
        '.Hang-1',
        '.Hang-center',
        '.Normal-Drop-Misc',
        '.Hang-1-space-above',
        '.toc-title a',
        'external-link'

    ]
    var getMobileHtml = function (element, isPeriodical){
        var p, newP,
            div = element.querySelector('div'),
            paras = getMobileParagraphs(element, isPeriodical),
            wrapper = document.createElement('div'),
            readingData = $rootScope.readingData,
            resPath;

          if(readingData){
              resPath =  readingData.currentFolder + '/' + readingData.currentBook;
          }

        wrapper.className = div.className;
        for (i = 0; i < paras.length; i++){
            p = paras[i];
            // newP = document.createElement('p');
            // newP.innerHTML = p.innerText;
            newP = p.cloneNode(true);
            newP.setAttribute('style', '');

            if(angular.element(newP).hasClass('sermon-table')){
                angular.element(newP).removeClass('sermon-table').addClass('mob-sermon-table');
                var tocLinks = newP.querySelectorAll('a');
                for (var j = 0; j < tocLinks.length; j++) {
                    if(tocLinks[j].getAttribute('data-periodical-link')){
                        tocLinks[j].setAttribute('href', '/reader/' + resPath + '/' +
                            tocLinks[j].getAttribute('data-periodical-link'));
                    }
                }
            }
            if (newP.hasChildNodes()){
              var children = newP.querySelectorAll('span');

              for (var j = 0; j < children.length; j++) {
                var id = children[j].id
                children[j].setAttribute('style', '');
                children[j].setAttribute('id', 'mobile_' + id);
              }
            }
            if(newP.getAttribute('type') == 'image'){
                var im = document.createElement('img');
                im.setAttribute('src', '/data/' + resPath + newP.getAttribute('src'));
                im.setAttribute('alt', newP.getAttribute('alt'));
                newP = im;
            }
             wrapper.appendChild(newP);
        }
        return wrapper;
    }

    var getMobileParagraphs = function(element, isPeriodical){
        var clone = element.cloneNode(true),
            skippedNodes = clone.querySelectorAll(SKIPPED_CLASSES.join(', ')),
            length = skippedNodes.length,
            tab =angular.element(clone.querySelectorAll('table'));

        /* remove any nodes that should be skipped */
        while(length--){
          skippedNodes[length].parentNode.removeChild(skippedNodes[length]);
        }
           return tab.hasClass('sermon-table journals-sentinels') ?
               clone.querySelectorAll('table') : (isPeriodical ?
               clone.querySelectorAll('p, h1, h2, a, external-link') :
               clone.querySelectorAll('p, h1, h2'));
    }


    var link = function (scope, element, attrs) {
        scope.resourcePath = '/static/scienceandhealth';
        scope.$watch(function () {
            return scope.$eval(attrs.bindCompiledHtml);
        }, function (value) {
            element.html(value);

            if(attrs.noMobile){
                $compile(element.contents())(scope);
            } else {
                var isPeriodical = helpers.isPeriodical(scope);
                setMobileText(isPeriodical).then(function(){
                    $compile(element.contents())(scope);
                })
            }
        });

        var setMobileText = function(isPeriodical){
            return $q(function(resolve, reject) {

                var epub = element[0].getElementsByTagName('div')[0];
                if (epub && epub.className != 'koh-loader'){
                    var mobileHtml = getMobileHtml(element[0], isPeriodical);

                    scope.mobileText = $sce.trustAsHtml(mobileHtml.outerHTML);

                    //force digest cycle before compiling element
                    $timeout(function(){
                        resolve();
                    });
                }
            }, function(err){
                ;
                reject();
            });
        };
    };

    return {
        restrict: 'A',
        link: link
    };
}]);
