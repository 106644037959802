angular.module('koh').controller('ForgotCtrl',
	['$scope',
	'helpers',
	'Restangular',
	'$stateParams',
	'$http',
	'$rootScope',
	'$location',
	'$state',
	'apiUrl',
		function($scope,
			helpers,
			Restangular,
			$stateParams,
			$http,
			$rootScope,
			$location,
			$state,
			apiUrl
	) {
		// $scope.email = '';
		$scope.token = $stateParams.token;
		$scope.error = $stateParams.error;
		$scope.apiUrl = apiUrl;
		helpers.toggleMenu($rootScope, false);

		if( $rootScope.user )
		{
			$state.go('home');
		}

		if ($stateParams.message && !$rootScope.user) {
			$scope.message = $stateParams.message;
		}

		if ($stateParams.logout) {
				$rootScope.user = null;
				$http.get(apiUrl + '/api/logout').then(function(err, data) {
			});
		}

		if( $stateParams.token && !$rootScope.user ){
			$http.get(apiUrl + '/api/reset/' + $stateParams.token)
			.then(function(response) {
				$scope.message = null;
				$scope.error = null;
			},
			function(response){
				$scope.error = response.data.error;
				$scope.message = null;
				$state.go('forgot', {error: response.data.error});
			});
		}

		$scope.resetPassword = function() {
			var authObj = { email: $scope.email };

			$http.post($scope.apiUrl + '/api/forgot', authObj).
				success(function(data, status, headers, config) {
					$scope.message = data.status;
					$scope.error = null;
			}).
				error(function(data, status, headers, config) {
					$scope.error = data.error;
					$scope.message = null;
					$location.url('/forgot');
			});
		};

		$scope.saveNewPassword = function() {
			var authObj = { token: $stateParams.token, user: $scope.user };

			$http.post($scope.apiUrl + '/api/reset', authObj).
				success(function(data, status, headers, config) {
					$scope.message = data.success;
					$scope.error = data.error;

					if( $scope.error )
					{
						$state.go('forgot', {error: data.error});
					}
					else if( $scope.message )
					{
						$state.go('login', {message: $scope.message})
					}
			}).
				error(function(data, status, headers, config) {
					$scope.error = data.error || data.data.error;
					$scope.message = null;
					if( $scope.error )
					{
						$state.go('forgot', {error: data.error});
					}
			});
		};

}])
