// Note, when naming an angular directive, convert objects separated with dashes into camel case
// This is required!

angular.module('koh').directive('heroCarousel', [
	'Restangular',
  '$sce',
  '$log',
  '$timeout',
	function(
    Restangular,
    $sce,
    $log,
    $timeout) {
  return {
    transclude: true,
    templateUrl: './directives/pages/hero-carousel/hero-carousel.html',
    link: function (scope, element) {

      scope.notLoaded = true;
      scope.carouselImages = null;
      scope.slideIndex = null;
      scope.isLocked = false;

    	Restangular.one('home', 'carouselImages').getList().then(function(data) {
	    	scope.carouselImages = data;
        scope.notLoaded = false;
	    }, function(response) {
			    ;
      });

      // Figcaption disappears on video slides - manually set z-index to bring it back to front
      scope.$watch('slideIndex', function(){
        if (!scope.slideIndex) return;
        scope.$broadcast('slideChange');
      });

      scope.$on('videoPaused', function(event, isPaused){
        scope.isLocked = !isPaused;
        if(isPaused){
          $timeout(function() {
            element.find('ul').triggerHandler('touchmove').triggerHandler('touchcancel');
          }, 100);
        }
      });

      scope.trust = function(html){
         return $sce.trustAsHtml(html);
      }

    }


  };
}]);