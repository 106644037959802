'use strict';

var helpers = function () { return require('../libs/helpers'); };

angular.module('koh.reader', ['ui.router'])
  .config(['$stateProvider', function($stateProvider) {

    $stateProvider.state('reader', {
      url: "/reader",
      parent: 'root',
      views: {
        'root-reader': {
          controller: 'ReaderCtrl',
          templateUrl: "/controllers/reader/reader.html"
        }
      },
      resolve: {
        helpers: helpers,
        authenticate: function(kohauth) {
          return kohauth.authenticate();
        }
      }
    })
    .state('reader.lesson', {
      url: "/lessons/:lesson",
      views: {
        "reader": {
          templateUrl: "/controllers/lessons/lessonReader.html",
          controller: 'LessonsCtrl',
        }
      },
      resolve: {
        helpers: helpers,
        authenticate: function(kohauth) {
          return kohauth.authenticate();
        },
        lessonObj: ['$stateParams', 'readerService', '$rootScope',
          function($stateParams, readerService, $rootScope) {
            $rootScope.currentLesson = $stateParams.lesson;
            return readerService.getLessonObj($stateParams.lesson);
        }],
        tocObj: ['$stateParams', 'readerService',
          function($stateParams, readerService) {
            return readerService.getLessonTOC($stateParams.lesson);
        }],
        playlistObj: ['$stateParams', 'readerService',
          function($stateParams, readerService) {
            return readerService.getPlayListObj($stateParams.lesson);
        }],
        selectionsObj: ['$rootScope', 'readerService',
          function($rootScope, readerService) {
            return readerService.getSelectionsObj($rootScope);
        }]
      }
    })
    .state('reader.lesson.chapter', {
      url: "/:chapter",
      views: {
        "chapter": {
          templateUrl: "/controllers/books/chapter.html",
          controller: 'ChapterCtrl'
        }
      },
      params: {
        initial: false,
        isScrolling: false
      },
      resolve: {
        helpers: helpers,
        authenticate: function(kohauth) {
          return kohauth.authenticate();
        }
      }
    })
    .state('reader.book', {
      url: "/:folder/:book",
      views: {
        "reader": {
          templateUrl: "/controllers/books/books.html",
          controller: 'BooksCtrl'
        }
      },
      resolve: {
        helpers: helpers,
        authenticate: function(kohauth) {
          return kohauth.authenticate();
        },
        bookXML: ['$stateParams', 'readerService',
          function($stateParams, readerService) {
            return readerService.getBookXml($stateParams.folder, $stateParams.book);
        }],
        selectionsObj: ['$rootScope', 'readerService',
          function($rootScope, readerService) {
            return readerService.getSelectionsObj($rootScope);
        }],
        collectionObj: ['$rootScope', 'dataService',
          function($rootScope, dataService) {
            return dataService.getCollections();
        }]
      }
    })
    .state('reader.book.chapterByReference', {
      url: "/ref/:chapter",
      views: {
        "chapter": {
          templateUrl: "/controllers/books/chapter.html",
          controller: 'ChapterCtrl'
        }
      },
      params: {
        initial: false,
        reference: true
      },
      resolve: {
        helpers: helpers,
        authenticate: function(kohauth) {
          return kohauth.authenticate();
        }
      }
    })
    //https://koh.local/reader/bible/Jer/Jer_0016/Jeremiah 8:7
    .state('reader.book.chapter', {
      url: "/:chapter",
      views: {
        "chapter": {
          templateUrl: "/controllers/books/chapter.html",
          controller: 'ChapterCtrl'
        }
      },
      params: {
        initial: false,
        isScrolling: false
      },
      resolve: {
        helpers: helpers,
        authenticate: function(kohauth) {
          return kohauth.authenticate();
        }
      }
    })
    .state('reader.book.citation', {
      url: "/:chapter/:citation",
      views: {
        "chapter": {
          templateUrl: "/controllers/books/chapter.html",
          controller: 'ChapterCtrl'
        }
      },
      params: {
        initial: false,
        isScrolling: false
      },
      resolve: {
        helpers: helpers,
        authenticate: function(kohauth) {
          return kohauth.authenticate();
        }
      }
    })
    .state('reader.book.chapter.search', {
      url: "/search/:search",
      views: {
        "chapter": {
          templateUrl: "/controllers/books/chapter.html",
          controller: 'ChapterCtrl'
        }
      },
      params: {
        initial: false,
        isScrolling: false
      },
      resolve: {
        helpers: helpers,
        authenticate: function(kohauth) {
          return kohauth.authenticate();
        }
      }
    })
    .state('writings', {
      url: "/writings",
      templateUrl: "/controllers/reader/writings.html",
      params: {
      },
      resolve: {
        helpers: helpers,
        authenticate: function(kohauth) {
          return kohauth.authenticate();
        }
      }
    })
    .state('find', {
      url: "/books/:folder/:book/:chapter/:search",
      templateUrl: "/controllers/books/books.html",
      controller: 'BooksCtrl',
      params: {
      },
      resolve: {
        helpers: helpers,
        authenticate: function(kohauth) {
          return kohauth.authenticate();
        }
      }
    })
    .state('link', {
      url: "/links/:linkid",
      templateUrl: "/controllers/books/books.html",
      controller: 'BooksCtrl',
      params: {
      },
      resolve: {
        helpers: helpers,
        authenticate: function(kohauth) {
          return kohauth.authenticate();
        }
      }
    });
  }]);
