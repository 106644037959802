angular.module('koh').directive('videoPlayer',
	function($compile, $sce, $document, $timeout) {

    // function Html5Player (el, onChange){
    //   this.player = el;

    //   this.pause = function(){
    //     this.player.pause();
    //   }

    //   this.play = function(){
    //     this.player.play();
    //   }

    //   this.setZIndex = function(){
    //     el.style.zIndex = "0";
    //   }

    //   return this;
    // }

    function Player (el, onChange){
      this.element = el;
      this.player = $f(el);
      this.ready = false;
      this.userInitiated = false;
      this.onChange = onChange;

      // When the player is ready, add listeners for pause and play
      this.player.addEvent('ready', this.onReady.bind(this));
    }

    Player.prototype.pause = function(){
      if(this.ready){
        this.userInitiated = true;
        this.post('pause');
        // this.player.api('pause');
      }
    }

    Player.prototype.play = function(){
      if(this.ready){
        this.userInitiated = true;
        this.post('play');
        // this.player.api('play');
      }
    }

    Player.prototype.setZIndex = function(){
      this.element.style.zIndex = "0";
    }

    Player.prototype.onPause = function () {
        if(this.userInitiated) this.userInitiated = false;
        else {
          this.onChange(true)
        }
    }

    Player.prototype.onPlay = function () {
        if(this.userInitiated) this.userInitiated = false;
        else {
          this.onChange(false)
        }
    }

    Player.prototype.onReady = function() {
      this.ready = true;
      // console.log('video ready');

      this.player.addEvent('pause', this.onPause.bind(this));
      this.player.addEvent('play', this.onPlay.bind(this));
      this.player.addEvent('finish', this.onPause.bind(this));
    }

    Player.prototype.post = function (action, value) {
      var playerOrigin = '*';
      var data = {
        method: action
      };

      if (value) {
          data.value = value;
      }

      var message = JSON.stringify(data);
      this.element.contentWindow.postMessage(message, playerOrigin);
    }

    function insertScript (src){
      if(!window.$f){
        var head = document.getElementsByTagName('head')[0];
        var script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = src;
        head.appendChild(script);
      }
    }

    function link (scope, element) {
      scope.videoPaused = true;

      if(scope.src){
        // insertScript('//f.vimeocdn.com/js/froogaloop2.min.js');
        insertScript('/js/libs/froogaloop.js');
        scope.$watch(function(){
          return (element.find('iframe').length > 0 && window.$f)
        }, function(newValue, oldValue){
          if (newValue){
            scope.video = new Player(element.find('iframe')[0], getOnChange());
          }
        })
      } else{
        // scope.video = new Html5Player(element.find('video')[0], getOnChange());
      }

      scope.controlVideo = function() {
        if (scope.videoPaused){
          scope.video.play();
        } else{
          scope.video.pause()
        }
        scope.videoPaused = !scope.videoPaused;
      }

      scope.pauseVideo = function($event, pauseOnly) {
        scope.video.pause()
        scope.videoPaused = true;
      }

      scope.trustHtml = function(html){
       return $sce.trustAsHtml(html);
      }

      scope.trustSrc = function(src) {
        return $sce.trustAsResourceUrl(src);
      }

      scope.$on('pauseAllVideos', scope.pauseVideo);

      // Figcaption disappears on video slides - manually set z-index to bring it back to front
      scope.$on('slideChange', function(){
        // element.style.zIndex = "0";
        scope.video.setZIndex();
      });

      scope.$watch('videoPaused', function(newVal){
        scope.$emit('videoPaused', newVal);
      });

      function getOnChange(element, options) {
        return function onChange(value) {
          $timeout(function(){ scope.videoPaused = value; }, 0);
        }
      }
    }

    return {
      restrict: 'E',
      replace: true,
      templateUrl: './directives/pages/video-player/video-player.html',
      scope: {
        src: '@?',
        mp4Src: '@?',
        webmSrc: '@?',
        ogvSrc: '@?',
        imgSrc: '@?',
        alt: '@?'
      },
      link: link
    }

  });
