var _ = require('lodash');

angular.module('koh').controller('SignupCtrl', ['$scope', 'helpers', 'Restangular', '$stateParams', '$http', '$rootScope', '$location', 'apiUrl', '$state', function ($scope, helpers, Restangular, $stateParams, $http, $rootScope, $location, apiUrl, $state) {

  helpers.toggleMenu($rootScope, false);
  $scope.$state = $state;
  $scope.user = {};
  $scope.step = 1;
  $scope.regex = /^.*$/;

  if ($rootScope.user) {
    $state.go('home');
  }

  if ($stateParams.token) {

    $scope.verificationToken = $stateParams.token;
    $http.get(apiUrl + '/api/confirm/' + $stateParams.token).then(function (res) {

      $scope.message = null;
      $scope.error = null;
      window.scrollTo(0, 0);

      if (res.data && res.data.success) {
        $scope.step = 3;
      }

    }, function (res, status, headers, config) {

      $scope.message = null;
      $scope.error = null;
      window.scrollTo(0, 0);

      if (res.data && res.data.error) {
        $scope.error = res.data.error;
      }

    });
  }

  $scope.doSignup = function () {

    if ($scope.user.currentPassword != $scope.user.confirmPassword) {
      $scope.message = 'Passwords do not match';
      return;
    }

    $scope.user.password = $scope.user.currentPassword;
    $http.post(apiUrl + '/api/signup', {
      'email': $scope.user.email,
      'password': $scope.user.password,
      'userObject': $scope.user
    }).success(function (data, status, headers, config) {

      $scope.error = null;
      $scope.message = null;

      if (data.success) {
        $scope.message = data.success;
      } else if (data.error) {
        $scope.error = data.error;
      }

      $scope.step = 2;
      window.scrollTo(0, 0);

    }).error(function (data, status, headers, config) {

      $scope.step = 1;
      window.scrollTo(0, 0);

      if (data && data.error) {
        $scope.error = data.error;
        $scope.message = null;
      } else {
        $scope.error = 'The server may be unreachable at this time. Please try again later.';
        $scope.message = null;
      }

    });
  };

  $scope.$watch('user.address.country', function (newVal, oldVal) {
    if ($scope.user.address.country != 'United States') {
      $scope.user.address.state = "";
      $scope.regex = /^[-_ a-zA-Z0-9]+$/;
    } else {
      $scope.regex = /^\d{5}(-\d{4})?$/;
    }
  });

}])

  .directive('compareTo', function () {
    return {
      require: "ngModel",
      scope: {
        otherModelValue: "=compareTo"
      },
      link: function (scope, element, attributes, model) {
        model.$validators.compareTo = function (modelValue) {
          return modelValue == scope.otherModelValue;
        };
        scope.$watch("otherModelValue", function () {
          model.$validate();
        });
      }
    };
  })

  .directive('onlyDigits', function () {
    return {
      restrict: 'A',
      require: '?ngModel',
      link: function (scope, element, attrs, ngModel) {
        if (!ngModel) return;
        ngModel.$parsers.unshift(function (inputValue) {
          var digits = inputValue.split('').filter(function (s) {
            return (!isNaN(s) && s != ' ');
          }).join('');
          ngModel.$viewValue = digits;
          ngModel.$render();
          return digits;
        });
      }
    };
  });


