angular.module('koh').directive('externalLink', [
    '$rootScope',
    function (
    $rootScope
    ) {
    var STYLE_FOLDER = "Styles";
    var IMG_FOLDER = "Images";

    var addStylesheetLink = function(attrs, resourcePath){
        var styleSheets = document.getElementsByTagName('link'),
            path = resourcePath + attrs.href;
        for (var i = 0; i < styleSheets.length; i++){
            if(styleSheets[i].href && styleSheets[i].href.endsWith(path)) return;
        }
        var fileref = document.createElement("link");
        fileref.setAttribute("rel", "stylesheet");
        fileref.setAttribute("type", "text/css");
        fileref.setAttribute("href", path);
        document.getElementsByTagName("head")[0].appendChild(fileref);
    };

    var addImageLink = function(element, attrs, resourcePath){
        var image = document.createElement("img");
        image.setAttribute("src", resourcePath + attrs.src);
        image.setAttribute("alt", attrs.alt);
        image.setAttribute("class", attrs.class);
        element.replaceWith(image);
    };

    var constructResourcePath = function($scope){
        var page = $scope.page;
        return '/data/' + page.folder + '/' + page.book;
    }

    var link = function ($scope, element, attrs) {
        if ($scope.page.folder == 'book') {
            $scope.page.folder = 'books';
        }
        var resourcePath = constructResourcePath($scope) || resourcePath;
        switch(attrs.type){
            case("stylesheet"): 
                addStylesheetLink(attrs, resourcePath);
                break;
            case("image"): 
                addImageLink(element, attrs, resourcePath); 
                break;
            default: break;
        }
    }
    return {
        restrict: 'E',
        link: link
    };
}]);
