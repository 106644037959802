'use strict';
var _ = require('lodash');
var helpers = require('./helpers');
var cheerio = require('cheerio');

angular.module('koh').factory('booksService',
  function(
  	$q,
    $timeout,
    $http,
    $location,
    $rootScope,
    $state,
    $compile,
    apiUrl,
    dataService,
    Restangular) {
  		// Localized scope
  		var $scope = null;
  		var $stateParams = null;
  		var userdataObject = null;
  		var booksObject = null;
  		var bookID = null;
  		return {
  			init: function(ctrlScope, ctrlStateParams, ctrlUserdataObject) {
  				$scope = ctrlScope;
  				$stateParams = ctrlStateParams;
          // console.log(helpers.tims() + 'Setting userdata object');
  				userdataObject = userdataObject || ctrlUserdataObject;
  				booksObject = booksObject;
  			},
  			setBookInformation: function(xml, folder, book){
					bookID = book;
					var xmlFrag = this.getXMLFragment(xml),
							toc = this.createTableOfContents(xmlFrag);

					$scope.bookInfo = {};
					$scope.bookInfo.bookTitle = this.getBookTitle(xmlFrag);
          $scope.bookInfo.bookType = this.getBookType(xmlFrag);
					$scope.bookInfo.pubDate = this.getPubDate(xmlFrag);

					$scope.chapterList = this.createChapterListing(xmlFrag, folder, book, $scope.bookInfo.bookTitle, toc);
  			},
  			getXMLFragment: function(xml){
  				var xmlDoc = null;
  				if (window.DOMParser)
				  {
				    var parser=new DOMParser();
				    xmlDoc=parser.parseFromString(xml,"text/xml");
				  }
				else // Internet Explorer
				  {
				    xmlDoc=new ActiveXObject("Microsoft.XMLDOM");
				    xmlDoc.async=false;
				    xmlDoc.loadXML(xml);
				  }
				  return xmlDoc;
  			},
  			getBookTitle: function(xmlFrag){
  				var titleArr = xmlFrag.querySelector('title');
  				return titleArr.textContent;
  			},
        getBookType: function(xmlFrag){
          var titleArr = xmlFrag.querySelector('title');
          return titleArr.getAttribute('type');
        },
  			getPubDate: function(xmlFrag){
  				var titleArr = xmlFrag.querySelector('document');
  				return titleArr.getAttribute('publication-date');
  			},
  			createTableOfContents: function(xmlFrag){
  				var tocArray = xmlFrag.querySelectorAll('table-of-contents > link'),
  						toc = [];
  				for(var i=0; i < tocArray.length; i++){
  					var link = {},
  							node = tocArray[i];
  					link.ref = node.getElementsByTagName('ref')[0].textContent;
  					link.title = node.textContent.replace(link.ref, '');
  					toc.push(link);
  				}
  				return toc;
  			},
  			createChapterListing: function(xmlFrag, folder, book, bookTitle, toc){
  				;
  				var pageArray = xmlFrag.querySelectorAll('page-break[type="begin"]');
  				var itemArray = xmlFrag.querySelectorAll('item');
  				var chapterList = [];
  				var itemList = [];
  				/*
  				for(var i=0; i < itemArray.length; i++){
  					var item = {};
  					item.id = itemArray[i].id;
  					item.originalPage = itemArray[i]['original-page-range'];
  					if (item.originalPage.indexOf('-')) {
  						var opArr = item.originalPage.split('-');
  						item.originalPage = opArr[0];
  					}
  					itemList.push(item);
  				}*/

  				var getOP = function(title) {
  					var retVal = -1;
  					for (var i = 0 ; i < itemArray.length ; i++) {
  						if (itemArray[i].id == title) {
  							var retVal = itemArray[i].attributes[0].nodeValue;
  							if (retVal.indexOf('-')) {
		  						var opArr = retVal.split('-');
		  						retVal = opArr[0];
		  					}
		  					break;
  						}
  					}
  					return retVal;
  				}
  				for(var i=0; i < pageArray.length; i++){
  					var chapter = {},
  							nodeAttr = pageArray[i].attributes;

  					chapter.fileName = bookID + '_' + helpers.pad(nodeAttr.number.value, 4);

  					chapter.align = nodeAttr.align ? nodeAttr.align.value : '';
  					chapter.title = nodeAttr.title ? nodeAttr.title.value : '';
  					chapter.originalPageStart = getOP(chapter.title)
  					chapter.folder = folder;
  					chapter.book = book;
  					chapter.bookTitle = bookTitle;
  					var closestItem = pageArray[i].closest('item');
  					if (closestItem) {
  						chapter.chapterTitle = this.getChapterTitle(toc, closestItem);
  					}
  					else {
  						if (pageArray[i].nextSibling.nodeName == 'item') {
  							chapter.chapterTitle = pageArray[i].nextSibling.querySelector('title').textContent;
  						}
  					}
  					chapterList.push(chapter);
  				}
          if (folder == 'periodicals' || folder == 'sentinels'){
            var chapter = {};
	var firstStart = chapterList[0].originalPageStart;
            chapter.originalPageStart = firstStart;
            chapter.fileName = bookID + '_0000';
            chapter.title = '0';
            chapter.folder = folder;
            chapter.book = book;
            chapter.bookTitle = bookTitle;
            chapter.chapterTitle = '0';
            chapterList.unshift(chapter);
          }
  				return chapterList;
  			},
  			getChapterTitle: function(toc, parentNode){
  				var firstPage = parentNode.querySelector('page-break[type="begin"]');
  				var title = firstPage.attributes.title ? firstPage.attributes.title.value : '';
  				var chapter = _.find(toc, { 'ref' : title });
  				return chapter ? chapter.title : '';
  			},
  			doSearch: function() {
  				;
  				$scope.activeSearch = $scope.textSearch;
  				var doc = cheerio.load($scope.originalCurrentChapterData);
  				;
  				var eleArray = doc('*');
  				_.each(eleArray, function(row) {
  					$scope.rcFindData(row, function(obj) {
  						var searchIndex = obj.data.toLowerCase().indexOf($scope.activeSearch.toLowerCase());
  						if (searchIndex>-1) {
	  						// console.log(helpers.tims() + 'Found text:' + obj.data);
							var reg = new RegExp($scope.activeSearch, 'gi');
							// Injecting tags in here will cause them to be replaced with ;lt etc
							var checkDupe = obj.data.substr(searchIndex+$scope.activeSearch.length, 9);
							// Try to avoid dupe markup
							if (checkDupe!='SEARCHEND') {
								obj.data = obj.data.replace(reg, 'SEARCHSTART' + $scope.activeSearch + 'SEARCHEND');
							}
						}
  					});
  				});
  				// console.log(helpers.tims() + 'Reconstructing');
  				var newData = doc.html();
  				var reg = new RegExp('SEARCHSTART', 'g');
					newData = newData.replace(reg, '<span class="activeSearch">');
					reg = new RegExp('SEARCHEND', 'g');
					newData = newData.replace(reg, '</span>');
  				$scope.currentChapterData = newData;
          // console.log(helpers.tims() + 'Calling match links');
  				this.booksService.matchLinks($scope.currentChapterData);
  			},
				printDiv: function(divName) {
					var printContents = document.getElementById('markedUpData').innerHTML;
					var popupWin = window.open('', '_blank', 'width=300,height=300');
					popupWin.document.open()
					popupWin.document.write('<html><head><link rel="stylesheet" type="text/css" href="style.css" /></head><body onload="window.print()">' + printContents + '</html>');
					popupWin.document.close();
				},
  			getLinks: function(isHBL) {
  				//return dataService.loadLinks($scope.currentBook);
				return new Promise(function(resolve, reject) {
					var fullBookTitle = helpers.fixSectionTitle(helpers.getFullBookInfo($rootScope.bookData, $scope.currentBook).sectionTitle);
					///console.log('getting links');
					$rootScope.getCacheValue('links', $scope.currentBook, function () {
						return dataService.loadLinks($scope.currentBook,isHBL);
					})
					.then(function(links) {
						//$rootScope.links = links
						//console.log('Resolving links');
						var thisPage = helpers.getPageFromPath(window.location.pathname, true);
						if (links) {
							//if (links.length > 1) {
								if (helpers.noMatchIncOrOut(links, thisPage)) {
		                            ;
		                            $rootScope.linksRetries =  $rootScope.linksRetries || 3;
		                            $rootScope.linksRetries--;
		                            if ($rootScope.linksRetries < 0) {
		                            	;
		                            	reject([]);
		                            } else {
		                            	$rootScope.removeCachedValue($scope.currentBook, 'links', function() {
			                            	this.getLinks($rootScope.isHBL).then(function(links) {
			                            		resolve(links);
			                            	});
			                            });
		                            }
		                          }
		                      //}
	                      }
						resolve(links);
					});
					//return dataService.loadLinks($scope.currentBook);
				});
			},
			getMyIds: function () {
				return new Promise(function(resolve, reject) {
					if ($scope.myIds) {
						resolve();
					}
					else {
						if (_.isUndefined($scope.currentBook)||$scope.currentChapter==null) {
							reject('State params not loaded');
						}
						else {
							$scope.myIds = [];
							this.getLinks($rootScope.isHBL).then(function() {
								_.each($scope.links, function(row) {
									if (row.section == $scope.currentChapter && row.book == $scope.currentBook) {
										$scope.myIds.push(row._id);
									}
								})
								resolve();
							});
						}
					}
				});
			},
			generateLinkObj: function (selection, type) {
				var linkObj = {}

				linkObj.selection = selection;
				if (!_.isUndefined($rootScope.user)&&$rootScope.user!=null) {
					linkObj.user_id = $rootScope.user._id;
				}
				else {
          linkObj.user_id = '556f16ab0779d03b67d69d16';
        }
				if (selection) {
					var startPage = $scope.chapterList[selection.startPageIndex];
          var endPage = $scope.chapterList[selection.endPageIndex];
          var startPageNum = parseInt(startPage.fileName.match(/\d+/));
          var endPageNum = startPage===endPage? startPageNum: parseInt(endPage.fileName.match(/\d+/));
					linkObj.book = startPage.book;
					linkObj.folder = startPage.folder;
					linkObj.section = startPage.fileName.replace(/-.*$/g, '');
          linkObj.selection.startPageNum = startPageNum;
          linkObj.selection.endPageNum = endPageNum;
				} else {
					linkObj.book = $rootScope.readingData.currentBook;
					linkObj.folder = $rootScope.readingData.currentFolder;
					linkObj.section = $rootScope.readingData.currentChapter;
				}
				linkObj.note = '';
				linkObj.type = type;
     		linkObj.title = '';

     		document.getElementsByTagName('IFRAME')[0].contentDocument.getElementsByClassName('cke_editable')[0].innerHTML = '';
				return linkObj;
			},
	    loadSelections : function(scopeArg) { // selectionsObj){
	;
			var selectionsObject;
			var innerScope;
			if (scopeArg) {
				if (scopeArg.$id) {
					innerScope = scopeArg;
				} else {
					selectionsObject = scopeArg;
					innerScope = $scope;
				}
			} else {
				innerScope = $scope;
			}

	     	return new Promise(function(resolve, reject) {
	      		// console.log(helpers.tims() + 'i have : ' + innerScope);
	      		// resolve();

            //force this to not cache - this was causing issues when changes were made in one browser, but not refreshed in a different browser
		        Restangular.all('selections').one($rootScope.user._id + "?" + Date.now()).getList().then(function(data) {
		          	var selections = {};
      					innerScope.selections = data;
      					innerScope.cachedSelections =_.clone(innerScope.selections, true);
      					selections.bookmarks = _.where(data, {'type':'bookmark'});
      					selections.highlights = _.where(data, {'type':'highlight'});
      					selections.notes = _.where(data, {'type':'note'});
      					selections.links = _.where(data, {'type':'link'});
      					innerScope.user.selections = selections;
      					innerScope.user.citations = _.where(data, {'type':'citation'});
      					$rootScope.user.selections = innerScope.user.selections;
      					$rootScope.user.citations  = innerScope.user.citations;
      					$rootScope.cachedCitations = _.clone(innerScope.user.citations, true);
		          resolve();
		        });
	      	});
	    },

  		syncRootScope: function() {
  				// console.log(helpers.tims() + 'Syncing root scope');
				var readingData  = {
					currentBook: 		$scope.currentBook,
					currentChapter: $scope.currentChapter,
					currentFolder:  $scope.currentFolder,
					currentIndex: 	$scope.currentIndex,
					bookList: 			$scope.bookList,
					bookInfo: 			$scope.bookInfo,
					chapterList: 		$scope.chapterList,
					getFile: 				$scope.getFile,
					changeFontSize: $scope.changeFontSize,
					saveFromBook: 	$scope.saveFromBook,
					printCurrentItem: $scope.printCurrentItem,
					printCurrentPage: $scope.printCurrentPage,
					printPeriodical: $scope.printPeriodical,
					printPeriodicalItem: $scope.printPeriodicalItem,
					printLesson: $scope.printLesson,
					setReadingMode: $scope.setReadingMode,
					showSaveFlyout: $scope.showSaveFlyout
				};
				;
				$rootScope.readingData = readingData;
				if( readingData.chapterList ) $rootScope.readingData.chapterListCopy =  _.clone(readingData.chapterList);

				// Extending the user object to expose an easy method of calling citation org shower
				$rootScope.user.showCitationOrganizer = helpers.showCitationOrganizer;
			},
  		unsetRootScope: function() {
				$rootScope.readingData = null;
			},
			dashoutHtml: function(html) {

			},
			applyNoteMarkup: function(note, html) {
				// console.log(helpers.tims() + 'applying note markup');
        if (note.selection.length>0) {
  				var i = html.indexOf(note.selection[0].markup);
  				var output = html;
  				// Support new schema for arrays
  				_.each(note.selection, function(row) {
            if (row.markup.length<=1) {
              // console.log(helpers.tims() + 'Note cannot render!');
              var spanFind = html.substr(0, html.indexOf(row.startSpanId)).lastIndexOf('<span');
              var startingText = html.substr(spanFind);
              var endTextLoc = startingText.indexOf('>', startingText.indexOf(row.endSpanId));
              row.markup = startingText.substr(0, endTextLoc+1);
            }
  					var realStart = html.indexOf('>', html.indexOf(row.markup)+1)+1;
  					 output = output.substr(0, realStart) +
  						'<note-markup noteId="' + note._id + '"></note-markup>' +
  						output.substr(realStart);
  				})
        }
				return output;
			},
			matchLinks: function(newHtml, chapterDiv) {
				// console.log(helpers.tims() + 'Match links')

				var originalHtml = newHtml;
				var newBuiltHtml = originalHtml;
				var div = chapterDiv || angular.element(document.getElementById('pageContents'));

				this.getLinks($rootScope.isHBL).then(function(response) {
					_.each($scope.links, function(row) {
						if (row.book == $scope.currentBook) {
							if (row.section == $scope.currentChapter) {
								var replaceHtml = originalHtml.substr(row.selection.start, row.selection.length);
								var markedUpHtml = '<a class="linkText" ng-click="clickLink(\'' + row._id + '\');">' + replaceHtml + '</a>';
								newBuiltHtml = newBuiltHtml.replace(replaceHtml, markedUpHtml);
							}
						}
					});
					newBuiltHtml = newBuiltHtml.replace(/\uFEFF/, '');
					if (newBuiltHtml.indexOf('<body')>=0) {
						newHtml = newBuiltHtml.substr(newBuiltHtml.indexOf('<body'));
						newBuiltHtml = newHtml;
					}


					// console.log(helpers.tims() + 'Iterating notes');
          // console.log(helpers.tims() + 'Setting new html...');
          // $rootScope.updateDirectivePage('lol');//newBuiltHtml);

			    }, function(error) {
			    	// console.log(helpers.tims() + 'Got error: ' + error);
			    });
			}
  		}; // End return
}); // End Service
