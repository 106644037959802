angular.module('koh').directive('citationLink', [
    '$rootScope', 
    function ($rootScope) {

    var link = function ($scope, element, attrs) {
        //console.log('in link for citation-link');
        if (!attrs.citationLink.length){
            // default to first page
            element.attr('href', $scope.$parent.firstCitation);
        }

        var lessonDate = $scope.$parent.lessonDate;
        var citationList = $scope.$parent.citationList;
        var citation = attrs.citationLink;
        var citationObj = _.find(citationList, { 'citation' : citation });
        if(citationObj){
            var filename = citationObj.section;
            var path = '/reader/lessons/' + lessonDate + '/' + filename;
            element.attr('href', path);
        }
    };


    return {
        restrict: 'A',
        link: link
    };
}]);

angular.module('koh').directive('periodicalLink', [
    '$rootScope',
    '$state',
    function ($rootScope, $state) {

    var link = function ($scope, element, attrs) {
        var url = 'reader/' + $state.params.folder+ '/' +$state.params.book + '/' +attrs.periodicalLink;
        element.attr('href', url);
    };


    return {
        restrict: 'A',
        link: link
    };
}]);