// Note, when naming an angular directive, convert objects separated with dashes into camel case
// This is required!
angular.module('koh').directive( 'matchHeight', [ '$window', function(  $window ) {
  return {
    restrict: 'A',
    link: function( scope, elem, attrs ) {
      var win = angular.element($window);
      var diff = attrs['matchHeight'];
      function setSize(diff) {
      	  var newHeight = $window.innerHeight - diff;
	      elem.attr( 'style', 'min-height: ' + (newHeight) + 'px' );	      
	  };
  
      win.bind("resize",function(e) {
      	setSize(diff);
      });

      setSize(diff);
    }
  }
}]);