//var _ = require('lodash');

angular.module('koh').controller('HomeCtrl', 
		['$scope', 
		'helpers', 
		'Restangular', 
		'$rootScope',
		'$sce',
		'dataService',
		function($scope, 
			helpers, 
			Restangular,
			$rootScope,
			$sce,
			dataService) {
				$scope.errorMessage = null;
				// helpers.toggleMenu($rootScope, false);
				$rootScope.toggleObject = {
					menu : false,
					search: false
				};
        
				$rootScope.forceSearch = true;
				/// Totall faked data!
  				$scope.currentSubject = 'Life';
  				$scope.currentDate = 'January 1, 1905';
  				$scope.currentSource = 'Christian Science Bible Lessons';

  				// Precache commonly used caches
  				///////////////////////////////////////////////////////////////////////////
  				
          helpers.logMessage('Precaching data',$rootScope, 'HomeCtrl',1);
  				$rootScope.getCacheValue('searchmodel', 'all', dataService.getSearchModel).then(function(data) {
            helpers.logMessage('Search model cached',$rootScope, 'HomeCtrl',1);
  				});

  				

  				/*
          $rootScope.getCacheValue('searchmodel', 'all', dataService.getSearchModel).then(function(data) {
            helpers.logMessage('Precaching data',$rootScope, 'HomeCtrl',1);
          });
          
          helpers.logMessage('Starting genesis precache',$rootScope, 'HomeCtrl',1);
  				$rootScope.getCacheValue('Gen', 'bookxml',  function() { return dataService.fetchBookXml('bible','Gen')}).then(function(data) {
  					helpers.logMessage('Bible precached',$rootScope, 'HomeCtrl',1);
  				});

        helpers.logMessage('Starting SH precache',$rootScope, 'HomeCtrl',1);
  				$rootScope.getCacheValue('SH', 'bookxml',  function() { return dataService.fetchBookXml('books','SH')}).then(function(data) {
  					helpers.logMessage('SH Precached',$rootScope, 'HomeCtrl',1);
  				});
          /*
  				$rootScope.getCacheValue('links', 'Gen', function () { return dataService.loadLinks('Gen'); }).then(function(links) {
  					helpers.logMessage('Gen Links precached',$rootScope, 'HomeCtrl',1);
  				});

  				$rootScope.getCacheValue('links', 'SH', function () { return dataService.loadLinks('SH'); }).then(function(links) {
  					helpers.logMessage('Precaching data',$rootScope, 'HomeCtrl',1);
  				});*/
  				/*  				

  				$rootScope.getCacheValue('SH', 'bookxml', dataService.getSearchModel).then(function(data) {
  					console.log(helpers.tims() + '[HomeCtrl] book xml for Bible Gen cached');
  				});

  				$rootScope.getCacheValue('links', 'Gen', loadLinks).then(function(links) {
  					console.log(helpers.tims() + '[HomeCtrl] Search model for Bible GEN cached');
  				});
  				$rootScope.getCacheValue('links', 'SH', loadLinks).then(function(links) {
  					console.log(helpers.tims() + '[HomeCtrl] Search model for SH cached');
  				});
				*/

  				/*
  				$rootScope.getCacheValue('searchmodel', 'all', getSearchModel).then(function(data) {
  					console.log(helpers.tims() + '[HomeCtrl] Search model cached');
  				});
				*/
  				///////////////////////////////////////////////////////////////////////////
  				/*helpers.getDocumentText(Restangular, 'angular','ch01').then(function(resolveData) {
  					$scope.currentText = $sce.trustAsHtml(resolveData.chapter);
  				});
				*/

				$scope.errorClass = function() {
					var hideDiv = { 'display' : 'none '};
					return ($scope.errorMessage ? {} : hideDiv);
				}

				$scope.$on("$destroy", function(){
					$rootScope.forceSearch = false;
			    });

}]);


