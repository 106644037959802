var cheerio = require('cheerio');

angular.module('koh').controller('LessonsCtrl',
    ['$scope',
    'helpers',
    'Restangular',
    '$stateParams',
    '$compile',
    '$rootScope',
    '$sce',
    '$location',
    'apiUrl',
    '$timeout',
    'booksService',
    'selectionService',
    'lessonObj',
    'playlistObj',
    'selectionsObj',
    'tocObj',
    'documentSearchFactory',
    function(
      $scope,
      helpers,
      Restangular,
      $stateParams,
      $compile,
      $rootScope,
      $sce,
      $location,
      apiUrl,
      $timeout,
      booksService,
      selectionService,
      lessonObj,
      playlistObj,
      selectionsObj,
      tocObj,
      documentSearchFactory
      ) {

        $rootScope.isHBL = true;
        $scope.apiUrl = apiUrl;
        documentSearchFactory.isSearching = false;

        var selectionsObject = Restangular.all('selections');
        var userdataObject = selectionsObject.one($scope.user._id);
        var filesObject = Restangular.all('files');

        $scope.booksService = booksService;
        $scope.booksService.init($scope, $stateParams, userdataObject);

        $scope.selectionService = booksService;
        $rootScope.lessonContents = [];

        if($rootScope.readingMode == 'dual-page'){
          var mode = $rootScope.user.readingMode == 'dual-page' ? '' : $rootScope.user.readingMode;
          $scope.$parent.setReadingMode(mode);
        }
        // $scope.$on('$destroy', function(){
        //   $scope.$parent.setReadingMode($rootScope.user.readingMode);
        // });

        var lessonsObject = Restangular.all('lessons');
        var playlistObject = Restangular.all('playlist');
        $scope.lessons = [];

        $scope.path = $location.url();

        $scope.printLessonItem = function() {
          ;
          $scope.lessonObj = lessonObj[0];
          $scope.lessonID = $scope.lessonObj._id;
          $scope.playlistObj = playlistObj.data;
          //console.log('lesson:', $scope.lessonObj);
          var url = $rootScope.apiUrl + '/api/lessons/print/' + $scope.lessonObj.filename + '.pdf';
           //// //console.log('Full url: '+ url + ' and scope url ' + $scope.);
          window.open(url);
        }

        var loadSearchResults = function(){
          $scope.loading = true;
          $scope.toggleObject.search = false;
          lessonsObject.all('search').post($scope.query).then(function(data){
            $scope.lessons = data;
            _.forEach($scope.lessons, function(lesson){
              var newDate = new Date();
              var cleanDate = lesson.publicationDate.replace(/"/g,'') +  ' 00:00:00'
              cleanDate = cleanDate.replace(' ', 'T')
              var testDate = new Date(cleanDate);
              lesson.dateStr = helpers.parseDate(testDate);
              lesson.titleStr = lesson.title.substr(0, lesson.title.indexOf('-')).trim()
              lesson.viewPath = '/lessons/' + lesson.filename;
            })
            $scope.loading = false;
          });
        }

        var loadLessonPlaylist = function(){
          booksService.unsetRootScope();
          ;
          $scope.currentIndex = null;

          $scope.lessonObj = lessonObj[0];
          $scope.lessonID = $scope.lessonObj._id;

          $scope.playlistObj = playlistObj.data;

          $scope.chapterList = generateChapterList($scope.playlistObj);
          ;
          $scope.citationList = generateCitationsList($scope.playlistObj);

          $rootScope.lessonContents = createTableOfContents(tocObj, $scope.lessonObj.filename, $scope.chapterList)

          setCitationMarkers($scope.citationList, $scope.lessonObj);

          $scope.booksService.loadSelections(selectionsObj).then(function(){
            $rootScope.hbl = {};
            $rootScope.hbl.citations = $scope.citationList;
            $rootScope.$broadcast('invalidateRenderedPage');
          });

          $scope.bookInfo = {
            bookTitle: $scope.lessonObj.title,
            bookType: 'bible_lessons'
          };

          if ($scope.currentChapter && !$scope.currentIndex){
            var index = helpers.findChapterIndex($scope.chapterList, $scope.currentChapter);
            if (index > -1){
              $scope.currentIndex = index;
              $rootScope.$broadcast('bookLoaded', {currentIndex:  $scope.currentIndex});
            }
          }

          $scope.firstCitation = setFirstCitation($scope.lessonDate, $scope.chapterList);
          $scope.booksService.syncRootScope($scope);
        }

        var checkDupe = function(arr, item) {
          var bFound = false;
          for (var i = 0 ; i < arr.length ; i++) {
            if (JSON.stringify(arr[i]) === JSON.stringify(item) ) {
              bFound = true;
              return true;
            }
          }
          return bFound;
        }

        var generateChapterList = function(data) {
          var chapterList = [];
          // TODO:  ChapterList should be an object array
          _.each(data, function(row, i) {
            if (row.file) {

              var folder = row.file.folder;
              if (folder) {
                var title = row.citationLink;
                _.each(row.file.ranges, function(rangerow) {
                  _.each(rangerow.files, function(filesrow) {
                    folderArr = folder.replace('/Html/', '').split('/');
                    var item = {};
                    item.fileName = filesrow.replace('.html', '') + '-' + i;
                    item.align = '';
                    item.title = title;
                    item.book = folderArr.pop();
                    item.folder = folderArr.pop();
                    if (!checkDupe(chapterList, item)) {
                      chapterList.push(item);
                    }
                    else {
                      //console.log('Found dupe: ' + item);
                    }
                  });
                });
              }
              else {
                ;
              }
            }
          });
          return chapterList;
        }

        var generateCitationsList = function(data) {
          var citationList = [];
          // TODO:  ChapterList should be an object array
          _.each(data, function(row, i) {
            if (row.file) {
              if (row.file.error) return;
              var citationLink = row.citationLink;
              var citation = row.citation,
                  ranges = row.file.ranges;
              if(row.file.data) ranges = row.file.data.ranges || [];
              _.each(ranges, function(rangerow) {
                _.each(rangerow.files, function(file){
                  var selection = {},
                      selObj = _.clone(rangerow),
                      section = file.replace('.html', '') + '-' + i;
                  selection.citation = citation;
                  selection.citationLink = citationLink;
                  selection.section = section;
                  selection.type = 'citation';

                  selection.selection = new Array(selObj);
                  citationList.push(selection);

                });
              });
            }
          });
          return citationList;
        }

        var generateTableOfContents = function(current){
          return new Promise(function(resolve, reject) {
            var endDate  = helpers.getLessonDate( current, 14 );
            var startDate  = helpers.getLessonDate( current, -14 );
            var query = { 'originalDate': { '$gte': startDate, '$lte':  endDate} };
            playlistObject.all('search').post(query).then(function(data){
              resolve(createTableOfContents(data.plain()));
            }, function(err){
              reject(err);
            });
          });
        }

        var createTableOfContents = function(lessonArray, cur, chapterList){
          var toc = [];

          _.each(lessonArray, function(data){
            var lesson = {}
            lesson.title = data.title;
            lesson.filename = data.filename;
            lesson.id = data.filename;
            lesson.contents = [];

            if (lesson.filename == cur){
              lesson.contents = _.uniq(chapterList, 'title');;
            }
            toc.push(lesson);
          });
          return toc;
        }

        var setCitationMarkers = function(citationList, lessonObj){
          var lastCitations = [];
          _.each(lessonObj.lessons, function(section) {
            var citations = section.citations,
                bibleEnd = null,
                shEnd = null;

            /* walk backwards through citations to get last bible/sh citations */
            for (var i = citations.length -1; i >= 0; i--){
              if (!shEnd) shEnd = citations[i].sh;
              if (!bibleEnd) bibleEnd = citations[i].bible;
            }
            lastCitations.push(shEnd);
            lastCitations.push(bibleEnd);
          });

          /* add appropriate markers to all lesson/section end citations */
          _.each(lastCitations, function(citation, i) {
            var citationObj = _.findLast(citationList, { 'citation' : citation });
            if(!_.isUndefined(citationObj)){
              if (i >= (lastCitations.length - 2) ){
                citationObj.selection[0].specialMarkings = 'end-lesson';
              } else {
                citationObj.selection[0].specialMarkings = 'end-section';
              }
            }
          });

          /* add in markers for citations continued on more than one page */
          var curCitation = '',
              curSection = '',
              curSpanIDs = '';

          _.each(citationList, function(citation, i){
            var selectionObj = citation.selection[0];
            if (curCitation != citation.citation){
              curCitation = citation.citation;
              curSection = citation.section;
              curSpanIDs = selectionObj.startSpanId + selectionObj.endSpanId;
            } else if( curSection != citation.section  && curSpanIDs != (selectionObj.startSpanId + selectionObj.endSpanId) ){
              citationList[i - 1].selection[0].specialMarkings = 'continue-citation';
              curSection = citation.section;
              curSpanIDs = (selectionObj.startSpanId + selectionObj.endSpanId)
            }
          });
        }

        var setFirstCitation = function(lessonDate, chapterList){
          if (chapterList.length > 0){
            var filename = chapterList[0].fileName;
            return '/reader/lessons/' + lessonDate + '/' + filename;
          }
        }

        $scope.getExitPath = function(){
          var chapter = $scope.currentChapter.replace(/^_|-.*$/g,'');
          return '/reader/' + $scope.currentFolder + '/' + $scope.currentBook + '/' + chapter;
        }

        $rootScope.lessonDate = $scope.lessonDate = $stateParams.lesson;

        if ($scope.lessonDate) {
          loadLessonPlaylist();
        } else if ($stateParams.dateStart && $stateParams.dateEnd){
          $scope.query = $stateParams;
          loadSearchResults();
        } else {
          $scope.promoContent = null;
          var folder = 'lesson-landing';

          Restangular.one('content', folder).get().then(function(data) {
            $scope.promoContent = $sce.trustAsHtml(data);
          }, function(response) {
              ;
          });
        }

        // Make sure selections get cleared when exiting lesson
        $scope.$on('$destroy', function(){
          $rootScope.user.selections = [];
          $rootScope.user.citations  = [];
          $rootScope.cachedCitations = [];
        });
      }
    ]
);
